import styled from '@emotion/styled'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { COLORS, THEMES } from 'styling/variables'

function Pagination({
  id,
  exercises,
  hasTimeEnded,
  isCompetitionSubmited,
  theme,
  listView,
  hasModules,
}) {
  const routeHistory = useHistory()
  const { childId, moduleIndex, exerciseIndex } = useParams()
  const savedAnswers = JSON.parse(localStorage.getItem('structure_ans'))
  const [answerdQuestions, setAnswerdQuestions] = useState([])
  const [visibleElements, setVisibleElements] = useState([0])

  useEffect(() => {
    //scrolls to the element when transitioning from submiting the module and clicking on going back to the current module
    if (
      +exerciseIndex + 1 >= exercises.length &&
      !(hasTimeEnded || isCompetitionSubmited)
    ) {
      scrollToEx(listView, exerciseIndex)
    }

    answerdQuestionsHandler(savedAnswers)
  }, [exerciseIndex])

  useEffect(() => {
    if (listView && !(hasTimeEnded || isCompetitionSubmited)) {
      if (
        !(
          +exerciseIndex + 1 >= exercises.length &&
          !(hasTimeEnded || isCompetitionSubmited)
        )
      )
        scrollToEx(listView, exerciseIndex)
    }

    listView && document.addEventListener('scroll', scrollingHandler)

    return () => {
      document.removeEventListener('scroll', scrollingHandler)
    }
  }, [listView])

  const answerdQuestionsHandler = (savedAnswers) => {
    let answerd = []

    exercises.forEach((ex, index) => {
      const value = hasModules
        ? savedAnswers[moduleIndex].exercises[index][`id_${ex.id}`]
        : savedAnswers[index][`id_${ex.id}`]

      if (typeof value === 'object') {
        if (Object.values(value).length) answerd.push(ex.id.toString())
      } else {
        if (value) answerd.push(ex.id.toString())
      }
    })

    setAnswerdQuestions(answerd)
  }

  const scrollToEx = (listView, exId) => {
    const el = document.getElementById(`${exId}`)
    if (listView && !(+exId >= exercises?.length) && el) {
      const isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === '[object SafariRemoteNotification]'
        })(
          !window['safari'] ||
            (typeof safari !== 'undefined' &&
              window['safari'].pushNotification),
        )

      if (isSafari) {
        el.scrollIntoViewIfNeeded(true)
      } else {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        })
      }
    }
  }

  const whichElementsAreVisible = (el) => {
    let rect = el.getBoundingClientRect()

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  const scrollingHandler = debounce((e) => {
    const exercises = document.querySelectorAll('.exercise')
    const elInView = []

    exercises.forEach((ex) => {
      if (whichElementsAreVisible(ex)) {
        elInView.push(ex.id)

        urlHandlerOnScroll(ex.id, e)
      }
    })

    setVisibleElements(elInView)
  }, 200)

  const urlHandlerOnScroll = debounce((exId, e) => {
    const scrollingData = document.querySelector('html')

    if (scrollingData.scrollTop === 0) {
      routeHistory.replace(`/hub/competitions/${childId}/${moduleIndex}/${0}`)
    } else {
      routeHistory.replace(
        `/hub/competitions/${childId}/${moduleIndex}/${exId}`,
      )
    }
  }, 200)

  const onButtonClickHandler = (listView, exId) => {
    routeHistory.replace(`/hub/competitions/${childId}/${moduleIndex}/${exId}`)

    if (listView) {
      scrollToEx(listView, exId)
    }
  }

  return (
    <ExcButtonWrapper id={id}>
      {exercises.map((exercise, index) => {
        let isInView = false
        let isAnswerd = 'empty'

        if (listView) {
          isInView = visibleElements?.some((elIndex) => {
            return elIndex == index
          })
        } else {
          isInView = +exerciseIndex === index
        }

        if (
          answerdQuestions.some((ans) => +ans === exercise.id) ||
          exercise.mediaFiles.length
        ) {
          isAnswerd = 'populated'
        }

        return (
          <ExcButton
            id={isAnswerd}
            type="button"
            key={index}
            theme={theme}
            className={isInView ? 'active' : null}
            onClick={() => {
              onButtonClickHandler(listView, index)
            }}
          >
            {index + 1}
          </ExcButton>
        )
      })}
    </ExcButtonWrapper>
  )
}

export { Pagination }

const ExcButtonWrapper = styled('div')(({ customCss, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 5,
  marginBottom: 20,
  marginTop: 20,
  justifyContent: 'center',

  ...customCss,
}))

const ExcButton = styled('button')(({ customCss, theme }) => ({
  display: 'grid',
  placeItems: 'center',
  padding: 5,
  width: 40,
  height: 40,

  border: `1px solid ${THEMES[+theme]}`,
  color: THEMES[+theme],
  fontSize: 22,
  backgroundColor: '#ffff',

  ':hover': {
    cursor: 'pointer',
    backgroundColor: THEMES[+theme],
    color: '#ffff',
  },

  '&.active': {
    backgroundColor: `${THEMES[+theme]} !important`,
    color: '#ffff !important ',
  },

  '&#populated': {
    background: `${THEMES[+theme]}14 0% 0% no-repeat `,
    color: THEMES[+theme],

    ':hover': {
      cursor: 'pointer',
      backgroundColor: THEMES[+theme],
      color: '#ffff',
    },
  },

  // '&#empty': {
  //   backgroundColor: COLORS.secRed,
  //   color: '#ffff',
  // },

  ...customCss,
}))
