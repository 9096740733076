import styled from '@emotion/styled'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInputMessage } from 'shared/elements/FormInputMessage'
import { COLORS } from 'styling/variables'

export const FormSelect = styled(
  ({
    id,
    description,
    label,
    name,
    control,
    error,
    render,
    rules,
    defaultValue,
    className,
    translationKeys,
  }) => {
    const { t } = useTranslation(['shared'])

    return (
      <label id={id} className={className}>
        {t(label)}
        <br />

        {description && <span>{t(description, { ...translationKeys })}</span>}
        {control && (
          <Controller
            rules={rules}
            control={control}
            className="select"
            name={name}
            rules={rules}
            render={render}
            defaultValue={defaultValue}
          />
        )}
        {error && (
          <FormInputMessage className="input-error">
            <i class='fas fa-exclamation-triangle'></i> {' '}
            {t(`errors:${error.message}`)}
          </FormInputMessage>
        )}
      </label>
    )
  },
)(({ customCss }) => ({
  color: COLORS.mainGray,
  fontSize: 16,

  transition: '0.3s all',
  marginBottom: 30,
  display: 'inline-block',
  position: 'relative',
  width: '100%',

  '&:hover': {
    color: COLORS.primBlue,
    '& .css-yk16xz-control ': {
      borderColor: COLORS.primBlue,
    },
  },

  '&:focus-within': {
    color: COLORS.secBlue,
  },
  '& .select-error': {
    '& .css-yk16xz-control': {
      borderColor: COLORS.secRed,
    },
  },
  '& span': {
    margin: 0,
    color: COLORS.mainGray,
    fontSize: 14,
    fontWeight: 'normal',
  },

  '& .css-2b097c-container': {
    '& .css-26l3qy-menu': {
      zIndex: '5',
    },
  },

  '& .css-1pahdxg-control, .css-1fhf3k1-control': {
    marginTop: 5,
    color: COLORS.secBlue,
    borderRadius: 5,
    height: 45,
    width: '100%',
    boxSizing: 'border-box',
    fontSize: 16,
    fontWeight: 'normal',
    transition: '0.3s all',

    '.css-g1d714-ValueContainer': {
      flexWrap: 'nowrap',
      '.css-1rhbuit-multiValue': {
        borderRadius: '5px',
      },
    },

    '&.css-g1d714-ValueContainer, .css-1uccc91-singleValue': {
      flexWrap: 'nowrap',
      color: COLORS.secBlue,
      fontWeight: 'normal',
    },

    '&:focus': {
      borderColor: COLORS.secBlue,
    },
    '&.input-error': {
      borderColor: COLORS.secRed,
    },
    '&.input-success': {
      borderColor: COLORS.secGreen,
    },
  },

  '& .css-yk16xz-control ': {
    marginTop: 5,
    border: `2px solid ${COLORS.mainGray}`,
    color: COLORS.secBlue,
    borderRadius: 5,
    height: 45,
    width: '100%',
    boxSizing: 'border-box',
    fontSize: 16,
    fontWeight: 'normal',
    transition: '0.3s all',
    '.css-g1d714-ValueContainer': {
      flexWrap: 'nowrap',

      '.css-1rhbuit-multiValue': {
        borderRadius: '5px',
      },
    },

    '&.css-g1d714-ValueContainer, .css-1uccc91-singleValue': {
      flexWrap: 'nowrap',
      color: COLORS.secBlue,
      fontWeight: 'normal',
      top: '52%',
    },

    '&:hover': {
      borderColor: COLORS.primBlue,
    },

    '&:focus': {
      borderColor: COLORS.secBlue,
    },
    '&.input-error': {
      borderColor: COLORS.secRed,
    },
    '&.input-success': {
      borderColor: COLORS.secGreen,
    },
  },
  ...customCss,
}))
