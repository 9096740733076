import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { CONSTANTS, EscButton, PageInfo, TitleIconWrapper } from 'shared'
import { DeclarationModal } from '../components/kyoso-orders/DeclarationModal'
import { InvoiceModal } from '../components/kyoso-orders/InvoiceModal'
import { OrderModal } from '../components/kyoso-orders/OrderModal'
import { FiltrationForm } from '../components/kyoso-orders/FiltrationForm'
import { OrdersAccordion } from '../components/kyoso-orders/OrdersAccordion'
import {
  getParticipationsForChildWithFilter,
  saveInvalidateDeclaration,
  saveResetValidatedDeclaration,
  saveValidateDeclaration,
  updateParticipation,
} from '../services/kyoso/competitions'
import { KyosoLayout } from '../shared'
import {
  lab_hideGlobalLoading,
  lab_showGlobalLoading,
} from '../store/generalSlice'
import { lab_selectUser } from '../store/userSlice'

function KyosoOrdersPage() {
  const routeHistory = useHistory()
  const reduxUser = useSelector(lab_selectUser)
  const [showEditOrderModal, setShowEditOrderModal] = useState(null)
  const [showDeclarationModal, setShowDeclarationModal] = useState(null)
  const [showInvoiceModal, setShowInvoiceModal] = useState(null)
  const [ordersData, setOrdersData] = useState(null)
  const [orderFilters, setOrderFilters] = useState(null)
  const [totalOrders, setTotalOrders] = useState(null)
  const [isReset, setIsReset] = useState(false)
  const { childId } = useParams()
  const routeLocation = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    getOrdersDataHandler()
  }, [orderFilters])

  const getOrdersDataHandler = () => {
    if (orderFilters) {
      dispatch(lab_showGlobalLoading())
      getParticipationsForChildWithFilter(childId, {
        searchText: orderFilters?.search || '_omit_',
        startDate: orderFilters?.start_date || '_omit_',
        endDate: orderFilters?.end_date || '_omit_',
        paymentMethod: orderFilters?.payment_type.value || '_omit_',
        isPaid: orderFilters?.is_paid,
        isUnpaid: orderFilters?.is_not_paid,
        isInvited: orderFilters?.is_invited,
        isAutoSignedUp: orderFilters?.is_auto_signedup,
        declaration: orderFilters?.declaration,
      })
        .then((res) => {
          setOrdersData(res.participations)
          setTotalOrders(res.total_participations)
        })
        .catch((err) => console.log(err))
        .finally(() => dispatch(lab_hideGlobalLoading()))
    } else if (isReset) {
      dispatch(lab_showGlobalLoading())
      getParticipationsForChildWithFilter(childId)
        .then((res) => {
          setOrdersData(res.participations)
          setTotalOrders(res.total_participations)
        })
        .catch((err) => console.log(err))
        .finally(() => dispatch(lab_hideGlobalLoading()))
    }
  }

  const openModal = (orderId, modalType) => {
    const { EDIT_MODAL, DECLARATION_MODAL, INVOICE_MODAL } = CONSTANTS.modals
    const [userOrderData] = ordersData.filter((ord) => ord.order_id === orderId)

    switch (modalType) {
      case EDIT_MODAL:
        setShowEditOrderModal(userOrderData)
        break
      case DECLARATION_MODAL:
        setShowDeclarationModal({
          competition_name: routeLocation.state.name,
          owner: reduxUser.username,
          ...userOrderData,
        })
        break
      case INVOICE_MODAL:
        setShowInvoiceModal(userOrderData)
        break

      default:
        console.log(modalType)
    }
  }

  const filterCallbackHandler = (filters, isReset = false) => {
    setIsReset(isReset)
    setOrderFilters(filters)
  }

  const saveEditOrderModal = (
    orderId,
    isDataTouched,
    newUserData,
    paymentType,
    isPaid,
  ) => {
    updateParticipation(orderId, {
      newUserData: isDataTouched ? JSON.stringify(newUserData) : '_omit_',
      paymentMethod: paymentType,
      isPaid: isPaid,
    })
      .then((res) => getOrdersDataHandler())
      .catch((err) => console.log(err))
      .finally(() => setShowEditOrderModal(null))
  }

  const closeEditOrderModal = (e) => {
    setShowEditOrderModal(null)
  }

  const closeEditDeclarationModal = () => {
    setShowDeclarationModal(null)
  }

  const closeEditInvoiceModal = () => {
    setShowInvoiceModal(null)
  }

  const saveModalHandler = (declarationId, action) => {
    const { declaration_status } = CONSTANTS
    console.log(action, declarationId)

    switch (action) {
      case declaration_status.ANNULED:
        saveInvalidateDeclaration(declarationId)
          .then((res) => getOrdersDataHandler())
          .catch((err) => console.log(err))
          .finally(() => closeEditDeclarationModal())
        break

      case declaration_status.IS_VALID:
        saveValidateDeclaration(declarationId)
          .then((res) => getOrdersDataHandler())
          .catch((err) => console.log(err))
          .finally(() => closeEditDeclarationModal())

        break

      case declaration_status.RESET:
        saveResetValidatedDeclaration(declarationId)
          .then((res) => getOrdersDataHandler())
          .catch((err) => console.log(err))
          .finally(() => closeEditDeclarationModal())
        break
      default:
        break
    }
  }
  const saveInvoiceModal = () => {}

  return (
    <>
      {showEditOrderModal && (
        <OrderModal
          modalData={showEditOrderModal}
          closeModalCallback={closeEditOrderModal}
          saveModalCallback={saveEditOrderModal}
        />
      )}

      {showDeclarationModal && (
        <DeclarationModal
          modalData={showDeclarationModal}
          closeModalCallback={closeEditDeclarationModal}
          saveModalCallback={saveModalHandler}
        />
      )}

      {showInvoiceModal && (
        <InvoiceModal
          modalData={showInvoiceModal}
          closeModalCallback={closeEditInvoiceModal}
          saveModalCallback={saveInvoiceModal}
        />
      )}

      <KyosoLayout>
        <TitleIconWrapper>
          <i className="fas fa-users"></i>

          <PageInfo
            title={t('lab:KyosoOrders:KyosoOrdersTitle', {
              child_id: routeLocation.state?.id,
              parent_name: routeLocation.state?.name,
              child_grade: routeLocation.state?.grade_name,
            })}
          />
        </TitleIconWrapper>

        <EscButton onClick={() => routeHistory.goBack()} />

        <FiltrationForm
          submitCallback={filterCallbackHandler}
          totalOrders={totalOrders}
        />

        {ordersData && (
          <OrdersAccordion
            orders={ordersData}
            openModalCallback={openModal}
            changePaidStatusCallback={(orderId, isPaid, paymentType) => {
              saveEditOrderModal(orderId, false, null, paymentType, isPaid)
            }}
          />
        )}
      </KyosoLayout>
    </>
  )
}

export { KyosoOrdersPage }
