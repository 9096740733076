import styled from '@emotion/styled'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import NoImgPlaceholder from 'assets/img/no_img_placeholder.png'
import { ImageWrapper, PageInfo, TitleIconWrapper } from 'shared'
import { COLORS, QUERIES, THEMES } from 'styling/variables'
import {
  formatDateAndTime,
  getFileLinkFromServerStorage,
} from 'utils/helpers/common'
import { CompetitionFilterForm } from '../components/competitions/CompetitionFilterForm'
import { CompetitionsContainer, GenericButton } from '../shared'
import HTMLReactParser from 'html-react-parser'

import { useDispatch, useSelector } from 'react-redux'
import { hub_selectTheme, hub_setPage } from '../store/generalSlice'
import { InfoButton } from '../components/competitions/InfoButton'
import { filterCompetitions } from '../services/competition'
import { useHistory } from 'react-router'

function CompetitionsSeachPage() {
  const dispatch = useDispatch()
  const routeHistory = useHistory()
  const currentTheme = useSelector(hub_selectTheme)
  const { t } = useTranslation()
  const [filters, setFilters] = useState({})
  const [competitions, setCompetitions] = useState([])

  const filtrationCallback = (filters) => {
    setFilters(filters || {})
  }

  useEffect(() => {
    dispatch(
      hub_setPage({ title: t('hub:Competitions:CompetitionsSearchTitle') }),
    )

    filterCompetitions({
      name: filters.competition_name || '_omit_',
      schoolGrade: filters.competition_grade || '_omit_',
      schoolSubject: filters.competition_school_subject || '_omit_',
      owner: filters.competition_owner || '_omit_',
      types: filters.types?.length ? filters.types : '_omit_',
    })
      .then((response) => {
        setCompetitions(response.competitions)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [filters])

  const signUpHandler = (competitionId) => {
    routeHistory.push(`/hub/competitions/${competitionId}`)
  }

  const moreInfoHandler = () => {}

  return (
    <>
      <TitleIconWrapper>
        <i className="fas fa-search"></i>
        <PageInfo title={t('hub:Competitions:CompetitionsSearchTitle')} />
      </TitleIconWrapper>

      <PageInfo
        customCss={{
          h2: { color: COLORS.secBlack, marginBottom: 20 },
        }}
        subtitle={t('hub:Competitions:CompetitionsSearchSubtitle')}
      />

      <CompetitionFilterForm submitCallback={filtrationCallback} />

      <CompetitionsContainer>
        {competitions.length
          ? competitions.map((competition, index) => {
              // console.log(competition)
              return (
                <Competition key={index} theme={currentTheme}>
                  <ImageWrapper
                    customCss={{ maxWidth: 100, height: 100, margin: 0, marginBottom: 10, display: 'inline-block' }}
                    className="img-wrapper"
                  >
                    <img
                      src={
                        competition?.picture
                          ? getFileLinkFromServerStorage(competition.picture)
                          : NoImgPlaceholder
                      }
                      alt="exc-img"
                    />
                  </ImageWrapper>

                  <div className="content-wrapper">
                    <p className="content-title">
                      {t('hub:Competitions:CompetitionTitle', {
                        parent_name: competition.name,
                        grade: competition.grade_name,
                      })}{' '}
                    </p>
                    <p className="content-subtitle">
                      {t('hub:Competitions:CompetitionSubtitle', {
                        subject: competition.subject_name,
                        year: competition.year,
                        competition_type: competition.type_name,
                      })}
                    </p>
                    <p className="content-owner">
                      {t('hub:Competitions:CompetitionOwnerInfo', {
                        competition_owner: competition.owner_name,
                      })}
                    </p>
                    <div className="content-details gray">
                      {JSON.parse(competition.intervals).map((dates, index) => (
                        <div key={index}>
                          {HTMLReactParser(
                            t('hub:Competitions:CompetitionDetails', {
                              start_date: formatDateAndTime(dates.startDate),
                              end_date: formatDateAndTime(dates.endDate),
                            }),
                          )}
                        </div>
                      ))}
                    </div>
                    <p className="content-price">
                      {!competition.child_price && !competition.parent_price ? (
                        <span className="free">
                          {HTMLReactParser(
                            t('hub:Participation:PaymentFreeLabel'),
                          )}
                        </span>
                      ) : (
                        HTMLReactParser(
                          t('hub:Competitions:CompetitionPrice', {
                            competition_price: competition.child_price
                              ? competition.child_price
                              : competition.parent_price,
                          }),
                        )
                      )}
                    </p>
                  </div>

                  <div className="button-wrapper">
                    <GenericButton
                      customCss={{
                        marginTop: '0',
                        marginBottom: 20,
                        width: '100%',
                      }}
                      theme={currentTheme}
                      onClick={() => signUpHandler(competition.child_id)}
                    >
                      {t('hub:Competitions:SignUpButton')}
                    </GenericButton>

                    {/* INCLUDE competition ID  */}
                    <InfoButton
                      urlTo={`/hub/competitions/${competition.child_id}`}
                    >
                      {t('hub:Competitions:MoreInfoButton')}
                    </InfoButton>
                  </div>
                </Competition>
              )
            })
          : null}
      </CompetitionsContainer>
    </>
  )
}

export { CompetitionsSeachPage }

const Competition = styled('div')(({ theme }) => {
  return {
    border: `1px solid ${COLORS.borderGray}`,
    placeItems: 'center',
    borderRadius: 10,
    padding: 15,
    display: 'grid',
    gridTemplateColumns: '100px 2fr 1fr',
    gap: 15,

    '.gray': {
      color: COLORS.mainGray,
    },

    '.content-wrapper': {
      justifySelf: 'start',
      display: 'flex',
      flexDirection: 'column',
      gap: 10,

      '.content-title': {
        fontWeight: 600,
        fontSize: 16,
      },

      '.content-price ': {
        display: 'flex',
        alignItems: 'center',
        color: THEMES[+theme],

        i: { marginRight: 10, fontSize: 20 },
      },
    },

    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      // gridTemplateColumns: '100px 1fr',
      display: 'inline-block',
      textAlign: 'center',

      '.content-wrapper': {
        
  
        '.content-subtitle, .content-owner, .content-details': {
          fontSize: 14,
        },

        '.content-details div': {
          marginBottom: 7,
        },
  
        '.content-price ': {
          marginBottom: 20,
          fontSize: 14,
        },
      },  

      '.button-wrapper': {
        gridColumn: '1 /3',
        button: { width: '100%' },
      },
    },
  }
})
