import API from 'utils/backendApi'

function getFAQ() {
  return API('lab').GET('/general/faq')
}

function sendInquiry(user_id, title, content) {
  const body = JSON.stringify({
    user_id,
    title,
    content,
  })
  return API('lab').POST('/general/new-inquiry', body)
}

export { getFAQ, sendInquiry }
