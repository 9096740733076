import styled from '@emotion/styled'
import { GenericButton } from 'platforms/kyoso-hub/shared'
import { hub_selectTheme } from 'platforms/kyoso-hub/store/generalSlice'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  EscButton,
  FormCheckBox,
  FormInput,
  PageInfo,
  SchoolGradeSelect,
  SchoolSubjectSelect,
} from 'shared'
import { COLORS, QUERIES } from 'styling/variables'
import { CompetitionsCreatorsSelect } from 'shared/components/CompetitionsCreatorsSelect'
import { getAllKyosoTypesHook, getWindowSizeHook } from 'utils/helpers/hooks'

function CompetitionFilterForm({ submitCallback }) {
  const windowSize = getWindowSizeHook()
  const kyosoTypes = getAllKyosoTypesHook()
  const currentTheme = useSelector(hub_selectTheme)
  const [showMobileFilters, setShowMobileFilters] = useState(false)
  const [isChecked, setIsChecked] = useState(null)
  const { t } = useTranslation()
  const {
    reset,
    register,
    control,
    getValues,
    handleSubmit,
    trigger,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      competition_name: '',
      competition_grade: '',
      competition_school_subject: '',
      competition_owner: '',
      types: [],
    },
  })

  const onSubmitFilter = (data) => {
    submitCallback({
      competition_name: data.competition_name?.trim() || null,
      competition_grade: data.competition_grade,
      competition_school_subject: data.competition_school_subject,
      competition_owner: data.competition_owner || null,
      types: data.types.length ? data.types.map((x) => +x) : null,
    })
  }

  const resetDataHandler = () => {
    setIsChecked(false)
    reset()
    submitCallback(null)
  }

  return windowSize.width <= QUERIES.mobile ? (
    <FormLayout noValidate onSubmit={handleSubmit(onSubmitFilter)}>
      {!showMobileFilters && (
        <GenericButton
          type="button"
          theme={currentTheme}
          onClick={() => setShowMobileFilters((prevState) => !prevState)}
        >
          {t('lab:Kyoso:FiltrationTitle')}
        </GenericButton>
      )}

      {showMobileFilters && (
        <div className="mobile-wrap">
          <PageInfo
            title={t('lab:Kyoso:FiltrationTitle')}
            customCss={{ h1: { marginTop: 10 }, textAlign: 'center' }}
          />

          <EscButton
            onClick={() => setShowMobileFilters((prevState) => !prevState)}
          />

          <div className="top-inputs">
            <FormInput
              customCss={{ marginBottom: '0' }}
              id="filter-name"
              type="text"
              name="competition_name"
              label="lab:Kyoso:FilterName"
              register={register()}
            />

            <SchoolGradeSelect
              customCss={{ marginBottom: '0' }}
              id="school-grade"
              label="lab:Kyoso:FilterSchoolGrade"
              name="competition_grade"
              onChange={() => trigger('competition_grade')}
              setValue={setValue}
              isClearable={true}
              getValues={getValues}
              control={control}
            />

            <SchoolSubjectSelect
              customCss={{ marginBottom: '0' }}
              id="school-subject"
              label="lab:Kyoso:FilterSchoolSubject"
              name="competition_school_subject"
              setValue={setValue}
              getValues={getValues}
              onChange={() => trigger('competition_school_subject')}
              control={control}
              setError={setError}
              clearErrors={clearErrors}
              isClearable={true}
            />

            <CompetitionsCreatorsSelect
              customCss={{ marginBottom: '0' }}
              id="competition-creator"
              label={t('shared:SelectCompetitionOwnerLabel')}
              name="competition_owner"
              setValue={setValue}
              getValues={getValues}
              onChange={() => trigger('competition_owner')}
              control={control}
              setError={setError}
              clearErrors={clearErrors}
              isClearable={true}
            />
          </div>
          <div className="bottom-checkboxes">
            {kyosoTypes?.map((x) => (
              <FormCheckBox
                key={`${x.id}_${x.name}`}
                id={`type_${x.id}_${x.name}`}
                name="types"
                label={x.name}
                value={x.id}
                register={register()}
                checked={() => isChecked}
                customCss={{
                  marginBottom: '0',
                  ' .text': {
                    marginLeft: '10px',
                    gridColumn: '2',
                    color: COLORS.mainGray,
                    justifySelf: 'left',
                  },
                }}
              />
            ))}
          </div>

          <GenericButton
            type="reset"
            className="inverted clear"
            onClick={(e) => resetDataHandler(e)}
            customCss={{ minWidth: '0' }}
            theme={currentTheme}
          >
            <i className="fas fa-times"></i>
          </GenericButton>

          <GenericButton
            className="black"
            type="submit"
            customCss={{}}
            theme={currentTheme}
          >
            {t('lab:Kyoso:SubmitFilterButton')}
          </GenericButton>
        </div>
      )}
    </FormLayout>
  ) : (
    <FormLayout noValidate onSubmit={handleSubmit(onSubmitFilter)}>
      <div className="top-inputs">
        <FormInput
          customCss={{ marginBottom: '0' }}
          id="filter-name"
          type="text"
          name="competition_name"
          label="lab:Kyoso:FilterName"
          register={register()}
        />

        <SchoolGradeSelect
          customCss={{ marginBottom: '0' }}
          id="school-grade"
          label="lab:Kyoso:FilterSchoolGrade"
          name="competition_grade"
          onChange={() => trigger('competition_grade')}
          setValue={setValue}
          isClearable={true}
          getValues={getValues}
          control={control}
        />

        <SchoolSubjectSelect
          customCss={{ marginBottom: '0' }}
          id="school-subject"
          label="lab:Kyoso:FilterSchoolSubject"
          name="competition_school_subject"
          setValue={setValue}
          getValues={getValues}
          onChange={() => trigger('competition_school_subject')}
          control={control}
          setError={setError}
          clearErrors={clearErrors}
          isClearable={true}
        />

        <CompetitionsCreatorsSelect
          customCss={{ marginBottom: '0' }}
          id="competition-creator"
          label={t('shared:SelectCompetitionOwnerLabel')}
          name="competition_owner"
          setValue={setValue}
          getValues={getValues}
          onChange={() => trigger('competition_owner')}
          control={control}
          setError={setError}
          clearErrors={clearErrors}
          isClearable={true}
        />

        <GenericButton
          type="reset"
          className="inverted clear"
          onClick={(e) => resetDataHandler(e)}
          customCss={{ minWidth: '0' }}
          theme={currentTheme}
        >
          <i className="fas fa-times"></i>
        </GenericButton>

        <GenericButton
          className="black"
          type="submit"
          customCss={{}}
          theme={currentTheme}
        >
          {t('lab:Kyoso:SubmitFilterButton')}
        </GenericButton>
      </div>

      <div className="bottom-checkboxes">
        {kyosoTypes?.map((x) => (
          <FormCheckBox
            key={`${x.id}_${x.name}`}
            id={`type_${x.id}_${x.name}`}
            name="types"
            label={x.name}
            value={x.id}
            register={register()}
            checked={() => isChecked}
            customCss={{
              marginBottom: '0',
              ' .text': {
                marginLeft: '10px',
                gridColumn: '2',
                color: COLORS.mainGray,
                justifySelf: 'left',
              },
            }}
          />
        ))}
      </div>
    </FormLayout>
  )
}

export { CompetitionFilterForm }

const FormLayout = styled('form')({
  width: '100%',

  '.top-inputs': {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1.5fr 1fr 50px 0.5fr',
    gridColumnGap: '30px',
    marginBottom: 20,
    alignItems: 'end',
  },

  '.bottom-checkboxes': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 30,
  },

  '& .button': {
    cursor: 'pointer',
    gridColumn: '5',
    backgroundColor: '#fff',
    border: 'none',
    alignSelf: 'center',
    marginBottom: '5px',

    '& i': {
      fontSize: '30px',
      color: COLORS.mainGray,
    },
  },

  [`@media (max-width: ${QUERIES.tablet}px)`]: {
    gridColumnGap: '15px',

    '.top-inputs': {
      gridTemplateColumns: '1fr 1fr  ',
      gap: 20,
    },
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',

    '.mobile-wrap': {
      border: `1px solid ${COLORS.secBlack}`,
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      padding: 10,

      '.top-inputs': {
        display: 'flex',
        gap: 10,
        flexDirection: 'column',
      },

      '.bottom-checkboxes': {
        flexDirection: 'column',
        gap: 10,
      },
    },
  },
})
