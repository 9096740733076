import styled from '@emotion/styled'
import { unwrapResult } from '@reduxjs/toolkit'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ContentTwoColumsLayout } from 'shared'
import { QUERIES } from 'styling/variables'
import { ChangePasswordForm } from '../components/settings/ChangePasswordForm'
import { ChangeProfileNameForm } from '../components/settings/ChangeProfileNameForm'
import { VariantThreePaymentForm } from '../components/settings/VariantThreePaymentForm'
import { VariantTwoPaymentForm } from '../components/settings/VariantTwoPaymentForm'
import { changePassword } from '../services/user'
import {
  lab_changeUsername,
  lab_selectPaymentVariant,
  lab_updateUser,
} from '../store/userSlice'

export function SettingsPage() {
  const paymentVariantType = useSelector(lab_selectPaymentVariant)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onSubmitHandler = (data, submitType, setError, setOnSuccess, reset) => {
    switch (submitType) {
      case 'changePassword':
        changePassword(data.current_password, data.new_password)
          .then((res) => {
            setOnSuccess('changePassword')
            reset()
          })
          .catch((error) => {
            setError('current_password', {
              type: 'manual',
              message: t('errors:104'),
            })
          })
        break
      case 'changeUsername':
        dispatch(
          lab_changeUsername({
            profile_name: data.profile_name.trim(),
          }),
        )
          .then(unwrapResult)
          .then(() => {
            setOnSuccess('changeUsername')
          })
          .catch((error) => {
            console.log(error)
          })
        break
      case 'paymentInfo':
        dispatch(
          lab_updateUser({
            ...data,
          }),
        )
          .then(unwrapResult)
          .then(() => {
            setOnSuccess('paymentInfo')
          })
          .catch((error) => {
            console.log(error)
          })
        break
      default:
        console.error(
          'Wrong submit type in function onSubmitHandler from Settings(LAB): ',
          submitType,
        )
        break
    }
  }

  const variantTypeHandler = (variantType) => {
    let variantBankForm = null

    switch (variantType) {
      case '1':
        break

      case '2':
        variantBankForm = (
          <VariantTwoPaymentForm onSubmitHandler={onSubmitHandler} />
        )

        break

      case '3':
        variantBankForm = (
          <VariantThreePaymentForm onSubmitHandler={onSubmitHandler} />
        )

        break

      default:
    }

    return variantBankForm
  }

  return (
    <>
      <ContentTwoColumsLayout>
        <FirstColumn>
          <ChangePasswordForm onSubmitHandler={onSubmitHandler} />

          <ChangeProfileNameForm onSubmitHandler={onSubmitHandler} />
        </FirstColumn>

        <SecondColumn>{variantTypeHandler(paymentVariantType)}</SecondColumn>
      </ContentTwoColumsLayout>
    </>
  )
}

const FirstColumn = styled('div')({
  'form:first-of-type': {
    marginBottom: 20,
  },

  [`@media (min-width: ${QUERIES.tablet}px)`]: {
    form: {
      display: 'flex',
      flexDirection: 'column',

      maxWidth: '400px',
    },

    button: {
      maxWidth: 250,
    },
  },
})

const SecondColumn = styled('div')({
  [`@media (min-width: ${QUERIES.tablet}px)`]: {
    form: {
      display: 'flex',
      flexDirection: 'column',

      button: {
        maxWidth: 250,

        alignSelf: 'flex-end',
      },
    },
  },
})
