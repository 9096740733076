import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import bpay from 'assets/img/payments/bpay.png'
import easypay from 'assets/img/payments/easypay.png'
import mastercard from 'assets/img/payments/mastercard.png'
import visa from 'assets/img/payments/visa.png'
import HTMLReactParser from 'html-react-parser'

import {
  ContentTwoColumsLayout,
  EscButton,
  TinyTextContentPreview,
} from 'shared'
import { COLORS, QUERIES } from 'styling/variables'
import { GenericButton, HubModalBackground } from 'platforms/kyoso-hub/shared'
import { useSelector } from 'react-redux'
import { hub_selectTheme } from 'platforms/kyoso-hub/store/generalSlice'
import { formatDateAndTime } from 'utils/helpers/common'

function ParticipationModal({ closeModalCallback, participationDetails }) {
  const { t } = useTranslation()
  const { childId } = useParams()
  const currentTheme = useSelector(hub_selectTheme)

  const closeModalHandler = (e) => {
    const backDrop = document.getElementById('modal-background')

    if (e.target === backDrop) {
      closeModalCallback()
    }
  }

  return (
    <HubModalBackground id="modal-background" onClick={closeModalHandler}>
      <ModalWrap>
        <ModalLayout>
          <EscButton onClick={() => closeModalCallback()} />
          <h1 id="modal-title" className="green">
            {t('hub:Participation:ModalTitle')}
          </h1>

          <ContentTwoColumsLayout>
            <div id="order-info">
              <div>
                <h2 id="headline">{`${childId} - ${participationDetails?.name} ${participationDetails?.child_grade_name} `}</h2>
                <h3 id="sub-headline">{`${participationDetails?.subject_name} - ${participationDetails?.year} - ${participationDetails?.type_name} `}</h3>
              </div>

              <p id="participant-id">
                <span>{t('lab:KyosoOrders:ParticipantId')}: </span>
                <span className="blue">
                  {participationDetails.participant_id}
                </span>
              </p>

              <p id="order-id">
                <span>{t('lab:KyosoOrders:OrderId')}: </span>
                <span className="blue">{participationDetails.order_id}</span>
              </p>

              <p id="order-date">
                <span>{t('lab:KyosoOrders:OrderDate')}: </span>
                <span className="blue">
                  {formatDateAndTime(participationDetails.order_date)}
                </span>
              </p>

              <p id="price" className="red">
                {t('hub:Participation:Price', {
                  price: participationDetails.child_price
                    ? participationDetails.child_price
                    : participationDetails.parent_price,
                })}
              </p>
            </div>

            <div id="order-payment-type">
              <p className="black">{`${t(
                'hub:Participation:PaymentTypeLabel',
              )}: ${
                +participationDetails.payment_type === 1
                  ? t('hub:Participation:BankPayment')
                  : t('hub:Participation:ЕPay')
              }`}</p>

              {+participationDetails.payment_type === 1 ? (
                <>
                  <p className="gray">
                    {t('hub:Participation:ModalPaymentBankInstruction')}
                  </p>

                  <p className="black">{t('lab:Settings:LabelBankInfo')}:</p>

                  <TinyTextContentPreview
                    customCss={{
                      gridRow: '3',
                      gridColumn: '1 /4',
                      margin: '20px 0',
                      border: `1px solid ${COLORS.borderGray}`,
                      padding: 10,
                      borderRadius: 10,
                    }}
                    html={participationDetails.owner_bank_details}
                  />
                </>
              ) : (
                <>
                  <p className="gray">
                    {t('hub:Participation:ModalPaymentEpayInstruction')}
                  </p>
                  <div className="payment-types">
                    <img src={visa}></img>
                    <img src={mastercard}></img>
                    <img src={easypay}></img>
                    <img src={bpay}></img>
                  </div>
                </>
              )}
            </div>
          </ContentTwoColumsLayout>
          <div id="modal-footer">
            {+participationDetails.payment_type === 1 ? (
              <>
                <p className="gray">{t('hub:Participation:ModalFooter')}</p>
                <GenericButton
                  customCss={{ width: 250, marginTop: 10 }}
                  theme={currentTheme}
                  onClick={closeModalCallback}
                >
                  {t('shared:Modals:ModalCloseButton')}
                </GenericButton>
              </>
            ) : (
              <p className="gray">
                {HTMLReactParser(
                  t('hub:Participation:ModalFooterEpay', {
                    link: 'https://www.facebook.com/',
                  }),
                )}
              </p>
            )}
          </div>
        </ModalLayout>
      </ModalWrap>
    </HubModalBackground>
  )
}

export { ParticipationModal }

const ModalWrap = styled('div')({
  display: 'table-cell',
  verticalAlign: 'middle',
})

const ModalLayout = styled('div')({
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '70%',
  maxHeight: '90%',
  maxWidth: 1400,
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 0px 5px #0000000D',
  borderRadius: 25,
  padding: '20px 50px',
  fontFamily: 'Balsamiq Sans',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',

  '.blue': {
    color: COLORS.secBlue,
  },

  '.red': {
    color: COLORS.secRed,
  },

  '.green': {
    color: COLORS.secGreen,
  },

  '.black': {
    color: COLORS.secBlack,
  },

  '.gray': {
    color: COLORS.mainGray,
  },

  '::-webkit-scrollbar': {
    display: 'none',
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    padding: '30px 20px',
  },

  '#modal-title': {
    textAlign: 'center',
  },

  '#order-info': {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,

    '.red': {
      fontSize: 20,
    },

    '#headline': {
      fontSize: 22,
      marginBottom: 10,
    },

    '#sub-headline': {
      marginBottom: 20,
    },
  },

  '#order-payment-type': {
    '.black': {
      fontSize: 20,
      marginBottom: 10,
    },

    '.gray': {
      fontSize: 14,
      marginBottom: 20,
    },

    '.payment-types': {
      img: {
        margin: '0  5px',
      },
    },
  },

  '#modal-footer': {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    p: { textAlign: 'center' },
  },
})
