import styled from '@emotion/styled'
import { forEach } from 'lodash'
import { SubmitButtonLab } from 'platforms/kyoso-lab/shared'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormInput, FormRadioButtons, TinyTextEditor } from 'shared'
import { COLORS } from 'styling/variables'

function AnswersFormsByType({
  answerType,
  answers,
  appendAnswer,
  removeAnswer,
  specialScorings,
  appendScoring,
  removeScoring,
  formControl,
  formSetValue,
  formRegister,
  formState,
  formWatch,
  formValues,
  formTrigger,
  formClearError,
  getDefaultAnswerStructure,
}) {
  const { t } = useTranslation()
  const [accordionIndex, setAccordionIndex] = useState(null)

  const scoringTypeRadioBtns = [
    {
      id: 0,
      name: 'scoringType',
      label: t('lab:KyosoExerciseCreateUpdateModule:ScoringType0'),
      register: formRegister({ required: 'Required' }),
      error: formState.errors.scoringType,
    },
    {
      id: 1,
      name: 'scoringType',
      label: t('lab:KyosoExerciseCreateUpdateModule:ScoringType1'),
      register: formRegister({ required: 'Required' }),
      error: formState.errors.scoringType,
    },
    {
      id: 2,
      name: 'scoringType',
      label: t('lab:KyosoExerciseCreateUpdateModule:ScoringType2'),
      register: formRegister({ required: 'Required' }),
      error: formState.errors.scoringType,
    },
  ]

  // console.log(answers)

  useEffect(() => {
    // Fix accordion opening after refresh of new answers data for single answer type
    if (answerType === '0' && answers.find((x) => x.is_other)?.other_answers) {
      if (answers.find((x) => x.is_other).other_answers.length === 1) {
        setAccordionIndex(0)
        document
          .querySelector(
            '#answers-forms-by-type-layout .fields .accordion-other-answer .content_0',
          )
          .classList.add('open')
      } else {
        if (accordionIndex !== null)
          document
            .querySelector(
              `#answers-forms-by-type-layout .fields .accordion-other-answer .content_${accordionIndex}`,
            )
            .classList.add('open')
        else {
          const allAccordionContents = document.querySelectorAll(
            '#answers-forms-by-type-layout .fields .accordion-other-answer .content',
          )
          allAccordionContents.forEach((element) => {
            if (element.classList.contains('open'))
              element.classList.remove('open')
          })
        }
      }
    }
  }, [answers])

  function toggleAccordionOtherAnswers(e, innerIndex) {
    const accordion = e.target.parentNode.parentNode
    const allAccordionContents = document.querySelectorAll(
      '#answers-forms-by-type-layout .fields .accordion-other-answer .content',
    )
    if (accordionIndex === innerIndex) {
      setAccordionIndex(null)
      accordion.querySelector(`.content_${innerIndex}`).classList.remove('open')
    } else {
      setAccordionIndex(innerIndex)
      allAccordionContents.forEach((element) => {
        if (element.classList.contains('open')) element.classList.remove('open')
      })
      accordion.querySelector(`.content_${innerIndex}`).classList.add('open')
    }
  }

  function resetCheckboxesForSingleTypeAnswers(e) {
    document
      .querySelectorAll('#answers-forms-by-type-layout input[type=checkbox]')
      .forEach((x) => {
        if (x.name.includes('is_other')) return
        else if (x.id !== e.target.id) {
          x.checked = false
          formTrigger(x.name)
        } else {
          x.checked = true
          formTrigger(x.name)
        }
      })
    if (formState.errors?.noCorrectAnswer) formClearError('noCorrectAnswer')
  }

  function checkRadioChangeCheckbox(e) {
    document
      .querySelectorAll('#answers-forms-by-type-layout div.radio-checkbox')
      .forEach((x) => {
        const checkbox = x.parentNode.querySelector(
          'input[type=checkbox][name*="is_correct"]',
        )
        if (x !== e.target) {
          x.classList.remove('checked')
        } else {
          x.classList.add('checked')
          checkbox.click()
        }
      })
  }

  function showDeleteAnswerButton(index) {
    if (answerType === '0' && index < 2) return false
    else if (answerType === '1' && index < 3) return false
    else if ((answerType === '2' || answerType === '3') && index < 1)
      return false
    else return true
  }

  const handleSpecialScoring = () => {
    const { answers, scoringType } = formValues()
    const isSpecialScoringOn = scoringType === '2'

    if (isSpecialScoringOn) {
      if (answerType === '1') {
        const correctAnswers = answers.filter((ans) => ans.is_correct)
        // console.log('correctAnswers', correctAnswers, specialScorings)

        if (correctAnswers.length < specialScorings.length) {
          for (let index = 0; index < correctAnswers.length - 1; index++) {
            removeScoring()
          }
        } else {
          for (let index = 0; index < correctAnswers.length - 1; index++) {
            appendScoring({ points: 0 }, { shouldFocus: false })
          }
        }
      } else if (answerType === '2') {
        for (let index = 0; index < answers.length - 1; index++) {
          appendScoring({ points: 0 }, { shouldFocus: false })
        }
      }
    } else {
      for (let index = 0; index < specialScorings.length - 1; index++) {
        removeScoring()
      }
    }
  }

  function duplicateAnswer(index) {
    const {
      id,
      keyId,
      kyoso_exercise_id,
      is_correct,
      position,
      ...duplicatedAnswer
    } = formValues().answers[index]
    appendScoring({ points: 0 }, { shouldFocus: false })
    appendAnswer({
      ...duplicatedAnswer,
      position: Math.max(...formValues().answers.map((x) => x.position)) + 1,
    })
  }

  if (answerType)
    return (
      <AnswersFormsByTypeLayout id="answers-forms-by-type-layout">
        {formState.errors?.noCorrectAnswer && (
          <div id="noCorrectAnswer">
            {formState.errors.noCorrectAnswer.message}
          </div>
        )}
        {answers.map((answer, index) => {
          if (answerType === '0')
            return (
              !answer.is_other && (
                <SingleAnswersLayout key={answer.keyId}>
                  {answer.id && (
                    <input
                      hidden
                      type="number"
                      name={`answers[${index}].id`}
                      defaultValue={answer.id}
                      ref={formRegister()}
                    />
                  )}
                  <input
                    hidden
                    type="number"
                    name={`answers[${index}].position`}
                    defaultValue={answer.position}
                    ref={formRegister()}
                  />
                  <input
                    hidden
                    id={answer.keyId}
                    type="checkbox"
                    name={`answers[${index}].is_correct`}
                    defaultChecked={answer.is_correct}
                    onChange={resetCheckboxesForSingleTypeAnswers}
                    ref={formRegister()}
                  />
                  <RadioButton
                    className={`radio-checkbox${
                      answer.is_correct ? ' checked' : ''
                    }`}
                    onClick={checkRadioChangeCheckbox}
                  />

                  <TinyTextEditor
                    control={formControl}
                    name={`answers[${index}].answer`}
                    label={t('lab:KyosoExerciseCreateUpdateModule:TextLabel')}
                    rules={{ required: 'Required' }}
                    error={
                      formState.errors.answers &&
                      formState.errors.answers[index]?.answer
                    }
                    defaultValue={answer.answer}
                    extendInitOptions={{ menubar: false }}
                  />

                  <div className="points">
                    {formWatch(`answers[${index}].is_correct`) && (
                      <div className="correct-answer-text">
                        {t(
                          'lab:KyosoExerciseCreateUpdateModule:CorrectAnswerLabel',
                        )}
                      </div>
                    )}
                    <FormInput
                      label={t(
                        'lab:KyosoExerciseCreateUpdateModule:PointsLabel',
                      )}
                      type="number"
                      min="0"
                      name={`answers[${index}].points`}
                      register={formRegister({ required: 'Required' })}
                      error={
                        formState.errors.answers &&
                        formState.errors.answers[index]?.points
                      }
                      defaultValue={answer.points}
                      customCss={{
                        width: 190,
                        marginBottom: 0,
                      }}
                    />
                  </div>
                  <DuplicateDeleteButtons>
                    <i
                      className="far fa-copy"
                      onClick={() => duplicateAnswer(index)}
                    ></i>
                    {showDeleteAnswerButton(index) && (
                      <i
                        className="fas fa-trash-alt"
                        onClick={() => {
                          removeAnswer(answer.id, index)
                          removeScoring(specialScorings.length - 1)
                        }}
                      ></i>
                    )}
                  </DuplicateDeleteButtons>
                </SingleAnswersLayout>
              )
            )

          if (answerType === '1')
            return (
              <MultiAnswersLayout key={answer.keyId}>
                {answer.id && (
                  <input
                    hidden
                    type="number"
                    name={`answers[${index}].id`}
                    defaultValue={answer.id}
                    ref={formRegister()}
                  />
                )}
                <input
                  hidden
                  type="number"
                  name={`answers[${index}].position`}
                  defaultValue={answer.position}
                  ref={formRegister()}
                />
                <input
                  id={answer.keyId}
                  type="checkbox"
                  name={`answers[${index}].is_correct`}
                  defaultChecked={answer.is_correct}
                  onChange={(e) => {
                    formState.errors?.noCorrectAnswer &&
                      formClearError('noCorrectAnswer')
                    handleSpecialScoring()

                    if (!e.target.checked) {
                      removeScoring(specialScorings.length - 1)
                    }
                  }}
                  ref={formRegister()}
                />

                <TinyTextEditor
                  control={formControl}
                  id={`answers[${index}].answer`}
                  name={`answers[${index}].answer`}
                  label={t('lab:KyosoExerciseCreateUpdateModule:TextLabel')}
                  rules={{ required: 'Required' }}
                  error={
                    formState.errors.answers &&
                    formState.errors.answers[index]?.answer
                  }
                  defaultValue={answer.answer}
                  extendInitOptions={{ menubar: false }}
                />

                <div className="points">
                  {formWatch(`answers[${index}].is_correct`) && (
                    <div className="correct-answer-text">
                      {t(
                        'lab:KyosoExerciseCreateUpdateModule:CorrectAnswerLabel',
                      )}
                    </div>
                  )}
                  <FormInput
                    label={t('lab:KyosoExerciseCreateUpdateModule:PointsLabel')}
                    type="number"
                    min="0"
                    name={`answers[${index}].points`}
                    register={formRegister({ required: 'Required' })}
                    error={
                      formState.errors.answers &&
                      formState.errors.answers[index]?.points
                    }
                    defaultValue={answer.points}
                    customCss={{
                      width: 190,
                      marginBottom: 0,
                    }}
                  />
                </div>
                <DuplicateDeleteButtons>
                  <i
                    className="far fa-copy"
                    onClick={() => duplicateAnswer(index)}
                  ></i>
                  {showDeleteAnswerButton(index) && (
                    <i
                      className="fas fa-trash-alt"
                      onClick={() => {
                        removeAnswer(answer.id, index)
                        removeScoring(specialScorings.length - 1)
                      }}
                    ></i>
                  )}
                </DuplicateDeleteButtons>
              </MultiAnswersLayout>
            )

          if (answerType === '2')
            return (
              <OpenAnswersLayout key={answer.keyId}>
                {answer.id && (
                  <input
                    hidden
                    type="number"
                    name={`answers[${index}].id`}
                    defaultValue={answer.id}
                    ref={formRegister()}
                  />
                )}
                <input
                  hidden
                  type="number"
                  name={`answers[${index}].position`}
                  defaultValue={answer.position}
                  ref={formRegister()}
                />
                <FormInput
                  label={t(
                    'lab:KyosoExerciseCreateUpdateModule:AnswerDescriptionLabel',
                  )}
                  type="text"
                  name={`answers[${index}].description`}
                  register={formRegister()}
                  defaultValue={answer.description}
                  customCss={{
                    width: 320,
                    marginBottom: 30,
                  }}
                />
                <div className="possible-answers-label">
                  {t(
                    'lab:KyosoExerciseCreateUpdateModule:PossibleCorrectAnswersLabel',
                  )}
                </div>
                {answer.answer.map((innerAnswer, innerIndex) => (
                  <InnerOpenAnswers key={innerIndex}>
                    <FormInput
                      label={t(
                        'lab:KyosoExerciseCreateUpdateModule:AnswerLabel',
                      )}
                      type="text"
                      name={`answers[${index}].answer[${innerIndex}].answer`}
                      defaultValue={innerAnswer.answer}
                      register={formRegister({ required: 'Required' })}
                      error={
                        formState.errors.answers &&
                        formState.errors.answers[index]?.answer &&
                        formState.errors.answers[index]?.answer[innerIndex]
                          ?.answer
                      }
                      customCss={{
                        width: 320,
                      }}
                    />
                    <FormInput
                      label={t(
                        'lab:KyosoExerciseCreateUpdateModule:PointsLabel',
                      )}
                      type="number"
                      min="0"
                      register={formRegister({ required: 'Required' })}
                      error={
                        formState.errors.answers &&
                        formState.errors.answers[index]?.answer &&
                        formState.errors.answers[index]?.answer[innerIndex]
                          ?.points
                      }
                      name={`answers[${index}].answer[${innerIndex}].points`}
                      defaultValue={innerAnswer.points}
                      customCss={{
                        width: 320,
                      }}
                    />
                    {innerIndex > 0 && (
                      <SubmitButtonLab
                        className="black"
                        type="button"
                        onClick={() => {
                          const newAnswers = formValues().answers
                          newAnswers[index].answer.splice(innerIndex, 1)
                          formSetValue(`answers`, newAnswers)
                        }}
                        customCss={{ width: 50, marginBottom: 10 }}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </SubmitButtonLab>
                    )}
                  </InnerOpenAnswers>
                ))}
                <SubmitButtonLab
                  type="button"
                  className="black"
                  customCss={{ width: 180 }}
                  onClick={() => {
                    const newAnswers = formValues().answers
                    newAnswers[index].answer.push({
                      answer: null,
                      points: 0,
                    })
                    formSetValue(`answers`, newAnswers)
                  }}
                >
                  <i className="fas fa-plus"></i>
                  <span className="new-answer-text">
                    {t('lab:KyosoExerciseCreateUpdateModule:NewBtnLabel')}
                  </span>
                </SubmitButtonLab>
                <DuplicateDeleteButtons>
                  <i
                    className="far fa-copy"
                    onClick={() => duplicateAnswer(index)}
                  ></i>
                  {showDeleteAnswerButton(index) && (
                    <i
                      className="fas fa-trash-alt"
                      onClick={() => {
                        removeAnswer(answer.id, index)
                        removeScoring(specialScorings.length - 1)
                      }}
                    ></i>
                  )}
                </DuplicateDeleteButtons>
              </OpenAnswersLayout>
            )

          if (answerType === '3')
            return (
              <LongAnswersLayout key={answer.keyId}>
                <div className="title">
                  {t('lab:KyosoExerciseCreateUpdateModule:LongAnswerTitle')}
                </div>
                <div className="content">
                  {t('lab:KyosoExerciseCreateUpdateModule:LongAnswerContent')}
                </div>
                {answer.id && (
                  <input
                    hidden
                    type="number"
                    name={`answers[${index}].id`}
                    defaultValue={answer.id}
                    ref={formRegister()}
                  />
                )}
                <input
                  hidden
                  type="number"
                  name={`answers[${index}].position`}
                  defaultValue={answer.position}
                  ref={formRegister()}
                />
                <FormInput
                  label={t(
                    'lab:KyosoExerciseCreateUpdateModule:AnswerDescriptionLabel',
                  )}
                  type="text"
                  name={`answers[${index}].description`}
                  register={formRegister()}
                  defaultValue={answer.description}
                  customCss={{
                    width: 320,
                    marginBottom: 0,
                  }}
                />
                <DuplicateDeleteButtons>
                  <i
                    className="far fa-copy"
                    onClick={() => duplicateAnswer(index)}
                  ></i>
                  {showDeleteAnswerButton(index) && (
                    <i
                      className="fas fa-trash-alt"
                      onClick={() => removeAnswer(answer.id, index)}
                    ></i>
                  )}
                </DuplicateDeleteButtons>
              </LongAnswersLayout>
            )
        })}

        {answerType === '3' && (
          <div className="long-answer-info">
            {t('lab:KyosoExerciseCreateUpdateModule:LongAnswerInfo')}
          </div>
        )}

        <NewAnswersButtons>
          <SubmitButtonLab
            type="button"
            className="black"
            customCss={{
              width: 180,
            }}
            onClick={() => {
              // console.log('546', getDefaultAnswerStructure(+answerType))
              if (answerType === '2') {
                handleSpecialScoring()
              }
              appendAnswer({
                ...getDefaultAnswerStructure(+answerType)[0],
                position:
                  Math.max(
                    ...answers.map((x) => x.position).filter((x) => +x !== 100),
                  ) + 1,
              })
            }}
          >
            <i className="fas fa-plus"></i>
            <span className="new-answer-text">
              {t('lab:KyosoExerciseCreateUpdateModule:NewAnswerBtnLabel')}
            </span>
          </SubmitButtonLab>
          {answerType === '0' && !answers.filter((x) => x.is_other).length && (
            <SubmitButtonLab
              type="button"
              className="black"
              customCss={{
                width: 180,
              }}
              onClick={() => {
                appendAnswer({
                  ...getDefaultAnswerStructure(+answerType)[0],
                  other_answers: [
                    {
                      description: null,
                      answers: [{ answer: null, points: 0 }],
                    },
                  ],
                  is_other: true,
                  position: 100,
                })
              }}
            >
              <i className="fas fa-pen"></i>
              <span className="new-other-answer-text">
                {t(
                  'lab:KyosoExerciseCreateUpdateModule:NewOtherAnswerBtnLabel',
                )}
              </span>
            </SubmitButtonLab>
          )}
        </NewAnswersButtons>

        {answerType === '0' && answers.filter((x) => x.is_other).length ? (
          <>
            <div className="new-other-answer-label">
              {t('lab:KyosoExerciseCreateUpdateModule:NewOtherAnswerBtnLabel')}
            </div>
            <OtherAnswersLayout key={answers.find((x) => x.is_other).keyId}>
              {answers.find((x) => x.is_other).id && (
                <input
                  hidden
                  type="number"
                  name={`answers[${answers.findIndex((x) => x.is_other)}].id`}
                  defaultValue={answers.find((x) => x.is_other).id}
                  ref={formRegister()}
                />
              )}
              <input
                hidden
                type="number"
                name={`answers[${answers.findIndex(
                  (x) => x.is_other,
                )}].position`}
                defaultValue={answers.find((x) => x.is_other).position}
                ref={formRegister()}
              />
              <input
                hidden
                type="checkbox"
                name={`answers[${answers.findIndex(
                  (x) => x.is_other,
                )}].is_other`}
                defaultChecked={true}
                ref={formRegister()}
              />
              <input
                hidden
                id={answers.find((x) => x.is_other).keyId}
                type="checkbox"
                name={`answers[${answers.findIndex(
                  (x) => x.is_other,
                )}].is_correct`}
                defaultChecked={answers.find((x) => x.is_other).is_correct}
                onChange={resetCheckboxesForSingleTypeAnswers}
                ref={formRegister()}
              />
              <RadioButton
                className={`radio-checkbox${
                  answers.find((x) => x.is_other).is_correct ? ' checked' : ''
                }`}
                onClick={checkRadioChangeCheckbox}
              />
              <div className="fields">
                <TinyTextEditor
                  control={formControl}
                  name={`answers[${answers.findIndex(
                    (x) => x.is_other,
                  )}].answer`}
                  label={t('lab:KyosoExerciseCreateUpdateModule:TextLabel')}
                  rules={{ required: 'Required' }}
                  error={
                    formState.errors.answers &&
                    formState.errors.answers[
                      answers.findIndex((x) => x.is_other)
                    ]?.answer
                  }
                  defaultValue={answers.find((x) => x.is_other).answer}
                  extendInitOptions={{ menubar: false }}
                />

                {answers
                  .find((x) => x.is_other)
                  .other_answers.map((innerAnswer, innerIndex) => (
                    <InnerOpenAnswersAccordion
                      className="accordion-other-answer"
                      key={innerIndex}
                    >
                      <div className="title">
                        <h2
                          onClick={(e) =>
                            toggleAccordionOtherAnswers(e, innerIndex)
                          }
                        >
                          {formWatch(
                            `answers[${answers.findIndex(
                              (x) => x.is_other,
                            )}].other_answers[${innerIndex}].description`,
                          ) ||
                            t(
                              'lab:KyosoExerciseCreateUpdateModule:NewAccordionAnswerLabel',
                            )}
                        </h2>
                        <i
                          className={`fas fa-chevron-right ${
                            accordionIndex === innerIndex ? 'open' : null
                          }`}
                          onClick={(e) =>
                            toggleAccordionOtherAnswers(e, innerIndex)
                          }
                        />
                      </div>

                      <div className={`content content_${innerIndex}`}>
                        <InnerOpenAnswersContainer>
                          <FormInput
                            label={t(
                              'lab:KyosoExerciseCreateUpdateModule:AnswerDescriptionLabel',
                            )}
                            type="text"
                            name={`answers[${answers.findIndex(
                              (x) => x.is_other,
                            )}].other_answers[${innerIndex}].description`}
                            register={formRegister()}
                            defaultValue={
                              answers.find((x) => x.is_other).other_answers[
                                innerIndex
                              ].description
                            }
                            customCss={{
                              width: 320,
                              marginBottom: 30,
                            }}
                          />
                          <div className="possible-answers-label">
                            {t(
                              'lab:KyosoExerciseCreateUpdateModule:PossibleCorrectAnswersLabel',
                            )}
                          </div>
                          {innerAnswer.answers.map(
                            (innerInnerAnswer, innerInnerIndex) => (
                              <InnerOpenAnswers
                                key={`${innerIndex}_${innerInnerIndex}`}
                              >
                                <FormInput
                                  label={t(
                                    'lab:KyosoExerciseCreateUpdateModule:AnswerLabel',
                                  )}
                                  type="text"
                                  name={`answers[${answers.findIndex(
                                    (x) => x.is_other,
                                  )}].other_answers[${innerIndex}].answers[${innerInnerIndex}].answer`}
                                  defaultValue={innerInnerAnswer.answer}
                                  register={formRegister({
                                    required: 'Required',
                                  })}
                                  error={
                                    formState.errors.answers &&
                                    formState.errors.answers[
                                      answers.findIndex((x) => x.is_other)
                                    ]?.other_answers &&
                                    formState.errors.answers[
                                      answers.findIndex((x) => x.is_other)
                                    ]?.other_answers[innerIndex]?.answers &&
                                    formState.errors.answers[
                                      answers.findIndex((x) => x.is_other)
                                    ]?.other_answers[innerIndex]?.answers[
                                      innerInnerIndex
                                    ]?.answer
                                  }
                                  customCss={{
                                    width: 320,
                                  }}
                                />
                                <FormInput
                                  label={t(
                                    'lab:KyosoExerciseCreateUpdateModule:PointsLabel',
                                  )}
                                  type="number"
                                  min="0"
                                  register={formRegister({
                                    required: 'Required',
                                  })}
                                  error={
                                    formState.errors.answers &&
                                    formState.errors.answers[
                                      answers.findIndex((x) => x.is_other)
                                    ]?.other_answers &&
                                    formState.errors.answers[
                                      answers.findIndex((x) => x.is_other)
                                    ]?.other_answers[innerIndex]?.answers[
                                      innerInnerIndex
                                    ] &&
                                    formState.errors.answers[
                                      answers.findIndex((x) => x.is_other)
                                    ]?.other_answers[innerIndex]?.answers[
                                      innerInnerIndex
                                    ].points
                                  }
                                  name={`answers[${answers.findIndex(
                                    (x) => x.is_other,
                                  )}].other_answers[${innerIndex}].answers[${innerInnerIndex}].points`}
                                  defaultValue={innerInnerAnswer.points}
                                  customCss={{
                                    width: 320,
                                  }}
                                />
                                {innerInnerIndex > 0 && (
                                  <SubmitButtonLab
                                    className="black"
                                    type="button"
                                    onClick={() => {
                                      const newAnswers = formValues().answers
                                      newAnswers[
                                        answers.findIndex((x) => x.is_other)
                                      ].other_answers[
                                        innerIndex
                                      ].answers.splice(innerInnerIndex, 1)
                                      formSetValue(`answers`, newAnswers)
                                    }}
                                    customCss={{
                                      width: 50,
                                      marginBottom: 10,
                                    }}
                                  >
                                    <i className="fas fa-trash-alt"></i>
                                  </SubmitButtonLab>
                                )}
                              </InnerOpenAnswers>
                            ),
                          )}
                          <SubmitButtonLab
                            type="button"
                            className="black"
                            customCss={{ width: 180 }}
                            onClick={() => {
                              const newAnswers = formValues().answers
                              newAnswers[
                                answers.findIndex((x) => x.is_other)
                              ].other_answers[innerIndex].answers.push({
                                answer: null,
                                points: 0,
                              })
                              formSetValue(`answers`, newAnswers)
                            }}
                          >
                            <i className="fas fa-plus"></i>
                            <span className="new-answer-text">
                              {t(
                                'lab:KyosoExerciseCreateUpdateModule:NewBtnLabel',
                              )}
                            </span>
                          </SubmitButtonLab>
                          {innerIndex > 0 && (
                            <DuplicateDeleteButtons>
                              <i
                                className="fas fa-trash-alt"
                                onClick={() => {
                                  setAccordionIndex(null)
                                  const newAnswers = formValues().answers
                                  newAnswers[
                                    answers.findIndex((x) => x.is_other)
                                  ].other_answers.splice(innerIndex, 1)
                                  formSetValue(`answers`, newAnswers)
                                }}
                              ></i>
                            </DuplicateDeleteButtons>
                          )}
                        </InnerOpenAnswersContainer>
                      </div>
                    </InnerOpenAnswersAccordion>
                  ))}

                <SubmitButtonLab
                  type="button"
                  className="black"
                  customCss={{
                    width: 180,
                    display: 'block',
                    margin: '0 auto',
                    marginTop: 30,
                  }}
                  onClick={() => {
                    const newAnswers = formValues().answers
                    newAnswers[
                      answers.findIndex((x) => x.is_other)
                    ].other_answers.push({
                      description: null,
                      answers: [
                        {
                          answer: null,
                          points: 0,
                        },
                      ],
                    })
                    formSetValue(`answers`, newAnswers)
                  }}
                >
                  <i className="fas fa-plus"></i>
                  <span className="new-answer-text">
                    {t(
                      'lab:KyosoExerciseCreateUpdateModule:NewAnswerFieldLabel',
                    )}
                  </span>
                </SubmitButtonLab>
              </div>
              <div className="points">
                {formWatch(
                  `answers[${answers.findIndex((x) => x.is_other)}].is_correct`,
                ) && (
                  <div className="correct-answer-text">
                    {t(
                      'lab:KyosoExerciseCreateUpdateModule:CorrectAnswerLabel',
                    )}
                  </div>
                )}
              </div>
              <DuplicateDeleteButtons>
                <i
                  className="fas fa-trash-alt"
                  onClick={() => {
                    removeAnswer(
                      answers.find((x) => x.is_other).id,
                      answers.findIndex((x) => x.is_other),
                    )
                  }}
                ></i>
              </DuplicateDeleteButtons>
            </OtherAnswersLayout>
          </>
        ) : null}

        {(answerType === '1' || answerType === '2') && (
          <Scoring>
            <div className="type">
              <FormRadioButtons
                radioButtons={scoringTypeRadioBtns}
                label="lab:KyosoExerciseCreateUpdateModule:ScoringTypeLabel"
                type="radio"
                name="scoringType"
                register={formRegister({ required: 'Required' })}
                onChange={(e) => {
                  handleSpecialScoring(e)
                }}
                error={formState.errors.scoringType}
              />
              {answerType === '2' && (
                <div className="open-answer-info">
                  {t('lab:KyosoExerciseCreateUpdateModule:OpenAnswerInfo')}
                </div>
              )}
            </div>
            {formWatch('scoringType') === '2' && (
              <SpecialScoring>
                {specialScorings.map((scoring, index) => (
                  <FormInput
                    key={scoring.id}
                    label={t(
                      index > 0
                        ? 'lab:KyosoExerciseCreateUpdateModule:SpecialPointsLabelPlural'
                        : 'lab:KyosoExerciseCreateUpdateModule:SpecialPointsLabel',
                      { count: index + 1 },
                    )}
                    type="number"
                    min="0"
                    register={formRegister({ required: 'Required' })}
                    error={
                      formState.errors.specialScoring &&
                      formState.errors.specialScoring[index]?.points
                    }
                    name={`specialScoring[${index}].points`}
                    defaultValue={+scoring.points}
                    customCss={{ marginBottom: 0 }}
                  />
                ))}
              </SpecialScoring>
            )}
          </Scoring>
        )}
      </AnswersFormsByTypeLayout>
    )
  else return null
}

export { AnswersFormsByType }

const AnswersFormsByTypeLayout = styled('div')({
  marginBottom: 30,

  '#noCorrectAnswer': {
    textAlign: 'center',
    color: COLORS.secRed,
    fontSize: 18,
    marginBottom: 20,
  },

  '.new-answer-text, .new-other-answer-text': {
    marginLeft: 10,
  },

  '.new-other-answer-label': {
    fontSize: 18,
    color: COLORS.secBlack,
    marginBottom: 15,
  },

  '.long-answer-info': {
    color: COLORS.secRed,
    fontSize: 18,
    marginBottom: 30,
  },
})

const DuplicateDeleteButtons = styled('div')({
  position: 'absolute',
  top: 20,
  right: 20,
  display: 'flex',
  gap: 20,

  i: {
    cursor: 'pointer',
    fontSize: 20,
    width: 20,
    height: 20,
  },

  '.fa-copy': {
    color: COLORS.secBlack,
  },

  '.fa-trash-alt': {
    color: COLORS.secRed,
  },
})

const commonAnswerLayoutProps = {
  position: 'relative',
  padding: '20px 30px',
  border: `1px solid ${COLORS.borderGray}`,
  borderRadius: 15,
  marginBottom: 30,
}

const singleMultiLayoutProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 30,
  ...commonAnswerLayoutProps,

  '.correct-answer-text': {
    color: COLORS.secGreen,
    fontSize: 18,
    marginBottom: 10,
  },
}

const SingleAnswersLayout = styled('div')({
  ...singleMultiLayoutProps,
})

const OtherAnswersLayout = styled('div')({
  ...singleMultiLayoutProps,

  '.fields': {
    width: '100%',
  },

  '.points': {
    width: 240,
  },

  '.possible-answers-label': {
    color: COLORS.secBlack,
    fontSize: 18,
    marginBottom: 30,
  },
})

const MultiAnswersLayout = styled('div')({
  ...singleMultiLayoutProps,
})

const OpenAnswersLayout = styled('div')({
  ...commonAnswerLayoutProps,

  '.possible-answers-label': {
    color: COLORS.secBlack,
    fontSize: 18,
    marginBottom: 30,
  },
})

const InnerOpenAnswersContainer = styled('div')({
  position: 'relative',
})

const InnerOpenAnswers = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 35,
})

const LongAnswersLayout = styled('div')({
  ...commonAnswerLayoutProps,

  '.title': {
    color: COLORS.secBlack,
    fontSize: 18,
    marginBottom: 15,
  },

  '.content': {
    color: COLORS.mainGray,
    fontSize: 16,
    marginBottom: 20,
  },
})

const NewAnswersButtons = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 35,
  marginBottom: 30,
})

const Scoring = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  marginBottom: 30,
  columnGap: 10,

  '.open-answer-info': {
    color: COLORS.secRed,
    fontSize: 18,
    marginTop: 20,
  },
})

const SpecialScoring = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: 70,
  gap: 30,
})

const RadioButton = styled('div')({
  position: 'relative',
  height: 22,
  minWidth: 22,
  border: `2px solid ${COLORS.borderGray}`,
  borderRadius: '50%',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: COLORS.borderGray,
  },

  '&.checked': {
    '&:hover': {
      backgroundColor: 'initial',
    },

    '&:after': {
      visibility: 'visible',
    },
  },

  '&:after': {
    visibility: 'hidden',
    borderRadius: '50%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    content: `''`,
    width: 12,
    height: 12,
    backgroundColor: COLORS.secBlue,
  },
})

const InnerOpenAnswersAccordion = styled('div')({
  border: '2px solid #00000026',
  borderRadius: 25,
  padding: 20,
  backgroudColor: 'lightgray',
  marginBottom: 30,

  '& .title': {
    margin: '10px 0px',
    position: 'relative',

    '& h2': {
      fontSize: 'larger',
      marginBottom: 10,
      cursor: 'pointer',
      fontWeight: 'bold',
      color: COLORS.secBlack,
    },

    '& i': {
      position: 'absolute',
      top: 0,
      right: 0,
      fontSize: 'x-large',
      color: COLORS.secBlack,
      cursor: 'pointer',

      '&.open': {
        transform: 'rotate(90deg)',
      },
    },
  },

  '& .content': {
    marginTop: 30,
    display: 'none',
  },

  '& .content.open': {
    display: 'block',
  },
})
