import styled from '@emotion/styled'
import { GenericButton, HubModalBackground } from 'platforms/kyoso-hub/shared'
import { hub_selectTheme } from 'platforms/kyoso-hub/store/generalSlice'
import {
  hub_hidePaymentProcessModal,
  hub_selectPaymentProcessModalState,
} from 'platforms/kyoso-hub/store/paymentSlice'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { EscButton, ModalLayout, PageInfo } from 'shared'
import { COLORS } from 'styling/variables'

function PaymentStatusModal() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const userTheme = useSelector(hub_selectTheme)
  const { show, paymentStatus } = useSelector(
    hub_selectPaymentProcessModalState,
  )
  const [modalState, setModalState] = useState(null)

  useEffect(() => {
    show && paymentStatusHandler(paymentStatus)
  }, [paymentStatus])

  const paymentStatusHandler = (paymentStatus) => {
    let modalContent = null

    switch (paymentStatus) {
      case 'SUCCESS':
        modalContent = {
          title: t('hub:Participation:ModalEpaySuccessTitle'),
          content: t('hub:Participation:ModalEpaySuccessContent'),
          color: COLORS.secGreen,
        }
        break
      case 'FAIL':
        modalContent = {
          title: t('hub:Participation:ModalEpayFailTitle'),
          content: t('hub:Participation:ModalEpayFailContent'),
          color: COLORS.secRed,
        }
        break
      case 'PROCESSING':
        modalContent = {
          title: t('hub:Participation:ModalEpayRedirectTitle'),
          content: t('hub:Participation:ModalEpayRedirectContent'),
          color: COLORS.secYellow,
        }
        break
      default:
        // console.log(paymentStatus)
    }

    setModalState(modalContent)
  }

  const closeModalHandler = () => {
    dispatch(hub_hidePaymentProcessModal())
  }

  return show && modalState ? (
    <HubModalBackground>
      <ModalLayout customCss={{ width: 'auto', textAlign: 'center' }}>
        <PageInfo
          title={modalState.title}
          customCss={{ h1: { color: `${modalState.color}` } }}
        />

        <EscButton onClick={closeModalHandler} />

        <ModalContent>{modalState.content}</ModalContent>

        <GenericButton
          customCss={{ maxWidth: 250, margin: 'auto' }}
          onClick={closeModalHandler}
          theme={userTheme}
        >
          {t('shared:Modals:ModalCloseButton')}
        </GenericButton>
      </ModalLayout>
    </HubModalBackground>
  ) : null
}

export { PaymentStatusModal }

const ModalContent = styled('p')(({ customCss }) => ({
  marginBottom: 20,
  fontSize: 18,

  ...customCss,
}))
