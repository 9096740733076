import API from 'utils/backendApi'

function insertChildInvites(childId, emails) {
  const body = JSON.stringify({
    childId,
    emails,
  })
  return API('lab').POST('/kyoso/child/invites', body)
}

function removeChildInvite(inviteId) {
  return API('lab').DELETE(`/kyoso/child/invite/${inviteId}`)
}

function getChildInvites(childId) {
  return API('lab').GET(`/kyoso/child/${childId}/invites`)
}

export { getChildInvites, insertChildInvites, removeChildInvite }
