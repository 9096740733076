import API from 'utils/backendApi'

function getInvitedCompetitions() {
  return API('hub').GET(`/participation/invited`)
}

function getSignedUpCompetitions() {
  return API('hub').GET(`/participation/signedUp`)
}

function getConductingCompetitions() {
  return API('hub').GET(`/participation/canStart`)
}

function getParticipationDataForCompetitionModal(childId) {
  return API('hub').GET(`/participation/modal/${childId}/edit`)
}

function saveParticipationDataForCompetitionModal(
  participationId,
  { newUserData = '_omit_', isTouchedData = '_omit_' } = {},
) {
  const body = JSON.stringify({
    new_user_data: newUserData,
    is_touched_data: isTouchedData,
  })

  return API('hub').POST(`/participation/modal/${participationId}/save`, body)
}

export {
  getInvitedCompetitions,
  getSignedUpCompetitions,
  getConductingCompetitions,
  getParticipationDataForCompetitionModal,
  saveParticipationDataForCompetitionModal,
}
