import styled from '@emotion/styled'
import { QUERIES } from 'styling/variables'

export const PersonalizationWrapper = styled('div')(({ customCss }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gridTemplateRows: '1fr 1fr',
  gridGap: '20px',
  marginBottom: 20,

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  ...customCss,
}))
