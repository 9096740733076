import { SubmitButtonLab } from 'platforms/kyoso-lab/shared'
import { lab_selectUser } from 'platforms/kyoso-lab/store/userSlice'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  ConfirmationMessage,
  FormInput,
  PageInfo,
  TinyTextEditor,
} from 'shared'
import { COLORS } from 'styling/variables'

export function VariantThreePaymentForm({ onSubmitHandler }) {
  const { t } = useTranslation()
  const reduxUser = useSelector(lab_selectUser)
  const [onSuccess, setOnSuccess] = useState(null)

  const { register, handleSubmit, control, formState, setError } = useForm({
    mode: 'onBlur',
  })

  const onSubmit = (data) => {
    onSubmitHandler(data, 'paymentInfo', setError, setOnSuccess)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageInfo
          title={t('lab:Settings:PaymentInfoTitle')}
          subtitle={t('lab:Settings:PaymentInfoSubTitle')}
          customCss={{
            marginBottom: 30,
            h1: { marginBottom: 10, fontSize: 20 },
          }}
        />

        {onSuccess === 'paymentInfo' && (
          <ConfirmationMessage
            color={COLORS.secBlue}
            message={t('shared:BankDetailsChangedSuccessfully')}
            removeMessageCallback={() => setOnSuccess(false)}
          />
        )}

        <TinyTextEditor
          id="bank-info"
          name="bank_info"
          label="lab:Settings:LabelBankInfo"
          control={control}
          error={formState.errors.bank_info}
          defaultValue={reduxUser.bankDetails}
          extendInitOptions={{
            toolbar: [
              'undo redo cut copy paste pastetext | styleselect forecolor fontsize | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent ',
              'subscript superscript | link image table  bullist numlist |',
            ],
          }}
        />

        <FormInput
          id="merchant-id"
          type="text"
          name="merchant_id"
          description={t('lab:Settings:DescriptionMerchantId')}
          label={t('lab:Settings:LabelMerchantId')}
          register={register()}
          error={formState.errors.profile_name}
          touched={formState.touched.profile_name}
          defaultValue={reduxUser.KIN}
        />

        <FormInput
          id="secret-word"
          type="text"
          name="secret_word"
          description={t('lab:Settings:DescriptionSecretWord')}
          label={t('lab:Settings:LabelSecretWord')}
          register={register()}
          error={formState.errors.profile_name}
          touched={formState.touched.profile_name}
          defaultValue={reduxUser.secretWord}
        />

        <SubmitButtonLab className="black">
          {t('lab:Shared:SubmitButtonSave')}
        </SubmitButtonLab>
      </form>
    </>
  )
}
