import React, { useEffect, useState } from 'react'
import { getAllKyosoTypes } from 'services/shared'
import { FormRadioButtons } from './FormRadioButtons'

export function KyosoTypesRadioButtons({
  label,
  id,
  type,
  name,
  register,
  error,
}) {
  const [kyosoTypes, setKyosoTypes] = useState([])

  useEffect(() => {
    getAllKyosoTypes()
      .then((response) => {
        let kyosoTypes = []

        kyosoTypes = response.types.map((type) => ({
          ...type,
          name: name,
          label: type.name,
          register: register,
        }))

        setKyosoTypes(kyosoTypes)
      })
      .catch((error) => console.log(error))
  }, [])

  return (
    <FormRadioButtons
      radioButtons={kyosoTypes}
      id={id}
      type={type}
      error={error}
      label={label}
    />
  )
}
