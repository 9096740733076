import { SubmitButtonLab } from 'platforms/kyoso-lab/shared'
import { lab_selectUser } from 'platforms/kyoso-lab/store/userSlice'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ConfirmationMessage, FormInput, PageInfo } from 'shared'
import { COLORS } from 'styling/variables'

export function ChangeProfileNameForm({ onSubmitHandler }) {
  const { t } = useTranslation()
  const reduxUser = useSelector(lab_selectUser)
  const [onSuccess, setOnSuccess] = useState(null)

  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    formState,
    setError,
  } = useForm({
    mode: 'onBlur',
  })

  const onSubmit = (data) => {
    onSubmitHandler(data, 'changeUsername', setError, setOnSuccess)
  }

  return (
    <>
      <PageInfo
        title={t('lab:Settings:ChangeNameTitle')}
        subtitle={t('lab:Settings:ChangeNameSubTitle')}
        customCss={{ marginBottom: 30, h1: { marginBottom: 10, fontSize: 20 } }}
      />

      {onSuccess === 'changeUsername' && (
        <ConfirmationMessage
          color={COLORS.secBlue}
          message={t('shared:UsernameChangedSuccessfully')}
          removeMessageCallback={() => setOnSuccess(false)}
        />
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          id="profile-name"
          type="text"
          name="profile_name"
          label={`${t('lab:Settings:ProfileName')} `}
          register={register()}
          error={formState.errors.profile_name}
          touched={formState.touched.profile_name}
          defaultValue={reduxUser.username}
        />

        <SubmitButtonLab className="black">
          {t('lab:Settings:Change')}
        </SubmitButtonLab>
      </form>
    </>
  )
}
