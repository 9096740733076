import { GenericButton } from 'platforms/kyoso-hub/shared'
import { hub_selectTheme } from 'platforms/kyoso-hub/store/generalSlice'
import React from 'react'
import { useSelector } from 'react-redux'

export function InfoButton({ urlTo, children }) {
  const currentTheme = useSelector(hub_selectTheme)

  return (
    <a href={urlTo} target="_blank" rel="noopener noreferrer">
      <GenericButton
        type="button"
        customCss={{
          margin: 0,
          minWidth: 45,
          minHeight: 45,
          width: '100%',
        }}
        className="inverted"
        theme={currentTheme}
      >
        {children}
      </GenericButton>
    </a>
  )
}
