import styled from '@emotion/styled'
import { getAvailableChildrenForBundle } from 'platforms/kyoso-lab/services/kyoso/bundles'
import { List, ListWrapper, SubmitButtonLab } from 'platforms/kyoso-lab/shared'
import {
  lab_hideGlobalLoading,
  lab_showGlobalLoading,
} from 'platforms/kyoso-lab/store/generalSlice'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { getAllKyosoTypes } from 'services/shared'
import {
  ContentTwoColumsLayout,
  FirstColumn,
  FormCheckBox,
  FormInput,
  PageInfo,
  SchoolGradeSelect,
  SchoolSubjectSelect,
  SecondColumn,
} from 'shared'
import { COLORS } from 'styling/variables'
import { minMaxValue, yearValidator } from 'utils/helpers/validators'

export function KyosoBundleForm({ bundleContent, submitCallback, isDisabled }) {
  const { action } = useParams()
  const { t } = useTranslation()
  const [disabled, setDisabled] = useState(isDisabled || false)
  const [kyosoTypes, setKyosoTypes] = useState([])
  const [kyosoChildren, setKyosoChildren] = useState(
    bundleContent?.kyoso_children || [],
  )
  const [participants, setParticipants] = useState(
    bundleContent?.participations || 0,
  )
  const [disabledChildren, setDisabledChildren] = useState(
    kyosoChildren.filter((child) => child.is_checked).map((x) => `${x.id}`),
  )
  const dispatch = useDispatch()

  const {
    register,
    getValues,
    handleSubmit,
    setError,
    setValue,
    trigger,
    control,
    clearErrors,
    formState,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      name: bundleContent?.name || null,
      schoolSubject: bundleContent?.subject_id || null,
      year: bundleContent?.year || null,
      grade: bundleContent?.grade_id || null,
      price: bundleContent?.price || null,
      participants: bundleContent?.participations || null,
      isOpen: bundleContent?.open || null,
      selected_types: bundleContent?.selected_types?.split(',') || [],
    },
  })

  const onSubmit = (data) => {
    // console.log(data)

    const updatedData = {
      ...data,
      children:
        typeof data.children !== 'object'
          ? data.children
            ? [data.children]
            : []
          : data.children,
    }
    console.log(updatedData)

    submitCallback(updatedData)
  }

  useEffect(() => {
    getAllKyosoTypes()
      .then((res) => setKyosoTypes(res.types))
      .catch((err) => console.log(err))

    if (bundleContent?.selected_types) {
      dispatch(lab_showGlobalLoading())
      getAvailableChildrenForBundle(
        bundleContent?.grade_id,
        bundleContent?.subject_id,
        bundleContent?.selected_types,
      )
        .then((response) => {
          setKyosoChildren(response.children)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => dispatch(lab_hideGlobalLoading()))
    }
  }, [])

  const onChangeHandler = () => {
    const { schoolSubject, grade, selected_types } = getValues()

    if (schoolSubject && grade) {
      dispatch(lab_showGlobalLoading())
      getAvailableChildrenForBundle(grade, schoolSubject, selected_types)
        .then((response) => {
          setKyosoChildren(response.children)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => dispatch(lab_hideGlobalLoading()))
    }
  }

  const onChangeParticipants = (e) => {
    if (e.target.value >= getValues('children')?.length) {
      clearErrors('err_msg')
    }

    setParticipants(e.target.value)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContentTwoColumsLayout customCss={{ marginBottom: 20 }}>
        <FirstColumn>
          <PageInfo
            subtitle={t('lab:KyosoBundle:BundleSubTitle')}
            customCss={{
              '& h2': {
                fontSize: '18px',
                marginBottom: 20,
              },
            }}
          />
          <FirstRow>
            <FormInput
              customCss={{ marginBottom: 0 }}
              id="name"
              type="text"
              name="name"
              label="lab:Shared:LabelName"
              register={register({
                required: 'Required',
              })}
              error={formState.errors.name}
              touched={formState.touched.name}
            />
          </FirstRow>

          <SecondRow>
            <SchoolSubjectSelect
              id="schoolSubject"
              customCss={{ marginBottom: 0 }}
              label="lab:Shared:LabelSubject"
              name="schoolSubject"
              disabled={disabled}
              setValue={setValue}
              getValues={getValues}
              clearErrors={clearErrors}
              onChange={() => {
                trigger('schoolSubject'), onChangeHandler()
              }}
              setError={setError}
              rules={{ required: 'Required' }}
              control={control}
              defaultValue={null}
              error={formState.errors.schoolSubject}
            />

            <FormInput
              customCss={{ marginBottom: 0 }}
              id="year"
              type="number"
              name="year"
              label={`${t('lab:Shared:TitleYear')} *`}
              readOnly={disabled}
              onChange={() => trigger('year')}
              register={register({
                required: 'Required',
                validate: {
                  yearValidation: (value) => yearValidator(value),
                },
              })}
              error={formState.errors.year}
              touched={formState.touched.year}
            />

            <SchoolGradeSelect
              customCss={{
                marginBottom: 0,
              }}
              id="grade"
              name="grade"
              disabled={disabled}
              rules={{ required: 'Required' }}
              label={`${t('shared:LabelSchoolClass')} *`}
              setValue={setValue}
              getValues={getValues}
              onChange={() => {
                trigger('grade'), onChangeHandler()
              }}
              control={control}
              defaultValue={null}
              error={formState.errors.grade}
            />
          </SecondRow>

          <ThirdRow>
            <FormInput
              customCss={{ marginBottom: '0' }}
              id="price"
              type="number"
              name="price"
              step="0.01"
              min="0.01"
              label={`${t('lab:KyosoBundle:LabelPriceBundle')} *`}
              readOnly={disabled}
              register={register({
                required: 'Required',
              })}
              error={formState.errors.price}
              touched={formState.touched.price}
            />

            <FormInput
              customCss={{ marginBottom: '0' }}
              id="participants"
              type="number"
              name="participants"
              label={`${t('lab:KyosoBundle:LabelParticipants')} *`}
              readOnly={disabled}
              onChange={(e) => onChangeParticipants(e)}
              register={register({
                required: 'Required',
                validate: {
                  minLength: (value) =>
                    minMaxValue(
                      value,
                      2,
                      'min',
                      t('lab:KyosoBundle:ErrorMsgMinParticipants'),
                    ),
                },
              })}
              error={formState.errors.participants}
              touched={formState.touched.participants}
            />

            <FormCheckBox
              defaultChecked={getValues('isOpen')}
              id="isOpen"
              name="isOpen"
              label="lab:KyosoBundle:LabelOpen"
              register={register()}
              error={formState.errors.isOpen}
              touched={formState.touched.isOpen}
              onChange={(e) => setDisabled(e.target.checked)}
              customCss={{
                justifyContent: 'left',
                alignSelf: 'center',
                marginTop: 25,
                marginBottom: 0,
                '.text': {
                  color: COLORS.mainGray,
                },
              }}
            />
          </ThirdRow>

          <ForthRow className="bottom-checkboxes">
            {kyosoTypes.map((type) => {
              return (
                <FormCheckBox
                  key={`${type.id}_${type.name}`}
                  id={`type_${type.id}_${type.name}`}
                  name={`selected_types`}
                  label={type.name}
                  value={type.id}
                  register={register()}
                  disabled={disabled}
                  onChange={onChangeHandler}
                  customCss={{
                    marginBottom: '0',
                    ' .text': {
                      marginLeft: '10px',
                      gridColumn: '2',
                      color: COLORS.mainGray,
                      justifySelf: 'left',
                    },
                  }}
                />
              )
            })}
          </ForthRow>

          {formState.errors.err_msg && (
            <ErrorMsg>{formState.errors.err_msg.message}</ErrorMsg>
          )}
        </FirstColumn>

        <SecondColumn customCss={{ height: '100%' }}>
          <PageInfo
            subtitle={t('lab:KyosoBundle:EventsDescription')}
            customCss={{
              '& h2': {
                fontSize: '16px',
                marginBottom: 20,
              },
            }}
          />

          {formState.errors.err_msg_children && (
            <ErrorMsg>{formState.errors.err_msg_children.message}</ErrorMsg>
          )}

          <ErrorMsg>{t('lab:KyosoBundle:ErrorMsgNoChildren')}</ErrorMsg>

          <ListWrapper customCss={{ minHeight: '500px', height: 'auto' }}>
            <List customCss={{ gridTemplateColumns: '1fr', padding: 5 }}>
              {kyosoChildren.map((child) => {
                // console.log(child)
                return (
                  <FormCheckBox
                    defaultChecked={child.is_checked}
                    value={child.id}
                    key={child.id}
                    id={child.id}
                    name="children"
                    label={child.name}
                    disabled={child.is_passed || disabled}
                    register={register()}
                    onChange={(e) => {
                      if (
                        getValues('children').length <=
                        getValues('participants')
                      ) {
                        clearErrors('err_msg')
                      }

                      setDisabledChildren(getValues('children'))
                    }}
                    error={formState.errors.children}
                    touched={formState.touched.children}
                    customCss={{
                      justifyContent: 'left',
                      alignSelf: 'center',
                      marginTop: 0,
                      marginBottom: 0,
                      '.text': {
                        color: COLORS.mainGray,
                      },
                    }}
                  />
                )
              })}
            </List>
          </ListWrapper>
        </SecondColumn>
      </ContentTwoColumsLayout>

      <PageFooter>
        <PageInfo
          subtitle={t('lab:KyosoBundle:FooterDescription')}
          customCss={{
            '& h2': {
              fontSize: '16px',
            },
          }}
        />

        <SubmitButtonLab
          type="submit"
          className="black"
          customCss={{ marginLeft: 20, width: 350 }}
        >
          {action === 'insert'
            ? t('lab:Shared:SubmitButtonCreate')
            : t('lab:Shared:SubmitButtonSave')}
        </SubmitButtonLab>
      </PageFooter>
    </form>
  )
}

const FirstRow = styled('div')(({ customCss }) => ({
  gridRow: '1',
  display: 'grid',

  marginBottom: '30px',

  ...customCss,
}))

const SecondRow = styled('div')({
  gridRow: '2',
  display: 'grid',
  gridTemplateColumns: '2fr 1fr 1fr',
  alignItems: 'end',
  gridColumnGap: '25px',
  marginBottom: '30px',
})

const ThirdRow = styled('div')({
  gridRow: '3',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  alignItems: 'end',
  gridColumnGap: '25px',
  marginBottom: '30px',
})

const ForthRow = styled('div')({
  gridRow: '4',
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  gap: 20,
})

const PageFooter = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const ErrorMsg = styled('p')({
  color: COLORS.secRed,
  marginBottom: 20,
})
