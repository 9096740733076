import styled from '@emotion/styled'

export const CompetitionInfo = styled('div')(({ customCss }) => ({
  display: 'flex',
  alignItems: 'center',

  '#headline': {
    fontSize: 22,
    marginBottom: 10,
  },
  ...customCss,
}))
