import styled from '@emotion/styled'
import { COLORS } from '../../../styling/variables'

function Footer() {
  return (
    <HubFooter id="hub-footer">
      <div>
        <FooterLink
          href="https://www.kyoso.tech/site/index.php/terms-conditions/"
          target="_blank"
        >
          Общи условия
        </FooterLink>
        <FooterLink
          href="https://www.kyoso.tech/site/index.php/privacy-policy/"
          target="_blank"
        >
          Политика за поверителност
        </FooterLink>
      </div>
      © {new Date().getFullYear()} kyoso.tech - всички права запазени
    </HubFooter>
  )
}

export { Footer }

const HubFooter = styled('div')({
  gridArea: 'footer',
  textAlign: 'center',
  backgroundColor: 'transparent',
  color: COLORS.secBlue,
  fontFamily: 'Balsamiq Sans',
  fontSize: 16,
  width: '100%',
})

const FooterLink = styled('a')({
  color: COLORS.secRed,
  textDecoration: 'none',
  fontSize: 14,
  paddingBottom: 10,
  display: 'inline-block',
  marginLeft: 10,
  marginRight: 10,

  [`:hover`]: {
    textDecoration: 'underline',
  },
})
