import API from 'utils/backendApi'

function getAllBundles({
  itemsPerPage = '_omit_',
  pageNumber = '_omit_',
  schoolGrade = '_omit_',
  schoolSubject = '_omit_',
  year = '_omit_',
} = {}) {
  const body = JSON.stringify({
    items: itemsPerPage,
    page: pageNumber,
    grade_id: schoolGrade,
    subject_id: schoolSubject,
    year,
  })
  return API('lab').POST('/kyoso/bundles', body)
}

function getBundle(bundleId) {
  return API('lab').GET(`/kyoso/bundle/${bundleId}`)
}

function getAvailableChildrenForBundle(
  schoolGrade,
  schoolSubject,
  selectedTypes,
) {
  const body = JSON.stringify({
    grade_id: schoolGrade,
    subject_id: schoolSubject,
    selected_types: selectedTypes,
  })
  return API('lab').POST('/kyoso/bundle/availableChildren', body)
}

function insertBundle(
  owner_id,
  name,
  subject_id,
  grade_id,
  {
    year = '_omit_',
    price = '_omit_',
    participations = '_omit_',
    open = '_omit_',
    kyoso_children = '_omit_',
    selected_types = '_omit_',
  } = {},
) {
  const body = JSON.stringify({
    id: '_omit_',
    owner_id,
    name,
    subject_id,
    grade_id,
    year,
    price,
    participations,
    open,
    kyoso_children,
    selected_types,
  })
  return API('lab').POST('/kyoso/bundle', body)
}

function updateBundle(
  id,
  name,
  subject_id,
  grade_id,
  {
    year = '_omit_',
    price = '_omit_',
    participations = '_omit_',
    open = '_omit_',
    kyoso_children = '_omit_',
    selected_types = '_omit_',
  } = {},
) {
  const body = JSON.stringify({
    id,
    owner_id: '_omit_',
    name,
    subject_id,
    grade_id,
    year,
    price,
    participations,
    open,
    kyoso_children,
    selected_types,
  })
  return API('lab').POST('/kyoso/bundle', body)
}

function getOrdersForBundleWithFilter(
  bundleId,
  {
    itemsPerPage = '_omit_',
    pageNumber = '_omit_',
    searchText = '_omit_',
    startDate = '_omit_',
    endDate = '_omit_',
    paymentMethod = '_omit_',
    isPaid = '_omit_',
    isUnpaid = '_omit_',
    declaration = '_omit_',
  } = {},
) {
  const body = JSON.stringify({
    items: itemsPerPage,
    page: pageNumber,
    search_text: searchText,
    start_date: startDate,
    end_date: endDate,
    payment_method: paymentMethod,
    is_paid: isPaid,
    is_unpaid: isUnpaid,
    declaration,
  })
  return API('lab').POST(`/kyoso/bundle/${bundleId}/orders`, body)
}

function getBundleEvents(bundleId) {
  return API('lab').GET(`/kyoso/bundle/${bundleId}/events`)
}

export {
  getAllBundles,
  getBundle,
  getAvailableChildrenForBundle,
  insertBundle,
  updateBundle,
  getOrdersForBundleWithFilter,
  getBundleEvents,
}
