export const COLORS = {
  mainGray: '#979CA8',
  primBlue: '#1EBCEB',
  secBlue: '#0086CE',
  secRed: '#C21F24',
  secGreen: '#6FA31C',
  secBlack: '#3F3A51',
  secYellow: '#F8D030',
  bgGray: '#FAFAFA',
  borderGray: '#DADEE6',
  disabledGray: '#F2F2F2',
}
export const THEMES = [
  COLORS.secBlue,
  COLORS.secRed,
  COLORS.secGreen,
  COLORS.secBlack,
]

export const QUERIES = {
  tablet: 1300,
  mobile: 767,
}
