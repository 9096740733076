import styled from '@emotion/styled'
import React, { useEffect } from 'react'
import { useState } from 'react'
import NoImgPlaceholder from 'assets/img/no_img_placeholder.png'
import { useHistory, useParams } from 'react-router-dom'
import {
  CompetitionInfo,
  ContentTwoColumsLayout,
  ImageWrapper,
  PageInfo,
  ParticipationDeclaration,
} from 'shared'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'styling/variables'
import { ParticipationForm } from '../components/participation/ParticipationForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  getParticipationInfo,
  participationSignUp,
} from '../services/participation'
import { getFileLinkFromServerStorage } from 'utils/helpers/common'
import {
  hub_hideGlobalLoader,
  hub_setPage,
  hub_showGlobalLoader,
} from '../store/generalSlice'
import { ParticipationModal } from '../components/participation/ParticipationModal'
import { hub_showPaymentProcessModal } from '../store/paymentSlice'
import { hub_selectUser } from '../store/userSlice'
import {
  deleteDeclaration,
  getDeclaration,
  saveDeclaration,
} from '../services/user'

function ParticipationPage() {
  const getUserData = useSelector(hub_selectUser)
  const routeHistory = useHistory()
  const dispatch = useDispatch()
  const [userData, setUserData] = useState(getUserData)
  const [pageContent, setPageContent] = useState(null)
  const [modalData, setModalData] = useState(null)
  const [isFree, setIsFree] = useState(false)
  const [declaration, setDeclaration] = useState(null)
  const [isUserInvited, setIsUserInvited] = useState(false)
  const [hideDeclaration, setHideDeclaration] = useState(false)
  const { childId } = useParams()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(hub_showGlobalLoader())
    getParticipationInfo(childId)
      .then((response) => {
        const {
          type_id,
          is_user_invited,
          parent_price,
          child_price,
          name,
          child_grade_name,
          sign_up_type,
        } = response.info

        // console.log(response)

        if (is_user_invited && sign_up_type === '1') {
          routeHistory.replace('/hub/')
        } else {
          dispatch(
            hub_setPage({
              title: t('hub:Participation:ParticipationPageTitle', {
                name: `${name} ${child_grade_name} `,
              }),
            }),
          )

          if (type_id === 4 || type_id === 5) {
            setHideDeclaration(true)
          }

          if (is_user_invited) {
            setIsUserInvited(true)
          }

          if (!child_price && !parent_price) {
            setIsFree(true)
          }
          setPageContent(response.info)
          getSignature()
        }
      })
      .catch((error) => {
        console.log(error)
        routeHistory.replace('/hub/')
      })
      .finally(() => dispatch(hub_hideGlobalLoader()))
  }, [])

  const onSubmit = (data, formState) => {
    const hasInvoiceData = data.invoice || null
    const invoiceData = (({
      invoiceType,
      invoice_PIN,
      invoice_VAT,
      invoice_address,
      invoice_city,
      invoice_name,
      invoice_firm_name,
      invoice_receiver,
      invoice_responsible_person,
      invoice_unit_id_code,
    }) => ({
      type: invoiceType,
      company: {
        invoice_VAT,
        invoice_address,
        invoice_city,
        invoice_firm_name,
        invoice_receiver,
        invoice_responsible_person,
        invoice_unit_id_code,
      },
      individual: {
        invoice_PIN,
        invoice_address,
        invoice_city,
        invoice_name,
      },
    }))(data)
    const { parent_id, user_grade_id, owner_bank_details } = pageContent

    const touchedFields = Object.keys(formState.touched).filter(
      (field) => !['invoice', 'paymentType'].some((key) => field.includes(key)),
    )

    const newUserData = { ...data }
    delete newUserData.paymentType
    let isDataTouched = false

    if (touchedFields.length) {
      isDataTouched = true
    } else if (data.grade_id != user_grade_id) {
      isDataTouched = true
    }

    participationSignUp(
      parent_id,
      childId,
      isDataTouched,
      data.paymentType,
      owner_bank_details,
      {
        newUserData: isDataTouched ? JSON.stringify(newUserData) : null,
        invoiceData: hasInvoiceData
          ? invoiceData.type === '1'
            ? JSON.stringify(invoiceData.individual)
            : JSON.stringify(invoiceData.company)
          : null,
      },
    )
      .then((response) => {
        // console.log(response)

        setModalData({
          ...pageContent,
          participant_id: response.participant_id,
          order_id: response.order_id,
          order_date: response.order_date,
          payment_type: data.paymentType,
        })

        if (isFree) {
          closeModalHandler()
        }
      })
      .catch((err) => console.log(err))
  }

  const onInputChangeHandler = (data) => {
    setUserData({ ...userData, ...data })
  }

  const closeModalHandler = () => {
    setModalData(null)
    routeHistory.push('/hub/competitions')
  }

  const getSignature = () => {
    getDeclaration(userData.id, childId)
      .then((response) => {
        setDeclaration(response.declaration)
      })
      .catch((error) => console.log(error))
  }

  const saveSignatureHandler = (signature) => {
    if (signature)
      saveDeclaration(signature, '1', { child_id: childId })
        .then(() => {
          getSignature()
        })
        .catch((error) => {
          console.log(error)
        })
    else
      deleteDeclaration({ child_id: childId, type: declaration.type })
        .then(() => {
          getSignature()
        })
        .catch((error) => {
          console.log(error)
        })
  }

  return (
    pageContent && (
      <>
        {modalData && !isFree && (
          <ParticipationModal
            participationDetails={modalData}
            closeModalCallback={closeModalHandler}
          />
        )}

        <ContentTwoColumsLayout
          customCss={{ gridTemplateColumns: '2fr 1.5fr' }}
        >
          <FirstColumn>
            <CompetitionInfo customCss={{ marginBottom: 20 }}>
              <ImageWrapper>
                <img
                  src={
                    pageContent?.picture
                      ? getFileLinkFromServerStorage(pageContent.picture)
                      : NoImgPlaceholder
                  }
                  alt="exc-img"
                />
              </ImageWrapper>
              <div>
                <h2 id="headline">{`${pageContent?.name} ${pageContent?.child_grade_name} `}</h2>
                <h3 id="sub-headline">{`${pageContent?.subject_name} - ${pageContent?.year} - ${pageContent?.type_name} `}</h3>
              </div>
            </CompetitionInfo>

            <PageInfo
              customCss={{
                marginBottom: 20,
                p: {
                  color: COLORS.secGreen,
                },
              }}
              subtitle={
                isUserInvited
                  ? t('hub:Participation:UserInvitedDescrtiption', {
                      id_parent_child: `${pageContent?.name} ${pageContent?.child_grade_name} `,
                      owner_name: pageContent?.owner_name,
                    })
                  : t('hub:Participation:UserNotInvitedDescription')
              }
            />

            {pageContent && (
              <ParticipationForm
                pageContent={pageContent}
                onSubmitHandler={onSubmit}
                onChangeCallback={onInputChangeHandler}
                isFree={isFree}
              />
            )}
          </FirstColumn>
          {!hideDeclaration && (
            <SecondColumn>
              <ParticipationDeclaration
                data={{
                  ...userData,
                  declaration: declaration,
                  owner_name: pageContent?.owner_name,
                  competition_name: pageContent?.name,
                  signature: declaration?.signature,
                }}
                onSubmitHandler={saveSignatureHandler}
              />
            </SecondColumn>
          )}
        </ContentTwoColumsLayout>
      </>
    )
  )
}

export { ParticipationPage }

const FirstColumn = styled('div')({
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'column',
})

const SecondColumn = styled('div')({
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'column',
})
