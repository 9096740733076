import styled from '@emotion/styled'
import { relativeTimeRounding } from 'moment'
import { NavLink } from 'react-router-dom'
import { COLORS, QUERIES, THEMES } from 'styling/variables'

export const IconLinkButton = styled(NavLink)(({ customCss, theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 28,
  color: COLORS.mainGray,
  cursor: 'pointer',
  borderRight: `3px solid transparent`,
  position: 'relative',

  '& i:before': {
    display: 'inline-block',
    textDecoration: 'none',
  },
  '&:hover': {
    background: `${COLORS.bgGray} 0% 0% no-repeat padding-box`,
    borderRight: `3px solid ${COLORS.bgGray}`,
  },
  '&.active': {
    background: `${THEMES[+theme]}14 0% 0% no-repeat padding-box`,
    color: THEMES[+theme],
    borderRight: `3px solid ${THEMES[+theme]}`,
  },
  '&.soon': {
    background: 'white',
    color: COLORS.mainGray,
    borderRight: `3px solid transparent`,
    '&:before': {
      content: '"скоро"',
      position: 'absolute',
      fontSize: 12,
      backgroundColor: COLORS.secRed,
      padding: 2,
      color: 'white',
      borderRadius: 4,
      top: 10,
      right: 15
    },
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    fontSize: 24,
    border: 'none',
    borderTop: `3px solid #ffff`,

    '&.active': {
      background: `${THEMES[+theme]}14 0% 0% no-repeat padding-box`,
      color: THEMES[+theme],
      border: 'none',
      borderTop: `3px solid ${THEMES[+theme]}`,
    },
  },

  ...customCss,
}))


export const IconLinkExternalButton = styled('a')(({ customCss, theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 28,
  color: COLORS.mainGray,
  cursor: 'pointer',
  borderRight: `3px solid transparent`,
  position: 'relative',

  '& i:before': {
    display: 'inline-block',
    textDecoration: 'none',
  },
  '&:hover': {
    background: `${COLORS.bgGray} 0% 0% no-repeat padding-box`,
    borderRight: `3px solid ${COLORS.bgGray}`,
  },
  '&.active': {
    background: `${THEMES[+theme]}14 0% 0% no-repeat padding-box`,
    color: THEMES[+theme],
    borderRight: `3px solid ${THEMES[+theme]}`,
  },
  '&.soon': {
    background: 'white',
    color: COLORS.mainGray,
    borderRight: `3px solid transparent`,
    '&:before': {
      content: '"скоро"',
      position: 'absolute',
      fontSize: 12,
      backgroundColor: COLORS.secRed,
      padding: 2,
      color: 'white',
      borderRadius: 4,
      top: 10,
      right: 15
    },
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    fontSize: 24,
    border: 'none',
    borderTop: `3px solid #ffff`,

    '&.active': {
      background: `${THEMES[+theme]}14 0% 0% no-repeat padding-box`,
      color: THEMES[+theme],
      border: 'none',
      borderTop: `3px solid ${THEMES[+theme]}`,
    },
  },

  ...customCss,
}))
