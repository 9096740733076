import styled from '@emotion/styled'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  changePassword,
  deleteDeclaration,
  getDeclaration,
  saveDeclaration,
} from 'platforms/kyoso-hub/services/user'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { PersonalDataDeclaration } from 'shared'
import { COLORS, QUERIES, THEMES } from 'styling/variables'
import { AdditionalInfoForm } from '../components/settings/AdditionalInfoForm'
import { ChangePasswordForm } from '../components/settings/ChangePasswordForm'
import { PersonalizationForm } from '../components/settings/PersonalizationForm'
import { getUserData } from '../services/user'
import {
  hub_hideGlobalLoader,
  hub_selectTheme,
  hub_setPage,
  hub_showGlobalLoader,
} from '../store/generalSlice'
import { hub_selectUser, hub_updateUser } from '../store/userSlice'

export function SettingsPage() {
  const reduxUser = useSelector(hub_selectUser)
  const dispatch = useDispatch()
  const currentTheme = useSelector(hub_selectTheme)
  const [action, setAction] = useState('changePassword')
  const [userData, setUserData] = useState(null)
  const [signature, setSignature] = useState(null)
  const { t } = useTranslation()

  const menuItemHandler = (action) => {
    setAction(action)
  }

  const getData = () => {
    dispatch(hub_showGlobalLoader())

    getUserData(reduxUser.id)
      .then((response) => {
        setUserData(response.userData)
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(hub_hideGlobalLoader()))
  }

  const getSignature = () => {
    getDeclaration(reduxUser.id)
      .then((response) => {
        setSignature(response.declaration?.signature)
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    getData()
    getSignature()

    dispatch(
      hub_setPage({
        title: t('shared:Settings'),
      }),
    )
  }, [action])

  const onSubmitHandler = (data, submitType, setError, setOnSuccess, reset) => {
    switch (submitType) {
      case 'changePassword':
        changePassword(data.current_password, data.new_password)
          .then((response) => {
            setOnSuccess('changePassword')
            reset()
          })
          .catch((err) => {
            setError('current_password', {
              type: 'manual',
              message: t('errors:104'),
            })
          })

        break
      case 'changePersonalInfo':
        dispatch(
          hub_updateUser({
            user_id: reduxUser.id,
            username: data.username,
            avatar: data.avatar,
            theme: data.theme,
          }),
        )
          .then(unwrapResult)
          .then((response) => {
            setOnSuccess('changePersonalInfo')
            getData()
          })

        break
      case 'changeAdditionalInfo':
        dispatch(
          hub_updateUser({
            user_id: reduxUser.id,
            full_name: data.fullName,
            grade_id: data.schoolGrade || '_omit_',
            school: data.schoolName,
            city: data.town,
            parent_names: data.parentName,
            parent_phone: data.parentPhone,
          }),
        )
          .then(unwrapResult)
          .then((response) => {
            setOnSuccess('changeAdditionalInfo')
            getData()
          })

        break
      case 'declarationInfo':
        if (data)
          saveDeclaration(data, '0')
            .then((res) => {
              getSignature()
            })
            .catch((error) => {
              console.log(error)
            })
        else
          deleteDeclaration({ type: '0' })
            .then(() => {
              getSignature()
            })
            .catch((error) => {
              console.log(error)
            })
        break
      default:
        console.error(
          'Wrong submit type in function onSubmitHandler from Settings(HUB): ',
          submitType,
        )
        break
    }
  }

  const actionHandler = (act) => {
    let content = <ChangePasswordForm />

    switch (act) {
      case 'changePassword':
        content = <ChangePasswordForm onSubmitHandler={onSubmitHandler} />
        break
      case 'changePersonalInfo':
        content = <PersonalizationForm onSubmitHandler={onSubmitHandler} />
        break
      case 'changeAdditionalInfo':
        content = userData && (
          <AdditionalInfoForm
            userData={userData}
            onSubmitHandler={onSubmitHandler}
          />
        )
        break
      case 'declarationInfo':
        content = (
          <PersonalDataDeclaration
            data={{ ...userData, signature }}
            onSubmitHandler={onSubmitHandler}
          />
        )
        break
      default:
        console.error(
          'Wrong act in function actionHandler from Settings(HUB): ',
          act,
        )
        break
    }

    return content
  }

  return (
    <>
      <PageLayout>
        <SideMenu>
          <MenuItem
            className={action === 'changePassword' ? 'active' : null}
            onClick={() => menuItemHandler('changePassword')}
            theme={currentTheme}
          >
            {t('hub:Settings:PasswordChangeTitle')}
          </MenuItem>
          <MenuItem
            className={action === 'changePersonalInfo' ? 'active' : null}
            onClick={() => menuItemHandler('changePersonalInfo')}
            theme={currentTheme}
          >
            {t('hub:Settings:PersonalInfoTitle')}
          </MenuItem>
          <MenuItem
            className={action === 'changeAdditionalInfo' ? 'active' : null}
            onClick={() => menuItemHandler('changeAdditionalInfo')}
            theme={currentTheme}
          >
            {t('hub:Settings:AdditionalInfoTitle')}
          </MenuItem>
          <MenuItem
            className={action === 'declarationInfo' ? 'active' : null}
            onClick={() => menuItemHandler('declarationInfo')}
            theme={currentTheme}
          >
            {t('hub:Settings:DeclarationInfoTitle')}
          </MenuItem>
        </SideMenu>

        <DisplayContent>{actionHandler(action)}</DisplayContent>
      </PageLayout>
    </>
  )
}
const PageLayout = styled('div')(({ customCss }) => ({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '2fr 7fr',
  border: `1px solid ${COLORS.borderGray}`,
  borderRadius: 20,
  overflow: 'hidden',

  [`@media (max-width: ${QUERIES.tablet}px)`]: {
    display: 'block',
    flexDirection: 'row',
  },
}))

const SideMenu = styled('div')(({ customCss }) => ({
  display: 'grid',
  gridTemplateRows: '1fr 1fr 1fr 1fr',
  borderRight: `1px solid ${COLORS.borderGray}`,
  placeItems: 'center',

  '& :nth-of-type(1)': {
    borderBottom: `1px solid ${COLORS.borderGray}`,
  },

  '& :nth-of-type(2)': {
    borderBottom: `1px solid ${COLORS.borderGray}`,
  },
  '& :nth-of-type(3)': {
    borderBottom: `1px solid ${COLORS.borderGray}`,
  },

  [`@media (max-width: ${QUERIES.tablet}px)`]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridTemplateRows: 'none',
    borderRight: 'none',
    borderBottom: `1px solid ${COLORS.borderGray}`,

    '& :nth-of-type(1)': {
      borderBottom: `5px solid #ffff`,
    },

    '& :nth-of-type(2)': {
      borderBottom: `5px solid #ffff`,
    },
    '& :nth-of-type(3)': {
      borderBottom: `5px solid #ffff`,
    },

    '& :last-of-type': {
      borderBottom: `5px solid #ffff`,
      borderRight: 'none',
    },
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    gridTemplateRows: '1fr 1fr 1fr 1fr',
    gridTemplateColumns: 'none',

    '& :nth-of-type(1)': {
      borderRight: 'none',
    },

    '& :nth-of-type(2)': {
      borderTop: `1px solid ${COLORS.borderGray}`,
      borderRight: 'none',
    },
    '& :nth-of-type(3)': {
      borderTop: `1px solid ${COLORS.borderGray}`,
      borderRight: 'none',
    },

    '& :last-of-type': {
      borderTop: `1px solid ${COLORS.borderGray}`,
      borderRight: 'none',
    },
  },
}))

const MenuItem = styled('div')(({ customCss, theme }) => ({
  height: '100%',
  width: '100%',
  padding: '20px ',
  display: 'grid',
  justifyContent: 'right',
  alignContent: 'center',
  color: COLORS.mainGray,

  fontSize: '18px',
  textAlign: 'right',
  borderRight: `5px solid #ffff `,

  ' :hover': {
    cursor: 'pointer',
    background: COLORS.bgGray,
    color: THEMES[+theme],
    borderRight: `5px solid ${COLORS.bgGray} `,
  },

  '&.active': {
    background: `${THEMES[+theme]}14 0% 0% no-repeat padding-box`,
    color: THEMES[+theme],
    borderRight: `5px solid ${THEMES[+theme]}`,
  },

  [`@media (max-width: ${QUERIES.tablet}px)`]: {
    borderRight: `1px solid ${COLORS.borderGray}`,
    textAlign: 'center',
    justifyContent: 'center',

    ' :hover': {
      borderRight: `1px solid ${COLORS.borderGray}`,
      borderBottom: `5px solid ${COLORS.bgGray}  `,
    },

    '&.active': {
      borderRight: `1px solid ${COLORS.borderGray} `,
      borderBottom: `5px solid ${THEMES[+theme]} !important`,
    },
  },
  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    padding: 10,
    fontSize: 16,

    ' :hover': {
      borderRight: `none`,
      borderBottom: `5px solid ${COLORS.bgGray}  `,
    },

    '&.active': {
      borderRight: `none `,
      borderBottom: `5px solid ${THEMES[+theme]} !important`,
    },
  },
}))

const DisplayContent = styled('div')(({ customCss }) => ({
  padding: 30,
  // height: 'calc(100vh - 270px)',

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    padding: 10,
  },
}))
