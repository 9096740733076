import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import hub_generalSlice from 'platforms/kyoso-hub/store/generalSlice'
import hub_userSlice, {
  hub_logOutUser,
} from 'platforms/kyoso-hub/store/userSlice'
import hub_competitionSlice from 'platforms/kyoso-hub/store/competitionSlice'
import hub_paymentSlice from 'platforms/kyoso-hub/store/paymentSlice'
import lab_userSlice, {
  lab_logOutUser,
} from 'platforms/kyoso-lab/store/userSlice'
import lab_generalSlice from 'platforms/kyoso-lab/store/generalSlice'
import { decodeJWT, handleRejectedFetch } from './helpers/common'
import { askForToken } from './backendApi'

const KYOSO_REDUX_STORE = 'kyoso_redux_store'
const cachedStore = localStorage.getItem(KYOSO_REDUX_STORE)
const platform = window.location.href.includes('lab') ? 'lab' : 'hub'

const checkToken = (store) => (next) => (action) => {
  const tokenData = decodeJWT(askForToken(platform))
  const user = store.getState()[platform].user.id
  const date = new Date()
  const expDate = new Date(tokenData?.exp)
  let result = next(action)

  try {
    if (expDate <= date) {
      throw new Error('EXP_SESSION')
    }

    if (!user) {
      throw new Error('NO_USER')
    }
  } catch (error) {
    if (platform === 'lab') {
      return lab_logOutUser(tokenData.id).finally(() => {
        lab_clearAuthToken()
        handleRejectedFetch(error, store)
        window.location.assign('/lab?unauthorized=true')
      })
    } else {
      return hub_logOutUser().finally(() => {
        hub_clearAuthToken()
        handleRejectedFetch(error, store)
        window.location.assign('/hub?unauthorized=true')
      })
    }
  } finally {
    return result
  }
}

const storeConfig = {
  reducer: {
    hub: combineReducers({
      user: hub_userSlice,
      general: hub_generalSlice,
      competition: hub_competitionSlice,
      payment: hub_paymentSlice,
    }),
    lab: combineReducers({ user: lab_userSlice, general: lab_generalSlice }),
  },
  middleware: [...getDefaultMiddleware(), checkToken],
}

if (cachedStore) {
  storeConfig.preloadedState = JSON.parse(cachedStore)
}

const store = configureStore(storeConfig)

store.subscribe(() => {
  const tokenData = decodeJWT(askForToken(platform))

  if (tokenData) {
    localStorage.setItem(KYOSO_REDUX_STORE, JSON.stringify(store.getState()))
  } else {
    localStorage.removeItem(KYOSO_REDUX_STORE)
  }
})

export default store
