import API from '../../../utils/backendApi'

function getCompetitionInfo(childId) {
  return API('hub').GET(`/competition/info?childId=${childId}`)
}

function getPublicCompetitionData(childId) {
  return API('hub').GET(
    `/competition/public/get-competition-data?childId=${childId}`,
  )
}

function checkForPublic(childId) {
  return API('hub').GET(`/competition/public/check-public?childId=${childId}`)
}

function getCompetitionExercises(childId, userId) {
  return API('hub').GET(
    `/competition/exercises?childId=${childId}&userId=${userId}`,
  )
}

function uploadAnswerFilesForExercise(token, files) {
  const body = new FormData()
  body.append('token', decodeURIComponent(token))
  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    body.append(`solution_${i + 1}`, file)
  }

  return API('hub').POST(`/competition/exercises/media/save`, body)
}

function getAnswerFilesForExercise(token) {
  const body = JSON.stringify({ token: decodeURIComponent(token) })

  return API('hub').POST(`/competition/exercises/media/get`, body)
}

function deleteAnswerFileForExercise(token, path, fromMobile = false) {
  const body = JSON.stringify({
    token: decodeURIComponent(token),
    file_path: path,
    fromMobile,
  })

  return API('hub').POST(`/competition/exercises/media/delete`, body)
}

function startCompetition(childId) {
  const body = JSON.stringify({ child_id: childId })

  return API('hub').POST(`/competition/start`, body)
}

function saveAnswersForCompetition(childId, answersJson, answersStructJson) {
  const body = JSON.stringify({
    child_id: childId,
    answers: answersJson,
    answers_struct: answersStructJson,
  })

  return API('hub').POST(`/competition/answers/save`, body)
}

function finishCompetition(
  childId,
  moduleId = null,
  { nextModuleId = null, nextModuleDuration = null, isFinal = false } = {},
) {
  const body = JSON.stringify({
    child_id: childId,
    module_id: moduleId,
    next_module_id: nextModuleId,
    next_module_duration: nextModuleDuration,
    is_final: isFinal,
  })

  return API('hub').POST(`/competition/finish`, body)
}

function getCompetitionScoreData(childId) {
  return API('hub').GET(`/competition/${childId}/score`)
}

function filterCompetitions({
  itemsPerPage = '_omit_',
  pageNumber = '_omit_',
  name = '_omit_',
  schoolGrade = '_omit_',
  schoolSubject = '_omit_',
  owner = '_omit_',
  types = '_omit_',
} = {}) {
  const body = JSON.stringify({
    items: itemsPerPage,
    page: pageNumber,
    name,
    grade_id: schoolGrade,
    subject_id: schoolSubject,
    owner,
    types,
  })
  return API('hub').POST('/competition/filter', body)
}

function resetCompetition(childId) {
  return API('hub').POST(`/participation/results/personal/${childId}/reset`)
}

function getCompetitionsResults() {
  return API('hub').GET('/competition/results')
}

function getCompetitionsResultsInPDF(competitionResultsData) {
  const body = JSON.stringify({
    data: competitionResultsData,
  })
  return API('hub').DOWNLOAD_FILE('/competition/results/pdf', {
    method: 'post',
    body,
  })
}

function getCompetitionsExercisesInPDF(child_id) {
  const body = JSON.stringify({
    child_id,
  })
  return API('hub').DOWNLOAD_FILE('/competition/info/pdf', {
    method: 'post',
    body,
  })
}

export {
  getCompetitionInfo,
  getCompetitionExercises,
  uploadAnswerFilesForExercise,
  getAnswerFilesForExercise,
  deleteAnswerFileForExercise,
  startCompetition,
  saveAnswersForCompetition,
  finishCompetition,
  getCompetitionScoreData,
  filterCompetitions,
  resetCompetition,
  getCompetitionsResults,
  getCompetitionsResultsInPDF,
  getCompetitionsExercisesInPDF,
  getPublicCompetitionData,
  checkForPublic,
}
