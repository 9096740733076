import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { EscButton, ModalLayout, PageInfo, TileElement } from 'shared'
import { COLORS, THEMES } from 'styling/variables'
import { hub_selectTheme } from '../store/generalSlice'

function MobileMenuModal({ closeModalCallback }) {
  const routeHistory = useHistory()
  const currentTheme = useSelector(hub_selectTheme)
  const { t } = useTranslation()

  const menuItemClickHandler = (menuAction) => {
    switch (menuAction) {
      case 'COMPETITIONS':
        routeHistory.push('/hub/competitions')

        break
      case 'RESULTS':
        routeHistory.push('/hub/results')

        break
      case 'CALLENDAR':
        // routeHistory.push('/hub/callendar')

        break
      case 'NEWS':
        window.open("https://kyoso.tech/site", "_blank");

        break
      default:
        // console.log(menuAction)
    }

    closeModalCallback()
  }

  return (
    <HubModalMenuBackground>
      <HubModalMenuLayout>
        <EscButton onClick={closeModalCallback} />

        <PageInfo
          customCss={{
            textAlign: 'center',
            h1: { color: THEMES[+currentTheme], fontSize: 22, marginTop: 30 },
            h2: { fontSize: 16 },
          }}
          title={t('hub:MenuTitle')}
          subtitle={t('hub:MenuSubtitle')}
        />

        <MenuButtonsContainer>
          <TileElement onClick={() => menuItemClickHandler('COMPETITIONS')}>
            <i className="fas fa-award"></i>
            <p>{t('shared:Competitions')}</p>
          </TileElement>

          <TileElement onClick={() => menuItemClickHandler('RESULTS')}>
            <i className="fas fa-chart-bar"></i>
            <p>{t('shared:Results')}</p>
          </TileElement>

          <TileElement 
            // onClick={() => menuItemClickHandler('CALLENDAR')}
            className="soon">
            <i className="far fa-calendar-alt"></i>
            <p>{t('shared:Calendar')}</p>
          </TileElement>

          <TileElement onClick={() => menuItemClickHandler('NEWS')}>
            <i className="far fa-newspaper"></i>
            <p>{t('shared:News')}</p>
          </TileElement>
        </MenuButtonsContainer>
      </HubModalMenuLayout>
    </HubModalMenuBackground>
  )
}

export { MobileMenuModal }

const HubModalMenuBackground = styled('div')({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: COLORS.bgGray,
})

const HubModalMenuLayout = styled('div')({
  overflow: 'auto',
  padding: 10,
  position: 'fixed',
  top: 60,
  bottom: 60,
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 0px 5px #0000001A',
  borderRadius: 20,
  maxWidth: 1600,
  width: 'calc(100% - 20px)',
  margin: 'auto',
  left: '50%',
  transform: 'translateX(-50%)',
  minHeight: 'calc(100vh - 200px)',
})

const MenuButtonsContainer = styled('div')({
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
})
