import styled from '@emotion/styled'
import { SubmitButtonLab } from 'platforms/kyoso-lab/shared'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yearValidator } from 'utils/helpers/validators'
import { useParams } from 'react-router'
import {
  ContentTwoColumsLayout,
  FormCheckBox,
  FormFile,
  FormInput,
  FormRadioButtons,
  PageInfo,
  SchoolSubjectSelect,
  TinyTextEditor,
} from 'shared'

import { FormDatePicker } from 'shared/components/FormDatePicker'
import { KyosoTypesRadioButtons } from 'shared/components/KyosoTypesRadioButtons'
import { COLORS } from 'styling/variables'
import { getFileLinkFromServerStorage } from 'utils/helpers/common'

export function KyosoParentForm({ parentData, submitCallBack }) {
  const { action, parentId } = useParams()
  const { t } = useTranslation()
  const [isDisabled, setIsDisabled] = useState(null)

  const {
    reset,
    register,
    control,
    getValues,
    handleSubmit,
    setValue,
    trigger,
    setError,
    clearErrors,
    formState,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      dates: parentData?.intervals
        ? JSON.parse(parentData.intervals)
        : [{ startDate: '', endDate: '' }],
      name: parentData?.name || null,
      year: parentData?.year || null,
      schoolSubject: parentData?.subject_id || null,
      type: `${parentData?.type_id}`,
      price: parentData?.price || null,
      description: parentData?.description || null,
      visibility: parentData?.visibility || '2',
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dates',
  })

  const visibilityRadioButtons = [
    {
      id: 0,
      name: 'visibility',
      label: t('lab:KyosoParent:VisibilityPublic'),
      register: register({ required: 'Required' }),
      error: formState.errors.visibility,
    },
    {
      id: 1,
      name: 'visibility',
      label: t('lab:KyosoParent:VisibilityPrivate'),
      register: register({ required: 'Required' }),
      error: formState.errors.visibility,
    },
    {
      id: 2,
      name: 'visibility',
      label: t('lab:KyosoParent:VisibilityHidden'),
      register: register({ required: 'Required' }),
      error: formState.errors.visibility,
    },
  ]

  useEffect(() => {
    if (parentData?.paid !== null) {
      if (+parentData?.paid === 0) {
        setValue('isFree', true)
        setIsDisabled('isFree')
      } else if (+parentData?.paid === 1 && parentData.price) {
        setIsDisabled('price')
      } else if (+parentData?.paid === 2) {
        setValue('isIndividual', true)
        setIsDisabled('isIndividual')
      }
    } else {
      setIsDisabled(null)
    }
  }, [parentData])

  const onSubmit = (data, addChild) => {
    const shouldAddChild = addChild === 'ADD_CHILD'

    if (!Object.keys(formState.errors).length) {
      submitCallBack(data, shouldAddChild)
    }
  }

  const isDisabledHandler = (e, name) => {
    if (e.target.type === 'checkbox') {
      if (isDisabled === name) {
        return setIsDisabled(null)
      }
    } else {
      if (e.target.value) {
        setIsDisabled(name)
      } else {
        return setIsDisabled(null)
      }
    }

    clearErrors('requred_priceInfo')
    setIsDisabled(name)
  }

  const validateHandler = (e) => {
    e.preventDefault()
    const { price, isIndividual, isFree } = getValues()

    if (!price && !isIndividual && !isFree) {
      setError('requred_priceInfo', {
        type: 'manual',
        message: t('lab:KyosoParent:RequiredPriceOptions'),
      })
    } else {
      handleSubmit((data) => onSubmit(data, e.target.name))()
    }
  }

  return (
    <form onSubmit={validateHandler}>
      <ContentTwoColumsLayout>
        <FirstColumn>
          <FirstRow>
            <FormInput
              customCss={{ marginBottom: '0' }}
              id="formName"
              type="text"
              name="name"
              label="lab:Shared:LabelName"
              register={register({
                required: 'Required',
              })}
              error={formState.errors.name}
              touched={formState.touched.name}
            />
          </FirstRow>

          <SecondRow>
            <SchoolSubjectSelect
              id="schoolSubject"
              customCss={{ marginBottom: 0 }}
              label="lab:Shared:LabelSubject"
              name="schoolSubject"
              setValue={setValue}
              getValues={getValues}
              clearErrors={clearErrors}
              onChange={() => trigger('schoolSubject')}
              setError={setError}
              rules={{ required: 'Required' }}
              control={control}
              defaultValue={null}
              error={formState.errors.schoolSubject}
            />

            <FormInput
              customCss={{ marginBottom: '0' }}
              id="formYear"
              type="number"
              name="year"
              label="lab:KyosoParent:LabelYear"
              register={register({
                required: 'Required',
                validate: {
                  yearValidation: (value) => yearValidator(value),
                },
              })}
              error={formState.errors.year}
              touched={formState.touched.year}
            />
          </SecondRow>

          <FormFile
            customCss={{}}
            id="headerImg"
            type="file"
            name="image"
            label="lab:KyosoParent:LabelImg"
            buttonLabel="lab:KyosoParent:LabelUploadButton"
            readOnly={true}
            register={register()}
            error={formState.errors.image}
            touched={formState.touched.image}
          />

          {parentData?.picture && (
            <SubmitButtonLab
              type="button"
              className="black"
              onClick={() =>
                window.open(
                  getFileLinkFromServerStorage(parentData.picture),
                  '_blank',
                )
              }
              id="picture-preview"
            >
              Покажи логото
            </SubmitButtonLab>
          )}

          <KyosoTypesRadioButtons
            label="lab:KyosoParent:LabelType"
            id={'radioButtons'}
            type="radio"
            name="type"
            register={register({ required: 'Required' })}
            error={formState.errors.type}
          />

          <ul>
            <PageInfo
              title={t('lab:KyosoParent:DatePicker')}
              customCss={{
                '& h1': {
                  fontSize: '18px',
                  marginBottom: 15,
                },
              }}
            />
            {fields.map((item, index) => {
              return (
                <ListItem key={item.id}>
                  <FormDatePicker
                    id="startDate"
                    control={control}
                    label="lab:KyosoParent:LabelStartDate"
                    name={`dates[${index}].startDate`}
                    setValue={setValue}
                    error={
                      formState.errors.dates &&
                      formState.errors.dates[index]?.startDate
                    }
                    defaultValue={`${item.startDate}`}
                  />

                  <FormDatePicker
                    id="endDate"
                    control={control}
                    label="lab:KyosoParent:LabelEndDate"
                    name={`dates[${index}].endDate`}
                    setValue={setValue}
                    error={
                      formState.errors.dates &&
                      formState.errors.dates[index]?.endDate
                    }
                    defaultValue={`${item.endDate}`}
                  />

                  <SubmitButtonLab
                    type="button"
                    className="black"
                    disabled={index === 0}
                    onClick={() => {
                      remove(index)
                    }}
                  >
                    <i className="fas fa-trash-alt"></i>{' '}
                  </SubmitButtonLab>
                </ListItem>
              )
            })}

            <SubmitButtonLab
              type="button"
              className="black"
              customCss={{ marginBottom: '20px' }}
              onClick={() => append({ startDate: '', endDate: '' })}
            >
              {t('lab:KyosoParent:AddDateInput')}
            </SubmitButtonLab>
          </ul>
        </FirstColumn>

        <SecondColumn>
          {formState.errors.requred_priceInfo && (
            <p className="red">{formState.errors.requred_priceInfo.message}</p>
          )}
          <FirstRow
            customCss={{
              gridTemplateColumns: '1fr 1fr 1fr',
              placeItems: 'center',
            }}
          >
            <FormInput
              customCss={{ marginBottom: '0' }}
              id="formPrice"
              type="number"
              name="price"
              step="0.01"
              min="0.01"
              label="lab:KyosoParent:LabelPrice"
              register={register()}
              onChange={(e) => {
                isDisabledHandler(e, 'price')
              }}
              error={formState.errors.price}
              touched={formState.touched.price}
              readOnly={isDisabled !== null && isDisabled !== 'price'}
            />

            <FormCheckBox
              defaultChecked={getValues('isFree')}
              id="freePriceCheckBox"
              name="isFree"
              label="lab:KyosoParent:LabelFree"
              register={register()}
              value={true}
              onChange={(e) => {
                isDisabledHandler(e, 'isFree')
              }}
              disabled={isDisabled !== null && isDisabled !== 'isFree'}
              error={formState.errors.isFree}
              touched={formState.touched.isFree}
              customCss={{
                justifyContent: 'left',
                marginTop: 25,
                marginBottom: 0,
                '.text': {
                  color: COLORS.mainGray,
                },
              }}
            />

            <FormCheckBox
              defaultChecked={getValues('isIndividual')}
              id="individualPriceCheckBox"
              name="isIndividual"
              label="lab:KyosoParent:LabelIndividual"
              value={true}
              register={register()}
              disabled={isDisabled !== null && isDisabled !== 'isIndividual'}
              onChange={(e) => {
                isDisabledHandler(e, 'isIndividual')
              }}
              error={formState.errors.isIndividual}
              touched={formState.touched.isIndividual}
              customCss={{
                justifyContent: 'left',
                marginTop: 25,
                marginBottom: 0,

                '.text': {
                  color: COLORS.mainGray,
                },
              }}
            />
          </FirstRow>

          <TinyTextEditor
            id="baseDescription"
            name="description"
            label="lab:KyosoParent:LabelDescriptionText"
            control={control}
            error={formState.errors.description}
            extendInitOptions={{
              toolbar: [
                'undo redo cut copy paste pastetext | styleselect forecolor fontsizeselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent ',
                'subscript superscript | link image table  bullist numlist |',
              ],
            }}
          />

          <FormRadioButtons
            radioButtons={visibilityRadioButtons}
            label="lab:KyosoParent:LabelVisibility"
            id="visibilityRadioButtons"
            type="radio"
            name="visibility"
            register={register()}
            error={formState.errors.visibility}
          />
        </SecondColumn>
      </ContentTwoColumsLayout>

      <PageFooter>
        <PageInfo subtitle={t('lab:KyosoParent:SecondDescriptionPage')} />

        <SubmitButtonLab
          type="submit"
          className="black"
          customCss={{ marginLeft: 25, maxWidth: '250px' }}
        >
          {action === 'insert'
            ? t('lab:Shared:SubmitButtonCreate')
            : t('lab:KyosoParent:SubmitButtonEdit')}
        </SubmitButtonLab>

        <SubmitButtonLab
          type="submit"
          className="black"
          name="ADD_CHILD"
          customCss={{ marginLeft: 25, maxWidth: '250px' }}
          onClick={(e) => validateHandler(e)}
        >
          {t('lab:KyosoParent:AddChildButton')}
        </SubmitButtonLab>
      </PageFooter>
    </form>
  )
}

const ListItem = styled('ul')({
  display: 'grid',
  alignItems: 'end',
  gridTemplateColumns: '2fr 2fr 45px',
  gridColumnGap: '20px',
  marginBottom: '20px',
})

const PageFooter = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const FirstColumn = styled('div')({
  '#picture-preview': {
    marginBottom: 30,
  },
})

const SecondColumn = styled('div')({
  '.red': {
    color: COLORS.secRed,
  },
})

const FirstRow = styled('div')(({ customCss }) => ({
  gridRow: '1',
  display: 'grid',
  gridColumnGap: '25px',

  marginBottom: '30px',

  ...customCss,
}))

const SecondRow = styled('div')({
  gridRow: '2',
  display: 'grid',
  gridTemplateColumns: '2fr 200px',
  alignItems: 'end',
  gridColumnGap: '25px',
  marginBottom: '30px',
})
