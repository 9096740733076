import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FormInput, PageInfo, TitleIconWrapper } from 'shared'
import { ChildInformation } from '../components/kyoso-child-evaluation/ChildInformation'
import {
  getCompetitionEvaluationData,
  getCompetitionManualScoring,
  saveCompetitionNewManualScoring,
} from '../services/kyoso/competitions'
import { SubmitButtonLab } from '../shared'
import {
  lab_hideGlobalLoading,
  lab_showGlobalLoading,
} from '../store/generalSlice'

function KyosoChildEvaluationPage() {
  const { childId, userId } = useParams()
  const [allChildrenData, setAllChildrenData] = useState({})
  const [filteredChildrenData, setFilteredChildrenData] = useState([])
  const [showDataIndex, setShowDataIndex] = useState(0)
  const [scoring, setScoring] = useState([])
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { register, formState, handleSubmit } = useForm({
    mode: 'onSubmit',
  })

  useEffect(() => {
    dispatch(lab_showGlobalLoading())
    Promise.all([
      getCompetitionEvaluationData(childId),
      getCompetitionManualScoring(childId),
    ])
      .then((response) => {
        const { message, statCode, ...body } = response[0]
        setScoring(response[1].scoringData)
        setAllChildrenData(body)
        if (userId)
          setFilteredChildrenData([
            body.evaluationData?.find((x) => x.user_id === +userId),
          ])
        else setFilteredChildrenData(body.evaluationData)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        dispatch(lab_hideGlobalLoading())
      })
  }, [childId])

  function onSearchFilterSubmit(data) {
    const textToSearch = data.searchText.trim()
    setShowDataIndex(0)
    if (textToSearch)
      setFilteredChildrenData(
        allChildrenData.evaluationData.filter(
          (x) =>
            x.full_name === textToSearch ||
            x.participant_id === textToSearch ||
            `KY-${x.id}` === textToSearch ||
            x.email === textToSearch,
        ),
      )
    else setFilteredChildrenData(allChildrenData.evaluationData)
  }

  function goNextChild() {
    setShowDataIndex(showDataIndex + 1)
  }

  function goPrevChild() {
    setShowDataIndex(showDataIndex - 1)
  }

  function canGoNextChild() {
    return showDataIndex < filteredChildrenData.length - 1
  }

  function canGoPrevChild() {
    return showDataIndex > 0
  }

  function saveNewPoints(
    newPoints,
    userId,
    exerciseId,
    answerId,
    moduleId = null,
  ) {
    let newScore = scoring.find((x) => x.user_id === userId).points || {}
    if (moduleId) {
      ;((newScore[moduleId] ??= {})[exerciseId] ??= {})[answerId] = newPoints
    } else {
      ;(newScore[exerciseId] ??= {})[answerId] = newPoints
    }
    saveCompetitionNewManualScoring(childId, userId, JSON.stringify(newScore))
      .then(() => {
        setScoring((oldState) => {
          oldState.splice(
            oldState.findIndex((x) => x.user_id === userId),
            1,
            {
              user_id: userId,
              points: newScore,
            },
          )
          return oldState
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <KyosoChildEvaluationLayout>
      <div className="header">
        <TitleIconWrapper customCss={{ margin: 0 }}>
          <i className="fas fa-upload upload-icon"></i>
          <PageInfo
            title={`${childId} - ${allChildrenData.parentName} - ${
              allChildrenData.gradeName
            }
          - ${t('lab:KyosoChildEvaluation:TitleSubtext')}`}
          />
        </TitleIconWrapper>
        <div className="page-title"></div>

        <SearchFilter noValidate onSubmit={handleSubmit(onSearchFilterSubmit)}>
          <FormInput
            id="searchText"
            label={t('lab:KyosoChildEvaluation:FilterSearchText')}
            type="text"
            name="searchText"
            register={register()}
            defaultValue={null}
            customCss={{
              marginBottom: 0,
            }}
            error={formState.errors.searchText}
          />
          <SubmitButtonLab className="black" customCss={{ width: 170 }}>
            {t('lab:KyosoChildEvaluation:FilterSearchText')}
          </SubmitButtonLab>
        </SearchFilter>
      </div>
      {filteredChildrenData[showDataIndex] && (
        <ChildInformation
          prevChildCallback={goPrevChild}
          nextChildCallback={goNextChild}
          canGoNext={canGoNextChild}
          canGoPrev={canGoPrevChild}
          childInfo={filteredChildrenData[showDataIndex]}
          scoring={scoring}
          changePoints={saveNewPoints}
        />
      )}
    </KyosoChildEvaluationLayout>
  )
}

export { KyosoChildEvaluationPage }

const KyosoChildEvaluationLayout = styled('div')({
  '& .header': {
    display: 'flex',
    alignItems: 'flex-end',

    '& .page-title': {
      display: 'flex',
      alignItems: 'center',
    },
  },
})

const SearchFilter = styled('form')({
  display: 'flex',
  alignItems: 'flex-end',
  gap: 30,
  marginLeft: 'auto',
})
