import { Switch, Route, Redirect } from 'react-router-dom'
import { getAuthToken as lab_getAuthToken } from 'platforms/kyoso-lab/services/authentication'
import { getAuthToken as hub_getAuthToken } from 'platforms/kyoso-hub/services/authentication'

import { LandingPage as hub_LandingPage } from 'platforms/kyoso-hub/pages/Landing'
import { LandingLayout as hub_LandingLayout } from 'platforms/kyoso-hub/components/layouts/Landing'
import { OnboardingPage as hub_OnboardingPage } from 'platforms/kyoso-hub/pages/Onboarding'
import { MainLayout as hub_MainLayout } from 'platforms/kyoso-hub/components/layouts/Main'
import { SupportPage as hub_SupportPage } from 'platforms/kyoso-hub/pages/Support'
import { DashboardPage as hub_DashboardPage } from 'platforms/kyoso-hub/pages/Dashboard'
import { SettingsPage as hub_SettingsPage } from 'platforms/kyoso-hub/pages/Settings'
import { CompetitionsPage as hub_CompetitionsPage } from 'platforms/kyoso-hub/pages/Competitions'
import { CompetitionPage as hub_CompetitionPage } from 'platforms/kyoso-hub/pages/Competition'
import { CompetitionView as hub_CompetitionView } from 'platforms/kyoso-hub/components/competition/CompetitionView'
import { UploadExerciseSolutionPage as Hub_UploadExerciseSolutionPage } from 'platforms/kyoso-hub/pages/UploadExerciseSolution'
import { PublicRankingPage as Hub_PublicRankingPage } from 'platforms/kyoso-hub/pages/PublicRankingPage'
import { ParticipationPage as hub_ParticipationPage } from 'platforms/kyoso-hub/pages/Participation'
import { CompetitionsSeachPage as hub_CompetitionsSearchPage } from 'platforms/kyoso-hub/pages/CompetitionsSeach'
import { BundlesSearch as hub_BundlesSearch } from 'platforms/kyoso-hub/pages/BundlesSearch'
import { BundlePurchasePage as hub_BundlePurchasePage } from 'platforms/kyoso-hub/pages/BundlePurchase'
import { ResultsPage as hub_ResultsPage } from 'platforms/kyoso-hub/pages/Results'
import { PersonalResultsPage as hub_PersonalResultsPage } from 'platforms/kyoso-hub/pages/PersonalResults'
import { RankingPage as hub_RankingPage } from 'platforms/kyoso-hub/pages/Ranking'

import { LandingPage as lab_LandingPage } from 'platforms/kyoso-lab/pages/Landing'
import { LandingLayout as lab_LandingLayout } from 'platforms/kyoso-lab/components/layouts/Landing'
import { MainLayout as Lab_MainLayout } from 'platforms/kyoso-lab/components/layouts/Main'
import { DashboardPage as lab_DashboardPage } from 'platforms/kyoso-lab/pages/Dashboard'
import { SupportPage as lab_SupportPage } from 'platforms/kyoso-lab/pages/Support'
import { SettingsPage as lab_SettingsPage } from 'platforms/kyoso-lab/pages/Settings'
import { KyosoPage as lab_KyosoPage } from 'platforms/kyoso-lab/pages/Kyoso'
import { KyosoChild as lab_KyosoChild } from 'platforms/kyoso-lab/pages/KyosoChild'
import { KyosoChildPreviewPage as lab_KyosoChildPreviewPage } from 'platforms/kyoso-lab/pages/KyosoChildPreview'
import { KyosoChildTrackingPage as lab_KyosoChildTrackingPage } from 'platforms/kyoso-lab/pages/KyosoChildTracking'
import { KyosoChildEvaluationPage as lab_KyosoChildEvaluationPage } from 'platforms/kyoso-lab/pages/KyosoChildEvaluation'
import { KyosoParent as lab_KyosoParent } from 'platforms/kyoso-lab/pages/KyosoParent'
import { KyosoParentPreviewPage as lab_KyosoParentPreviewPage } from 'platforms/kyoso-lab/pages/KyosoParentPreview'
import { KyosoInvites as lab_KyosoInvites } from 'platforms/kyoso-lab/pages/KyosoInvites'
import { KyosoBundles as lab_KyosoBundles } from 'platforms/kyoso-lab/pages/KyosoBundles'
import { KyosoBundle as lab_KyosoBundle } from 'platforms/kyoso-lab/pages/KyosoBundle'
import { KyosoExercises as lab_KyosoExercises } from 'platforms/kyoso-lab/pages/KyosoExercises'
import { NotFoundPage as Lab_NotFoundPage } from 'platforms/kyoso-lab/pages/NotFound'
import { KyosoOrdersPage as lab_KyosoOrdersPage } from 'platforms/kyoso-lab/pages/KyosoOrders'
import { KyosoBundleOrdersPage as lab_KyosoBundleOrdersPage } from 'platforms/kyoso-lab/pages/KyosoBundleOrders'
import { KyosoResultsPage as lab_KyosoResultsPage } from 'platforms/kyoso-lab/pages/KyosoResults'

function RouterConfig() {
  return (
    <Switch>
      <Redirect exact="true" from="/" to="/lab" />

      {/* LAB */}
      <Route
        path="/lab"
        exact={true}
        render={() =>
          loadPageWithoutAuth(lab_LandingPage, lab_LandingLayout, 'lab')
        }
      />
      <Route
        path="/lab/support"
        render={() => loadProtectedPage(lab_SupportPage, Lab_MainLayout, 'lab')}
      />
      <Route
        path="/lab/settings"
        render={() =>
          loadProtectedPage(lab_SettingsPage, Lab_MainLayout, 'lab')
        }
      />
      <Route
        path="/lab/dashboard"
        exact={true}
        render={() =>
          loadProtectedPage(lab_DashboardPage, Lab_MainLayout, 'lab')
        }
      />
      <Route
        path="/lab/dashboard/kyoso"
        exact={true}
        render={() => loadProtectedPage(lab_KyosoPage, Lab_MainLayout, 'lab')}
      />
      <Route
        path="/lab/dashboard/kyoso/child/:action(insert|\d+)"
        exact={true}
        render={() => loadProtectedPage(lab_KyosoChild, Lab_MainLayout, 'lab')}
      />
      <Route
        path="/lab/dashboard/kyoso/child/:childId/preview"
        exact={true}
        render={() =>
          loadProtectedPage(lab_KyosoChildPreviewPage, Lab_MainLayout, 'lab')
        }
      />
      <Route
        path="/lab/dashboard/kyoso/child/:childId/tracking"
        exact={true}
        render={() =>
          loadProtectedPage(lab_KyosoChildTrackingPage, Lab_MainLayout, 'lab')
        }
      />
      <Route
        path="/lab/dashboard/kyoso/child/:childId/evaluation/:userId?"
        exact={true}
        render={() =>
          loadProtectedPage(lab_KyosoChildEvaluationPage, Lab_MainLayout, 'lab')
        }
      />
      <Route
        path="/lab/dashboard/kyoso/parent/:action(insert|update)?/:parentId?"
        exact={true}
        render={() => loadProtectedPage(lab_KyosoParent, Lab_MainLayout, 'lab')}
      />
      <Route
        path="/lab/dashboard/kyoso/parent/:parentId/preview"
        exact={true}
        render={() =>
          loadProtectedPage(lab_KyosoParentPreviewPage, Lab_MainLayout, 'lab')
        }
      />
      <Route
        path="/lab/dashboard/kyoso/add-participants"
        exact={true}
        render={() =>
          loadProtectedPage(lab_KyosoInvites, Lab_MainLayout, 'lab')
        }
      />
      <Route
        path="/lab/dashboard/kyoso/bundles"
        exact={true}
        render={() =>
          loadProtectedPage(lab_KyosoBundles, Lab_MainLayout, 'lab')
        }
      />
      <Route
        path="/lab/dashboard/kyoso/bundles/orders/:bundleId"
        exact={true}
        render={() =>
          loadProtectedPage(lab_KyosoBundleOrdersPage, Lab_MainLayout, 'lab')
        }
      />
      <Route
        path="/lab/dashboard/kyoso/bundles/:action(insert|update)?/:bundleId?"
        exact={true}
        render={() => loadProtectedPage(lab_KyosoBundle, Lab_MainLayout, 'lab')}
      />
      <Route
        path="/lab/dashboard/kyoso/exercises"
        exact={true}
        render={() =>
          loadProtectedPage(lab_KyosoExercises, Lab_MainLayout, 'lab')
        }
      />
      <Route
        path="/lab/dashboard/kyoso/:childId/orders"
        exact={true}
        render={() =>
          loadProtectedPage(lab_KyosoOrdersPage, Lab_MainLayout, 'lab')
        }
      />

      <Route
        path="/lab/dashboard/kyoso/child/:childId/ranking"
        exact={true}
        render={() =>
          loadProtectedPage(lab_KyosoResultsPage, Lab_MainLayout, 'lab')
        }
      />
      <Route
        path="/lab/*"
        exact={true}
        render={() => <Lab_MainLayout children={<Lab_NotFoundPage />} />}
      />

      {/* HUB */}
      <Route
        path="/hub/:action(login|register)?"
        exact={true}
        render={() =>
          loadPageWithoutAuth(hub_LandingPage, hub_LandingLayout, 'hub')
        }
      />
      <Route
        path="/hub/dashboard"
        exact={true}
        render={() =>
          loadProtectedPage(hub_DashboardPage, hub_MainLayout, 'hub')
        }
      />
      <Route
        path="/hub/onboarding/:step"
        exact={true}
        render={() =>
          loadProtectedPage(hub_OnboardingPage, hub_MainLayout, 'hub')
        }
      />
      <Route
        path="/hub/competitions"
        exact={true}
        render={() =>
          loadProtectedPage(hub_CompetitionsPage, hub_MainLayout, 'hub')
        }
      />
      <Route
        path="/hub/competitions/search"
        exact={true}
        render={() =>
          loadProtectedPage(hub_CompetitionsSearchPage, hub_MainLayout, 'hub')
        }
      />
      <Route
        path="/hub/bundles/search"
        exact={true}
        render={() =>
          loadProtectedPage(hub_BundlesSearch, hub_MainLayout, 'hub')
        }
      />
      <Route
        path="/hub/bundles/purchase/:bundleId"
        exact={true}
        render={() =>
          loadProtectedPage(hub_BundlePurchasePage, hub_MainLayout, 'hub')
        }
      />
      <Route
        path="/hub/competitions/:childId"
        exact={true}
        render={() =>
          loadProtectedPage(hub_CompetitionPage, hub_MainLayout, 'hub')
        }
      />
      <Route
        path="/hub/competitions/:childId/:moduleIndex/:exerciseIndex"
        exact={true}
        render={() =>
          loadProtectedPage(hub_CompetitionView, hub_MainLayout, 'hub')
        }
      />
      <Route
        path="/hub/competitions/participation/:childId"
        exact={true}
        render={() =>
          loadProtectedPage(hub_ParticipationPage, hub_MainLayout, 'hub')
        }
      />
      <Route
        path="/hub/results"
        exact={true}
        render={() => loadProtectedPage(hub_ResultsPage, hub_MainLayout, 'hub')}
      />
      <Route
        path="/hub/results/personal/:childId"
        exact={true}
        render={() =>
          loadProtectedPage(hub_PersonalResultsPage, hub_MainLayout, 'hub')
        }
      />
      <Route
        path="/hub/results/ranking/:childId"
        exact={true}
        render={() => loadProtectedPage(hub_RankingPage, hub_MainLayout, 'hub')}
      />
      <Route
        path="/hub/support"
        exact={true}
        render={() => loadProtectedPage(hub_SupportPage, hub_MainLayout, 'hub')}
      />
      <Route
        path="/hub/settings"
        exact={true}
        render={() =>
          loadProtectedPage(hub_SettingsPage, hub_MainLayout, 'hub')
        }
      />
      <Route
        path="/hub/upload-solution/:token"
        exact={true}
        render={() => <Hub_UploadExerciseSolutionPage />}
      />
      <Route
        path="/hub/public-ranking/:childId"
        exact={true}
        render={() => <Hub_PublicRankingPage />}
      />
    </Switch>
  )
}

function loadPageWithoutAuth(Component, Layout, platform) {
  const routeConfigs = getPlatformRoutingConfigs(platform, 'noauth')

  if (routeConfigs.token) {
    return <Redirect to={routeConfigs.redirectUrl} />
  } else {
    return <Layout children={<Component />} />
  }
}

function loadProtectedPage(Component, Layout, platform) {
  const routeConfigs = getPlatformRoutingConfigs(platform, 'protected')

  if (routeConfigs.token) {
    return <Layout children={<Component />} />
  } else {
    return <Redirect to={routeConfigs.redirectUrl} />
  }
}

function getPlatformRoutingConfigs(platform, routeType) {
  const config = {}

  switch (platform) {
    case 'lab':
      config.token = lab_getAuthToken()
      switch (routeType) {
        case 'protected':
          config.redirectUrl = '/lab'
          break
        case 'noauth':
          config.redirectUrl = '/lab/dashboard'
          break
        default:
          config.redirectUrl = '/lab'
          break
      }
      break
    case 'hub':
      config.token = hub_getAuthToken()
      switch (routeType) {
        case 'protected':
          config.redirectUrl = '/hub/login'
          break
        case 'noauth':
          config.redirectUrl = '/hub/dashboard'
          break
        default:
          config.redirectUrl = '/hub'
          break
      }
      break
    default:
      config.token = null
      config.redirectUrl = ''
      break
  }

  return config
}

export { RouterConfig }
