import { SubmitButtonLab } from 'platforms/kyoso-lab/shared'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ConfirmationMessage, FormInput, PageInfo } from 'shared'
import { COLORS } from 'styling/variables'
import {
  matchingPasswordsValidation,
  minLengthValidator,
  whiteSpaceValidator,
} from 'utils/helpers/validators'

export function ChangePasswordForm({ onSubmitHandler }) {
  const { t } = useTranslation()
  const [onSuccess, setOnSuccess] = useState(null)

  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    setError,
    reset,
    formState,
  } = useForm({
    mode: 'onBlur',
  })

  const onSubmit = (data) => {
    onSubmitHandler(data, 'changePassword', setError, setOnSuccess, reset)
  }

  return (
    <>
      <PageInfo
        title={t('lab:Settings:ChangePasswordTitle')}
        subtitle={t('lab:Settings:ChangePasswordSubTitle')}
        customCss={{ marginBottom: 30, h1: { marginBottom: 10, fontSize: 20 } }}
      />

      {onSuccess === 'changePassword' && (
        <ConfirmationMessage
          color={COLORS.secBlue}
          message={t('shared:PasswordChangedSuccessfully')}
          removeMessageCallback={() => setOnSuccess(false)}
        />
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          id="current-password"
          type="password"
          name="current_password"
          label={`${t('shared:LabelCurrentPassword')} *`}
          showPassword
          onChange={(e) => {
            return {
              trim: whiteSpaceValidator(e),
              trigger: trigger('current_password'),
            }
          }}
          register={register({
            required: 'Required',
          })}
          error={formState.errors.current_password}
          touched={formState.touched.current_password}
        />

        <FormInput
          id="new-password"
          type="password"
          name="new_password"
          label={`${t('shared:LabelNewPassword')} *`}
          succesMessage="CorrectInput"
          showPassword
          onChange={(e) => {
            return {
              trim: whiteSpaceValidator(e),
              trigger: trigger('new_password'),
            }
          }}
          register={register({
            required: 'Required',
            validate: {
              minLength: (value) =>
                minLengthValidator(value, 8, 'PasswordTooShort'),
            },
          })}
          error={formState.errors.new_password}
          touched={formState.touched.new_password}
        />

        <FormInput
          id="confirm-password"
          type="password"
          name="confirm_password"
          label={`${t('shared:LabelConfirmPassword')} `}
          succesMessage="CorrectInput"
          onChange={(e) => {
            return {
              trim: whiteSpaceValidator(e),
              trigger: trigger('confirm_password'),
            }
          }}
          register={register({
            required: 'Required',
            validate: {
              confirmPassword: (value) =>
                matchingPasswordsValidation(value, getValues('new_password')),
            },
          })}
          error={formState.errors.confirm_password}
          touched={formState.touched.confirm_password}
        />

        <SubmitButtonLab className="black">
          {t('lab:Settings:Change')}
        </SubmitButtonLab>
      </form>
    </>
  )
}
