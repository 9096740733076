import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { hub_selectPage, hub_selectTheme } from '../store/generalSlice'
import { hub_logOutUser, hub_selectUser } from '../store/userSlice'
import { avatars } from '../../../styling/avatars'
import { useTranslation } from 'react-i18next'
import { IconButton } from '../shared/index'
import { COLORS, THEMES } from '../../../styling/variables'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { PageInfo } from '../../../shared/index'

function Header() {
  const currentPage = useSelector(hub_selectPage)
  const currentTheme = useSelector(hub_selectTheme)
  const reduxUser = useSelector(hub_selectUser)
  const dispatch = useDispatch()
  const routeHistory = useHistory()
  const [openedWidgetDropdown, setOpenedWidgetDropdown] = useState(null)
  const profileWidget = useRef(null)
  const profileWidgetMenu = useRef(null)
  const notificationsWidget = useRef(null)
  const notificationsWidgetMenu = useRef(null)
  const { t } = useTranslation()

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideOfWidgetsMenu)

    return () => {
      document.removeEventListener('click', handleClickOutsideOfWidgetsMenu)
    }
  }, [openedWidgetDropdown])

  function handleClickOutsideOfWidgetsMenu(event) {
    if (
      (openedWidgetDropdown === 'profile' &&
        !profileWidgetMenu.current?.contains(event.target) &&
        !notificationsWidget.current?.contains(event.target)) ||
      (openedWidgetDropdown === 'notifications' &&
        !notificationsWidgetMenu.current?.contains(event.target) &&
        !profileWidget.current?.contains(event.target))
    ) {
      setOpenedWidgetDropdown(null)
    }
  }

  function goBack() {
    window.history.back()
  }

  function logOutUser() {
    dispatch(hub_logOutUser(reduxUser.id)).finally(() => {
      routeHistory.push('/hub')
    })
  }

  return (
    <HubHeader id="hub-header">
      <IconButton
        id="hub-header-history-back"
        customCss={{
          borderRight: '1px solid #0000001A',
        }}
        onClick={() => goBack()}
      >
        <i className="fas fa-chevron-left"></i>
      </IconButton>
      <PageInfo
        id="hub-header-current-page"
        title={currentPage.title}
        subtitle={currentPage.subtitle}
        theme={currentTheme}
        customCss={{
          marginLeft: '25px',
          h1: {
            margin: 0,
          },
        }}
      />
      <WidgetsContainer id="hub-header-widgets">
        {/* <IconButton
          ref={notificationsWidget}
          id="hub-header-notifications"
          onClick={() =>
            openedWidgetDropdown !== 'notifications' &&
            setOpenedWidgetDropdown('notifications')
          }
        >
          <i className="far fa-bell"></i>
          <NotificationsDot />
          {openedWidgetDropdown === 'notifications' && (
            <NotificationsMenu
              ref={notificationsWidgetMenu}
              id="hub-header-notifications-menu"
              theme={currentTheme}
            >
              <div className="hub-header-notifications-menu-item">
                <i className="fas fa-award"></i>
                <span>
                  Отвори записването за ЕЛА! РЕШИ! ПОБЕДИ! Запиши се сега!
                </span>
              </div>
              <div className="hub-header-notifications-menu-item">
                <i className="fas fa-award"></i>
                <span>
                  Отвори записването за ЕЛА! РЕШИ! ПОБЕДИ! Запиши се сега!
                </span>
              </div>
              <div
                id="hub-header-notifications-show-all"
                onClick={() => {
                  routeHistory.push('/hub/notifications')
                }}
              >
                {t('shared:ShowAll')}
              </div>
            </NotificationsMenu>
          )}
        </IconButton> */}
        <ProfileWidget
          ref={profileWidget}
          id="hub-header-profile"
          theme={currentTheme}
          onClick={() =>
            openedWidgetDropdown !== 'profile' &&
            setOpenedWidgetDropdown('profile')
          }
        >
          <div id="hub-header-profile-avatar">
            <img
              src={avatars[reduxUser.avatar]?.src || avatars[0].src}
              alt="avatar"
            />
          </div>
          <div id="hub-header-profile-names">
            <span>{reduxUser.username || reduxUser.email}</span>
            <span>{t('shared:Profile')}</span>
          </div>
          <div id="hub-header-profile-arrow">
            <i className="fas fa-chevron-down"></i>
          </div>
          {openedWidgetDropdown === 'profile' && (
            <div ref={profileWidgetMenu} id="hub-header-profile-menu">
              {/* <div
                onClick={() => {
                  routeHistory.push('/hub/payments')
                }}
              >
                <i className="fas fa-coins"></i>
                <span>{t('shared:Payments')}</span>
              </div> */}
              <div
                onClick={() => {
                  routeHistory.push('/hub/settings')
                  setOpenedWidgetDropdown(null)
                }}
              >
                <i className="fas fa-cogs"></i>
                <span>{t('shared:Settings')}</span>
              </div>
              <div onClick={() => logOutUser()}>
                <i className="fas fa-door-open"></i>
                <span>{t('shared:Exit')}</span>
              </div>
            </div>
          )}
        </ProfileWidget>
      </WidgetsContainer>
    </HubHeader>
  )
}

export { Header }
const HubHeader = styled('div')({
  gridArea: 'header',
  height: 80,
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 0px 5px #0000001A',
  display: 'flex',
  flexFlow: 'row no-wrap',
  position: 'sticky',
  top: 0,
  zIndex: 999,
})

const WidgetsContainer = styled('div')({
  marginLeft: 'auto',
  display: 'flex',
})

const NotificationsDot = styled('div')({
  backgroundColor: '#EF3B45',
  width: 10,
  height: 10,
  borderRadius: '50%',
  position: 'absolute',
  top: '25%',
  right: '25%',
})

const NotificationsMenu = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 80,
  left: '50%',
  transform: 'translateX(-50%)',
  width: 400,
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 0px 5px #0000001A',
  border: `1px solid ${THEMES[+theme]}`,
  borderRadius: '0px 0px 15px 15px',
  fontFamily: 'Balsamiq Sans',
  fontSize: 16,

  '& .hub-header-notifications-menu-item': {
    width: '100%',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 20px',

    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '50%',
      bottom: '100%',
      width: 0,
      height: 0,
      zIndex: 1002,
      transform: 'translateX(-50%)',
      borderBottom: `15px solid ${THEMES[+theme]}`,
      borderTop: '15px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
    },
    '&:not(:last-child)': {
      borderBottom: '1px solid #0000001A',
    },
    '& i': {
      fontSize: 28,
      width: 28,
      marginRight: 20,
      color: THEMES[+theme],
    },
    '& span': {
      color: COLORS.secBlack,
    },
  },

  '& #hub-header-notifications-show-all': {
    height: 30,
    lineHeight: '30px',
    backgroundColor: THEMES[+theme],
    color: 'white',
    textDecoration: 'underline',
    textAlign: 'center',
    borderRadius: '0px 0px 14px 14px',
  },
}))

const ProfileWidget = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  borderLeft: '1px solid #0000001A',
  padding: '0 15px',
  cursor: 'pointer',

  '& #hub-header-profile-avatar': {
    width: 45,
    height: 45,
    borderRadius: '50%',
    border: `3px solid ${THEMES[+theme]}`,
    marginRight: 15,

    '& img': {
      width: '100%',
      height: '85%',
      marginTop: '15%',
    },
  },

  '& #hub-header-profile-names': {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Balsamiq Sans',
    marginRight: 15,

    '& span:first-of-type': {
      fontSize: 18,
      color: COLORS.secBlack,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: 150,
    },

    '& span:last-of-type': {
      fontSize: 16,
      color: COLORS.mainGray,
    },
  },

  '& #hub-header-profile-arrow': {
    display: 'flex',
    fontSize: 18,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: COLORS.secBlack,
  },

  '& #hub-header-profile-menu': {
    position: 'absolute',
    top: 80,
    width: '100%',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 5px #0000001A',
    zIndex: 1001,
    borderRadius: '0px 0px 15px 15px',
    overflow: 'hidden',

    '& div': {
      padding: '15px 30px',
      display: 'flex',
      alignItems: 'center',
      fontSize: 18,

      // '&:nth-of-type(1)': {
      //   color: '#F8D030',
      //   borderBottom: '1px solid #0000001A',
      // },
      '&:nth-of-type(1)': {
        color: THEMES[+theme],
        borderBottom: '1px solid #0000001A',
      },
      '&:nth-of-type(2)': {
        color: '#FFFFFF',
        backgroundColor: THEMES[+theme],
      },

      '& i': {
        width: 28,
        fontSize: 28,
        marginRight: 20,
      },
    },
  },
}))
