import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { UploadExerciseSolutionModule } from 'shared'
import {
  decodeBase64ToObject,
  getFileLinkFromServerStorage,
} from 'utils/helpers/common'
import HubLogo from 'assets/img/hub-logo.svg'
import { COLORS } from 'styling/variables'
import {
  deleteAnswerFileForExercise,
  getAnswerFilesForExercise,
  uploadAnswerFilesForExercise,
} from '../services/competition'

function UploadExerciseSolutionPage() {
  const { t } = useTranslation()
  const { token } = useParams()
  const routeHistory = useHistory()
  const [pageData, setPageData] = useState({})
  const [existingFiles, setExistingFiles] = useState([])
  const [fileError, setFileError] = useState(null)

  useEffect(() => {
    const tokenData = decodeBase64ToObject(token)
    if (tokenData) {
      setPageData(tokenData)
      getAnswerFilesForExercise(token)
        .then((response) => {
          setExistingFiles(response.existingFiles)
        })
        .catch(() => routeHistory.replace('/hub'))
    } else routeHistory.replace('/hub')
  }, [])

  function uploadFiles(files) {
    uploadAnswerFilesForExercise(token, files)
      .then((response) => {
        setExistingFiles(response.existingFiles)
      })
      .catch((error) => {
        console.log(error)
        setFileError(t('errors:UploadingFile'))
      })
  }

  function deleteUploadedFile(path, index) {
    deleteAnswerFileForExercise(token, path, true)
      .then(() => {
        const newFiles = [...existingFiles]
        newFiles.splice(index, 1)
        setExistingFiles(newFiles)
      })
      .catch((error) => {
        console.log(error)
        setFileError(t('errors:DeletingFile'))
      })
  }

  return (
    <UploadExerciseSolutionLayout>
      <img className="hub-logo" src={HubLogo} alt="hub_logo" />
      <div className="title">
        {pageData.parentName} - {pageData.gradeName}
      </div>
      <div className="subtitle">
        {pageData.subjectName} - {pageData.year} - {pageData.typeName}
      </div>
      <div className="exercise">
        {t('lab:KyosoExerciseCreateUpdateModule:ExerciseTitle', {
          exercise_index: pageData.exerciseNumber,
        })}
      </div>
      <UploadExerciseSolutionModule
        onFileSelected={uploadFiles}
        errorMsg={fileError}
        customCss={{ marginTop: 50 }}
      />
      <div className="attached-files-label">
        {t('hub:UploadFileForExercise:AttachedFiles')}
      </div>
      {existingFiles.length ? (
        <AlreadyUploadedFilesContainer>
          {existingFiles.map((file, index) => {
            const answerId = file.name.split('-')[3]

            if (+answerId === pageData.answerId) {
              return (
                <div key={index} className="file-entry">
                  {file.isImage ? (
                    <img
                      className="file-preview"
                      src={getFileLinkFromServerStorage(file.path)}
                      alt="file-img"
                    />
                  ) : (
                    <i className="far fa-file-pdf file-preview"></i>
                  )}
                  <div className="file-name">{file.name}</div>
                  <i
                    onClick={() => deleteUploadedFile(file.path, index)}
                    className="fas fa-trash-alt delete-file"
                  ></i>
                </div>
              )
            } else {
              return null
            }
          })}
        </AlreadyUploadedFilesContainer>
      ) : null}
    </UploadExerciseSolutionLayout>
  )
}

export { UploadExerciseSolutionPage }

const UploadExerciseSolutionLayout = styled('div')({
  width: '100%',
  height: '100%',
  display: 'grid',
  placeItems: 'center',
  textAlign: 'center',
  color: COLORS.secBlack,

  '& .hub-logo': {
    marginTop: 20,
    width: 130,
    height: 50,
  },

  '& .title': {
    marginTop: 20,
    fontSize: 18,
  },

  '& .subtitle': {
    marginTop: 5,
    fontSize: 16,
  },

  '& .exercise': {
    marginTop: 20,
    fontSize: 22,
    color: COLORS.mainGray,
  },

  '& .attached-files-label': {
    marginTop: 40,
    fontSize: 16,
  },
})

const AlreadyUploadedFilesContainer = styled('div')({
  marginTop: 10,
  width: 270,

  '& .file-entry': {
    marginBottom: 10,
    display: 'flex',
    gap: 15,
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .file-preview': {
      width: 42,
      height: 42,
      fontSize: 42,
      color: COLORS.secBlack,
      textAlign: 'center',
    },

    '& .file-name': {
      fontSize: 16,
      color: COLORS.mainGray,
    },

    '& .delete-file': {
      width: 22,
      height: 22,
      fontSize: 22,
      color: COLORS.secRed,
      cursor: 'pointer',
    },
  },
})
