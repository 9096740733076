import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'styling/variables'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker, { registerLocale } from 'react-datepicker'
import bg from 'date-fns/locale/bg'
import { props } from 'ramda'
import { FormInputMessage } from 'shared/elements/FormInputMessage'
registerLocale('bg', bg)

export const FormDatePicker = styled(
  ({
    id,
    description,
    label,
    name,
    rules,
    control,
    setValue,
    defaultValue,
    className,
    getValues,
    disabled = false,
    error,
    translationKeys,
  }) => {
    const { t } = useTranslation(['shared'])
    const [startDate, setStartDate] = useState(
      defaultValue
        ? moment(+defaultValue)
            .local()
            .toDate()
        : null,
    )

    useEffect(() => {
      setValue(name, startDate)
    }, [])

    return (
      <label id={id} className={className}>
        {t(label)}
        <br />
        {description && <span>{t(description, { ...translationKeys })}</span>}
        {control && (
          <Controller
            control={control}
            className="date-picker"
            name={name}
            rules={rules}
            render={(props) => {
              return (
                <DatePicker
                  disabled={disabled}
                  locale="bg"
                  selected={startDate}
                  onChange={(data) => {
                    setValue(name, data)
                    setStartDate(data)
                  }}
                  value={props.value}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption={t('shared:DatePickerTimeLabel')}
                  dateFormat="dd/MM/yyyy  HH:mm"
                />
              )
            }}
            defaultValue={defaultValue}
          />
        )}
        {error && (
          <FormInputMessage className="input-error">
            <i class="fas fa-exclamation-triangle"></i>{' '}
            {t(`errors:${error.message}`)}
          </FormInputMessage>
        )}
      </label>
    )
  },
)(({ customCss }) => ({
  color: COLORS.mainGray,
  fontSize: 16,

  transition: '0.3s all',
  display: 'inline-block',
  position: 'relative',
  width: '100%',
  '&:hover': {
    color: COLORS.primBlue,
  },
  '&:focus-within': {
    color: COLORS.secBlue,
  },

  '& span': {
    margin: 0,
    color: COLORS.mainGray,
    fontSize: 14,
    fontWeight: 'normal',
  },

  '& .react-datepicker-wrapper': {
    width: '100%',
  },

  '& .react-datepicker-popper': {
    zIndex: 55,

    '& .react-datepicker': {
      display: 'flex',
    },
  },

  '& .react-datepicker__input-container': {
    input: {
      marginTop: 5,
      border: `2px solid ${COLORS.mainGray}`,
      color: COLORS.secBlue,
      borderRadius: 5,
      height: 45,
      width: '100%',
      boxSizing: 'border-box',
      fontSize: 16,
      paddingLeft: 15,
      transition: '0.3s all',
      '&:hover': {
        borderColor: COLORS.primBlue,
      },
      '&:focus': {
        borderColor: COLORS.secBlue,
      },
      '&.input-error': {
        borderColor: COLORS.secRed,
      },
      '&.input-success': {
        borderColor: COLORS.secGreen,
      },
    },
  },

  ...customCss,
}))
