import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormInputMessage } from 'shared/elements/FormInputMessage'
import { COLORS } from 'styling/variables'

export const FormTextArea = styled(
  ({
    id,
    description,
    label,
    name,
    register,
    onBlur = () => {},
    onChange = () => {},
    defaultValue,
    className,
    error,
    translationKeys,
  }) => {
    const { t } = useTranslation(['shared'])

    return (
      <label id={id} className={className}>
        {t(label)}
        <br />
        {description && <span>{t(description, { ...translationKeys })}</span>}
        <TextArea
          onBlur={onBlur}
          onChange={onChange}
          className={error ? 'input-error' : null}
          name={name}
          ref={register}
          defaultValue={defaultValue}
        />
        {error && (
          <FormInputMessage className="input-error">
            <i className="fas fa-exclamation-triangle"></i>{' '}
            {t(`errors:${error.message}`)}
          </FormInputMessage>
        )}
      </label>
    )
  },
)(({ customCss }) => ({
  color: COLORS.mainGray,
  fontSize: 16,

  transition: '0.3s all',
  marginBottom: 30,
  display: 'inline-block',
  position: 'relative',
  width: '100%',
  height: 300,
  '&:hover': {
    color: COLORS.primBlue,
  },
  '&:focus-within': {
    color: COLORS.secBlue,
  },
  ...customCss,
}))

const TextArea = styled('textarea')({
  fontFamily: 'Montserrat',
  marginTop: 5,
  border: `2px solid ${COLORS.mainGray}`,
  color: COLORS.secBlue,
  borderRadius: 5,
  width: '100%',
  height: 'calc(100% - 30px)',
  boxSizing: 'border-box',
  fontSize: 16,
  padding: 10,
  transition: '0.3s all',
  resize: 'vertical',
  '&:hover': {
    borderColor: COLORS.primBlue,
  },
  '&:focus': {
    borderColor: COLORS.secBlue,
  },
  '&.input-error': {
    borderColor: COLORS.secRed,
  },
  '&.input-success': {
    borderColor: COLORS.secGreen,
  },
})
