import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import hub_logo from '../../../assets/img/hub-logo.svg'
import { IconButton, IconLinkButton } from '../shared/index'
import { useSelector } from 'react-redux'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { hub_selectTheme } from '../store/generalSlice'
import { useState } from 'react'
import { MobileMenuModal } from './MobileMenuModal'

function MobileSidebar() {
  const [openMenu, setOpenMenu] = useState(false)
  const { t } = useTranslation()
  const currentTheme = useSelector(hub_selectTheme)

  const closeModalHandler = () => {
    setOpenMenu(false)
  }

  return (
    <>
      {openMenu && <MobileMenuModal closeModalCallback={closeModalHandler} />}

      <HubSidebar id="mobile-hub-sidebar">
        <FooterMenuContainer>
          <IconLinkButton
            theme={currentTheme}
            title={t('shared:Dashboard')}
            onClick={closeModalHandler}
            to="/hub/dashboard"
          >
            <i className="fas fa-th-large"></i>
          </IconLinkButton>

          <IconButton
            className={`menu ${openMenu ? 'active' : null}`}
            theme={currentTheme}
            title={t('shared:Dashboard')}
            customCss={{ border: 'none', width: '100%' }}
            onClick={() => setOpenMenu((prevState) => !prevState)}
          >
            <i className="fas fa-bars"></i>
          </IconButton>

          <IconLinkButton
            theme={currentTheme}
            title={t('shared:Settings')}
            onClick={closeModalHandler}
            to="/hub/settings"
          >
            <i className="fas fa-cogs"></i>
          </IconLinkButton>
        </FooterMenuContainer>
      </HubSidebar>
    </>
  )
}

export { MobileSidebar }

const HubSidebar = styled('div')({
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 0px 5px #0000001A',
  position: 'fixed',
  bottom: 0,
  height: 50,
  width: '100%',
  zIndex: 500,
})

const FooterMenuContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  height: '100%',
})
