import API from '../../../utils/backendApi'
import MD5 from 'crypto-js/md5'

const LAB_AUTH_TOKEN = 'kyoso_lab_auth_token'

function getAuthToken() {
  return window.localStorage.getItem(LAB_AUTH_TOKEN) || null
}

function setAuthToken(token) {
  window.localStorage.setItem(LAB_AUTH_TOKEN, token)
}

function clearAuthToken() {
  window.localStorage.removeItem(LAB_AUTH_TOKEN)
}

function loginUser(email, password) {
  const body = JSON.stringify({
    email,
    password: MD5(password).toString(),
  })

  return API('lab').POST('/auth/login', body)
}

function logOutUser(userId) {
  const body = JSON.stringify({
    userId,
  })

  return API('lab').POST('/auth/logout', body)
}

function forgotUserPassword(email) {
  const body = JSON.stringify({
    email,
  })

  return API('lab').POST('/auth/forgotten', body)
}

function resetUserPassword(token, password) {
  const body = JSON.stringify({
    token,
    password: MD5(password).toString(),
  })

  return API('lab').POST('/auth/new-pass', body)
}

export {
  getAuthToken,
  setAuthToken,
  clearAuthToken,
  loginUser,
  logOutUser,
  forgotUserPassword,
  resetUserPassword,
}
