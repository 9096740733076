import styled from '@emotion/styled'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import HTMLReactParser from 'html-react-parser'
import {
  AnnuledDeclarationWarning,
  CONSTANTS,
  ContentTwoColumsLayout,
  FirstColumn,
  PageInfo,
  SecondColumn,
  TileElement,
  TitleIconWrapper,
} from 'shared'
import { COLORS, QUERIES, THEMES } from 'styling/variables'
import { CompetitionWrapper, GenericButton } from '../shared'
import {
  hub_hideGlobalLoader,
  hub_selectTheme,
  hub_setPage,
  hub_showGlobalLoader,
} from '../store/generalSlice'
import { useHistory } from 'react-router-dom'
import { hub_selectUser, hub_test } from '../store/userSlice'
import { getUserData } from '../services/user'
import { EditCompetitionModal } from '../components/competitions/EditCompetitionModal'
import { EditBundleModal } from '../components/competitions/EditBundleModal'
import { InfoButton } from '../components/competitions/InfoButton'

import {
  getBundleOrder,
  getBundleOrders,
  updateBundleOrder,
} from '../services/orders/bundle'
import {
  getConductingCompetitions,
  getInvitedCompetitions,
  getParticipationDataForCompetitionModal,
  getSignedUpCompetitions,
  saveParticipationDataForCompetitionModal,
} from '../services/competitions'
import { formatDateAndTime } from 'utils/helpers/common'

function CompetitionsPage() {
  const dispatch = useDispatch()
  const routeHistory = useHistory()
  const hubUser = useSelector(hub_selectUser)
  const currentTheme = useSelector(hub_selectTheme)
  const [bundles, setBundles] = useState([])
  const [userData, setUserData] = useState(null)
  const [upcomingCompetitions, setUpcomingCompetitions] = useState([])
  const [invitesForCompetitions, setInvitesForCompetitions] = useState([])
  const [inProgressCompetitions, setInProgressCompetitions] = useState([])
  const [openModal, setOpenModal] = useState(null)
  const { t } = useTranslation()

  const COMPETITION_MODAL = 'EDIT_COMPETITION'
  const BUNDLE_MODAL = 'EDIT_BUNDLE'
  const SEARCH_COMPETITIONS = 'SEARCH_COMPETITIONS'
  const SEARCH_BUDNLES = 'SEARCH_BUNDLES'
  const RESULTS = 'RESULTS'

  useEffect(() => {
    getCompetitionsData()
  }, [])

  const getCompetitionsData = async () => {
    dispatch(hub_setPage({ title: t('hub:Competitions:CompetitionsTitle') }))
    dispatch(hub_showGlobalLoader())
    try {
      const { userData } = await getUserData(hubUser.id)
      const { bundleOrders } = await getBundleOrders()
      const { canStartOrders } = await getConductingCompetitions()
      const { signedUpOrders } = await getSignedUpCompetitions()
      const { invitedOrders } = await getInvitedCompetitions()

      setUserData(userData)
      setBundles(bundleOrders)
      setInProgressCompetitions(canStartOrders)
      setUpcomingCompetitions(signedUpOrders)
      setInvitesForCompetitions(invitedOrders)
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(hub_hideGlobalLoader())
    }
  }

  const handleTileActions = (actionType) => {
    switch (actionType) {
      case SEARCH_COMPETITIONS:
        routeHistory.push('/hub/competitions/search')
        break
      case SEARCH_BUDNLES:
        routeHistory.push('/hub/bundles/search')

        break
      case RESULTS:
        routeHistory.push('/hub/results')
        break
      default:
      // console.log(actionType)
    }
  }

  const openEditModalHandler = async (childId, modalType, modalData) => {
    try {
      switch (modalType) {
        case COMPETITION_MODAL:
          const {
            participation,
          } = await getParticipationDataForCompetitionModal(childId)

          setOpenModal({
            modal_type: modalType,
            ...participation,
            ...modalData,
          })
          break
        case BUNDLE_MODAL:
          const bundleOrder = await getBundleOrder(childId)

          delete bundleOrder.message
          delete bundleOrder.statCode

          setOpenModal({
            modal_type: modalType,
            ...modalData,
            ...userData,
            bundleOrder,
            bundle_order_id: childId,
            used_children: bundleOrder.bundleChildren.filter(
              (child) => child.is_used,
            ),
            available_children: bundleOrder.bundleChildren.filter(
              (child) => !child.is_used,
            ),
          })

          break
        default:
          throw new Error('MODAL TYPE INCORRECT CASE')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const closeModalHandler = () => {
    setOpenModal(null)
    getCompetitionsData()
  }

  const saveEditCompetitionModal = (
    participationId,
    newUserData,
    isDataTouched,
  ) => {
    if (isDataTouched) {
      saveParticipationDataForCompetitionModal(participationId, {
        newUserData: JSON.stringify(newUserData),
        isTouchedData: isDataTouched,
      })
        .then((res) => getCompetitionsData())
        .catch((err) => console.log(err))
    }
  }

  const saveEditBundleModalHandler = (
    bundleOrderId,
    childrenArr,
    bundleChildren,
  ) => {
    let selectedChildrenArray = childrenArr

    if (typeof selectedChildrenArray !== 'object') {
      if (!selectedChildrenArray) {
        selectedChildrenArray = []
      } else {
        selectedChildrenArray = [selectedChildrenArray]
      }
    }

    updateBundleOrder(
      bundleOrderId,
      selectedChildrenArray.join(','),
      bundleChildren
        .filter((x) => selectedChildrenArray.includes(x.id.toString()))
        .reduce((prev, next) => {
          prev[next.id] = next.parent_id
          return prev
        }, {}),
    )
      .then((res) => {
        closeModalHandler()
      })
      .catch((err) => console.log(err))
  }

  const openCompetitionHandler = (childId) => {
    routeHistory.push(`/hub/competitions/${childId}`)
  }

  const openInviteHandler = (childId) => {
    routeHistory.push(`/hub/competitions/participation/${childId}`)
  }

  const declarationWarningMsgRenderer = ({
    is_invalid_declaration,
    is_confirmed_declaration,
    declaration_id,
    ...rest
  }) => {
    let declarationStatus = (
      <AnnuledDeclarationWarning
        onClick={() =>
          openEditModalHandler(rest.child_id, COMPETITION_MODAL, rest)
        }
        className="not-signed"
      >
        <i className="fas fa-info-circle"></i>
        {t('hub:Competitions:NotSignedDeclarationWarning')}
      </AnnuledDeclarationWarning>
    )

    if (is_invalid_declaration) {
      declarationStatus = (
        <AnnuledDeclarationWarning
          onClick={() =>
            openEditModalHandler(rest.child_id, COMPETITION_MODAL, rest)
          }
        >
          <i className="fas fa-exclamation-triangle"></i>
          {t('hub:Competitions:AnnuledDeclarationWarning')}
        </AnnuledDeclarationWarning>
      )
    } else if (is_confirmed_declaration) {
      declarationStatus = (
        <AnnuledDeclarationWarning
          className="valid"
          onClick={() =>
            openEditModalHandler(rest.child_id, COMPETITION_MODAL, rest)
          }
        >
          <i className="fas fa-check-circle"></i>
          {t('hub:Competitions:ValidDeclarationWarning')}
        </AnnuledDeclarationWarning>
      )
    } else if (!!declaration_id) {
      declarationStatus = (
        <AnnuledDeclarationWarning
          className="signed"
          onClick={() =>
            openEditModalHandler(rest.child_id, COMPETITION_MODAL, rest)
          }
        >
          <i className="fas fa-info-circle"></i>
          {t('hub:Competitions:SignedDeclarationWarning')}
        </AnnuledDeclarationWarning>
      )
    }

    return declarationStatus
  }

  return (
    <>
      {openModal?.modal_type === COMPETITION_MODAL && (
        <EditCompetitionModal
          closeModalCallback={closeModalHandler}
          onSubmitCallback={saveEditCompetitionModal}
          modalData={openModal}
        />
      )}

      {openModal?.modal_type === BUNDLE_MODAL && (
        <EditBundleModal
          closeModalCallback={closeModalHandler}
          saveModalCallback={saveEditBundleModalHandler}
          modalData={openModal}
        />
      )}

      <TitleIconWrapper>
        <i className="fas fa-award"></i>
        <PageInfo title={t('hub:Competitions:CompetitionsTitle')} />
      </TitleIconWrapper>
      <ContentTwoColumsLayout
        customCss={{
          width: '100%',
          [`@media (max-width: ${QUERIES.tablet}px)`]: {
            flexDirection: 'column-reverse',
          },
        }}
      >
        <FirstColumn>
          <EventContainer>
            <EventWrapper id="upcoming-signedup-competitions">
              <PageInfo
                customCss={{ h1: { fontSize: 18 }, h2: { fontSize: 16 } }}
                title={t('hub:Competitions:UpcomingSignedUpCompetitionsTitle')}
              />

              <div id="wrapper">
                {upcomingCompetitions.length ? (
                  upcomingCompetitions.map((competition, index) => {
                    return (
                      <CompetitionWrapper
                        id="competition-wrapper"
                        key={competition.child_id}
                        theme={currentTheme}
                      >
                        <div className="competition-content">
                          <p id="competition-title">
                            {t('hub:Competitions:CompetitionTitle', {
                              parent_name: competition.parent_name,
                              grade: competition.grade_name,
                            })}
                          </p>
                          <p id="competition-subtitle">
                            {t('hub:Competitions:CompetitionSubtitle', {
                              subject: competition.subject_name,
                              year: competition.year,
                              competition_type: competition.type_name,
                            })}
                            <br />
                            {t('hub:Competitions:ParticipantId')}
                            <strong>{competition.participant_id}</strong>
                          </p>
                          <div id="competition-details" className="gray">
                            {JSON.parse(competition.intervals)?.map(
                              (dates, index) => (
                                <div key={index}>
                                  {HTMLReactParser(
                                    t('hub:Competitions:CompetitionDetails', {
                                      start_date: formatDateAndTime(
                                        dates.startDate,
                                      ),
                                      end_date: formatDateAndTime(
                                        dates.endDate,
                                      ),
                                    }),
                                  )}
                                </div>
                              ),
                            )}
                          </div>

                          {declarationWarningMsgRenderer(competition)}
                        </div>

                        <div className="kyoso_buttons">
                          <GenericButton
                            onClick={() =>
                              openEditModalHandler(
                                competition.child_id,
                                COMPETITION_MODAL,
                                competition,
                              )
                            }
                            customCss={{
                              margin: 0,
                              minWidth: 45,
                              minHeight: 45,
                            }}
                            theme={currentTheme}
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </GenericButton>

                          {/* INCLUDE competition ID  */}

                          <InfoButton
                            urlTo={`competitions/${competition.child_id}`}
                          >
                            <i className="fas fa-info-circle"></i>
                          </InfoButton>
                        </div>
                      </CompetitionWrapper>
                    )
                  })
                ) : (
                  <p className="red">
                    {t('hub:Competitions:MissingSignedUpCompetitions')}
                  </p>
                )}
              </div>
            </EventWrapper>

            <EventWrapper id="invites-for-competitions">
              <PageInfo
                customCss={{
                  marginBottom: 10,
                  h1: { fontSize: 18 },
                  h2: { fontSize: 16 },
                }}
                title={t('hub:Competitions:InvitesForCompetitionsTitle')}
                subtitle={t('hub:Competitions:InvitesForCompetitionsSubtitle')}
              />

              <div id="wrapper">
                {invitesForCompetitions.length ? (
                  invitesForCompetitions.map((competition, index) => {
                    return (
                      <CompetitionWrapper
                        id="competition-wrapper"
                        key={index}
                        theme={currentTheme}
                      >
                        <div className="competition-content">
                          <p id="competition-title">
                            {t('hub:Competitions:CompetitionTitle', {
                              parent_name: competition.parent_name,
                              grade: competition.grade_name,
                            })}
                          </p>
                          <p id="competition-subtitle">
                            {t('hub:Competitions:CompetitionSubtitle', {
                              subject: competition.subject_name,
                              year: competition.year,
                              competition_type: competition.type_name,
                            })}
                          </p>
                          <div id="competition-details" className="gray">
                            {JSON.parse(competition.intervals)?.map(
                              (dates, index) => (
                                <div key={index}>
                                  {HTMLReactParser(
                                    t('hub:Competitions:CompetitionDetails', {
                                      start_date: formatDateAndTime(
                                        dates?.startDate,
                                      ),
                                      end_date: formatDateAndTime(
                                        dates?.endDate,
                                      ),
                                    }),
                                  )}
                                </div>
                              ),
                            )}
                          </div>

                          {declarationWarningMsgRenderer(competition)}
                        </div>

                        <div className="kyoso_buttons">
                          <GenericButton
                            customCss={{ margin: 0 }}
                            theme={currentTheme}
                            onClick={() =>
                              openInviteHandler(competition.child_id)
                            }
                          >
                            {t('hub:Competitions:SignUpButton')}
                          </GenericButton>

                          <InfoButton
                            urlTo={`competitions/${competition.child_id}`}
                          >
                            <i className="fas fa-info-circle"></i>
                          </InfoButton>
                        </div>
                      </CompetitionWrapper>
                    )
                  })
                ) : (
                  <p className="red">
                    {t('hub:Competitions:MissingInvitesForCompetitions')}
                  </p>
                )}
              </div>
            </EventWrapper>

            <EventWrapper id="bundles">
              <PageInfo
                customCss={{
                  marginBottom: 10,
                  h1: { fontSize: 18 },
                  h2: { fontSize: 16 },
                }}
                title={t('hub:Competitions:BundlesTitle')}
                subtitle={t('hub:Competitions:BundlesSubtitle')}
              />

              <div id="wrapper">
                {bundles.length ? (
                  bundles.map((bundle, index) => {
                    return (
                      <CompetitionWrapper
                        id="competition-wrapper"
                        key={index}
                        theme={currentTheme}
                      >
                        <div className="competition-content">
                          <p id="competition-title">{bundle.bundle_name}</p>
                          <p id="competition-subtitle">
                            {t('hub:Competitions:BundleSubtitle', {
                              bundle_owner: bundle.owner_name,
                              num_participations: bundle.bundle_participations,
                            })}
                          </p>
                        </div>

                        <div className="kyoso_buttons">
                          <GenericButton
                            onClick={() =>
                              openEditModalHandler(
                                bundle.id,
                                BUNDLE_MODAL,
                                bundle,
                              )
                            }
                            customCss={{
                              margin: 0,
                              minWidth: 45,
                              minHeight: 45,
                            }}
                            theme={currentTheme}
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </GenericButton>

                          {/* INCLUDE competition ID  */}

                          <InfoButton urlTo={'competitions/67'}>
                            <i className="fas fa-info-circle"></i>
                          </InfoButton>
                        </div>
                      </CompetitionWrapper>
                    )
                  })
                ) : (
                  <p className="red">{t('hub:Competitions:MissingBundles')}</p>
                )}
              </div>
            </EventWrapper>
          </EventContainer>
        </FirstColumn>

        <SecondColumn>
          <ActionContainer>
            <div id="tiles-wrapper">
              <TileElement
                customCss={{ width: '100%', fontSize: 14, lineHeight: 1.5 }}
                onClick={() => handleTileActions(SEARCH_COMPETITIONS)}
              >
                <i className="fas fa-search"></i>
                <p>{t('hub:Competitions:SearchTileLabel')}</p>
              </TileElement>
              <TileElement
                customCss={{ width: '100%', fontSize: 14, lineHeight: 1.5 }}
                onClick={() => handleTileActions(SEARCH_BUDNLES)}
              >
                <i className="fas fa-money-check-alt"></i>
                <p>{t('hub:Competitions:BundleTileLabel')}</p>
              </TileElement>
              <TileElement
                customCss={{ width: '100%', fontSize: 14, lineHeight: 1.5 }}
                onClick={() => handleTileActions(RESULTS)}
              >
                <i className="fas fa-chart-bar"></i>
                <p>{t('hub:Competitions:ResultsTileLabel')}</p>
              </TileElement>
            </div>

            <div id="currently-conducting">
              <EventWrapper>
                {inProgressCompetitions ? (
                  <>
                    <PageInfo
                      customCss={{
                        marginBottom: 10,
                        h2: { fontSize: 16, color: THEMES[currentTheme] },
                      }}
                      subtitle={t(
                        'hub:Competitions:CurrentlyCondcutingSubtitle',
                      )}
                    />

                    <div id="wrapper">
                      {inProgressCompetitions.length ? (
                        inProgressCompetitions.map((competition, index) => {
                          return (
                            <CompetitionWrapper
                              customCss={{
                                border: `2px solid ${THEMES[currentTheme]}`,
                              }}
                              id="competition-wrapper"
                              key={index}
                              theme={currentTheme}
                            >
                              <div className="competition-content">
                                <p id="competition-title">
                                  {t('hub:Competitions:CompetitionTitle', {
                                    parent_name: competition.parent_name,
                                    grade: competition.grade_name,
                                  })}
                                </p>
                                <p id="competition-subtitle">
                                  {t('hub:Competitions:CompetitionSubtitle', {
                                    subject: competition.subject_name,
                                    year: competition.year,
                                    competition_type: competition.type_name,
                                  })}
                                  <br />
                                  {t('hub:Competitions:ParticipantId')}
                                  <strong>{competition.participant_id}</strong>
                                </p>
                                <div id="competition-details" className="gray">
                                  {JSON.parse(competition.intervals)?.map(
                                    (dates, index) => (
                                      <div key={index}>
                                        {HTMLReactParser(
                                          t(
                                            'hub:Competitions:CompetitionDetails',
                                            {
                                              start_date: formatDateAndTime(
                                                dates.startDate,
                                              ),
                                              end_date: formatDateAndTime(
                                                dates.endDate,
                                              ),
                                            },
                                          ),
                                        )}
                                      </div>
                                    ),
                                  )}
                                </div>
                                {declarationWarningMsgRenderer(competition)}
                              </div>

                              <div className="kyoso_buttons">
                                <GenericButton
                                  customCss={{
                                    margin: 0,
                                  }}
                                  theme={currentTheme}
                                  onClick={() =>
                                    openCompetitionHandler(competition.child_id)
                                  }
                                >
                                  {t('hub:Competitions:CompetitionStartLabel')}
                                </GenericButton>

                                <GenericButton
                                  onClick={() =>
                                    openEditModalHandler(
                                      competition.child_id,
                                      COMPETITION_MODAL,
                                      competition,
                                    )
                                  }
                                  customCss={{
                                    margin: 0,
                                    minWidth: 45,
                                    minHeight: 45,
                                  }}
                                  className="inverted"
                                  theme={currentTheme}
                                >
                                  <i className="fas fa-pencil-alt"></i>
                                </GenericButton>
                              </div>
                            </CompetitionWrapper>
                          )
                        })
                      ) : (
                        <p className="red">
                          {t('hub:Competitions:MissingConductigCompetitions')}
                        </p>
                      )}
                    </div>
                  </>
                ) : null}
              </EventWrapper>
            </div>
          </ActionContainer>
        </SecondColumn>
      </ContentTwoColumsLayout>
    </>
  )
}

export { CompetitionsPage }

const EventContainer = styled('div')(({ customCss }) => ({
  '.red': {
    color: COLORS.secRed,
    fontsize: 600,
  },

  '#upcoming-signedup-competitions #invites-for-competitions #bundles': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  ...customCss,
}))

const ActionContainer = styled('div')(({ customCss }) => ({
  '#tiles-wrapper': {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
    gap: 10,
    i: { fontSize: 35 },
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    '#currently-conducting': {
      '#wrapper': {
        '#competition-wrapper': {
          flexDirection: 'column',
          gap: 10,
          '.kyoso_buttons': {
            width: '100%',
          },

          '.competition-content': {
            width: '100%',
          },
        },
      },
    },

    '#tiles-wrapper': {
      flexDirection: 'column',
      gap: 20,
      button: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        height: 100,

        i: { fontSize: 35 },
        p: { fontSize: 16 },
      },
    },
  },
  ...customCss,
}))

const EventWrapper = styled('div')(({ customCss }) => ({
  display: 'flex',
  flexDirection: 'column',

  '.gray': {
    color: COLORS.mainGray,
  },

  '.red': {
    color: COLORS.secRed,
  },

  '#wrapper ': {
    minHeight: '250px',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },

  '.kyoso_buttons': {
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    '#wrapper': {
      '#competition-wrapper': {
        flexDirection: 'column',
        gap: 10,
        '.kyoso_buttons': {
          width: '100%',
          justifyContent: 'center',
        },

        '.competition-content': {
          width: '100%',
        },
      },
    },
  },

  ...customCss,
}))
