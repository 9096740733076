import { Editor } from '@tinymce/tinymce-react'
import '@wiris/mathtype-tinymce5'
import 'assets/kyoso-def/skins/content/kyoso-def/content.min.css'
import 'assets/kyoso-def/skins/ui/kyoso-def/skin.min.css'
import { COLORS } from 'styling/variables'
import { Controller } from 'react-hook-form'
import styled from '@emotion/styled'
import { content_style } from 'styling/tiny_content'
import { FormInputMessage } from 'shared/elements/FormInputMessage'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { SpinnerLoading } from 'shared/elements/SpinnerLoading'
import { getFileLinkFromServerStorage } from 'utils/helpers/common'

export const TinyTextEditor = styled(
  ({
    id,
    description,
    label,
    name,
    rules,
    control,
    defaultValue,
    className,
    onChangeHandler,
    extendInitOptions,
    error,
  }) => {
    const { t } = useTranslation()
    const [showLoadingEditor, setLoadingEditor] = useState(true)
    const [showEditor, setEditor] = useState(false)

    useEffect(() => {
      const timer = setTimeout(() => {
        setEditor(true)
      }, 100)
      return () => {
        clearTimeout(timer)
      }
    }, [])

    return (
      <label id={id} className={error ? `${className} error` : className}>
        {t(label)}
        <br />
        {description && <span>{description}</span>}
        {control && showEditor && (
          <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={(props) => {
              return (
                <Editor
                  initialValue={defaultValue}
                  ref={props.ref}
                  value={props.value}
                  {...props}
                  id={name}
                  onInit={() => {
                    setLoadingEditor(false)
                  }}
                  // DO NOT REMOVE THIS!!! TINYMCE IS FUCKED UP WITHOUT THIS USELESS FUNCTION!!!
                  onChange={(e) => {}}
                  onEditorChange={(content) => {
                    props.onChange(content)
                    onChangeHandler && onChangeHandler(content)
                  }}
                  init={{
                    plugins:
                      'tiny_mce_wiris link image code charmap table paste lists',
                    toolbar: [
                      'undo redo cut copy paste pastetext | styleselect forecolor fontsizeselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent ',
                      'subscript superscript | link image table  bullist numlist | charmap tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | code removeformat',
                    ],
                    charmap_append: [
                      [0x2600, 'sun'],
                      [0x2601, 'cloud'],
                    ],
                    images_upload_url: getFileLinkFromServerStorage(
                      '/api/shared/editor-image',
                    ),
                    image_class_list: [
                      { title: t('shared:LabelTinyZoomTitle'), value: 'zoom' },
                      { title: t('shared:LabelTinyNoZoomTitle'), value: '' },
                    ],
                    automatic_uploads: true,
                    paste_data_images: true,
                    paste_postprocess: function (plugin, args) {
                      if (args.node.children[0]?.tagName === 'IMG') {
                        args.node.children[0].className = 'zoom'
                      }
                    },
                    images_file_types:
                      'svg,jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp,heif,exif,tiff',
                    skin: false,
                    content_css: false,
                    content_style: content_style,
                    min_height: 400,
                    height: 500,
                    formats: {
                      title_1: {
                        block: 'div',
                        attributes: { class: 'title-1' },
                      },
                      title_2: {
                        block: 'div',
                        attributes: { class: 'title-2' },
                      },
                      title_3: {
                        block: 'div',
                        attributes: { class: 'title-3' },
                      },
                      title_4: {
                        block: 'div',
                        attributes: { class: 'title-4' },
                      },

                      paragraph_1: {
                        block: 'p',
                        attributes: { class: 'paragraph-1' },
                      },
                      paragraph_2: {
                        block: 'p',
                        attributes: { class: 'paragraph-2' },
                      },
                      paragraph_3: {
                        block: 'p',
                        attributes: { class: 'paragraph-3' },
                      },
                      paragraph_4: {
                        block: 'p',
                        attributes: { class: 'paragraph-4' },
                      },

                      overline: {
                        block: 'span',
                        attributes: { class: 'overline' },
                      },
                    },
                    style_formats: [
                      {
                        title: 'Headings',
                        items: [
                          { title: 'Heading 1', format: 'title_1' },
                          { title: 'Heading 2', format: 'title_2' },
                          { title: 'Heading 3', format: 'title_3' },
                          { title: 'Heading 4', format: 'title_4' },
                        ],
                      },
                      {
                        title: 'Paragraph',
                        items: [
                          {
                            title: `${t('shared:TinyMCE:Paragraph')} 1`,
                            format: 'paragraph_1',
                          },
                          {
                            title: `${t('shared:TinyMCE:Paragraph')} 2`,
                            format: 'paragraph_2',
                          },
                          {
                            title: `${t('shared:TinyMCE:Paragraph')} 3`,
                            format: 'paragraph_3',
                          },
                          {
                            title: `${t('shared:TinyMCE:Paragraph')} 4`,
                            format: 'paragraph_4',
                          },
                        ],
                      },
                      {
                        title: 'Inline',
                        items: [
                          { title: 'Bold', format: 'bold' },
                          { title: 'Italic', format: 'italic' },
                          { title: 'Underline', format: 'underline' },
                          { title: 'Overline', format: 'overline' },
                          { title: 'Strikethrough', format: 'strikethrough' },
                          { title: 'Superscript', format: 'superscript' },
                          { title: 'Subscript', format: 'subscript' },
                          { title: 'Code', format: 'code' },
                        ],
                      },
                      {
                        title: 'Align',
                        items: [
                          { title: 'Left', format: 'alignleft' },
                          { title: 'Center', format: 'aligncenter' },
                          { title: 'Right', format: 'alignright' },
                          { title: 'Justify', format: 'alignjustify' },
                        ],
                      },
                    ],
                    color_rows: '5',
                    color_map: [
                      COLORS.mainGray,
                      'mainGray',
                      COLORS.primBlue,
                      'primBlue',
                      COLORS.secBlue,
                      'secBlue',
                      COLORS.secRed,
                      'secRed',
                      COLORS.secGreen,
                      'secGreen',
                      COLORS.secBlack,
                      'secBlack',
                      COLORS.secYellow,
                      'secYellow',
                      COLORS.bgGray,
                      'bgGray',
                      COLORS.borderGray,
                      'borderGray',
                    ],
                    fontsize_formats:
                      '10px 12px 14px 16px 18px 20px 22px 24px 30px 36px 48px 60px 72px 96px',
                    language: 'bg_BG',
                    ...extendInitOptions,
                  }}
                />
              )
            }}
          />
        )}
        {error && (
          <FormInputMessage className="input-error">
            <i class="fas fa-exclamation-triangle"></i>{' '}
            {t(`errors:${error.message}`)}
          </FormInputMessage>
        )}
        {showLoadingEditor && <SpinnerLoading />}
      </label>
    )
  },
)(({ customCss }) => ({
  color: COLORS.mainGray,
  fontSize: 18,

  transition: '0.3s all',
  display: 'inline-block',
  position: 'relative',
  width: '100%',
  marginBottom: 20,

  '& .tox-tinymce': {
    marginTop: 5,
  },

  '&:hover': {
    color: COLORS.primBlue,
  },
  '&:focus-within': {
    color: COLORS.secBlue,
  },
  '& span': {
    margin: 0,
    color: COLORS.mainGray,
    fontSize: 14,
    fontWeight: 'normal',
  },
  '& .tox-statusbar__branding': {
    display: 'none !important',
  },

  ...customCss,
}))
