import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  clearAuthToken,
  loginUser,
  logOutUser,
  setAuthToken,
} from '../services/authentication'
import { decodeJWT, handleRejectedFetch } from '../../../utils/helpers/common'
import { changeBankDetails, changeUsername } from '../services/user'

// Thunks
export const lab_loginUser = createAsyncThunk(
  'user/lab_loginUser',
  async (data, thunkAPI) => {
    try {
      const response = await loginUser(data.email, data.password)

      const tokenData = decodeJWT(response.token)
      setAuthToken(response.token)

      return {
        id: tokenData?.id,
        email: data.email,
        username: tokenData?.username,
        bankDetails: tokenData?.bank_details,
        KIN: tokenData?.kin,
        secretWord: tokenData?.secret_word,
        paymentVariant: tokenData?.payment_variant,
        invoiceData: JSON.parse(tokenData?.invoice_data),
      }
    } catch (error) {
      handleRejectedFetch(error, thunkAPI)

      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const lab_changeUsername = createAsyncThunk(
  'user/lab_changeUsername',
  async (data, thunkAPI) => {
    try {
      const { profile_name } = data
      await changeUsername(profile_name)

      return {
        username: profile_name,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const lab_updateUser = createAsyncThunk(
  'user/lab_updateUser',
  async (data, thunkAPI) => {
    try {
      const {
        bank_info,
        merchant_id,
        secret_word,
        HDS,
        address,
        city,
        firm_name,
        invoice_number,
        receiver,
        responsible_person,
        unit_id_code,
      } = data

      const invoiceData = {
        HDS,
        address,
        city,
        firm_name,
        invoice_number,
        receiver,
        responsible_person,
        unit_id_code,
      }

      await changeBankDetails(
        secret_word,
        merchant_id,
        bank_info,
        JSON.stringify(invoiceData),
      )

      return {
        bankDetails: bank_info,
        KIN: merchant_id,
        secretWord: secret_word,
        ...invoiceData,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const lab_logOutUser = createAsyncThunk(
  'user/lab_logOutUser',
  async (data, thunkAPI) => {
    try {
      clearAuthToken()
      await logOutUser(thunkAPI.getState().lab.user.id)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

// Selectors
export const lab_selectUser = (state) => state.lab.user
export const lab_selectPaymentVariant = (state) => state.lab.user.paymentVariant

// Configure Slice
const initialState = {
  id: '',
  email: '',
  username: '',
  bankDetails: '',
  KIN: '',
  secretWord: '',
  paymentVariant: '',
  firmName: '',
  address: '',
  UIC: '',
  city: '',
  responsiblePersonName: '',
  receiver: '',
  HDS: '',
  InvoiceStartingNumber: '',
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [lab_loginUser.fulfilled]: (state, action) => {
      state.id = action.payload.id
      state.email = action.payload.email
      state.username = action.payload.username
      state.bankDetails = action.payload.bankDetails
      state.KIN = action.payload.KIN
      state.secretWord = action.payload.secretWord
      state.paymentVariant = action.payload.paymentVariant
      state.firmName = action.payload.invoiceData?.firm_name
      state.address = action.payload.invoiceData?.address
      state.UIC = action.payload.invoiceData?.unit_id_code
      state.city = action.payload.invoiceData?.city
      state.responsiblePersonName =
        action.payload.invoiceData?.responsible_person
      state.receiver = action.payload.invoiceData?.receiver
      state.HDS = action.payload.invoiceData?.HDS
      state.InvoiceStartingNumber = action.payload.invoiceData?.invoice_number
    },
    [lab_logOutUser.fulfilled]: (state) => {
      Object.keys(initialState).forEach((k) => {
        state[k] = initialState[k]
      })
    },
    [lab_changeUsername.fulfilled]: (state, action) => {
      state.username = action.payload.username
    },
    [lab_updateUser.fulfilled]: (state, action) => {
      state.bankDetails = action.payload.bankDetails
      state.KIN = action.payload.KIN
      state.secretWord = action.payload.secretWord
      state.firmName = action.payload.firm_name
      state.address = action.payload.address
      state.UIC = action.payload.unit_id_code
      state.city = action.payload.city
      state.responsiblePersonName = action.payload.responsible_person
      state.receiver = action.payload.receiver
      state.HDS = action.payload.HDS
      state.InvoiceStartingNumber = action.payload.invoice_number
    },
  },
})

// Actions and Reducer
export const {} = userSlice.actions
export default userSlice.reducer
