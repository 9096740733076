import React, { useEffect, useRef } from 'react'
import MathJax from 'react-mathjax3'

import styled from '@emotion/styled'
import { onImgZoomClickHandler } from 'utils/helpers/common'
import { debounce } from 'lodash'

export const TinyTextContentPreview = styled(
  ({
    html,
    className,
    id,
    onLoadMathJaxHandler = () => {},
    imgZoom = false,
  }) => {
    //Set Timout is here currenty because of the onLoad function, which does not trigger when the content is accecisble.
    const onLoadHandler = () =>
      setTimeout(() => {
        const elementContainer = document.getElementById(id)
        let hasClickEvent = false

        if (elementContainer && !hasClickEvent) {
          elementContainer.addEventListener('click', (e) => {
            onImgZoomClickHandler(e)
            hasClickEvent = true
          })
        }
      }, 100)

    return (
      <div id={id} className={className}>
        <MathJax.Context
          onLoad={() => {
            onLoadMathJaxHandler()
            imgZoom && onLoadHandler()
          }}
        >
          <MathJax.Html html={html} />
        </MathJax.Context>
      </div>
    )
  },
)(({ customCss }) => ({
  fontFamily: 'Montserrat',
  fontSize: '16px',

  img: {
    maxWidth: '100%',
    height: 'auto',

    '&.zoom': {
      cursor: 'zoom-in',
    },
  },

  em: {
    fontStyle: 'italic',
  },

  '& .title-1': {
    fontFamily: 'Balsamiq Sans',
    fontSize: '30px',
  },

  '& .title-2': {
    fontFamily: 'Balsamiq Sans',
    fontSize: '26px',
  },

  '& .title-3': {
    fontFamily: 'Balsamiq Sans',
    fontSize: '22px',
  },

  '& .title-4': {
    fontFamily: 'Balsamiq Sans',
    fontSize: '18px',
  },

  '& .paragraph-1': {
    fontFamily: 'Montserrat',
    fontSize: '20px',
  },

  '& .paragraph-2': {
    fontFamily: 'Montserrat',
    fontSize: '18px',
  },

  '& .paragraph-3': {
    fontFamily: 'Montserrat',
    fontSize: '16px',
  },

  '& .paragraph-4': {
    fontFamily: 'Montserrat',
    fontSize: '14px',
  },

  '.overline': {
    textDecoration: 'overline',
  },

  ...customCss,
}))
