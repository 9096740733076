import styled from '@emotion/styled'
import { QUERIES } from 'styling/variables'

export const HubModalBackground = styled('div')(({ customCss, theme }) => ({
  display: 'table',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(218,222,230,0.7) 0% 0% no-repeat padding-box',
  zIndex: 1000,

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    position: 'fixed',
    top: 0,
    height: '100vh',
  },
  ...customCss,
}))
