import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FormCheckBox,
  FormInput,
  FormRadioButtons,
  PageInfo,
  SchoolGradeSelect,
  UserDataForm,
} from 'shared'
import HTMLReactParser from 'html-react-parser'

import bpay from 'assets/img/payments/bpay.png'
import easypay from 'assets/img/payments/easypay.png'
import mastercard from 'assets/img/payments/mastercard.png'
import visa from 'assets/img/payments/visa.png'

import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { hub_selectTheme } from 'platforms/kyoso-hub/store/generalSlice'
import {
  firstAndLastNameValidator,
  locationValidator,
  numbersAndLatinLetters,
  schoolNameValidator,
  telephoneValidation,
} from 'utils/helpers/validators'
import { COLORS, THEMES } from 'styling/variables'
import { GenericButton } from 'platforms/kyoso-hub/shared'

function ParticipationForm({
  pageContent,
  onSubmitHandler,
  isFree,
  onChangeCallback,
}) {
  const currentTheme = useSelector(hub_selectTheme)

  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    control,
    getValues,
    trigger,
    setValue,
    watch,
    formState,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      full_name: pageContent?.full_name || null,
      grade_id: pageContent?.user_grade_id || null,
      city: pageContent?.city || null,
      school: pageContent?.school || null,
      parent_names: pageContent?.parent_names || null,
      parent_phone: pageContent?.parent_phone || null,
      invoiceType: '2',
    },
  })

  const paymentTypeRadioButtons = [
    {
      id: 1,
      name: 'paymentType',
      label: t('hub:Participation:BankPayment'),
      register: register({ required: 'Required' }),
      error: formState.errors.paymentType,
    },
    {
      id: 2,
      name: 'paymentType',
      label: t('hub:Participation:ЕPay'),
      register: register({ required: 'Required' }),
      error: formState.errors.paymentType,
    },
  ]

  const invoiceTypeRadioButtons = [
    {
      id: 1,
      name: 'invoiceType',
      label: t('hub:Participation:Individual'),
      register: register({ required: 'Required' }),
      error: formState.errors.invoiceType,
    },
    {
      id: 2,
      name: 'invoiceType',
      label: t('hub:Participation:LegalEntity'),
      register: register({ required: 'Required' }),
      error: formState.errors.invoiceType,
    },
  ]

  const submitHandler = (data) => {
    onSubmitHandler(data, formState)
  }

  const onChnageHandler = (event) => {
    let inputName = null

    if (event.target?.name) {
      inputName = event.target.name
    } else {
      inputName = event
    }

    onChangeCallback({ [inputName]: getValues(inputName) })
  }

  return (
    <>
      <Form onSubmit={handleSubmit(submitHandler)} onChange={onChnageHandler}>
        <PageInfo
          title={t('hub:Participation:UserDataTitle')}
          customCss={{
            '& h1': {
              fontSize: 22,
              marginBottom: 20,
              marginTop: 0,
            },
          }}
        />

        <UserDataForm>
          <FormInput
            customCss={{
              marginBottom: '0',
              gridColumn: '1 / 3',
              gridRow: '1',
            }}
            id="full_name"
            type="text"
            name="full_name"
            label={`${t('shared:LabelFirstAndLastName')} *`}
            register={register({
              required: 'Required',
              validate: {
                firstAndLastNameValidator: (value) =>
                  firstAndLastNameValidator(value),
              },
            })}
            error={formState.errors.full_name}
            touched={formState.touched.full_name}
          />

          <SchoolGradeSelect
            id="schoolGrade"
            customCss={{ marginBottom: '0' }}
            label={`${t('shared:LabelSchoolClass')} *`}
            name="grade_id"
            setValue={setValue}
            getValues={getValues}
            onChange={onChnageHandler}
            description="SchoolClassDescription"
            control={control}
            rules={{ required: 'Required' }}
            isMulti={false}
            defaultValue={null}
            translationKeys={{
              lastYear: `${new Date().getFullYear() - 1}`,
              currentYear: `${new Date().getFullYear()}`,
            }}
            error={formState.errors.grade_id}
          />

          <FormInput
            customCss={{
              marginBottom: '0',
              gridColumn: '1/ 2',
              gridRow: '2',
            }}
            id="town"
            type="text"
            name="city"
            label="LabelTown"
            description={'TownDescription'}
            register={register({
              validate: {
                locationValidator: (value) => locationValidator(value),
              },
            })}
            error={formState.errors.city}
            touched={formState.touched.city}
          />

          <FormInput
            customCss={{
              marginBottom: '0',
              gridColumn: '2/ 4',
              gridRow: '2',
            }}
            id="schoolName"
            type="text"
            name="school"
            label="LabelSchoolName"
            description={'SchoolNameDescription'}
            onBlur={(e) => trigger('school')}
            register={register({
              validate: {
                schoolNameValidator: (value) => schoolNameValidator(value),
              },
            })}
            error={formState.errors.school}
            touched={formState.touched.school}
          />

          <div id="parent-info">
            <FormInput
              customCss={{
                marginBottom: '0',
              }}
              id="parentName"
              type="text"
              name="parent_names"
              label="LabelFirstAndLastNameParent"
              register={register({
                validate: {
                  firstAndLastNameValidator: (value) =>
                    firstAndLastNameValidator(value),
                },
              })}
              error={formState.errors.parent_names}
              touched={formState.touched.parent_names}
            />

            <FormInput
              customCss={{
                marginBottom: '0',
              }}
              id="parentPhone"
              type="tel"
              name="parent_phone"
              label="LabelTelephone"
              register={register({
                validate: {
                  telephoneValidation: (value) => {
                    if (value) return telephoneValidation(value)
                  },
                },
              })}
              error={formState.errors.parent_phone}
              touched={formState.touched.parent_phone}
            />
          </div>
        </UserDataForm>

        {/* HIDE INVOICE */}
        {!isFree && false ? (
          <FormCheckBox
            key="invoice"
            id="invoice"
            name="invoice"
            label={t('hub:Participation:LabelInvoice')}
            register={register()}
            customCss={{ margin: '10px 0', alignSelf: 'flex-start' }}
          />
        ) : null}

        {/* Invoice Form */}

        {watch('invoice') ? (
          <>
            <PageInfo
              title={t('hub:Participation:InvoiceTitle')}
              customCss={{
                '& h1': {
                  fontSize: 22,
                  marginBottom: 20,
                  marginTop: 0,
                },
              }}
            />
            <FormRadioButtons
              radioButtons={invoiceTypeRadioButtons}
              id="invoice-type"
              type="radio"
              name="invoiceType"
              register={register()}
              error={formState.errors.invoiceType}
            />

            {watch('invoiceType') === '1' ? (
              <UserDataForm customCss={{ gridTemplateColumns: '2fr 1fr' }}>
                <FormInput
                  customCss={{
                    marginBottom: '0',
                  }}
                  id="invoice-name"
                  type="text"
                  name="invoice_name"
                  label={`${t('shared:LabеlFirstName')}*`}
                  register={register({ required: 'Required' })}
                  error={formState.errors.invoice_name}
                  touched={formState.touched.invoice_name}
                />

                <FormInput
                  customCss={{
                    marginBottom: '0',
                  }}
                  id="PIN"
                  type="number"
                  name="invoice_PIN"
                  label={`${t('hub:Participation:LabelPIN')}`}
                  register={register()}
                  error={formState.errors.invoice_PIN}
                  touched={formState.touched.invoice_PIN}
                />

                <FormInput
                  customCss={{
                    marginBottom: '0',
                  }}
                  id="invoice-address"
                  type="text"
                  name="invoice_address"
                  label={`${t('shared:LabelAddress')}*`}
                  register={register({ required: 'Required' })}
                  error={formState.errors.invoice_address}
                  touched={formState.touched.invoice_address}
                />
                <FormInput
                  customCss={{
                    marginBottom: '0',
                  }}
                  id="invoice_city"
                  type="text"
                  name="invoice_city"
                  label={`${t('shared:LabelCity')}*`}
                  register={register({ required: 'Required' })}
                  error={formState.errors.invoice_city}
                  touched={formState.touched.invoice_city}
                />
              </UserDataForm>
            ) : (
              <UserDataForm>
                <FormInput
                  customCss={{
                    marginBottom: '0',
                  }}
                  id="invoice-firm-name"
                  type="text"
                  name="invoice_firm_name"
                  label={`${t('hub:Participation:LabelFirmName')}*`}
                  register={register({ required: 'Required' })}
                  error={formState.errors.invoice_firm_name}
                  touched={formState.touched.invoice_firm_name}
                />

                <FormInput
                  customCss={{
                    marginBottom: '0',
                  }}
                  id="unit_id_code"
                  type="number"
                  name="invoice_unit_id_code"
                  label={`${t('hub:Participation:LabelUnitIdCode')}*`}
                  register={register({ required: 'Required' })}
                  error={formState.errors.invoice_unit_id_code}
                  touched={formState.touched.invoice_unit_id_code}
                />

                <FormInput
                  customCss={{
                    marginBottom: '0',
                  }}
                  id="invoice-VAT"
                  type="text"
                  name="invoice_VAT"
                  label={`${t('hub:Participation:LabelVAT')}*`}
                  onChange={() => trigger('invoice_VAT')}
                  register={register({
                    required: 'Required',
                    validate: {
                      numbersAndLatinLetters: (value) =>
                        numbersAndLatinLetters(value),
                    },
                  })}
                  error={formState.errors.invoice_VAT}
                  touched={formState.touched.invoice_VAT}
                />

                <FormInput
                  customCss={{
                    marginBottom: '0',
                    gridColumn: '1 / 3',
                  }}
                  id="invoice-address"
                  type="text"
                  name="invoice_address"
                  label={`${t('hub:Participation:LabelAddress')}*`}
                  register={register({ required: 'Required' })}
                  error={formState.errors.invoice_address}
                  touched={formState.touched.invoice_address}
                />

                <FormInput
                  customCss={{
                    marginBottom: '0',
                  }}
                  id="invoice-city"
                  type="text"
                  name="invoice_city"
                  label={`${t('shared:LabelCity')}*`}
                  register={register({ required: 'Required' })}
                  error={formState.errors.invoice_city}
                  touched={formState.touched.invoice_city}
                />

                <FormInput
                  customCss={{
                    marginBottom: '0',
                  }}
                  id="invoice-responsible-person"
                  type="text"
                  name="invoice_responsible_person"
                  label={`${t('hub:Participation:LabelResponsiblePerson')}`}
                  register={register()}
                  error={formState.errors.invoice_responsible_person}
                  touched={formState.touched.invoice_responsible_person}
                />

                <FormInput
                  customCss={{
                    marginBottom: '0',
                  }}
                  id="invoice-receiver"
                  type="text"
                  name="invoice_receiver"
                  label={`${t('hub:Participation:LabelReceiver')}`}
                  register={register()}
                  error={formState.errors.invoice_receiver}
                  touched={formState.touched.invoice_receiver}
                />
              </UserDataForm>
            )}
          </>
        ) : null}

        <PageInfo
          title={t('hub:Participation:Payment')}
          customCss={{
            '& h1': {
              fontSize: 22,
              marginBottom: 20,
              marginTop: 0,
            },
          }}
        />

        {isFree ? (
          <Price theme={currentTheme}>
            {HTMLReactParser(t('hub:Participation:PaymentFreeLabel'))}
          </Price>
        ) : (
          <Price theme={currentTheme}>
            {HTMLReactParser(
              t('hub:Participation:PaymentPriceLabel', {
                price: pageContent.child_price
                  ? pageContent.child_price
                  : pageContent.parent_price,
              }),
            )}
          </Price>
        )}
        {!isFree && (
          <Payments theme={currentTheme}>
            <FormRadioButtons
              customCss={{ gridRow: '2', marginBottom: '0', marginTop: 20 }}
              radioButtons={paymentTypeRadioButtons}
              label={'hub:Participation:PaymentTypeLabel'}
              id="paymentType"
              type="radio"
              name="paymentType"
              register={register()}
              error={formState.errors.paymentType}
            />

            {watch('paymentType') == 1 ? (
              <>
                <div className="payment-types">
                  <span>{t('hub:Participation:BankPaymentDesc')}</span>
                </div>
              </>
            ) : watch('paymentType') == 2 ? (
              <div className="payment-types">
                <img src={visa}></img>
                <img src={mastercard}></img>
                <img src={easypay}></img>
                <img src={bpay}></img>
              </div>
            ) : null}
            <p className="payment-info">{t('hub:Participation:PaymentInfo')}</p>
          </Payments>
        )}
        <p>Уверете се, че сте попълнили всички данни и сте подписали декларацията (ако желаете), преди да потвърдите записването.</p>
        {isFree ? (
          <GenericButton theme={currentTheme}>
            {t('hub:Participation:SignUpBtn')}
          </GenericButton>
        ) : (
          <GenericButton theme={currentTheme}>
            {watch('paymentType') == 2
              ? t('hub:Participation:EPayBtn')
              : t('hub:Participation:SignUpBtn')}
          </GenericButton>
        )}
      </Form>
    </>
  )
}

export { ParticipationForm }

const UserData = styled('div')(({ customCss }) => ({
  display: 'grid',
  alignContent: 'center',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridGap: '30px',
  alignItems: 'end',
  marginBottom: 30,
  width: '100%',

  ...customCss,
}))

const Payments = styled('div')(({ customCss, theme }) => ({
  display: 'grid',
  alignContent: 'start',
  gridTemplateColumns: '1fr 1fr',
  width: '100%',
  marginBottom: 20,

  '.payment-types': {
    gridColumn: '2',
    gridRow: '2',
    alignSelf: 'center',

    img: {
      margin: '0  5px',
    },
  },

  '.payment-info': {
    gridRow: '4',
    gridColumn: '1/ 4',
    fontSize: 14,
    color: COLORS.secRed,
  },
}))

const Form = styled('form')(({ customCss }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const Price = styled('span')(({ customCss, theme }) => ({
  alignSelf: 'start',
  color: THEMES[+theme],

  '.price-num': {
    fontSize: 20,
  },
}))
