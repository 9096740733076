import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SupportForm } from '../components/support/SupportForm'
import { getFAQ } from '../services/general'
import { ContactsComponent, ContentTwoColumsLayout } from 'shared/index'
import { useDispatch, useSelector } from 'react-redux'
import {
  hub_hideGlobalLoader,
  hub_selectTheme,
  hub_setPage,
  hub_showGlobalLoader,
} from '../store/generalSlice'
import { QUERIES } from '../../../styling/variables'
import { PageInfo, Accordion } from 'shared'

function SupportPage() {
  const currentTheme = useSelector(hub_selectTheme)
  const [questions, setQuestions] = useState([])
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(
      hub_setPage({
        title: t('hub:Support:Header'),
        subtitle: t('hub:Support:HeaderDescription'),
      }),
    )

    dispatch(hub_showGlobalLoader())

    getFAQ()
      .then((response) => {
        setQuestions(response.faq.sort((a, b) => a.q_order - b.q_order))
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(hub_hideGlobalLoader()))
  }, [])

  return (
    <ContentTwoColumsLayout
      customCss={{
        [`@media (max-width: ${QUERIES.tablet}px)`]: {
          display: 'flex',
          flexDirection: 'column-reverse',
        },
      }}
    >
      <div>
        <PageInfo
          title={t('shared:Support:PageTitle')}
          subtitle={t('shared:Support:PageDescription')}
          customCss={{
            '& h1': {
              marginBottom: 10,
            },
            '& h2': {
              marginBottom: 20,
            },
          }}
        />
        {questions && <Accordion questions={questions} theme={currentTheme} />}
      </div>

      <div>
        <SupportForm />
        <ContactsComponent customCss={{ marginTop: 20 }} />
      </div>
    </ContentTwoColumsLayout>
  )
}

export { SupportPage }
