import styled from '@emotion/styled'
import HTMLReactParser from 'html-react-parser'
import { getDeclarationCheckInfo } from 'platforms/kyoso-hub/services/participation'
import { getDeclaration } from 'platforms/kyoso-hub/services/user'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { UseGenralDeclarationBtn } from 'shared'
import { ContentDescription } from 'shared/elements/ContentDescription'
import { DeclarationInformation } from 'shared/elements/DeclarationInformation'
import { COLORS } from 'styling/variables'
import { calculateSchoolYears, formatDateAndTime } from 'utils/helpers/common'
import { getSchoolGradesHook } from 'utils/helpers/hooks'
import { DeclarationCanvas } from './DeclarationCanvas'
import { PageInfo } from './PageInfo'

function ParticipationDeclaration({ data, onSubmitHandler }) {
  const { t } = useTranslation('shared')
  const [declarationInfo, setDeclarationInfo] = useState(null)
  const schoolGrades = getSchoolGradesHook()
  const { childId } = useParams()

  useEffect(() => {
    setDeclarationInfo(data)
  }, [data])

  const useGenralDeclarationHandler = () => {
    getDeclarationCheckInfo(childId)
      .then(({ redirect, ...rest }) => {
        // console.log(rest)
        if (redirect) {
          window.open('/hub/settings', '_blank')
        } else {
          getDeclaration(declarationInfo.id, childId)
            .then(({ declaration }) => {
              setDeclarationInfo({ ...declarationInfo, declaration })
            })
            .catch((error) => console.log(error))
        }
      })
      .catch((err) => console.log(err))
  }

  return (
    <Wrapper>
      <PageInfo
        title={t('shared:PersonalDataDeclaration')}
        customCss={{
          '& h1': {
            marginBottom: 10,
          },
          '& h2': {
            marginBottom: 20,
          },
        }}
      />

      <ContentDescription
        customCss={{
          marginBottom: '0',
          '& :nth-of-type(2)': {
            marginBottom: '0',
          },
          a: {
            marginBottom: '20px',
            display: 'inline-block',
          },
          p: { marginBottom: 20 },
        }}
      >
        {HTMLReactParser(
          declarationInfo?.declaration?.type === '0'
            ? t('PersonalDeclarationDescriptionTypeOne', {
                more_info:
                  'https://www.kyoso.tech/site/index.php/privacy-policy/',
              })
            : t('PersonalDeclarationDescriptionTypeTwo', {
                more_info:
                  'https://www.kyoso.tech/site/index.php/privacy-policy/',
              }),
        )}
      </ContentDescription>

      {!(
        declarationInfo?.signature || declarationInfo?.declaration?.signature
      ) && (
        <UseGenralDeclarationBtn onClick={useGenralDeclarationHandler}>
          <i className="fas fa-signature"></i>
          <span>{t('shared:UseGenralDeclaration')}</span>
        </UseGenralDeclarationBtn>
      )}

      <DeclarationInformation>
        <div className="declaration">
          {HTMLReactParser(
            declarationInfo?.declaration?.type === '0'
              ? t(`${'shared:PersonalDeclarationTypeOne'}`, {
                  competition_owner: declarationInfo?.owner_name,
                  competition_name: declarationInfo?.competition_name,
                  parent_names: declarationInfo?.parent_names,
                  child_names: declarationInfo?.full_name,
                  grade: schoolGrades?.find(
                    (grade) => grade.id === declarationInfo.grade_id,
                  )?.name,
                  school: declarationInfo?.school,
                  city: declarationInfo?.city,
                  date_time: formatDateAndTime(Date.now()),
                  school_year_start: calculateSchoolYears().startSchoolYear,
                  school_year_end: calculateSchoolYears().endSchoolYear,
                })
              : t(`${'shared:PersonalDeclarationTypeTwo'}`, {
                  competition_owner: declarationInfo?.owner_name,
                  competition_name: declarationInfo?.competition_name,
                  parent_names: declarationInfo?.parent_names,
                  child_names: declarationInfo?.full_name,
                  grade: schoolGrades?.find(
                    (grade) => grade.id === declarationInfo.grade_id,
                  )?.name,
                  school: declarationInfo?.school,
                  city: declarationInfo?.city,
                  date_time: formatDateAndTime(Date.now()),
                  school_year_start: calculateSchoolYears().startSchoolYear,
                  school_year_end: calculateSchoolYears().endSchoolYear,
                }),
          )}
        </div>
      </DeclarationInformation>
      <DeclarationCanvas
        signatureURL={
          declarationInfo?.signature || declarationInfo?.declaration?.signature
        }
        onSaveCallback={onSubmitHandler}
        onClearCallback={onSubmitHandler}
      />
    </Wrapper>
  )
}

export { ParticipationDeclaration }

const Wrapper = styled('div')(({ customCss }) => ({
  display: 'flex',
  flexDirection: 'column',
}))
