import styled from '@emotion/styled'
import {
  saveAnswersForCompetition,
  uploadAnswerFilesForExercise,
} from 'platforms/kyoso-hub/services/competition'
import { hub_selectCompetitionToken } from 'platforms/kyoso-hub/store/competitionSlice'
import { mergeDeepRight } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import QRCode from 'qrcode.react'
import {
  FormInput,
  TinyTextContentPreview,
  TinyTextEditor,
  UploadExerciseSolutionModule,
} from 'shared'
import { COLORS, QUERIES } from 'styling/variables'
import {
  encodeObjectToBase64,
  getFileLinkFromServerStorage,
} from 'utils/helpers/common'
import { CompetitionRadioButton } from './CompetitionRadioButton'
import { useParams } from 'react-router-dom'
import { debounce } from 'lodash'
import { getAuthToken } from 'platforms/kyoso-hub/services/authentication'

export const ExerciseDisplay = styled(
  ({
    exIndex,
    exerciseContent,
    className,
    mediaFiles,
    deleteUploadedFileHandler,
  }) => {
    const competitionInfo = useSelector(hub_selectCompetitionToken)
    const { moduleIndex, childId } = useParams()
    const [fileError, setFileError] = useState(null)
    const savedAnswers = JSON.parse(localStorage.getItem('answers'))
    const { t } = useTranslation()

    const { register, watch, getValues, control, setValue, reset } = useForm({
      mode: 'onChage',
      defaultValues: {
        ...JSON.parse(localStorage.getItem('answers'))?.[childId],
      },
      shouldUnregister: true,
    })

    useEffect(() => {
      reset(savedAnswers[childId])
      // implementImgZoom()
    }, [exIndex])

    useEffect(() => {
      onChangeHandler()
    }, [mediaFiles, deleteUploadedFileHandler])

    const onChangeHandler = (e) => {
      const answers = JSON.parse(localStorage.getItem('answers'))
      const getStructure = JSON.parse(localStorage.getItem('structure_ans'))
      let value = watch(`${exerciseContent.id}`)

      let structuredAnswers = []

      if (e && e.target?.name.includes('other')) {
        value = watch(`other_${exerciseContent.id}`)
        value.unshift(answers[childId][exerciseContent.id])
      }

      if (getStructure && Object.values(getStructure)?.length) {
        if (exerciseContent.module_id) {
          structuredAnswers = Object.values(getStructure).map((mod, index) => {
            return {
              ...mod,

              exercises:
                index === +moduleIndex
                  ? mod.exercises.map((ex, index) => {
                      if (exIndex === index) {
                        return {
                          [`id_${exerciseContent.id}`]: value || '',
                          answer_type: exerciseContent.answer_type,
                          answer_descriptions:
                            exerciseContent.answer_type === '3'
                              ? exerciseContent.answers.map((a) => {
                                  return {
                                    ans_id: a.id,
                                    ans_description: a.description,
                                  }
                                })
                              : [],
                          media_files:
                            exerciseContent.answer_type === '3'
                              ? exerciseContent.answers.map((ans) => {
                                  return {
                                    ans_id: ans.id,
                                    ans_files: mediaFiles.filter((file) => {
                                      const fileId = file.name.split('-')[3]

                                      if (fileId == ans.id) return file
                                    }).length,
                                  }
                                })
                              : 0,
                        }
                      } else {
                        return ex
                      }
                    })
                  : mod.exercises,
            }
          })
        } else {
          structuredAnswers = Object.values(getStructure).map((ex, index) => {
            if (exIndex === index) {
              return {
                [`id_${exerciseContent.id}`]: value || '',
                answer_type: exerciseContent.answer_type,
                answer_descriptions:
                  exerciseContent.answer_type === '3'
                    ? exerciseContent.answers.map((a) => ({
                        ans_id: a.id,
                        ans_description: a.description,
                      }))
                    : [],
                media_files:
                  exerciseContent.answer_type === '3'
                    ? exerciseContent.answers.map((ans) => {
                        return {
                          ans_id: ans.id,
                          ans_files: mediaFiles.filter((file) => {
                            const fileId = file.name.split('-')[3]

                            if (fileId == ans.id) return file
                          }).length,
                        }
                      })
                    : 0,
              }
            } else {
              return ex
            }
          })
        }
      }

      if (answers && getAuthToken()) {
        const updatedAnswers = { ...answers[childId], ...getValues() }
        const updatedStructure = { ...getStructure, ...structuredAnswers }

        localStorage.setItem(
          'answers',
          JSON.stringify({ [childId]: updatedAnswers }),
        )

        localStorage.setItem('structure_ans', JSON.stringify(updatedStructure))

        //Sends Updated Answers
        saveAnswersForCompetition(
          childId,
          JSON.stringify(updatedAnswers),
          JSON.stringify(updatedStructure),
        )
      }
    }

    const onFileSelectedHandler = (files, token) => {
      uploadAnswerFilesForExercise(token, files).catch((error) => {
        console.log(error)
        setFileError(t('errors:UploadingFile'))
      })
    }

    const debounceInputs = debounce((e) => {
      onChangeHandler(e)
    }, 500)

    const handleAnswerType = (answerType) => {
      switch (answerType) {
        case '0':
          const typeZeroAnaswers = exerciseContent.answers.map((ans, index) => {
            const hasOtherAnswer = exerciseContent.answers.some(
              (ans) => ans.is_other,
            )

            if (ans.is_other && index === exerciseContent.answers.length - 1) {
              const isOtherId = ans.is_other && ans.id
              const disableInput = watch(`${exerciseContent.id}`) == isOtherId

              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                    gap: 20,
                  }}
                  key={`oth${exIndex} ${index}`}
                >
                  <CompetitionRadioButton
                    customCss={{ width: '100%' }}
                    key={`ex${exIndex} ${ans.id}`}
                    name={`${exerciseContent.id}`}
                    type="radio"
                    register={register}
                    onChange={onChangeHandler}
                    value={ans.id}
                    label={ans.answer}
                  />
                  <div style={{ display: !disableInput ? 'none' : 'block' }}>
                    {ans.other_answers.map((a, index) => (
                      <FormInput
                        key={`ex${exerciseContent.id} ${index}`}
                        customCss={
                          disableInput ? { opacity: '1' } : { opacity: '0' }
                        }
                        disabled={!disableInput}
                        name={`other_${exerciseContent.id}.${index}`}
                        type="text"
                        onChange={(e) => {
                          debounceInputs(e)
                          setValue(`${exerciseContent.id}`, `${isOtherId}`)
                        }}
                        register={register}
                        label={a}
                      />
                    ))}
                  </div>
                </div>
              )
            } else {
              return (
                <CompetitionRadioButton
                  key={`ex${exerciseContent.id} ${index}`}
                  name={`${exerciseContent.id}`}
                  type="radio"
                  register={register}
                  onChange={(e) => {
                    if (hasOtherAnswer) {
                      const wait = (ms) =>
                        new Promise((resolve) => setTimeout(resolve, ms))
                      wait(0)
                        .then(() => {
                          const otherValues = watch(
                            `other_${exerciseContent.id}`,
                          )

                          otherValues.forEach((val, index) => {
                            val &&
                              setValue(
                                `other_${exerciseContent.id}.${index}`,
                                null,
                              )
                          })
                        })
                        .then(() => {
                          !getValues(`other_${exerciseContent.id}`) &&
                            onChangeHandler(e)
                        })
                        .catch((err) => console.log(err))
                    } else {
                      onChangeHandler(e)
                    }
                  }}
                  value={ans.id}
                  label={ans.answer}
                />
              )
            }
          })

          return (
            <>
              <TypeDescription>
                {exerciseContent.instructions
                  ? exerciseContent.instructions
                  : t('hub:CompetitionView:TypeZeroDescription')}
              </TypeDescription>
              <Answers
                answersLength={exerciseContent.answers.length}
                id="question-answers"
              >
                {typeZeroAnaswers}
              </Answers>
            </>
          )

        case '1':
          const typeOneAnswers = exerciseContent.answers.map((ans, index) => {
            return (
              <CompetitionRadioButton
                key={`ex${exerciseContent.id} ${index}`}
                name={`${exerciseContent.id}`}
                type="checkbox"
                register={register}
                onChange={onChangeHandler}
                value={ans.id}
                label={ans.answer}
              />
            )
          })

          return (
            <>
              <TypeDescription>
                {exerciseContent.instructions
                  ? exerciseContent.instructions
                  : t('hub:CompetitionView:TypeOneDescription')}
              </TypeDescription>
              <Answers
                answersLength={exerciseContent.answers.length}
                id="question-answers"
              >
                {typeOneAnswers}
              </Answers>
            </>
          )

        case '2':
          const typeTwoAnswers = exerciseContent.answers.map((ans, index) => {
            return (
              <FormInput
                customCss={{ width: 200 }}
                key={`ex${exerciseContent.id} ${ans.id}`}
                name={`${exerciseContent.id}.id_${ans.id}`}
                type="text"
                register={register}
                onChange={(e) => {
                  debounceInputs(e)
                }}
                label={ans.description}
              />
            )
          })

          return (
            <>
              <TypeDescription>
                {exerciseContent.instructions
                  ? exerciseContent.instructions
                  : t('hub:CompetitionView:TypeTwoDescription')}
              </TypeDescription>
              <Answers
                answersLength={exerciseContent.answers.length}
                id="question-answers"
              >
                {typeTwoAnswers}
              </Answers>
            </>
          )

        case '3':
          return (
            <div style={{ padding: '5px' }}>
              <TypeDescription customCss={{ position: 'relative' }}>
                {exerciseContent.instructions
                  ? exerciseContent.instructions
                  : t('hub:CompetitionView:TypeThreeDescription')}
              </TypeDescription>

              {exerciseContent.answers.map((ans) => {
                const token = encodeObjectToBase64({
                  ...competitionInfo,
                  exerciseNumber: exIndex + 1,
                  exerciseId: exerciseContent.id,
                  answerId: ans.id,
                })

                const qrValue = `${window.location.origin}/hub/upload-solution/${token}`

                return (
                  <AnswerTypeThreeWrapper key={ans.id} id="question-answers">
                    <div id="tiny-wrapper">
                      <TinyTextEditor
                        control={control}
                        label={ans.description}
                        name={`${exerciseContent.id}.id_${ans.id}`}
                        onChangeHandler={(e) => {
                          debounceInputs(e)
                        }}
                        defaultValue={''}
                        extendInitOptions={{ menubar: false }}
                      />
                      <div className="sec-col">
                        <UploadExerciseSolutionModule
                          onFileSelected={(files) => {
                            onFileSelectedHandler(files, token)
                          }}
                          errorMsg={fileError}
                        />
                        <QRCode renderAs="svg" value={qrValue} size={150} />
                      </div>
                    </div>

                    {mediaFiles.length ? (
                      <div id="uploaded-files">
                        <span>
                          {t('hub:UploadFileForExercise:AttachedFiles')}
                        </span>
                        <AlreadyUploadedFilesContainer>
                          {mediaFiles.map((file, index) => {
                            const fileId = file.name.split('-')[3]

                            if (+fileId === ans.id) {
                              return (
                                <div key={index} className="file-entry">
                                  {file.isImage ? (
                                    <img
                                      className="file-preview"
                                      src={getFileLinkFromServerStorage(
                                        file.path,
                                      )}
                                      alt="file-img"
                                    />
                                  ) : (
                                    <i className="far fa-file-pdf file-preview"></i>
                                  )}
                                  <div className="file-name">{file.name}</div>
                                  <i
                                    onClick={() =>
                                      deleteUploadedFileHandler(
                                        exerciseContent.id,
                                        file.path,
                                        token,
                                        setFileError,
                                      )
                                    }
                                    className="fas fa-trash-alt delete-file"
                                  ></i>
                                </div>
                              )
                            } else {
                              return null
                            }
                          })}
                        </AlreadyUploadedFilesContainer>
                      </div>
                    ) : null}
                  </AnswerTypeThreeWrapper>
                )
              })}
            </div>
          )
        default:
          console.error(
            'Answer type was not correct in function handleAnswerType from ExerciseDisplay(HUB): ',
            answerType,
          )
          break
      }
    }

    return (
      <div className={`${className} exercise`} id={exIndex}>
        <h2 className="headline">
          {t('hub:CompetitionView:ExerciseNumber', { exNum: exIndex + 1 })}
        </h2>

        <div className="description-wrapper">
          <TinyTextContentPreview
            id={`exercise-content${exIndex}`}
            html={exerciseContent?.description}
            imgZoom
          />
        </div>
        <div className="answers">
          {handleAnswerType(exerciseContent?.answer_type)}
        </div>
      </div>
    )
  },
)(({ customCss = {}, theme }) =>
  mergeDeepRight(
    {
      height: '100%',
      width: '100%',
      padding: 20,

      '.headline': {
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 40,
        fontSize: 22,
        color: COLORS.mainGray,
      },

      '.answers': {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',

        rowGap: 20,
      },

      '.description-wrapper': {
        display: 'flex',
        alignItems: 'center',
      },

      '.description-wrapper, .answers': {
        marginBottom: 20,
        minHeight: 200,
      },
    },
    customCss,
  ),
)

const TypeDescription = styled('span')(({ customCss, theme }) => ({
  color: COLORS.mainGray,

  ...customCss,
}))

const Answers = styled('div')(({ customCss, theme, answersLength }) => {
  let justifyContent = 'flex-start'
  let gap = 80

  if (answersLength > 4) {
    justifyContent = 'space-between'
    gap = 20
  }
  return {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: justifyContent,

    alignItems: 'center',
    columnGap: gap,
    flexWrap: 'wrap',
    zIndex: 0,

    justifySelf: 'center',

    ...customCss,
  }
})

const AnswerTypeThreeWrapper = styled('div')(({ customCss, theme }) => ({
  marginTop: 20,

  '#tiny-wrapper': {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    placeItems: 'center',
  },

  '.sec-col': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    '#tiny-wrapper': {
      gridTemplateColumns: '1fr',
      placeItems: 'center',
    },
  },
  ...customCss,
}))

const AlreadyUploadedFilesContainer = styled('div')({
  marginTop: 10,
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: 10,

  '& .file-entry': {
    display: 'flex',
    gap: 15,
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .file-preview': {
      width: 42,
      height: 42,
      fontSize: 42,
      color: COLORS.secBlack,
      textAlign: 'center',
    },

    '& .file-name': {
      fontSize: 16,
      color: COLORS.mainGray,
    },

    '& .delete-file': {
      width: 22,
      height: 22,
      fontSize: 22,
      color: COLORS.secRed,
      cursor: 'pointer',
    },
  },
})
