import styled from '@emotion/styled'
import { hub_selectTheme } from 'platforms/kyoso-hub/store/generalSlice'
import { mergeDeepRight } from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'
import { COLORS, QUERIES, THEMES } from 'styling/variables'

export const SubmitCompetitionView = styled(
  ({
    headline,
    className,
    icon,
    content,
    disabledPrev = false,
    disabledNext = false,
    prevButton,
    nextButton,
    onClickPrev,
    onClickNext,
  }) => {
    return (
      <div className={className}>
        {headline && headline}

        {content && <div className="content">{content}</div>}

        <div className="button-wrapper">
          {prevButton && (
            <button
              disabled={disabledPrev}
              className="submit-module-prev"
              onClick={onClickPrev}
            >
              {prevButton}
            </button>
          )}
          {nextButton && (
            <button
              disabled={disabledNext}
              className="submit-module-next"
              onClick={onClickNext}
            >
              {nextButton}
            </button>
          )}
        </div>
      </div>
    )
  },
)(({ customCss = {}, theme }) =>
  mergeDeepRight(
    {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      justifyContent: 'space-around',
      padding: 10,

      '.title': {
        color: COLORS.secRed,
        fontSize: 22,
      },

      '.content': {
        margin: '40px 0',
        color: COLORS.secBlack,
      },

      '.button-wrapper': {
        display: 'flex',
        justifyContent: 'space-around',

        '.submit-module-next , .submit-module-prev': {
          minWidth: '200px',
          border: `1px solid ${THEMES[+theme]}`,
          padding: 10,
          fontSize: 18,
          borderRadius: 10,

          ':hover': {
            cursor: 'pointer',
          },
        },

        '.submit-module-next': {
          backgroundColor: THEMES[+theme],
          color: '#ffff',

          ':hover': {
            backgroundColor: '#ffff',
            color: THEMES[+theme],
          },
        },

        '.submit-module-prev': {
          backgroundColor: '#ffff',
          color: THEMES[+theme],

          ':hover': {
            backgroundColor: THEMES[+theme],
            color: '#ffff',
          },
        },
      },

      [`@media (max-width: ${QUERIES.mobile}px)`]: {
        '.button-wrapper': {
          flexDirection: 'column',
          gap: 10,
        },
      },
    },
    customCss,
  ),
)
