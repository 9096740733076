import styled from '@emotion/styled'
import { lab_selectGlobalLoader } from 'platforms/kyoso-lab/store/generalSlice'
import { useSelector } from 'react-redux'
import { GlobalLoader, SharedModal } from 'shared'
import { COLORS, QUERIES } from 'styling/variables'
import { Footer } from '../Footer'
import { Header } from '../Header'
import { MobileHeader } from '../MobileHeader'

function MainLayout({ children }) {
  const globalLoader = useSelector(lab_selectGlobalLoader)

  return (
    <LabMainLayout>
      <Header />
      <div id="lab-content">
        <div id="lab-content-children">{children}</div>
        <Footer />
        <SharedModal />
        {globalLoader && <GlobalLoader />}
      </div>
      <MobileHeader />
    </LabMainLayout>
  )
}

const LabMainLayout = styled('div')({
  '& #lab-content': {
    position: 'relative',
    padding: '25px 30px 15px 30px',
    backgroundColor: COLORS.bgGray,
    height: 'calc(100vh - 65px)',
    overflow: 'auto',

    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      padding: '10px 10px 15px 10px',
      height: 'calc(100vh - 100px)',
      minHeight: 'initial',
    },

    [`@media (max-width: ${QUERIES.tablet}px)`]: {
      padding: '20px 20px 15px 20px',
    },

    '& #lab-content-children': {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 0px 5px #0000000D',
      borderRadius: 25,
      maxWidth: 1600,
      margin: '0 auto',
      marginBottom: 20,
      minHeight: 'calc(100vh - 165px)',
      padding: 40,
      position: 'relative',

      [`@media (max-width: ${QUERIES.mobile}px)`]: {
        minHeight: 'calc(100vh - 165px)',
        padding: 20,
      },

      [`@media (max-width: ${QUERIES.tablet}px)`]: {
        padding: '20px',
      },
    },
  },
})

export { MainLayout }
