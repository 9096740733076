import styled from '@emotion/styled'
import { QUERIES } from 'styling/variables'

export const LandingPageTitle = styled('h2')(({ color }) => ({
  fontSize: 32,
  color,
  textAlign: 'center',
  marginBottom: 30,

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    fontSize: 24,
  },
}))
