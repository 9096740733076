import styled from '@emotion/styled/macro'
import { removeChildModule } from 'platforms/kyoso-lab/services/kyoso/children'
import { SubmitButtonLab } from 'platforms/kyoso-lab/shared/'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  CONSTANTS,
  FormCheckBox,
  FormFile,
  FormInput,
  FormRadioButtons,
  PageInfo,
  SchoolGradeSelect,
  TinyTextEditor,
} from 'shared'
import { FormDatePicker } from 'shared/components/FormDatePicker'
import { COLORS, QUERIES } from 'styling/variables'
import { getFileLinkFromServerStorage } from 'utils/helpers/common'

function KyosoChildForm({ child, submitCallback }) {
  const { action } = useParams()
  const {
    register,
    control,
    getValues,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    trigger,
    formState,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      grade: child?.grade_id || null,
      duration: child?.duration || null,
      has_modules: child?.has_modules || null,
      instructions: child?.instructions || null,
      submit_message: child?.submit_message || null,
      price: child?.price || null,
      signup: child?.sign_up_type || '0',
      unpaid:
        child?.paid !== undefined && child?.paid !== null ? !child.paid : null,
      rules_text: child?.rules_text || null,
      modules: child?.modules?.length
        ? child.modules
        : [
            {
              module_id: null,
              module_name: null,
              module_duration: null,
            },
            {
              module_id: null,
              module_name: null,
              module_duration: null,
            },
          ],
      visibility_start: child?.answers_visibility
        ? JSON.parse(child.answers_visibility).intervals?.start.type
        : '1',
      visibility_end: child?.answers_visibility
        ? JSON.parse(child.answers_visibility).intervals?.end.type
        : '0',
      types_to_download: child?.types_to_download_as_file?.split(',') || [],
      visible_types: child?.answers_visibility
        ? JSON.parse(child.answers_visibility)?.types?.split(',')
        : null,
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'modules',
  })
  const { t } = useTranslation()

  // console.log(JSON.parse(child.answers_visibility))

  const onFormSubmit = (data, submitterId) => {
    const goTo =
      submitterId === 'add_participants'
        ? '/lab/dashboard/kyoso/add-participants'
        : null

    if (data.has_modules) {
      const modulesDuration = data.modules.reduce((acc, curr) => {
        return acc + +curr.module_duration
      }, 0)

      if (modulesDuration !== +data.duration) {
        setError('wrong_durations', {
          type: 'manual',
          message: t('lab:KyosoChild:WrongDurationsErrorMsg'),
        })
        onFormErrors()
      } else {
        submitCallback(data, goTo)
      }
    } else {
      if (+data.duration > 0) {
        submitCallback(data, goTo)
      } else {
        setError('duration', {
          type: 'manual',
          message: t('lab:KyosoChild:MinDurationErr'),
        })
      }
    }
  }

  function deleteChildModule(moduleId, index) {
    if (moduleId) {
      removeChildModule(moduleId)
        .then(() => {
          remove(index)
        })
        .catch((error) => {
          console.log(error)
        })
    } else remove(index)
  }

  const calculateDurationForModules = (allModules) => {
    const reducedTime = allModules.reduce((acc, curVal) => {
      return acc + +curVal.module_duration
    }, 0)

    setValue('duration', reducedTime)
  }

  const signUpRadioButtons = [
    {
      id: 0,
      name: 'signup',
      label: t('lab:KyosoInvites:SignUpOne'),
      register: register(),
      error: formState.errors.signup,
    },
    {
      id: 1,
      name: 'signup',
      label: t('lab:KyosoInvites:SignUpTwo'),
      register: register(),
      error: formState.errors.signup,
    },
  ]

  const visibilityStartRadioButtons = [
    {
      id: 0,
      name: 'visibility_start',
      label: t('lab:KyosoChild:AfterSubmit'),
      register: register(),
      error: formState.errors.visibility_start,
    },
    {
      id: 1,
      name: 'visibility_start',
      label: t('lab:KyosoChild:AfterCompetition'),
      register: register(),
      error: formState.errors.visibility_start,
    },
    {
      id: 2,
      name: 'visibility_start',
      label: t('lab:KyosoChild:AfterResults'),
      register: register(),
      error: formState.errors.visibility_start,
    },
    {
      id: 3,
      name: 'visibility_start',
      label: t('lab:KyosoChild:AfterSpecificTime'),
      register: register(),
      error: formState.errors.visibility_start,
    },
  ]

  const visibilityEndRadioButtons = [
    {
      id: 0,
      name: 'visibility_end',
      label: t('lab:KyosoChild:AvailableForever'),
      register: register(),
      error: formState.errors.visibility_end,
    },
    {
      id: 1,
      name: 'visibility_end',
      label: t('lab:KyosoChild:AvailableUntilSpecificTime'),
      register: register(),
      error: formState.errors.visibility_end,
    },
  ]

  const answerSubTypes = [
    {
      id: 'download-questions',
      value: CONSTANTS.visibility_types.QUESTIONS,
      label: 'lab:KyosoChild:LabelQuestions',
    },
    {
      id: 'download-participant-answers',
      value: CONSTANTS.visibility_types.USER_ANSWERS,
      label: 'lab:KyosoChild:LabelParticipantAnswers',
    },
    {
      id: 'download-correct-answers',
      value: CONSTANTS.visibility_types.CORRECT_ANSWERS,
      label: 'lab:KyosoChild:LabelCorrectAnswers',
    },
    {
      id: 'download-solutions',
      value: CONSTANTS.visibility_types.SOLUTIONS,
      label: 'lab:KyosoChild:LabelSolutions',
    },
  ]

  return (
    <KyosoChildFormLayout>
      <div id="layout">
        <LeftContent>
          <div className="first-line">
            <SchoolGradeSelect
              id="grade"
              name="grade"
              label={`${t('shared:LabelSchoolClass')} *`}
              rules={{ required: 'Required' }}
              setValue={setValue}
              getValues={getValues}
              onChange={() => trigger('grade')}
              control={control}
              error={formState.errors.grade}
              customCss={{
                marginBottom: 0,
              }}
            />

            <FormInput
              id="duration"
              label={`${t('lab:KyosoChild:DurationInMinutesLabel')}*`}
              type="number"
              name="duration"
              min="0"
              register={register({ required: 'Required' })}
              onChange={() =>
                formState.errors.wrong_durations &&
                clearErrors('wrong_durations')
              }
              defaultValue={null}
              customCss={{
                marginBottom: 0,
              }}
              error={formState.errors.duration}
            />
          </div>
          <FormCheckBox
            id="has_modules"
            name="has_modules"
            label="lab:KyosoChild:HasModulesLabel"
            register={register()}
            customCss={{
              width: 220,
              marginBottom: 30,
              '.text': {
                marginLeft: 10,
                gridColumn: 2,
                color: COLORS.mainGray,
                justifySelf: 'left',
              },
            }}
          />
          {watch('has_modules') && (
            <>
              <div id="modules-label">
                {t('lab:KyosoChild:ModulesLabel')}
                {formState.errors?.wrong_durations && (
                  <span id="modules-label-error">
                    ({formState.errors.wrong_durations.message})
                  </span>
                )}
              </div>
              <ChildModules>
                {fields.map((field, index) => {
                  return (
                    <div className="module" key={field.id}>
                      <input
                        hidden
                        id="module_id"
                        name={`modules[${index}].module_id`}
                        defaultValue={field.module_id}
                        ref={register()}
                      />
                      <FormInput
                        customCss={{ marginBottom: 0 }}
                        id="module_name"
                        label="lab:KyosoChild:ModuleNameLabel"
                        type="text"
                        name={`modules[${index}].module_name`}
                        register={register({ required: 'Required' })}
                        error={
                          formState.errors.modules &&
                          formState.errors.modules[index]?.module_name
                        }
                        defaultValue={field.module_name}
                      />
                      <FormInput
                        customCss={{ marginBottom: 0 }}
                        id="module_duration"
                        label="lab:KyosoChild:DurationInMinutesLabel"
                        type="number"
                        onChange={() => {
                          formState.errors.wrong_durations &&
                            clearErrors('wrong_durations')

                          if (watch('has_modules')) {
                            calculateDurationForModules(getValues().modules)
                          }
                        }}
                        name={`modules[${index}].module_duration`}
                        min="0"
                        register={register({ required: 'Required' })}
                        error={
                          formState.errors.modules &&
                          formState.errors.modules[index]?.module_duration
                        }
                        defaultValue={field.module_duration}
                      />
                      {index > 1 && (
                        <SubmitButtonLab
                          className="black"
                          type="button"
                          id="remove_module"
                          onClick={() => {
                            deleteChildModule(field.module_id, index)
                          }}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </SubmitButtonLab>
                      )}
                    </div>
                  )
                })}
              </ChildModules>
              <SubmitButtonLab
                className="black"
                type="button"
                id="add_module"
                onClick={() =>
                  append({ module_name: null, module_duration: null })
                }
                customCss={{ width: 220, marginBottom: 30 }}
              >
                {t('lab:KyosoChild:AddModule')}
              </SubmitButtonLab>
            </>
          )}

          <FormRadioButtons
            customCss={{ marginBottom: '10px' }}
            radioButtons={signUpRadioButtons}
            label="lab:KyosoInvites:RadioButtonsLabel"
            id="signUpRadioButtons"
            disabled={child?.invited_users || false}
            type="radio"
            name="signup"
            error={formState.errors.signup}
          />

          {child?.invited_users ? (
            <WarningMessage>
              {t('lab:KyosoChild:RadioButtonsErrorMsg')}
            </WarningMessage>
          ) : null}

          <WarningMessage id="message">
            {t('lab:KyosoInvites:RadioButtonsMessage')}
          </WarningMessage>

          <TinyTextEditor
            label="lab:KyosoChild:InstructionsLabel"
            id="instructions"
            name="instructions"
            control={control}
            defaultValue={child?.instructions || null}
            extendInitOptions={{
              toolbar: [
                'undo redo cut copy paste pastetext | styleselect forecolor fontsizeselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent ',
                'subscript superscript | link image table  bullist numlist |',
              ],
            }}
          />

          <PageInfo
            subtitle={t('lab:KyosoChild:VisibleElementTypesInPersonalResults')}
            customCss={{ h2: { marginBottom: 20, marginTop: 20 } }}
          />

          <DownloadCheckboxOptionsWrapper>
            {answerSubTypes.map((ans) => (
              <FormCheckBox
                key={ans.id}
                id={`visible-${ans.id}`}
                name="visible_types"
                value={ans.value}
                label={ans.label}
                register={register()}
                error={formState.errors.types_to_download}
                touched={formState.touched.types_to_download}
              />
            ))}
          </DownloadCheckboxOptionsWrapper>

          <FormRadioButtons
            customCss={{ marginBottom: '0px' }}
            radioButtons={visibilityStartRadioButtons}
            label="lab:KyosoChild:LabelVisibilityStart"
            id="visibility-start"
            type="radio"
            onChange={() => {
              setValue('visibility_start_date', '')
            }}
            name="visibility_start"
            error={formState.errors.visibility_start}
          />

          <FormDatePicker
            customCss={{
              maxWidth: 300,
              marginBottom: 20,
              br: { display: 'none' },
              display: `${
                watch('visibility_start') === '3' ? 'block' : 'none'
              }`,
            }}
            rules={
              watch('visibility_start') === '3'
                ? { required: 'Required' }
                : null
            }
            disabled={watch('visibility_start') !== '3'}
            id="visibility-start-date"
            control={control}
            name="visibility_start_date"
            setValue={setValue}
            defaultValue={
              child?.answers_visibility
                ? JSON.parse(child.answers_visibility).intervals?.start.date
                : null
            }
            error={formState.errors.visibility_start_date}
          />

          <FormRadioButtons
            customCss={{ marginBottom: '0px', marginTop: 20 }}
            radioButtons={visibilityEndRadioButtons}
            label="lab:KyosoChild:LabelVisibilityEnd"
            id="visibility-end"
            onChange={() => {
              setValue('visibility_end_date', '')
            }}
            type="radio"
            name="visibility_end"
            error={formState.errors.visibility_end}
          />

          <FormDatePicker
            customCss={{
              maxWidth: 300,
              marginBottom: 20,
              br: { display: 'none' },
              display: `${watch('visibility_end') === '1' ? 'block' : 'none'}`,
            }}
            id="visibility-end-date"
            rules={
              watch('visibility_end') === '3' ? { required: 'Required' } : null
            }
            control={control}
            name="visibility_end_date"
            setValue={setValue}
            defaultValue={
              child?.answers_visibility
                ? JSON.parse(child.answers_visibility).intervals?.end.date
                : null
            }
            error={formState.errors.visibility_end_date}
          />

          <PageInfo
            subtitle={t('lab:KyosoChild:LabelDownloadTitle')}
            customCss={{ h2: { marginBottom: 20, marginTop: 20 } }}
          />

          <DownloadCheckboxOptionsWrapper>
            {answerSubTypes.map((ans) => (
              <FormCheckBox
                key={ans.id}
                id={ans.id}
                name="types_to_download"
                value={ans.value}
                label={ans.label}
                register={register()}
                error={formState.errors.types_to_download}
                touched={formState.touched.types_to_download}
              />
            ))}
          </DownloadCheckboxOptionsWrapper>
        </LeftContent>

        <RightContent>
          {child?.parent_paid_status === '2' && (
            <div className="first-line">
              <FormInput
                customCss={{ marginBottom: 0, width: 200 }}
                id="price"
                label="lab:KyosoChild:PriceLabel"
                type="number"
                step="0.01"
                min="0.01"
                name="price"
                disabled={watch('unpaid')}
                register={register()}
                defaultValue={null}
              />
              <FormCheckBox
                customCss={{ marginBottom: 0 }}
                id="unpaid"
                name="unpaid"
                label="shared:Unpaid"
                register={register()}
                onChange={() => setValue('price', null)}
                customCss={{
                  marginTop: 25,
                  marginBottom: 0,
                  '.text': {
                    marginLeft: 10,
                    gridColumn: 2,
                    color: COLORS.mainGray,
                    justifySelf: 'left',
                  },
                }}
              />
            </div>
          )}

          <TinyTextEditor
            label="lab:KyosoChild:SubmitMessageLabel"
            id="submit_message"
            name="submit_message"
            control={control}
            defaultValue={child?.submit_message || null}
            extendInitOptions={{
              toolbar: [
                'undo redo cut copy paste pastetext | styleselect forecolor fontsizeselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent ',
                'subscript superscript | link image table  bullist numlist |',
              ],
            }}
          />

          <TinyTextEditor
            label="lab:KyosoChild:RulesTextLabel"
            id="rules_text"
            name="rules_text"
            control={control}
            defaultValue={child?.rules_text || null}
            extendInitOptions={{
              toolbar: [
                'undo redo cut copy paste pastetext | styleselect forecolor fontsizeselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent ',
                'subscript superscript | link image table  bullist numlist |',
              ],
            }}
          />

          <FormFile
            id="rules_file"
            type="file"
            name="rules_file"
            label="lab:KyosoChild:RulesLinkLabel"
            buttonLabel="shared:Attach"
            readOnly={true}
            register={register()}
          />
          {child?.rules_link && (
            <SubmitButtonLab
              type="button"
              className="black"
              onClick={() =>
                window.open(
                  getFileLinkFromServerStorage(child.rules_link),
                  '_blank',
                )
              }
              id="rules-preview"
            >
              Покажи файла с правилата
            </SubmitButtonLab>
          )}
        </RightContent>
      </div>
      <FormFooter>
        <div id="info-text">{t('lab:KyosoChild:InfoText')}</div>
        <div id="submit-buttons">
          <SubmitButtonLab
            type="button"
            className="black"
            id="create_edit"
            customCss={{ height: 'auto' }}
            onClick={
              handleSubmit((data) => onFormSubmit(data, 'create_edit'))
              // onFormSubmit(null, e, 'create_edit')
            }
          >
            {action === 'insert'
              ? t('lab:Shared:SubmitButtonCreate')
              : t('lab:Shared:SubmitButtonSave')}
          </SubmitButtonLab>
          <SubmitButtonLab
            type="button"
            className="black"
            id="add_participants"
            customCss={{ height: 'auto' }}
            onClick={
              handleSubmit((data) => onFormSubmit(data, 'add_participants'))
              // (e) => onFormSubmit(null, e, 'add_participants')
            }
          >
            {t('lab:KyosoChild:AddParticipantsButton')}
          </SubmitButtonLab>
        </div>
      </FormFooter>
    </KyosoChildFormLayout>
  )
}

export { KyosoChildForm }

const KyosoChildFormLayout = styled('form')({
  '#layout': {
    width: '100%',
    display: 'flex',

    [`@media (max-width: ${QUERIES.tablet}px)`]: {
      flexDirection: 'column',
    },

    '#modules-label': {
      fontSize: 18,
      color: COLORS.secBlack,
      marginBottom: 20,
    },

    '#modules-label-error': {
      color: COLORS.secRed,
      marginLeft: 5,
    },
  },
})

const LeftContent = styled('div')({
  width: '50%',
  paddingRight: 35,

  [`@media (max-width: ${QUERIES.tablet}px)`]: {
    width: '100%',
    paddingRight: 0,
  },

  '.first-line': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: 35,
    marginBottom: 30,
  },
})

const RightContent = styled('div')({
  width: '50%',
  paddingLeft: 35,

  [`@media (max-width: ${QUERIES.tablet}px)`]: {
    width: '100%',
    paddingLeft: 0,
  },

  '.first-line': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: 35,
    marginBottom: 30,
  },
})

const ChildModules = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 25,
  marginBottom: 30,

  '.module': {
    display: 'grid',
    gridTemplateColumns: '2fr 2fr 40px',
    alignItems: 'flex-end',
    columnGap: 35,
  },
})

const FormFooter = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginTop: 30,
  gap: 30,

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    flexDirection: 'column',
  },

  '#info-text': {
    width: '60%',
    fontSize: 16,
    color: COLORS.mainGray,

    [`@media (max-width: ${QUERIES.tablet}px)`]: {
      width: '50%',
    },
    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      width: '100%',
    },
  },

  '#submit-buttons': {
    display: 'flex',
    width: '40%',
    gap: 20,

    [`@media (max-width: ${QUERIES.tablet}px)`]: {
      width: '50%',
    },
    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      width: '100%',
    },
  },
})

const WarningMessage = styled('p')({
  color: COLORS.secRed,
  fontsize: '18px',
  marginBottom: '20px',

  '&#message': {
    color: COLORS.secBlack,
  },
})

const DownloadCheckboxOptionsWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
})
