import styled from '@emotion/styled'
import { SettingsWidget } from 'platforms/kyoso-lab/shared'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { COLORS } from 'styling/variables'

export function BundlesContent({ bundlesContent }) {
  const [showContentSettings, setShowContentSettings] = useState(false)
  const contentSettings = useRef(null)

  const { t } = useTranslation()

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideOfWidgetsMenu)

    return () => {
      document.removeEventListener('click', handleClickOutsideOfWidgetsMenu)
    }
  }, [showContentSettings])

  const handleClickOutsideOfWidgetsMenu = (event) => {
    if (showContentSettings !== false) {
      !contentSettings.current?.contains(event.target) &&
        setShowContentSettings(false)
    }
  }

  const toggleParentSettings = (e, index) => {
    e.stopPropagation()

    if (showContentSettings === index) {
      return setShowContentSettings(null)
    }

    setShowContentSettings(index)
  }

  const checkStatus = (status) => {
    let colorClass

    if (status) {
      colorClass = 'green'
    } else {
      colorClass = 'red'
    }

    return colorClass
  }

  return (
    <div>
      <PackagesTitles>
        <span>{t('lab:Shared:TitleIdCode')}</span>
        <span>{t('lab:Shared:TitleName')}</span>
        <span>{t('lab:Shared:TitleYear')}</span>
        <span>{t('lab:Shared:TitleSubject')}</span>
        <span>{t('lab:Shared:TitleGrade')}</span>
        <span>{t('lab:Shared:TitleParticipantCount')}</span>
        <span>{t('lab:Shared:TitlePrice')}</span>
        <span>{t('lab:Shared:TitleSettings')}</span>
      </PackagesTitles>

      {bundlesContent.map((bundle, index) => {
        return (
          <PackagesWrapper
            className="wrapper"
            key={index}
            // onClick={(e) => toggleParent(e, index, parent.id)}
          >
            <span id="uid" className={checkStatus(bundle.open)}>
              {bundle.uid}
            </span>
            <span id="name">{bundle.name}</span>
            <span id="year">{bundle.year}</span>
            <span id="subject-name">{bundle.subject_name}</span>
            <span id="grades">{bundle.grade_name}</span>
            <span id="participations">{bundle.participations}</span>
            <span id="price">
              {t('shared:Currency', { price: bundle.price })}
            </span>
            <span
              id="settings-parent"
              onClick={(e) => toggleParentSettings(e, index)}
            >
              <i className={`fas fa-ellipsis-h`}></i>
              {showContentSettings === index && (
                <SettingsWidget ref={contentSettings}>
                  <Link to={`/lab/dashboard/kyoso/bundles/update/${bundle.id}`}>
                    <i className="fas fa-pencil-alt"></i>
                    <span>{t('lab:Kyoso:SettingsWidgetEdit')}</span>
                  </Link>

                  <Link
                    to={{
                      pathname: `/lab/dashboard/kyoso/bundles/orders/${bundle.id}`,
                      state: {
                        id: bundle.uid,
                        name: bundle.name,
                        grade_name: bundle.grade_name,
                      },
                    }}
                  >
                    <i className="fas fa-users"></i>
                    <span>{t('lab:Kyoso:SettingsWidgetBundleOrders')}</span>
                  </Link>

                  <Link to="/lab/support">
                    <i className="fas fa-trash-alt"></i>
                    <span>{t('lab:Kyoso:SettingsWidgetDelete')}</span>
                  </Link>
                </SettingsWidget>
              )}
            </span>
          </PackagesWrapper>
        )
      })}
    </div>
  )
}

const PackagesTitles = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr  4fr 1fr 2fr 1fr 1.5fr 1fr 1fr',
  placeItems: 'center',
  gridColumnGap: '20px',
  marginTop: '20px',
  color: COLORS.secBlue,
  fontWeight: '400',

  '& :nth-of-type(2)': {
    justifySelf: 'left',
  },

  '& :nth-of-type(4)': {
    justifySelf: 'left',
  },
})

const PackagesWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr  4fr 1fr 2fr 1fr 1.5fr 1fr 1fr',
  placeItems: 'center',
  gridColumnGap: '20px',
  gridRowGap: '20px',
  marginTop: '5px',
  fontWeight: '400',
  border: `1px solid ${COLORS.borderGray}`,
  borderRadius: '15px',
  padding: '20px 0',
  color: COLORS.mainGray,

  ' :hover': {
    color: COLORS.secBlack,
    backgroundColor: COLORS.bgGray,
  },

  '& .black': { color: COLORS.secBlack },
  '& .red': { color: COLORS.secRed },
  '& .yellow': { color: COLORS.secYellow },
  '& .green': { color: COLORS.secGreen },

  '& #add-grade': {
    color: COLORS.secBlue,
    textDecoration: 'underline',
  },

  '& #name': {
    justifySelf: 'left',
  },

  '& #subject-name': {
    fontSize: 14,
    justifySelf: 'left',
  },

  '& #grades, & #type-name': {
    fontSize: 14,
  },

  '& #settings-parent,#settings-child': {
    width: '30px',
    textAlign: 'center',
    position: 'relative',

    i: {
      fontSize: 'larger',
    },

    ':hover': {
      color: COLORS.secBlue,
      cursor: 'pointer',
    },
  },
})
