import styled from '@emotion/styled/macro'
import { COLORS } from 'styling/variables'

export const SettingsWidget = styled('div')(({ customCss }) => ({
  position: 'absolute',
  top: 30,
  left: '-20px',
  transform: 'translateX(-50%)',
  background: `#FFFFFF 0% 0% no-repeat padding-box`,
  border: `1px solid ${COLORS.borderGray}`,
  borderRadius: '10px',
  fontSize: 16,
  zIndex: 999,

  '&:before': {
    content: '""',
    width: '0px',
    height: '0px',
    position: 'absolute',
    borderLeft: '20px solid transparent',
    borderRight: `20px solid transparent`,
    borderTop: '10px solid transparent',
    borderBottom: `20px solid ${COLORS.borderGray}`,
    left: '70%',
    top: '-30px',

    transform: 'translateX(-50%)',
  },
  '&:after': {
    content: '""',
    width: '0px',
    height: '0px',
    position: 'absolute',
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderTop: '10px solid transparent',
    borderBottom: `18px solid #fff`,
    left: '70%',
    top: '-27px',
    transform: 'translateX(-50%)',
  },

  '& a': {
    display: 'flex',
    textDecoration: 'none',
    color: COLORS.secBlack,
    padding: '10px',
    alignItems: 'center',
    borderBottom: `1px solid ${COLORS.borderGray} `,

    ' :hover': {
      backgroundColor: COLORS.bgGray,
    },

    span: {
      width: '100%',
      textAlign: 'center',
    },

    i: {
      color: COLORS.secBlue,
      marginRight: '10px',
      width: '20px',
    },
  },

  '& a:first-of-type': {
    borderRadius: '10px 10px 0 0',
  },

  '& a:last-of-type': {
    display: 'flex',
    textDecoration: 'none',
    color: COLORS.secRed,
    padding: '10px',
    borderRadius: '0 0 10px 10px',
    borderBottom: `1px solid ${COLORS.bgGray} `,

    i: {
      color: COLORS.secRed,
      marginRight: '10px',
    },
  },
  ...customCss,
}))
