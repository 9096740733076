import styled from '@emotion/styled'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'styling/variables'
import UploadIcon from 'assets/img/answer-types/answers_long.svg'
import Parser from 'html-react-parser'
import { indexOf } from 'lodash'

export const UploadExerciseSolutionModule = styled(
  ({ className, onFileSelected, errorMsg }) => {
    const { t } = useTranslation()
    const solutionFile = useRef(null)
    const [fileErrors, setFileErrors] = useState(null)

    function handleFileUpload(e) {
      e.preventDefault()
      const files = e.target.files
      if (files.length) {
        let errors = ''
        const acceptedFileTypes = [
          '.jpg',
          '.jpeg',
          '.png',
          '.bmp',
          '.svg',
          '.pdf',
          '.JPG',
          '.JPEG',
          '.PNG',
          '.BMP',
          '.SVG',
          '.PDF',
        ]
        const filesToUpload = []

        for (const file of files) {
          const fileType = file.name.substring(file.name.indexOf('.'))

          if (file.size > 5000000) {
            errors += `${file.name} - ${t('errors:LimitМbForFileUpload', {
              mb: 5,
            })}<br/>`
          } else if (!acceptedFileTypes.some((type) => type === fileType)) {
            errors += `${file.name} - ${t('errors:FileTypeInvalid')}<br/>`
          } else {
            filesToUpload.push(file)
          }
        }
        if (errors) setFileErrors(errors)
        if (filesToUpload.length) onFileSelected(filesToUpload)
      }
    }

    return (
      <div className={className}>
        <input
          ref={solutionFile}
          hidden
          type="file"
          accept=".jpg,.jpeg,.png,.bmp,.svg,.pdf"
          multiple
          onChange={handleFileUpload}
          onClick={() => {
            setFileErrors(null)
          }}
        />
        <div
          className="upload-button"
          onClick={() => {
            solutionFile.current.click()
          }}
        >
          <img src={UploadIcon} alt="upload-icon" />
          <span>{t('hub:UploadFileForExercise:ButtonText')}</span>
        </div>
        <div className="info-text">
          {t('hub:UploadFileForExercise:Instructions')}
        </div>
        {(fileErrors || errorMsg) && (
          <div className="error-message">{Parser(fileErrors || errorMsg)}</div>
        )}
      </div>
    )
  },
)(({ customCss }) => ({
  width: 220,

  ...customCss,

  '& .upload-button': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 20,
    border: `2px solid ${COLORS.secBlack}`,
    borderRadius: 15,
    padding: '15px 30px',
    cursor: 'pointer',

    '&:hover': {
      borderColor: COLORS.secBlue,
    },
    '&:hover span': {
      color: COLORS.secBlue,
    },
    '& span': {
      fontWeight: 'bold',
      fontSize: 14,
      color: COLORS.secBlack,
    },
  },

  '& .info-text': {
    marginTop: 15,
    lineHeight: 1.2,
    fontSize: 14,
    color: COLORS.mainGray,
    textAlign: 'center',
  },

  '& .error-message': {
    marginTop: 20,
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
    color: COLORS.secRed,
  },
}))
