import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router'
import { getAllGrades } from 'services/shared'
import { EscButton, PageInfo } from 'shared'
import { COLORS } from 'styling/variables'
import { KyosoChildForm } from '../components/kyoso-child/KyosoChildForm'
import { getChild, insertChild, updateChild } from '../services/kyoso/children'
import {
  lab_hideGlobalLoading,
  lab_showGlobalLoading,
} from '../store/generalSlice'
import moment from 'moment'

function KyosoChild() {
  const { action } = useParams()
  const [grades, setGrades] = useState([])
  const routeLocation = useLocation()
  const routeHistory = useHistory()
  const [childInfo, setChildInfo] = useState(null)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    getAllGrades()
      .then((response) => setGrades(response.grades))
      .catch((error) => console.log(error))

    if (action !== 'insert') {
      dispatch(lab_showGlobalLoading())
      getChild(action)
        .then((response) => {
          setChildInfo({
            ...response.child,
            invited_users: response.invitedUsersNumber,
          })
        })
        .catch((error) => {
          console.log(error)
          routeHistory.replace('/lab')
        })
        .finally(() => dispatch(lab_hideGlobalLoading()))
    } else {
      setChildInfo({
        parent_paid_status: routeLocation.state?.parent_paid_status,
      })
    }
  }, [action])

  function createChild(data, goTo = null) {
    insertChild(routeLocation.state?.parent_id, data.grade, {
      duration: +data.duration || '_omit_',
      has_modules: data.has_modules,
      instructions: data.instructions || '_omit_',
      paid: !data.unpaid && !data.price ? '_omit_' : !data.unpaid,
      price: !data.unpaid && !data.price ? '_omit_' : +data.price,
      rules_text: data.rules_text || '_omit_',
      rules_file: data.rules_file ? data.rules_file[0] : '_omit_',
      submit_message: data.submit_message || '_omit_',
      modules: data.has_modules ? JSON.stringify(data.modules) : '_omit_',
      sign_up_type: data.signup,
      answers_visibility: JSON.stringify({
        types: data.visible_types.join(',') || '_omit_',
        intervals: {
          start: {
            type: data.visibility_start,
            date: moment(data.visibility_start_date).utc().valueOf(),
          },
          end: {
            type: data.visibility_end,
            date: moment(data.visibility_end_date).utc().valueOf(),
          },
        },
      }),
      types_to_download_as_file: data?.types_to_download.join(',') || '_omit_',
    })
      .then((response) => {
        if (goTo)
          routeHistory.push(goTo, {
            child_id: response.childId,
            parent_name: childInfo.parent_name,
            child_grade: grades.find((grade) => grade.id === data.grade),
            has_modules: data.has_modules,
          })
        else
          routeHistory.replace(
            `/lab/dashboard/kyoso/child/${response.childId}/preview`,
          )
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function editChild(data, goTo = null) {
    console.log(data)
    updateChild(+action, {
      grade_id: data.grade,
      duration: +data.duration || null,
      has_modules: data.has_modules,
      instructions: data.instructions || null,
      paid: !data.unpaid && !data.price ? null : !data.unpaid,
      price: !data.unpaid && !data.price ? null : +data.price,
      rules_text: data.rules_text || null,
      rules_file: data.rules_file ? data.rules_file[0] : '_omit_',
      submit_message: data.submit_message || null,
      modules: data.has_modules ? JSON.stringify(data.modules) : null,
      sign_up_type: data.signup,
      answers_visibility: JSON.stringify({
        types: data.visible_types.join(',') || null,
        intervals: {
          start: {
            type: data.visibility_start,
            date: moment(data.visibility_start_date).utc().valueOf(),
          },
          end: {
            type: data.visibility_end,
            date: moment(data.visibility_end_date).utc().valueOf(),
          },
        },
      }),
      types_to_download_as_file: data?.types_to_download.join(',') || null,
    })
      .then(() => {
        if (goTo)
          routeHistory.push(goTo, {
            child_id: action,
            parent_name: childInfo.parent_name,
            child_grade: grades.find((grade) => grade.id === data.grade),
            has_modules: data.has_modules,
          })
        else
          routeHistory.replace(`/lab/dashboard/kyoso/child/${action}/preview`)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const kyosoChildActionHandler = (action) => {
    let kyosoChildForm = null

    if (action === 'insert') {
      kyosoChildForm = <KyosoChildForm submitCallback={createChild} />
    } else {
      kyosoChildForm = childInfo && (
        <KyosoChildForm child={childInfo} submitCallback={editChild} />
      )
    }

    return kyosoChildForm
  }

  return (
    <KyosoChildLayout>
      <PageInfo
        title={
          action !== 'insert'
            ? t('lab:KyosoChild:EditTitle', {
                parent_name: childInfo?.parent_name,
              })
            : t('lab:KyosoChild:CreateTitle', {
                parent_name: routeLocation.state?.parent_name,
              })
        }
        subtitle={
          action !== 'insert'
            ? t('lab:KyosoChild:EditSubtitle')
            : t('lab:KyosoChild:CreateSubtitle')
        }
        customCss={{
          marginBottom: 30,
          '& h1': {
            color: COLORS.secBlack,
            marginBottom: 5,
          },
        }}
      />

      <EscButton onClick={() => routeHistory.goBack()} />

      {kyosoChildActionHandler(action)}
    </KyosoChildLayout>
  )
}

export { KyosoChild }

const KyosoChildLayout = styled('div')({})
