import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AgGridCompPublic } from 'shared'
import { getCompetitionRankingData } from 'platforms/kyoso-lab/services/kyoso/competitions'
import { getPublicCompetitionData } from '../services/competition'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { COLORS } from 'styling/variables'
import { hub_selectUser } from '../store/userSlice'
import { ImageWrapper } from 'shared'
import NoImgPlaceholder from 'assets/img/no_img_placeholder.png'
import {
  hub_hideGlobalLoader,
  hub_showGlobalLoader,
} from '../store/generalSlice.js'
import { PageInfo } from 'shared'
// import { exerciseCellRendererHook } from 'utils/helpers/hooks'

function RankingPage() {
  const { t } = useTranslation()
  const [rowData, setRowData] = useState(null)
  const [pageData, setPageData] = useState({})
  const { childId } = useParams()
  const dispatch = useDispatch()
  const reduxUser = useSelector(hub_selectUser)

  useEffect(() => {
    dispatch(hub_showGlobalLoader())
    getPublicCompetitionData(childId).then((response) => {
      setPageData(response.info)
      getCompetitionRankingData(childId)
        .then((response) => {
          setRowData({
            data: response.data,
            hasModules: response.hasModules,
          })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          dispatch(hub_hideGlobalLoader())
        })
    })
  }, [])

  const addPointsTag = (params, maxPoints) => {
    return `${params.value} / points - ${maxPoints}`
  }

  return (
    <RankingPageWrapper>
      <PageInfo
        title={t('hub:Competitions:ResultsTitle')}
        customCss={{
          '& h1': {
            marginBottom: 10,
          },
          '& h2': {
            marginBottom: 20,
          },
        }}
      />
      <div className="title">
        {pageData.name} - {pageData.grade_name}
      </div>
      <div className="subtitle">
        {pageData.subject_name} - {pageData.year} - {pageData.type_name} <br />
        {t('hub:Competitions:CompetitionOwnerInfo', {
          competition_owner: pageData.owner_name,
        })}
      </div>
      <div className="subtitle">
        Ако сте в профила си, редът на Вашето участие ще бъде със светлосин фон
        в таблицата по-долу.
      </div>
      {rowData ? (
        <>
          <div className="legend">
            Оцветяване в колоните със задачи: <br />
            <span className="correct legend-item">Верен отговор</span>
            <span className="wrong legend-item">Грешен отговор</span>
            <span className="blank legend-item">Неявил се / Неотговорил</span>
          </div>
          <div style={{ width: '100%' }}>
            <AgGridCompPublic
              gridOptions={{
                rowDragManaged: true,
              }}
              rowData={rowData?.data}
              hasModules={rowData?.hasModules}
              customColDef={{
                rowDrag: false,
                suppressMovable: true,
                suppressMenu: true,
              }}
              currentUser={reduxUser.id ? reduxUser.id : null}
            />
          </div>
        </>
      ) : null}
    </RankingPageWrapper>
  )
}

export { RankingPage }

const RankingPageWrapper = styled('div')({
  '& .title': {
    marginTop: 20,
    fontSize: 18,
  },

  '& .subtitle': {
    marginTop: 5,
    fontSize: 16,
  },
  '& .legend': {
    margin: '20px 0',
    '& .legend-item': {
      color: 'white',
      boxSizing: 'border-box',
      display: 'inline-block',
      margin: '5px 5px',
      '&.correct': {
        color: COLORS.secGreen,
      },
      '&.wrong': {
        color: COLORS.secRed,
      },
      '&.blank': {
        color: COLORS.secYellow,
      },
    },
  },
})
