import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { EscButton, PageInfo } from 'shared'
import { KyosoParentForm } from '../components/kyoso-parent/KyosoParentForm'
import {
  getParent,
  insertParent,
  updateParent,
} from '../services/kyoso/parents'
import {
  lab_hideGlobalLoading,
  lab_showGlobalLoading,
} from '../store/generalSlice'
import { lab_selectUser } from '../store/userSlice'
import { getAllKyosoTypesHook } from 'utils/helpers/hooks'
import moment from 'moment'

function KyosoParent() {
  const kyosoTypes = getAllKyosoTypesHook()
  const reduxUser = useSelector(lab_selectUser)
  const [parentData, setParentData] = useState(null)
  const { t } = useTranslation()
  const routeHistory = useHistory()
  const { action, parentId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (parentId) {
      dispatch(lab_showGlobalLoading())
      getParent(parentId)
        .then((response) => {
          setParentData(response.parent)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => dispatch(lab_hideGlobalLoading()))
    }
  }, [])

  const KyosoParentActionHandler = (action) => {
    let kyosoParentForm = null

    if (action === 'insert') {
      kyosoParentForm = <KyosoParentForm submitCallBack={createParent} />
    } else {
      kyosoParentForm = parentData && (
        <KyosoParentForm parentData={parentData} submitCallBack={editParent} />
      )
    }

    return kyosoParentForm
  }

  const createParent = (data, addChild) => {
    console.log(data)
    let datesParsed = '_omit_'
    let paidValue = '_omit_'

    if (data.isFree) {
      paidValue = '0'
    } else if (data.price) {
      paidValue = '1'
    } else if (data.isIndividual) {
      paidValue = '2'
    }

    if (data.dates && data.dates[0].startDate) {
      datesParsed = JSON.stringify(
        data.dates
          .map((date) => ({
            startDate: moment(date.startDate).toDate().getTime(),
            endDate: moment(date.endDate).toDate().getTime(),
          }))
          .sort((a, b) => a.endDate - b.endDate),
      )
    }

    insertParent(
      reduxUser.id,
      data.name.trim(),
      data.year,
      data.schoolSubject,
      data.type,
      {
        intervals: datesParsed,
        picture: data.image ? data.image[0] : '_omit_',
        description: data.description || '_omit_',
        visibility: data.visibility || '_omit_',
        paid: paidValue,
        price: +data.price || '_omit_',
      },
    )
      .then((response) => {
        addChild
          ? routeHistory.replace('/lab/dashboard/kyoso/child/insert', {
              parent_id: response.parentId,
              parent_name: data.name.trim(),
              parent_paid_status: paidValue === '_omit_' ? null : paidValue,
            })
          : routeHistory.replace(
              `/lab/dashboard/kyoso/parent/${response.parentId}/preview`,
            )
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const editParent = (data, addChild) => {
    let datesParsed = null
    let paidValue = null

    if (data.isFree) {
      paidValue = '0'
    } else if (data.price) {
      paidValue = '1'
    } else if (data.isIndividual) {
      paidValue = '2'
    }

    if (data.dates && data.dates[0].startDate) {
      datesParsed = JSON.stringify(
        data.dates
          .map((date) => ({
            startDate: moment(date.startDate).toDate().getTime(),
            endDate: moment(date.endDate).toDate().getTime(),
          }))
          .sort((a, b) => a.endDate - b.endDate),
      )
    }

    updateParent(+parentId, {
      name: data.name.trim(),
      year: data.year,
      subject_id: data.schoolSubject,
      type_id: data.type,
      intervals: datesParsed,
      picture: data.image ? data.image[0] : '_omit_',
      description: data.description || null,
      visibility: data.visibility,
      paid: paidValue,
      price: +data.price || null,
    })
      .then(() => {
        addChild
          ? routeHistory.replace('/lab/dashboard/kyoso/child/insert', {
              parent_id: parentId,
              parent_name: data.name.trim(),
              parent_paid_status: paidValue,
            })
          : routeHistory.replace(
              `/lab/dashboard/kyoso/parent/${+parentId}/preview`,
            )
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <PageInfo
        title={
          action === 'insert'
            ? t('lab:KyosoParent:KyosoParentTitleCreate')
            : t('lab:KyosoParent:KyosoParentTitleEdit', {
                parent_name: parentData?.name,
              })
        }
        subtitle={t('lab:KyosoParent:KyosoParentSubtitle')}
        customCss={{
          '& h1': {
            marginBottom: 5,
          },
          '& h2': {
            marginBottom: 20,
          },
        }}
      />

      <EscButton onClick={() => routeHistory.goBack()} />

      <>{KyosoParentActionHandler(action)} </>
    </>
  )
}

export { KyosoParent }
