import styled from '@emotion/styled/macro'
import LabLogo from 'assets/img/lab-logo.svg'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { COLORS, QUERIES } from 'styling/variables'
import { IconLinkButton, IconButton, IconLinkExternalButton } from '../shared'
import { lab_logOutUser, lab_selectUser } from '../store/userSlice'

function Header() {
  const reduxUser = useSelector(lab_selectUser)
  const dispatch = useDispatch()
  const routeHistory = useHistory()
  const { t } = useTranslation()

  function logOutUser() {
    dispatch(lab_logOutUser()).finally(() => {
      routeHistory.push('/lab')
    })
  }

  return (
    <LargeHeaderLayout>
      <div id="navigation-grid">
        <Logo>
          <img src={LabLogo} alt="lab_logo" />
        </Logo>
        <IconLinkButton to="/lab/dashboard">
          <i className="fas fa-th-large"></i>
        </IconLinkButton>
        <IconLinkButton className="soon" to="#">
          <i className="far fa-calendar-alt"></i>
        </IconLinkButton>
        <IconLinkExternalButton
          onClick={() => window.open('https://kyoso.tech/site', '_blank')}
        >
          <i className="far fa-newspaper"></i>
        </IconLinkExternalButton>
        <IconLinkButton to="/lab/settings">
          <i className="fas fa-cogs"></i>
        </IconLinkButton>
        <IconLinkButton to="/lab/support">
          <i className="far fa-question-circle" />
        </IconLinkButton>
        {/* <IconLinkButton to="/lab/notifications">
          <i className="far fa-bell"></i>
          <NotificationsDot />
        </IconLinkButton> */}
        <ProfileInfo>{reduxUser.username || t('shared:User')}</ProfileInfo>
        <IconButton onClick={logOutUser}>
          <i className="fas fa-door-open" />
        </IconButton>
      </div>
    </LargeHeaderLayout>
  )
}

const LargeHeaderLayout = styled('div')({
  height: 65,
  background: '#ffffff 0% 0% no-repeat padding-box',
  boxShadow: '0px 0px 5px #0000001a',

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    height: 50,
  },

  '& #navigation-grid': {
    display: 'grid',
    height: '100%',
    width: '100%',
    maxWidth: 1600,
    margin: '0 auto',
    gridTemplateColumns: '130px repeat(6, 1fr) 70px',
    placeItems: 'center',
    justifyContent: 'center',

    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      gridTemplateColumns: '70px 120px 70px',
      justifyContent: 'space-between',
    },

    [IconLinkButton]: {
      [`@media (max-width: ${QUERIES.mobile}px)`]: {
        border: 'none',
        order: 1,

        '&:nth-of-type(1), &:nth-of-type(2),&:nth-of-type(3),&:nth-of-type(4),&:nth-of-type(5)': {
          display: 'none',
        },

        '& i': {
          fontSize: 22,
        },
      },
    },

    [IconButton]: {
      [`@media (max-width: ${QUERIES.mobile}px)`]: {
        border: 'none',
        order: 3,

        '& i': {
          fontSize: 22,
        },
      },
    },
  },
})

const Logo = styled('div')({
  height: 35,
  width: 100,

  '& img': {
    width: '100%',
    height: '100%',
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    order: 2,
  },
})

const NotificationsDot = styled('div')({
  backgroundColor: '#EF3B45',
  width: 10,
  height: 10,
  borderRadius: '50%',
  position: 'absolute',
  top: '10%',
  right: '30%',

  [`@media (min-width: ${QUERIES.mobile + 1}px)`]: {
    right: '30%',
  },

  [`@media (min-width: ${QUERIES.tablet}px)`]: {
    right: '40%',
  },
})

const ProfileInfo = styled('div')({
  textAlign: 'center',
  width: '100%',
  padding: 5,
  textOverflow: 'ellipsis',
  minWidth: 125,
  overflow: 'hidden',
  fontSize: 18,
  color: COLORS.secBlack,

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    display: 'none',
  },
})

export { Header }
