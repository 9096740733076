import styled from '@emotion/styled'
import ReactSelect from 'react-select'
import { COLORS } from 'styling/variables'
import { FormSelect } from './FormSelect'

export const KyosoChildModulesSelect = styled(
  ({
    modules,
    id,
    label,
    name,
    control,
    setValue,
    disabled = false,
    getValues,
    description,
    isClearable = false,
    customCss,
    defaultValue,
    className,
    error,
    rules,
    onChange = () => {},
    translationKeys,
  }) => {
    return (
      <FormSelect
        className={className}
        id={id}
        customCss={customCss}
        label={label}
        name={name}
        rules={rules}
        error={error}
        description={description}
        render={() => (
          <ReactSelect
            options={modules}
            placeholder=" "
            isClearable={isClearable}
            isDisabled={disabled}
            className={error ? 'select-error' : ''}
            onChange={(e) => {
              setValue(name, e?.value || null)
              onChange(e)
            }}
            value={
              getValues(name)
                ? {
                    value: getValues(name),
                    label: modules.find(
                      (option) => option.value === getValues(name),
                    )?.label,
                  }
                : null
            }
          ></ReactSelect>
        )}
        control={control}
        defaultValue={defaultValue}
        translationKeys={translationKeys}
      />
    )
  },
)(({ customCss }) => ({
  color: COLORS.mainGray,
  fontSize: 16,

  transition: '0.3s all',
  marginBottom: 30,
  display: 'inline-block',
  position: 'relative',
  width: '100%',
  '&:hover': {
    color: COLORS.primBlue,
  },
  '&:focus-within': {
    color: COLORS.secBlue,
  },

  '& span': {
    margin: 0,
    color: COLORS.mainGray,
    fontSize: 14,
    fontWeight: 'normal',
  },
  '& .css-1pahdxg-control': {
    marginTop: 5,
    color: COLORS.secBlue,
    borderRadius: 5,
    height: 45,
    width: '100%',
    boxSizing: 'border-box',
    fontSize: 16,
    fontWeight: 'normal',
    transition: '0.3s all',

    '.css-g1d714-ValueContainer': {
      flexWrap: 'nowrap',
      '.css-1rhbuit-multiValue': {
        borderRadius: '5px',
      },
    },

    '&.css-g1d714-ValueContainer, .css-1uccc91-singleValue': {
      flexWrap: 'nowrap',
      color: COLORS.secBlue,
      fontWeight: 'normal',
    },
    '&:hover': {
      borderColor: COLORS.primBlue,
    },
    '&:focus': {
      borderColor: COLORS.secBlue,
    },
    '&.input-error': {
      borderColor: COLORS.secRed,
    },
    '&.input-success': {
      borderColor: COLORS.secGreen,
    },
  },

  '& .css-yk16xz-control ': {
    marginTop: 5,
    border: `2px solid ${COLORS.mainGray}`,
    color: COLORS.secBlue,
    borderRadius: 5,
    height: 45,
    width: '100%',
    boxSizing: 'border-box',
    fontSize: 16,
    fontWeight: 'normal',
    transition: '0.3s all',
    '.css-g1d714-ValueContainer': {
      flexWrap: 'nowrap',

      '.css-1rhbuit-multiValue': {
        borderRadius: '5px',
      },
    },

    '&.css-g1d714-ValueContainer, .css-1uccc91-singleValue': {
      flexWrap: 'nowrap',
      color: COLORS.secBlue,
      fontWeight: 'normal',
    },
    '&:hover': {
      borderColor: COLORS.primBlue,
    },
    '&:focus': {
      borderColor: COLORS.secBlue,
    },
    '&.input-error': {
      borderColor: COLORS.secRed,
    },
    '&.input-success': {
      borderColor: COLORS.secGreen,
    },
  },
  ...customCss,
}))
