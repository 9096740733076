import styled from '@emotion/styled'
import { hub_selectGlobalLoader } from 'platforms/kyoso-hub/store/generalSlice'
import { useSelector } from 'react-redux'
import { GlobalLoader } from 'shared/components/GlobalLoader'
import { COLORS, QUERIES } from 'styling/variables'
import { Footer } from '../Footer'
import { Header } from '../Header'
import { MobileHeader } from '../MobileHeader'
import { MobileSidebar } from '../MobileSidebar'
import { PaymentStatusModal } from '../participation/PaymentStatusModal'
import { Sidebar } from '../Sidebar'

function MainLayout({ children }) {
  const showLoader = useSelector(hub_selectGlobalLoader)

  return (
    <HubMainLayout id="hub-main-layout">
      <Sidebar />
      <Header />
      <MobileHeader />
      <div id="hub-content">
        <div id="hub-content-children">{children}</div>
        <Footer />
        <PaymentStatusModal />
        {showLoader && <GlobalLoader />}
      </div>

      <MobileSidebar />
    </HubMainLayout>
  )
}

export { MainLayout }

const HubMainLayout = styled('div')({
  display: 'grid',
  gridTemplateAreas: `'sidebar header' 'sidebar content ' 'sidebar footer '`,
  gridTemplateColumns: '100px auto',

  '& #hub-content': {
    gridArea: 'content',
    padding: 30,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: COLORS.bgGray,
    minHeight: 'calc(100vh - 80px)',

    '& #hub-content-children': {
      padding: 30,
      position: 'relative',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 0px 5px #0000001A',
      borderRadius: 20,
      maxWidth: 1600,
      marginBottom: 30,
      width: '100%',
      minHeight: 'calc(100vh - 200px)',
    },
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    display: 'flex',
    flexDirection: 'column',

    '& #hub-sidebar': {
      display: 'none',
    },

    '& #hub-header': {
      display: 'none',
    },

    '& #hub-content': {
      gridArea: 'content',
      padding: 10,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: COLORS.bgGray,
      minHeight: 'calc(100vh - 30px)',
      paddingBottom: 80,

      '& #hub-content-children': {
        padding: 10,
        position: 'relative',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 5px #0000001A',
        borderRadius: 20,
        maxWidth: 1600,
        marginBottom: 30,
        width: '100%',
        minHeight: 'calc(100vh - 200px)',
      },
    },

    'button:hover': {},
  },

  [`@media (min-width: ${QUERIES.mobile}px)`]: {
    '& #mobile-hub-sidebar': {
      display: 'none',
    },

    '& #mobile-hub-header': {
      display: 'none',
    },
  },
})
