import styled from '@emotion/styled'
import { QUERIES } from 'styling/variables'

const UserDataForm = styled('div')(({ customCss }) => ({
  display: 'grid',
  alignContent: 'center',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridGap: '30px',
  alignItems: 'end',
  marginBottom: 30,
  width: '100%',

  '#button-wrapper': {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: 20,
    gridColumn: '1/4',
  },

  '#parent-info': {
    width: '100%',
    gridColumn: '1/ 4',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 20,
    marginBottom: 20,
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,

    '#parent-info': {
      display: 'flex',
      flexDirection: 'column',
    },
  },

  ...customCss,
}))

export { UserDataForm }
