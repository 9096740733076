import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { SharedModal } from 'shared'

function NotFoundPage() {
  const routeHistory = useHistory()
  const { t } = useTranslation()

  return (
    <SharedModal
      show={true}
      title={t('lab:NotFound:Title')}
      content={t('lab:NotFound:Content')}
      iconText="404"
      rejectCallback={() => routeHistory.replace('/lab/dashboard')}
    />
  )
}

export { NotFoundPage }
