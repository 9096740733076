import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { ForgottenPasswordForm } from './ForgottenPasswordForm'
import { LoginForm } from './LoginForm'
import { COLORS } from '../../../../styling/variables'
import LoginImage from '../../../../assets/img/hub_login-background.svg'
import { useHistory } from 'react-router'
import { ConfirmationMessage } from 'shared/index'
import { DefaultButton, LoginRegisterSection } from '../../shared'

function Login({ confirmEmailStatus }) {
  const routeHistory = useHistory()
  const [showForgottenPassword, setShowForgottenPassword] = useState(false)
  const { t } = useTranslation(['shared'])

  const toggleForgottenPasswordHandler = () => {
    setShowForgottenPassword((prevState) => !prevState)
  }

  function showConfirmEmailStatus() {
    let message = ''
    let iconClass = ''
    if (confirmEmailStatus.statCode !== 200) {
      message = t(`errors:${confirmEmailStatus.statCode}`)
      iconClass = 'fas fa-times'
    } else {
      message = t('hub:UserEmailConfirmed')
      iconClass = 'fas fa-check'
    }

    return (
      <>
        <ConfirmationMessage
          color={COLORS.secRed}
          message={message}
          iconClass={iconClass}
        />
        <DefaultButton
          color={COLORS.secRed}
          onClick={() => routeHistory.go(0)}
          customCss={{
            marginTop: 10,
          }}
        >
          {t('BackToLogin')}
        </DefaultButton>
      </>
    )
  }

  return (
    <LoginRegisterSection color={COLORS.secRed} backgroundImgSrc={LoginImage}>
      {confirmEmailStatus ? (
        showConfirmEmailStatus()
      ) : showForgottenPassword ? (
        <>
          <ForgottenPasswordForm
            showForgottenPassword={showForgottenPassword}
          />
          <DefaultButton
            color={COLORS.secRed}
            onClick={toggleForgottenPasswordHandler}
            customCss={{
              marginTop: 10,
            }}
          >
            {t('BackToLogin')}
          </DefaultButton>
        </>
      ) : (
        <>
          <LoginForm />
          <AlternativeLogin id="alternative-login">
            {/* <div>{t('hub:AlternativeLogin')}</div>
            <DefaultButton color={COLORS.secRed}>
              <i className="fab fa-google"></i>
              <span>Google</span>
            </DefaultButton> */}
            <DefaultButton
              color={COLORS.secRed}
              onClick={toggleForgottenPasswordHandler}
            >
              {t('ForgottenPassword')}
            </DefaultButton>
          </AlternativeLogin>
        </>
      )}
    </LoginRegisterSection>
  )
}

export { Login }

const AlternativeLogin = styled('div')({
  margin: 'auto',

  '& div': {
    color: COLORS.secRed,
    fontSize: 18,
    width: '100%',
    marginBottom: 10,
  },
  '& button:nth-of-type(1)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    float: 'left',

    '& i': {
      fontSize: 34,
      marginRight: 15,
    },
  },
  '& button:nth-of-type(2)': {
    float: 'right',
    marginTop: 11,
  },
})
