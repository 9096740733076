import styled from '@emotion/styled'
import { QUERIES } from 'styling/variables'

export const FiltrationAndLegendWrapper = styled('div')(({ customCss }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  '& #filtrationButton': {
    display: 'none',
  },

  [`@media (min-width: ${QUERIES.mobile}px)`]: {
    '& form:nth-of-type(2)': {
      display: 'none',
    },
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    flexDirection: 'column-reverse',

    '& form:nth-of-type(1)': {
      display: 'none',
    },

    '& #filtrationButton': {
      display: 'block',
      i: {
        marginRight: '10px',
      },
    },
  },
  ...customCss,
}))
