import styled from '@emotion/styled/macro'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'styling/variables'

function ContactsComponent({ customCss }) {
  const { t } = useTranslation()

  return (
    <Container customCss={customCss}>
      <p className="company-name">{t('hub:Support:KYOSO LTD')}</p>
      <p>+ 359 893 528 094</p>
      <a href="mailto: support@kyoso.tech" target="_blank">
        support@kyoso.tech
      </a>
    </Container>
  )
}

export { ContactsComponent }

const Container = styled('div')(({ customCss = {} }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
  color: COLORS.secBlue,

  '.company-name': {
    textDecoration: 'underline',
  },

  a: {
    color: COLORS.secBlue,
  },

  ...customCss,
}))
