import styled from '@emotion/styled'
import { QUERIES } from 'styling/variables'

export const CompetitionsContainer = styled('div')(({ customCss, theme }) => {
  return {
    marginTop: 20,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 10,

    [`@media (max-width: ${QUERIES.tablet}px)`]: {
      gridTemplateColumns: '1fr',
    },

    ...customCss,
  }
})
