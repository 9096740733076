import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { COLORS } from 'styling/variables'

function GlobalLoader() {
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(true)
    }, 100)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  if (showLoader)
    return (
      <GLobalLoaderBackground>
        <GlobalLoaderLayout>
          <div className="container">
            <div className="dot dot-1"></div>
            <div className="dot dot-2"></div>
            <div className="dot dot-3"></div>
          </div>

          <svg>
            <defs>
              <filter id="goo">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation="10"
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"
                />
              </filter>
            </defs>
          </svg>
        </GlobalLoaderLayout>
      </GLobalLoaderBackground>
    )
  else return null
}

export { GlobalLoader }

const GLobalLoaderBackground = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(218,222,230,0.7) 0% 0% no-repeat padding-box',
  zIndex: 1000,
})

const GlobalLoaderLayout = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  fontFamily: 'Balsamiq Sans',

  '& .container': {
    width: 200,
    height: 200,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    margin: 'auto',
    filter: "url('#goo')",
    animation: 'rotate-move 2s ease-in-out infinite',

    '& .dot': {
      width: 70,
      height: 70,
      borderRadius: '50%',
      backgroundColor: '#000',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto',
    },

    '& .dot-3': {
      backgroundColor: COLORS.secRed,
      animation: 'dot-3-move 2s ease infinite, index 6s ease infinite',
    },

    '& .dot-2': {
      backgroundColor: COLORS.secGreen,
      animation: 'dot-2-move 2s ease infinite, index 6s -4s ease infinite',
    },

    '& .dot-1': {
      backgroundColor: COLORS.secYellow,
      animation: 'dot-1-move 2s ease infinite, index 6s -2s ease infinite',
    },
  },

  '@keyframes dot-3-move': {
    '20%': {
      transform: 'scale(1)',
    },
    '45%': {
      transform: 'translateY(-18px) scale(0.45)',
    },
    '60%': {
      transform: 'translateY(-90px) scale(0.45)',
    },
    '80%': {
      transform: 'translateY(-90px) scale(0.45)',
    },
    '100%': {
      transform: 'translateY(0px) scale(1)',
    },
  },

  '@keyframes dot-2-move': {
    '20%': {
      transform: 'scale(1)',
    },
    '45%': {
      transform: 'translate(-16px, 12px) scale(0.45)',
    },
    '60%': {
      transform: 'translate(-80px, 60px) scale(0.45)',
    },
    '80%': {
      transform: 'translate(-80px, 60px) scale(0.45)',
    },
    ' 100% ': {
      transform: 'translateY(0px) scale(1)',
    },
  },

  '@keyframes dot-1-move': {
    '20%': {
      transform: 'scale(1)',
    },
    '45%': {
      transform: 'translate(16px, 12px) scale(0.45)',
    },
    '60%': {
      transform: 'translate(80px, 60px) scale(0.45)',
    },
    '80%': {
      transform: 'translate(80px, 60px) scale(0.45)',
    },
    '100%': {
      transform: 'translateY(0px) scale(1)',
    },
  },

  '@keyframes rotate-move': {
    '55%': {
      transform: 'translate(-50%, -50%) rotate(0deg)',
    },
    '80%': {
      transform: 'translate(-50%, -50%) rotate(360deg)',
    },
    '100%': {
      transform: 'translate(-50%, -50%) rotate(360deg)',
    },
  },

  '@keyframes index': {
    '0%,100%': {
      zIndex: 3,
    },
    '33.3%': {
      zIndex: 2,
    },
    '66.6%': {
      zIndex: 1,
    },
  },
})
