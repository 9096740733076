import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ConfirmationMessage,
  ContentTwoColumsLayout,
  FirstColumn,
  FormInput,
  PageInfo,
  SubmitButtonHub,
} from 'shared'
import { COLORS, QUERIES } from 'styling/variables'
import { useForm } from 'react-hook-form'
import {
  matchingPasswordsValidation,
  minLengthValidator,
  whiteSpaceValidator,
} from 'utils/helpers/validators'
import { useSelector } from 'react-redux'
import { hub_selectTheme } from 'platforms/kyoso-hub/store/generalSlice'

export function ChangePasswordForm({ onSubmitHandler }) {
  const currentTheme = useSelector(hub_selectTheme)
  const [onSuccess, setOnSuccess] = useState(null)
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    setError,
    reset,
    formState,
  } = useForm({
    mode: 'onBlur',
  })

  const submitHandler = (data) => {
    onSubmitHandler(data, 'changePassword', setError, setOnSuccess, reset)
  }

  return (
    <>
      <PageInfo
        title={t('hub:Settings:PasswordChangeTitle')}
        subtitle={t('hub:Settings:PasswordChangeSubTitle')}
        customCss={{
          '& h1': {
            marginBottom: 10,
            marginTop: 0,
          },
          '& h2': {
            marginBottom: 10,
          },
        }}
      />

      <PageInfo
        subtitle={t('hub:Settings:PasswordChangeEmail')}
        customCss={{
          '& h2': {
            marginBottom: 40,
            '.sent-to-email': {
              color: COLORS.secBlue,
            },
          },
        }}
      />

      {onSuccess === 'changePassword' && (
        <ConfirmationMessage
          color={COLORS.secBlue}
          message={t('shared:PasswordChangedSuccessfully')}
          removeMessageCallback={() => setOnSuccess(false)}
        />
      )}

      <form onSubmit={handleSubmit(submitHandler)}>
        <ContentTwoColumsLayout
          customCss={{
            placeItems: 'center',
            gridTemplateColumns: '1fr 1fr',
            [`@media (max-width: ${QUERIES.tablet}px)`]: {
              gridGap: 10,
            },
          }}
        >
          <FirstColumn>
            <FormInput
              id="current-password"
              type="password"
              name="current_password"
              label={`${t('shared:LabelCurrentPassword')} *`}
              showPassword
              onChange={(e) => {
                return {
                  trim: whiteSpaceValidator(e),
                  trigger: trigger('current_password'),
                }
              }}
              register={register({
                required: 'Required',
              })}
              error={formState.errors.current_password}
              touched={formState.touched.current_password}
            />

            <FormInput
              id="new-password"
              type="password"
              name="new_password"
              description="shared:PasswordDescription"
              label={`${t('shared:LabelNewPassword')} *`}
              succesMessage="CorrectInput"
              showPassword
              onChange={(e) => {
                return {
                  trim: whiteSpaceValidator(e),
                  trigger: trigger('new_password'),
                }
              }}
              register={register({
                required: 'Required',
                validate: {
                  minLength: (value) =>
                    minLengthValidator(value, 8, 'PasswordTooShort'),
                },
              })}
              error={formState.errors.new_password}
              touched={formState.touched.new_password}
            />

            <FormInput
              id="confirm-password"
              type="password"
              name="confirm_password"
              label={`${t('shared:LabelConfirmPassword')} `}
              succesMessage="CorrectInput"
              onChange={(e) => {
                return {
                  trim: whiteSpaceValidator(e),
                  trigger: trigger('confirm_password'),
                }
              }}
              register={register({
                required: 'Required',
                validate: {
                  confirmPassword: (value) =>
                    matchingPasswordsValidation(
                      value,
                      getValues('new_password'),
                    ),
                },
              })}
              error={formState.errors.confirm_password}
              touched={formState.touched.confirm_password}
            />
          </FirstColumn>

          <SubmitButtonHub
            id="firstStepOnboarding"
            customCss={{
              widht: 90,
              height: 90,

              button: {
                left: 0,
              },

              [`@media (max-width: ${QUERIES.tablet}px)`]: {
                button: {
                  left: 'auto',
                },
              },
            }}
            theme={currentTheme}
            type="submit"
          />
        </ContentTwoColumsLayout>
      </form>
    </>
  )
}
