import styled from '@emotion/styled'
import { COLORS } from 'styling/variables'

export const SpinnerLoading = styled('div')(({ customCss }) => ({
  marginTop: 50,

  '@keyframes spinner': {
    '0%': {
      transform: 'translate3d(-50%, -50%, 0) rotate(0deg)',
    },
    '100%': {
      transform: 'translate3d(-50%, -50%, 0) rotate(360deg)',
    },
  },

  '&::before': {
    animation: '1.5s linear infinite spinner',
    animationPlayState: 'inherit',
    border: `solid 5px ${COLORS.mainGray}`,
    borderBottomColor: COLORS.secBlue,
    borderRadius: '50%',
    content: "''",
    height: 40,
    width: 40,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    willChange: 'transform',
  },

  ...customCss,
}))
