import React from 'react'
import styled from '@emotion/styled'
import HTMLReactParser from 'html-react-parser'
import { SubmitButtonLab } from 'platforms/kyoso-lab/shared'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import {
  EscButton,
  FormCheckBox,
  FormRadioButtons,
  ModalBackground,
  PageInfo,
} from 'shared'
import { COLORS, QUERIES } from 'styling/variables'

import { formatDateAndTime } from 'utils/helpers/common'

function EditOrderModal({ modalData, saveModalCallback, closeModalCallback }) {
  const routeLocation = useLocation()
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,

    formState,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      modal_paid: modalData?.paid,
      payment_type: modalData?.payment_method || null,
    },
  })

  const paymentTypeRadioButtons = [
    {
      id: 1,
      name: 'payment_type',
      label: t('hub:Participation:BankPayment'),
      register: register({ required: 'Required' }),
      error: formState.errors.payment_type,
    },
    {
      id: 2,
      name: 'payment_type',
      label: t('hub:Participation:ЕPay'),
      register: register({ required: 'Required' }),
      error: formState.errors.payment_type,
    },
  ]

  const closeModalHandler = (e) => {
    const backDrop = document.getElementById('modal-background')

    if (e.target === backDrop) {
      closeModalCallback()
    }
  }

  const onSubmitHandler = (data) => {
    const { grade_id } = modalData.participant_info

    const touchedFields = Object.keys(formState.touched).filter(
      (field) => field !== 'paymentType',
    )
    const newUserData = { ...data }
    delete newUserData.payment_type
    delete newUserData.modal_paid
    let isDataTouched = false

    if (touchedFields.length) {
      isDataTouched = true
    } else if (data.grade_id != grade_id) {
      isDataTouched = true
    }

    saveModalCallback(
      modalData.id,
      isDataTouched,
      newUserData,
      data.payment_type,
      data.modal_paid,
    )
  }

  return (
    <ModalBackground id="modal-background" onClick={closeModalHandler}>
      <ModalWrap>
        <ModalLayoutForm onSubmit={handleSubmit(onSubmitHandler)}>
          <ActionsContainer>
            <SubmitButtonLab
              className="black"
              customCss={{ maxWidth: 200 }}
              type="submit"
            >
              {t('lab:Shared:SubmitButtonSave')}
            </SubmitButtonLab>
            <EscButton
              onClick={() => closeModalCallback()}
              customCss={{ position: 'relative', top: 'auto', right: 'auto' }}
            />
          </ActionsContainer>

          <HeaderContainer>
            <div id="order-info">
              <PageInfo
                title={t('lab:KyosoOrders:OrdersModalTitle', {
                  id: routeLocation.state?.id,
                  name: routeLocation.state?.name,
                  grade_name: routeLocation.state?.grade_name,
                })}
              />
              {modalData.participant_id && (
                <p id="participant-id">
                  <span>{t('lab:KyosoOrders:ParticipantId')}: </span>
                  <span className="blue">{modalData.participant_id}</span>
                </p>
              )}

              <p id="order-id">
                <span>{t('lab:KyosoOrders:OrderId')}: </span>
                <span className="blue">{modalData.order_id}</span>
              </p>

              <p id="order-date">
                <span>{t('lab:KyosoOrders:OrderDate')}: </span>
                <span className="blue">
                  {formatDateAndTime(modalData.order_date)}
                </span>
              </p>

              <p id="participant-email">
                <span>{t('lab:KyosoOrders:ParticipantEmail')}: </span>
                <span className="blue">{modalData.participant_info.email}</span>
              </p>

              <p id="warning-text" className="red">
                {t('lab:KyosoOrders:ModalParticipentWarning')}
              </p>
            </div>

            {+modalData?.payment_method ? (
              <div id="order-status">
                <h3 id="status-title">{t('lab:KyosoOrders:StatusTitle')}</h3>

                <p id="status-warning" className="red">
                  {t('lab:KyosoOrders:StatusWarning')}
                </p>

                <FormCheckBox
                  key="modal-paid"
                  id="paid"
                  name="modal_paid"
                  label={t('lab:KyosoOrders:FilterCheckboxPayed')}
                  register={register()}
                  customCss={{
                    marginBottom: 10,
                    marginTop: 10,

                    ' .text': {
                      marginLeft: '10px',
                      gridColumn: '2',
                      justifySelf: 'left',
                    },
                  }}
                />

                <p id="status-message" className="green">
                  {HTMLReactParser(t('lab:KyosoOrders:StatusMessage'))}
                </p>
              </div>
            ) : null}
          </HeaderContainer>

          {+modalData?.payment_method ? (
            <>
              <FormRadioButtons
                customCss={{
                  gridRow: '2',
                  marginBottom: 10,
                  '#label-wrapper': {
                    marginBottom: 10,
                    '#label': {
                      color: COLORS.secBlack,
                    },
                  },
                }}
                disabled={modalData.payment_method === '1'}
                radioButtons={paymentTypeRadioButtons}
                label={'hub:Participation:PaymentTypeLabel'}
                id="paymentType"
                type="radio"
                name="payment_type"
                register={register()}
                error={formState.errors.payment_type}
              />
              <p id="modal-message">{t('lab:KyosoOrders:ModalMessage')}</p>
            </>
          ) : null}

          <SubmitButtonLab
            className="black"
            customCss={{ maxWidth: 200, alignSelf: 'center', marginTop: 20 }}
            type="submit"
          >
            {t('lab:Shared:SubmitButtonSave')}
          </SubmitButtonLab>
        </ModalLayoutForm>
      </ModalWrap>
    </ModalBackground>
  )
}

export { EditOrderModal }

const ModalWrap = styled('div')({
  display: 'table-cell',
  verticalAlign: 'middle',
})

const ModalLayoutForm = styled('form')({
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '95%',
  maxHeight: '90%',
  maxWidth: 1400,
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 0px 5px #0000000D',
  borderRadius: 25,
  padding: '90px 40px 40px 40px',
  fontFamily: 'Balsamiq Sans',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',

  '.blue': {
    color: COLORS.secBlue,
  },

  '.red': {
    color: COLORS.secRed,
  },

  '.green': {
    color: COLORS.secGreen,
  },

  '::-webkit-scrollbar': {
    display: 'none',
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    padding: '30px 20px',
  },

  '.answer-type-label': {
    color: COLORS.secBlack,
    fontSize: 18,
    marginBottom: 20,

    '.answer-type-error': {
      color: COLORS.secRed,
    },
  },
})

const ActionsContainer = styled('div')({
  position: 'absolute',
  top: 20,
  right: 30,
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 30,
})

const HeaderContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 30,
  marginBottom: 30,

  '#order-info': {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },

  '#order-status': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: `2px solid ${COLORS.borderGray}`,
    borderRadius: 10,
    padding: 15,
    gap: 10,

    '#status-title': {
      textAlign: 'center',
    },
  },
})

const ParticipantInfo = styled('div')({
  display: 'grid',
  alignContent: 'center',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridGap: '30px',
  alignItems: 'end',
  marginBottom: 30,
  width: '70%',
})
