import styled from '@emotion/styled'
import {
  getFileLinkFromServerStorage,
  onImgZoomClickHandler,
} from 'utils/helpers/common'
import { FormInput, PageInfo, PdfViewer, TinyTextContentPreview } from 'shared'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { COLORS } from 'styling/variables'

function ChildMediaResults({ childInfo, scoring, changePoints }) {
  const { register } = useForm()
  const { t } = useTranslation()

  function checkForPreviousPointsSet(
    user_id,
    exerciseId,
    answerId,
    moduleId = null,
  ) {
    let pointsData = null
    if (moduleId) {
      pointsData = scoring.find(
        (x) =>
          x.user_id === user_id &&
          x.points &&
          x.points[moduleId] &&
          x.points[moduleId][exerciseId] &&
          x.points[moduleId][exerciseId][answerId],
      )
      return pointsData ? +pointsData.points[moduleId][exerciseId][answerId] : 0
    } else {
      pointsData = scoring.find(
        (x) =>
          x.user_id === user_id &&
          x.points &&
          x.points[exerciseId] &&
          x.points[exerciseId][answerId],
      )
      return pointsData ? +pointsData.points[exerciseId][answerId] : 0
    }
  }

  return (
    <ChildMediaResultsLayout>
      {Object.entries(childInfo.answers).map((answerData, answerDataIndex) => {
        if (childInfo.modules_times) {
          return (
            <div className="module-container" key={answerData[0]}>
              {Object.entries(answerData[1].data).map(
                (exercise, exerciseIndex) => {
                  return (
                    <div className="exercise-container" key={exercise[0]}>
                      {Object.entries(exercise[1]).map((answer) => {
                        return (
                          <div key={answer[0]}>
                            <PageInfo
                              customCss={{ marginBottom: 20 }}
                              className="exercise-title"
                              title={t(
                                'lab:KyosoChildEvaluation:ModuleExcTitle',
                                {
                                  module_name: answerData[1].module_name,
                                  exc_index: exerciseIndex + 1,
                                },
                              )}
                              subtitle={answer[1].description}
                            />

                            <div className="answers-points-container">
                              <div className="answers-container">
                                <TinyTextContentPreview
                                  className="tiny-answer"
                                  id={`tiny-content-ans${answer[0]}`}
                                  html={answer[1].text}
                                  imgZoom
                                />
                                <PageInfo
                                  subtitle={t(
                                    'lab:KyosoChildEvaluation:UploadedFiles',
                                  )}
                                />
                                <div className="media-answers">
                                  {answer[1].media.map((media) => {
                                    if (media.isImage) {
                                      return (
                                        <div
                                          key={media.name}
                                          onClick={onImgZoomClickHandler}
                                        >
                                          <img
                                            className="zoom"
                                            style={{ cursor: 'zoom-in' }}
                                            src={getFileLinkFromServerStorage(
                                              media.path,
                                            )}
                                            alt="smth"
                                          />
                                        </div>
                                      )
                                    } else {
                                      return (
                                        <div key={media.name}>
                                          <PdfViewer document={media.path} />
                                        </div>
                                      )
                                    }
                                  })}
                                </div>
                              </div>
                              <div>
                                <FormInput
                                  key={`${childInfo.participant_id}-${answerData[0]}-${answer[0]}`}
                                  label={t(
                                    'lab:KyosoChildEvaluation:PointsLabel',
                                  )}
                                  type="number"
                                  min="0"
                                  name={`${answerData[0]}-${exercise[0]}-${answer[0]}`}
                                  register={register}
                                  defaultValue={checkForPreviousPointsSet(
                                    childInfo.user_id,
                                    exercise[0],
                                    answer[0],
                                    answerData[0],
                                  )}
                                  onChange={(e) =>
                                    changePoints(
                                      e.target.value,
                                      childInfo.user_id,
                                      +exercise[0],
                                      +answer[0],
                                      +answerData[0],
                                    )
                                  }
                                  customCss={{
                                    marginBottom: 0,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  )
                },
              )}
            </div>
          )
        } else {
          return Object.entries(answerData[1]).map(
            ([subAnswerId, subAnswer]) => {
              return (
                <div key={subAnswerId}>
                  <PageInfo
                    customCss={{ marginBottom: 20 }}
                    className="exercise-title"
                    title={t('lab:KyosoChildEvaluation:Exercise', {
                      exc_index: answerDataIndex + 1,
                    })}
                    subtitle={subAnswer.description}
                  />

                  <div className="answers-points-container">
                    <div className="answers-container">
                      <TinyTextContentPreview
                        id={`tiny-content${subAnswerId}`}
                        className="tiny-answer"
                        html={subAnswer.text}
                        imgZoom
                      />
                      <PageInfo
                        subtitle={t('lab:KyosoChildEvaluation:UploadedFiles')}
                      />
                      <div className="media-answers">
                        {subAnswer.media.map((media) => {
                          if (media.isImage) {
                            return (
                              <div
                                key={media.name}
                                onClick={onImgZoomClickHandler}
                              >
                                <img
                                  className="zoom"
                                  style={{ cursor: 'zoom-in' }}
                                  src={getFileLinkFromServerStorage(media.path)}
                                  alt="smth"
                                />
                              </div>
                            )
                          } else {
                            return (
                              <div key={media.name}>
                                <PdfViewer document={media.path} />
                              </div>
                            )
                          }
                        })}
                      </div>
                    </div>
                    <div>
                      <FormInput
                        key={`${childInfo.participant_id}-${answerData[0]}-${subAnswerId}`}
                        label={t('lab:KyosoChildEvaluation:PointsLabel')}
                        type="number"
                        min="0"
                        name={`${answerData[0]}-${subAnswerId}`}
                        register={register}
                        defaultValue={checkForPreviousPointsSet(
                          childInfo.user_id,
                          answerData[0],
                          subAnswerId,
                        )}
                        onChange={(e) =>
                          changePoints(
                            e.target.value,
                            childInfo.user_id,
                            +answerData[0],
                            +subAnswerId,
                          )
                        }
                        customCss={{
                          marginBottom: 0,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )
            },
          )
        }
      })}
    </ChildMediaResultsLayout>
  )
}

export { ChildMediaResults }

const ChildMediaResultsLayout = styled('div')({
  marginTop: 30,

  '.answers-points-container': {
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    alignItems: 'center',
    gap: 20,

    '.tiny-answer': {
      minHeight: 300,
      border: `1px solid ${COLORS.borderGray} `,
      padding: 10,
      borderRadius: 10,
    },
  },

  '.answers-container': {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,

    '.media-answers': {
      display: 'flex',
      gap: 20,
      flexWrap: 'wrap',

      img: { maxWidth: 300, height: 'auto' },
    },
  },
})
