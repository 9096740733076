import styled from '@emotion/styled'
import HTMLReactParser from 'html-react-parser'
import {
  deleteAnswerFileForExercise,
  finishCompetition,
  getCompetitionExercises,
  startCompetition,
} from 'platforms/kyoso-hub/services/competition'
import {
  hub_selectPage,
  hub_selectTheme,
  hub_setPage,
} from 'platforms/kyoso-hub/store/generalSlice'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { SubmitButtonHub, TinyTextContentPreview } from 'shared'
import { COLORS, QUERIES, THEMES } from 'styling/variables'
import { ExerciseDisplay } from './ExerciseDisplay'
import { Pagination } from './Pagination'
import { SubmitCompetitionView } from './SubmitCompetitionView'
import Countdown from 'react-countdown'
import { useWebSocket, WSSendTypeEnum } from 'utils/helpers/common'
import { hub_selectUser } from 'platforms/kyoso-hub/store/userSlice'
import {
  hub_selectSubmitMessage,
  hub_selectCompetitionInfo,
} from 'platforms/kyoso-hub/store/competitionSlice'

import moment from 'moment'

function CompetitionView() {
  const dispatch = useDispatch()
  const competitionInfo = useSelector(hub_selectCompetitionInfo)
  const routeHistory = useHistory()
  const pageTitle = useSelector(hub_selectPage)
  const userData = useSelector(hub_selectUser)
  const submitMessage = useSelector(hub_selectSubmitMessage)
  const currentTheme = useSelector(hub_selectTheme)
  const { childId, moduleIndex, exerciseIndex } = useParams()
  const [competitions, setCompetition] = useState(null)
  const [countdownTime, setCountdownTime] = useState(null)
  const [hasModules, setHasModules] = useState(false)
  const [listView, setListView] = useState(false)
  const [competitionSubmited, setCompetitionSubmited] = useState(false)
  const [hasTimeEnded, setTimeHasEnded] = useState(false)
  const [exercisesMedia, setExercisesMedia] = useState([])
  const [competitionStatus, setCompetitionStatus] = useState(null)
  const webSocket = useWebSocket(userData.id, webSocketHandler, {
    channel: 'competition',
    debug: true,
  })

  const { t } = useTranslation()

  function webSocketHandler(message) {
    const data = message.data ? JSON.parse(message.data) : null

    if (data) {
      if (data.type === WSSendTypeEnum.NewExerciseMedia) {
        setExercisesMedia((state) => {
          const copyState = [...state]
          copyState.splice(
            copyState.findIndex(
              (x) => x.exercise_id === +data.content.exercise_id,
            ),
            1,
            data.content,
          )
          return copyState
        })
      }
      if (data.type === WSSendTypeEnum.DeleteExerciseMedia) {
        setExercisesMedia((state) => {
          const copyState = [...state]
          let oldItem = copyState?.find(
            (cs) => cs.exercise_id === +data.content.exercise_id,
          )
          if (
            oldItem?.files?.length &&
            oldItem.files.map((f) => f.path).includes(data.content.del_path)
          ) {
            oldItem.files.splice(
              oldItem.files.findIndex(
                (oi) => oi.path === data.content.del_path,
              ),
              1,
            )
            copyState.splice(
              copyState.findIndex(
                (x) => x.exercise_id === +data.content.exercise_id,
              ),
              1,
              oldItem,
            )
          }
          return copyState
        })
      }
      if (data.type === WSSendTypeEnum.RemovedDeviceFromCompetition) {
        routeHistory.push('/hub')
      }
      if (data.type === WSSendTypeEnum.Timer) {
        setCountdownTime(+data.content)
      }
    }
  }

  useEffect(() => {
    startCompetition(childId)
      .then((res) => {
        dispatch(
          hub_setPage({
            title: `${competitionInfo.parentName} - ${competitionInfo.childUId} `,
          }),
        )
        const { current_module, answers, answersStruct } = res
        getExercisesForCompetition(childId, userData.id, answersStruct)
        setCompetitionStatus('IN_PROGRESS')
        const getAnswers = JSON.parse(answers)

        if (answers) {
          localStorage.setItem(
            'answers',
            JSON.stringify({ [childId]: getAnswers }),
          )
        } else {
          localStorage.setItem('answers', JSON.stringify({ [childId]: {} }))
        }

        if (current_module !== +moduleIndex) {
          routeHistory.replace(
            `/hub/competitions/${childId}/${current_module}/0`,
          )
        }
      })
      .catch((err) => {
        routeHistory.replace('/hub/competitions')
      })

    return () => {
      localStorage.removeItem('answers')
      localStorage.removeItem('structure_ans')
    }
  }, [])

  useEffect(() => {
    if (competitionSubmited) {
      //When competition ends, removes the timer

      setCountdownTime(null)
    }
  }, [competitionSubmited])

  useEffect(() => {
    if (
      moment(countdownTime) < moment.utc() &&
      competitionStatus !== 'SUBMIT_COMPETITION'
    ) {
      setCompetitionStatus('OUT_OF_TIME')
    }
  }, [countdownTime])

  const getExercisesForCompetition = (childId, userId, getAnswersStruct) => {
    const answersStruct = JSON.parse(getAnswersStruct)

    getCompetitionExercises(childId, userId)
      .then((res) => {
        setExercisesMedia(
          res.exercises.map((exercise) => ({
            exercise_id: exercise.id,
            files: exercise.mediaFiles,
          })),
        )

        if (res.allModules.length) {
          // console.log(res)
          // const currentModule = JSON.parse(localStorage.getItem(`cur_mod`))
          const competitions = JSON.parse(res.order).map((ord) => ({
            id: ord,
            ...res.exercises.find((ex) => ex.id === ord),
          }))

          const modulesWithExc = res.allModules.map((mod) => {
            return {
              module_id: mod.id,
              module_name: mod.name,
              module_duration: mod.duration,
              exercises: competitions.filter((ex) => ex.module_id === mod.id),
            }
          })

          if (answersStruct) {
            localStorage.setItem(
              'structure_ans',
              JSON.stringify({ ...answersStruct }),
            )
          } else {
            const structuredAnswers = modulesWithExc.map((mod) => {
              return {
                ...mod,
                exercises: mod.exercises.map((ex) => {
                  return {
                    [`id_${ex.id}`]: '',
                    answer_type: ex.answer_type,
                    answer_descriptions:
                      ex.answer_type === '3'
                        ? ex.answers.map((a) => ({
                            ans_id: a.id,
                            ans_description: a.description,
                          }))
                        : [],
                    media_files:
                      ex.answer_type === '3'
                        ? ex.answers.map((ans) => {
                            return {
                              ans_id: ans.id,
                              ans_description: ex.mediaFiles.length
                                ? ans.description
                                : '',
                              ans_files: ex.mediaFiles.filter((file) => {
                                const fileId = file.name.split('-')[3]

                                if (fileId == ans.id) return file
                              }).length,
                            }
                          })
                        : 0,
                  }
                }),
              }
            })

            localStorage.setItem(
              'structure_ans',
              JSON.stringify({ ...structuredAnswers }),
            )
          }

          setHasModules(true)
          setCompetition(modulesWithExc)
        } else {
          if (answersStruct) {
            localStorage.setItem(
              'structure_ans',
              JSON.stringify({ ...answersStruct }),
            )
          } else {
            const structuredAnswers = JSON.parse(res.order).map((ord) => {
              const ex = res.exercises.find((ex) => ex.id === ord)

              return {
                [`id_${ord}`]: '',
                answer_type: ex.answer_type,
                answer_descriptions:
                  ex.answer_type === '3'
                    ? ex.answers.map((a) => ({
                        ans_id: a.id,
                        ans_description: a.description,
                      }))
                    : [],
                media_files:
                  ex.answer_type === '3'
                    ? ex.answers.map((ans) => {
                        return {
                          ans_id: ans.id,
                          ans_files: ex.mediaFiles.filter((file) => {
                            const fileId = file.name.split('-')[3]

                            if (fileId == ans.id) return file
                          }).length,
                        }
                      })
                    : 0,
              }
            })

            localStorage.setItem(
              'structure_ans',
              JSON.stringify({ ...structuredAnswers }),
            )
          }

          setCompetition(
            JSON.parse(res.order).map((ord) => ({
              id: ord,
              duration: res.childDuration,
              ...res.exercises.find((ex) => ex.id === ord),
            })),
          )

          setHasModules(false)
        }
      })
      .catch((err) => console.log(err))
  }

  const onButtonClickHandler = (nextExIndex) => {
    if (hasModules) {
      nextExIndex >= competitions[moduleIndex].exercises.length
        ? setCompetitionStatus('SUBMIT_MODULE')
        : competitionStatus !== 'OUT_OF_TIME' &&
          competitionStatus !== 'SUBMIT_COMPETITION' &&
          setCompetitionStatus('IN_PROGRESS')
    } else {
      nextExIndex >= competitions.length
        ? setCompetitionStatus('SUBMIT')
        : competitionStatus !== 'OUT_OF_TIME' &&
          competitionStatus !== 'SUBMIT_COMPETITION' &&
          setCompetitionStatus('IN_PROGRESS')
    }

    routeHistory.replace(
      `/hub/competitions/${childId}/${moduleIndex}/${nextExIndex}`,
    )
  }

  const onCompleteCompetition = () => {
    routeHistory.replace('/hub/competitions')
  }

  const onSubmitModule = (
    nextModuleIndex,
    nextExerciseIndex,
    isLastModule = false,
  ) => {
    if (isLastModule) {
      finishCompetition(childId, competitions[moduleIndex]?.module_id, {
        isFinal: true,
      })
        .then((res) => {
          setCompetitionSubmited(true)
          setCompetitionStatus('SUBMIT_COMPETITION')
        })
        .catch((err) => console.log(err))
    } else {
      if (hasTimeEnded) {
        setTimeHasEnded(false)
      }

      finishCompetition(childId, competitions[moduleIndex].module_id, {
        nextModuleId: competitions[nextModuleIndex].module_id,
        nextModuleDuration: competitions[nextModuleIndex].module_duration,
      })
        .then((res) => {
          routeHistory.replace(
            `/hub/competitions/${childId}/${nextModuleIndex}/${nextExerciseIndex}`,
          )
          setCompetitionStatus('IN_PROGRESS')
        })
        .catch((err) => console.log(err))
    }
  }

  const countdownRenderer = ({
    formatted: { hours, minutes, seconds },
    completed,
    props,
    total,
  }) => {
    const timeWarningHandler = (remainingMin) => {
      const moduleDuration = hasModules
        ? competitions[moduleIndex]?.module_duration
        : competitions[0]?.duration
      let className = COLORS.secGreen

      if (
        remainingMin <= 0.25 * moduleDuration &&
        remainingMin >= 0.1 * moduleDuration
      ) {
        className = COLORS.secYellow
      } else if (remainingMin <= 0.1 * moduleDuration) {
        className = COLORS.secRed
      }

      return className
    }

    return (
      <div id="competition-timer" className={listView ? 'timer-list-view' : ''}>
        <span
          style={{
            fontSize: 23,
            color: timeWarningHandler(total / 60000),
          }}
          className={`timer-time `}
        >
          {hours}:{minutes}:{seconds}
        </span>
      </div>
    )
  }

  function deleteUploadedFileHandler(exercise_id, path, token, setFileError) {
    deleteAnswerFileForExercise(token, path)
      .then(() => {
        setExercisesMedia((state) => {
          const copyState = [...state]
          let oldItem = copyState.find((i) => i.exercise_id === exercise_id)
          oldItem.files.splice(
            oldItem.files.findIndex((f) => f.path === path),
            1,
          )
          copyState.splice(
            copyState.findIndex((cs) => cs.exercise_id === exercise_id),
            1,
            oldItem,
          )
          return copyState
        })
      })
      .catch((error) => {
        console.log(error)
        setFileError(t('errors:DeletingFile'))
      })
  }

  const changeViewHandler = () => {
    setListView((prevState) => !prevState)
  }

  const competitionStatusHandler = (
    hasModules,
    compStatus,
    competitions,
    listView,
  ) => {
    const exIndex = +exerciseIndex
    const modIndex = +moduleIndex
    let competitionDisplay = null

    if (hasModules) {
      const exercisesLength = competitions[modIndex].exercises.length
      const modulesLength = competitions.length

      switch (compStatus) {
        case 'IN_PROGRESS':
          if (listView) {
            competitionDisplay = competitions[modIndex]?.exercises.map(
              (exc, index) => {
                return (
                  <div key={`ex-wrapper ${exc.id}`} style={{ width: '100%' }}>
                    <ExerciseDisplay
                      exIndex={index}
                      exerciseContent={exc}
                      mediaFiles={
                        exercisesMedia.find(
                          (exMedia) => exMedia.exercise_id === exc.id,
                        )?.files || []
                      }
                      deleteUploadedFileHandler={deleteUploadedFileHandler}
                      customCss={{
                        '.description-wrapper': {
                          minHeight: '10vh !important',
                        },

                        '.exercises': {
                          minHeight: '10vh !important ',
                        },
                      }}
                    />
                    {competitions[modIndex].exercises.length !== index + 1 && (
                      <hr style={{ width: '100%' }} />
                    )}
                  </div>
                )
              },
            )
          } else {
            competitionDisplay = (
              <ExerciseDisplay
                exIndex={exIndex}
                exerciseContent={competitions[modIndex].exercises[exIndex]}
                deleteUploadedFileHandler={deleteUploadedFileHandler}
                mediaFiles={
                  exercisesMedia.find(
                    (exMedia) =>
                      exMedia.exercise_id ===
                      competitions[modIndex].exercises[exIndex]?.id,
                  )?.files || []
                }
              />
            )
          }

          break
        case 'OUT_OF_TIME':
          if (modIndex === modulesLength - 1) {
            //Time has ended for the last module
            competitionDisplay = (
              <SubmitCompetitionView
                customCss={{}}
                theme={currentTheme}
                headline={
                  <div>
                    <h2 className="title" style={{ marginBottom: '20px' }}>
                      {t('hub:CompetitionView:TimeEndedOnModuleTitle', {
                        index: modIndex + 1,
                        name: competitions[modIndex]?.module_name,
                      })}
                    </h2>
                    <i
                      style={{ fontSize: '99px', color: COLORS.secRed }}
                      className="far fa-clock"
                    ></i>
                  </div>
                }
                content={HTMLReactParser(
                  t('hub:CompetitionView:TimeEndedOnLastModuleText', {
                    index: modIndex + 1,
                    name: competitions[modIndex]?.module_name,
                  }),
                )}
                nextButton={t('hub:CompetitionView:SubmitCompetition')}
                onClickNext={() => onSubmitModule(modIndex + 1, 0, true)}
              />
            )
          } else {
            competitionDisplay = (
              <SubmitCompetitionView
                theme={currentTheme}
                headline={
                  <div>
                    <h2 className="title" style={{ marginBottom: '20px' }}>
                      {t('hub:CompetitionView:TimeEndedOnModuleTitle', {
                        index: modIndex + 1,
                        name: competitions[modIndex]?.module_name,
                      })}
                    </h2>
                    <i
                      style={{ fontSize: '99px', color: COLORS.secRed }}
                      className="far fa-clock"
                    ></i>
                  </div>
                }
                content={HTMLReactParser(
                  t('hub:CompetitionView:TimeEndedOnModuleText', {
                    index: modIndex + 1,
                    name: competitions[modIndex]?.module_name,
                    nextModuleIndex: modIndex + 2,
                    nextModuleName: competitions[modIndex + 1]?.module_name,
                  }),
                )}
                nextButton={t('hub:CompetitionView:ToNextModuleBtn', {
                  toIndex: modIndex + 2,
                  toName: competitions[modIndex + 1]?.module_name,
                })}
                onClickNext={() => onSubmitModule(modIndex + 1, 0, false)}
              />
            )
          }

          break
        case 'SUBMIT_MODULE':
          if (modIndex === modulesLength - 1 && exIndex >= exercisesLength) {
            //Shows the Submit LastModule Display

            competitionDisplay = (
              <SubmitCompetitionView
                theme={currentTheme}
                headline={
                  <h2 className="title">
                    {t('hub:CompetitionView:SubmitCompetition')}
                  </h2>
                }
                content={HTMLReactParser(
                  t('hub:CompetitionView:CompetitionSubmitText'),
                )}
                prevButton={t('hub:CompetitionView:ToPrevModuleBtn', {
                  index: modIndex + 1,
                  name: competitions[modIndex].module_name,
                })}
                onClickPrev={() => onButtonClickHandler(exerciseIndex - 1)}
                nextButton={t('hub:CompetitionView:SubmitCompetition')}
                onClickNext={() => onSubmitModule(modIndex + 1, 0, true)}
              />
            )
          } else {
            //Shows the submitModuleDisplay
            competitionDisplay = (
              <SubmitCompetitionView
                theme={currentTheme}
                headline={
                  <h2 className="title">
                    {t('hub:CompetitionView:ModuleSubmitTitle', {
                      index: modIndex + 1,
                      name: competitions[modIndex]?.module_name,
                    })}
                  </h2>
                }
                content={HTMLReactParser(
                  t('hub:CompetitionView:ModuleSubmitText', {
                    index: modIndex + 1,
                    name: competitions[modIndex]?.module_name,
                    toIndex: modIndex + 2,
                    toName: competitions[modIndex + 1]?.module_name,
                  }),
                )}
                prevButton={t('hub:CompetitionView:ToPrevModuleBtn', {
                  index: modIndex + 1,
                  name: competitions[modIndex]?.module_name,
                })}
                onClickPrev={() => onButtonClickHandler(exerciseIndex - 1)}
                nextButton={t('hub:CompetitionView:ToNextModuleBtn', {
                  toIndex: modIndex + 2,
                  toName: competitions[modIndex + 1]?.module_name,
                })}
                // disabledNext={}
                onClickNext={() => onSubmitModule(modIndex + 1, 0)}
              />
            )
          }

          break
        case 'SUBMIT_COMPETITION':
          competitionDisplay = (
            <SubmitCompetitionView
              customCss={{}}
              theme={currentTheme}
              headline={
                <div>
                  <h2 className="title" style={{ marginBottom: '20px' }}>
                    {t('hub:CompetitionView:CompleteCompetitionTitle', {
                      competition_name: `${pageTitle.title}`,
                    })}
                  </h2>
                </div>
              }
              content={
                <TinyTextContentPreview html={submitMessage.submitMessage} />
              }
              nextButton={t('hub:CompetitionView:CompleteCompetitionBtn')}
              onClickNext={onCompleteCompetition}
            />
          )

          break

        default:
          console.error(
            'CompStatus was not correct in function competitionStatusHandler from CompetitionView(HUB): ',
            compStatus,
          )
          break
      }
    } else {
      switch (compStatus) {
        case 'IN_PROGRESS':
          if (listView) {
            competitionDisplay = competitions.map((exc, index) => {
              return (
                <div key={index} style={{ width: '100%' }}>
                  <ExerciseDisplay
                    exIndex={index}
                    exerciseContent={exc}
                    mediaFiles={
                      exercisesMedia.find(
                        (exMedia) => exMedia.exercise_id === exc.id,
                      )?.files || []
                    }
                    deleteUploadedFileHandler={deleteUploadedFileHandler}
                    customCss={{
                      '.description-wrapper': {
                        minHeight: '10vh !important',
                      },

                      '.exercises': {
                        minHeight: '10vh !important ',
                      },
                    }}
                  />
                  {competitions.length !== index + 1 && (
                    <hr style={{ width: '100%' }} />
                  )}
                </div>
              )
            })
          } else {
            competitionDisplay = (
              <ExerciseDisplay
                exIndex={exIndex}
                exerciseContent={competitions[exIndex]}
                deleteUploadedFileHandler={deleteUploadedFileHandler}
                mediaFiles={
                  exercisesMedia.find(
                    (exMedia) =>
                      exMedia.exercise_id === competitions[exIndex]?.id,
                  )?.files || []
                }
              />
            )
          }

          break
        case 'OUT_OF_TIME':
          //Time has ended for the last module
          competitionDisplay = (
            <SubmitCompetitionView
              customCss={{}}
              theme={currentTheme}
              headline={
                <div>
                  <h2 className="title" style={{ marginBottom: '20px' }}>
                    {t('hub:CompetitionView:TimeEndedOnModuleTitle', {
                      index: modIndex + 1,
                      name: competitions[modIndex]?.module_name,
                    })}
                  </h2>
                  <i
                    style={{ fontSize: '99px', color: COLORS.secRed }}
                    className="far fa-clock"
                  ></i>
                </div>
              }
              content={HTMLReactParser(
                t('hub:CompetitionView:TimeEndedOnLastModuleText', {
                  index: modIndex + 1,
                  name: competitions[modIndex]?.module_name,
                }),
              )}
              nextButton={t('hub:CompetitionView:SubmitCompetition')}
              onClickNext={() => onSubmitModule(modIndex + 1, 0, true)}
            />
          )

          break
        case 'SUBMIT':
          competitionDisplay = (
            <SubmitCompetitionView
              theme={currentTheme}
              headline={
                <h2 className="title">
                  {t('hub:CompetitionView:SubmitCompetition')}
                </h2>
              }
              content={HTMLReactParser(
                t('hub:CompetitionView:CompetitionSubmitText'),
              )}
              prevButton={t('hub:CompetitionView:BackToEx')}
              onClickPrev={() => onButtonClickHandler(exIndex - 1)}
              nextButton={t('hub:CompetitionView:SubmitCompetition')}
              onClickNext={() => onSubmitModule(modIndex + 1, 0, true)}
            />
          )

          break
        case 'SUBMIT_COMPETITION':
          competitionDisplay = (
            <SubmitCompetitionView
              customCss={{}}
              theme={currentTheme}
              headline={
                <div>
                  <h2 className="title" style={{ marginBottom: '20px' }}>
                    {t('hub:CompetitionView:CompleteCompetitionTitle', {
                      competition_name: `${pageTitle.title}`,
                    })}
                  </h2>
                </div>
              }
              content={
                <TinyTextContentPreview html={submitMessage.submitMessage} />
              }
              nextButton={t('hub:CompetitionView:SubmitCompetition')}
              onClickNext={onCompleteCompetition}
            />
          )

          break

        default:
          console.error(
            'CompStatus was not correct in function competitionStatusHandler from CompetitionView(HUB): ',
            compStatus,
          )
          break
      }
    }

    return competitionDisplay
  }

  return (
    competitions && (
      <CompetitionWrapper style={{ width: '100%' }}>
        {!listView ? (
          <>
            {hasModules && (
              <>
                <hr />
                <CompetitionPagination>
                  {!competitionSubmited &&
                    competitions?.map((mod, index) => {
                      return (
                        <ModalLabelContainer
                          key={index}
                          customCss={
                            +moduleIndex === index
                              ? {
                                  width: '100%',
                                  display: 'grid',
                                  placeItems: 'center',
                                  [`@media (max-width: ${QUERIES.mobile}px)`]: {
                                    width: '100px',
                                  },
                                }
                              : { minWidth: '100px', textAlign: 'center' }
                          }
                        >
                          <ModuleLabel
                            customCss={
                              +moduleIndex === index
                                ? {
                                    position: 'absolute !important',
                                    transform: 'translate(-50%, 0px)',
                                    left: '50%',
                                    color: COLORS.secBlack,
                                    ':before': {
                                      content: "''",
                                      position: 'absolute',
                                      top: -30,
                                      left: '50%',
                                      transform: 'translate(-50%, 0)',
                                      width: 14,
                                      height: 14,
                                      borderRadius: '50%',
                                      border: `2px solid ${COLORS.secBlack}`,
                                      background: COLORS.secBlack,
                                      display: 'inline-block',
                                    },
                                  }
                                : {
                                    color: COLORS.borderGray,
                                    ':before': {
                                      content: "''",
                                      position: 'absolute',
                                      top: -28,
                                      left: '50%',
                                      transform: 'translate(-50%, 0)',
                                      width: 15,
                                      height: 15,
                                      borderRadius: '50%',
                                      border: `2px solid ${COLORS.borderGray}`,
                                      background: '#ffff',
                                      display: 'inline-block',
                                    },
                                  }
                            }
                          >
                            {t('hub:CompetitionView:ModuleTitle', {
                              index: index + 1,
                              module_name: mod.module_name,
                            })}
                          </ModuleLabel>
                        </ModalLabelContainer>
                      )
                    })}
                </CompetitionPagination>
              </>
            )}

            {!competitionSubmited &&
              (hasModules ? (
                <Pagination
                  id="pagination"
                  exercises={competitions[moduleIndex].exercises}
                  hasTimeEnded={hasTimeEnded}
                  isCompetitionSubmited={competitionSubmited}
                  theme={currentTheme}
                  hasModules={hasModules}
                  onButtonClickHandler={(exercise) =>
                    onButtonClickHandler(exercise, listView)
                  }
                />
              ) : (
                <Pagination
                  id="pagination"
                  exercises={competitions}
                  hasTimeEnded={hasTimeEnded}
                  isCompetitionSubmited={competitionSubmited}
                  theme={currentTheme}
                  hasModules={hasModules}
                  onButtonClickHandler={(exercise) =>
                    onButtonClickHandler(exercise, listView)
                  }
                />
              ))}
            {/* </div> */}

            <CompetitionBody id="competition-body">
              {/* <LeftColumn> */}
              <ChangeViewButton
                id="competition-changeView-button"
                customCss={{ position: 'absolute', top: 0 }}
                onClick={changeViewHandler}
              >
                <i className="fas fa-list-ol"></i>
                {t('hub:CompetitionView:ChangeViewBtn')}
              </ChangeViewButton>

              <SubmitButtonHub
                id="prevExc"
                theme={currentTheme}
                disabled={
                  +exerciseIndex + 1 === 1 ||
                  competitionStatus === 'SUBMIT_COMPETITION' ||
                  competitionStatus === 'OUT_OF_TIME'
                }
                type="submit"
                customCss={{
                  width: 90,
                  height: 90,
                  transform: 'rotate(180deg)',
                }}
                onClick={() => onButtonClickHandler(exerciseIndex - 1)}
              />
              {/* </LeftColumn> */}

              <MiddleColumn
                customCss={{ width: '100%', height: '100%' }}
                id="competition-question"
              >
                {competitionStatusHandler(
                  hasModules,
                  competitionStatus,
                  competitions,
                )}
              </MiddleColumn>

              {/* <RightColumn> */}
              {countdownTime && (
                <CountdownTimerText>
                  <span className="timer-headline">
                    {hasModules
                      ? HTMLReactParser(
                          t('hub:CompetitionView:TimerHeadlineWithModules'),
                        )
                      : t('hub:CompetitionView:TimerHeadline')}
                  </span>
                  <Countdown
                    id="competition-timer"
                    key={countdownTime}
                    date={countdownTime}
                    renderer={countdownRenderer}
                    onComplete={() => {
                      setTimeHasEnded(true)
                      setCompetitionStatus('OUT_OF_TIME')
                    }}
                  />
                </CountdownTimerText>
              )}

              <SubmitButtonHub
                id="nextExc"
                disabled={
                  (competitions &&
                    (hasModules
                      ? competitions[moduleIndex].exercises.length + 2 ===
                        +exerciseIndex + 2
                      : competitions.length + 2 === +exerciseIndex + 2)) ||
                  competitionStatus === 'SUBMIT_COMPETITION' ||
                  competitionStatus === 'OUT_OF_TIME'
                }
                customCss={{
                  width: 90,
                  height: 90,
                }}
                theme={currentTheme}
                onClick={() => onButtonClickHandler(+exerciseIndex + 1)}
              />
              {/* </RightColumn> */}
            </CompetitionBody>
          </>
        ) : (
          //List View
          <>
            <CompetitionBodyListView id="competition-body">
              <LeftColumn
                id="left-column"
                customCss={{
                  justifyContent: 'flex-start',
                  [`@media (max-width: ${QUERIES.mobile}px)`]: {
                    position: 'sticky',
                    top: 100,
                  },
                }}
              >
                <ColumnContainer
                  customCss={{
                    '#pagination': {
                      justifyContent: 'center',
                    },
                  }}
                >
                  <ChangeViewButton
                    id="competition-changeView-button"
                    onClick={changeViewHandler}
                  >
                    <i className="fas fa-arrows-alt-h"></i>
                    {t('hub:CompetitionView:ChangeViewBtn')}
                  </ChangeViewButton>

                  {hasModules
                    ? !competitionSubmited &&
                      competitions?.map((mod, index) => {
                        return (
                          <CompetitionPagination
                            id="competition-pagination"
                            key={index}
                            customCss={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              gap: 0,
                            }}
                          >
                            <span>
                              {t('hub:CompetitionView:ModuleTitle', {
                                index: index + 1,
                                module_name: mod.module_name,
                              })}
                            </span>
                            {+moduleIndex === index && (
                              <Pagination
                                listView={listView}
                                id="pagination"
                                exercises={competitions[moduleIndex].exercises}
                                hasTimeEnded={hasTimeEnded}
                                isCompetitionSubmited={competitionSubmited}
                                theme={currentTheme}
                                hasModules={hasModules}
                                onButtonClickHandler={(exercise) =>
                                  onButtonClickHandler(exercise, listView)
                                }
                              />
                            )}
                          </CompetitionPagination>
                        )
                      })
                    : !competitionSubmited && (
                        <Pagination
                          id="pagination"
                          exercises={competitions}
                          hasTimeEnded={hasTimeEnded}
                          isCompetitionSubmited={competitionSubmited}
                          theme={currentTheme}
                          hasModules={hasModules}
                          onButtonClickHandler={(exercise) =>
                            onButtonClickHandler(exercise, listView)
                          }
                          listView={listView}
                        />
                      )}
                </ColumnContainer>
              </LeftColumn>

              <RightColumn
                id="right-column"
                customCss={{
                  justifyContent: 'flex-start',
                  [`@media (max-width: ${QUERIES.mobile}px)`]: {
                    position: 'sticky',
                    top: 100,
                  },
                }}
              >
                <ColumnContainer id="timer-submit-container">
                  {countdownTime && (
                    <CountdownTimerText>
                      <span className="timer-headline">
                        {hasModules
                          ? HTMLReactParser(
                              t('hub:CompetitionView:TimerHeadlineWithModules'),
                            )
                          : t('hub:CompetitionView:TimerHeadline')}
                      </span>
                      <Countdown
                        id="competition-timer"
                        key={countdownTime}
                        date={countdownTime}
                        renderer={countdownRenderer}
                        onComplete={() => {
                          setTimeHasEnded(true)
                          setCompetitionStatus('OUT_OF_TIME')
                        }}
                      />
                    </CountdownTimerText>
                  )}
                  <SubmitModuleButton
                    customCss={{ marginTop: 10 }}
                    theme={currentTheme}
                    disabled={competitionStatus === 'OUT_OF_TIME'}
                    onClick={() => {
                      const exIndex = hasModules
                        ? competitions[moduleIndex]?.exercises.length
                        : competitions.length

                      onButtonClickHandler(exIndex)
                    }}
                  >
                    {t('hub:CompetitionView:SubmitModalBtn')}
                  </SubmitModuleButton>
                </ColumnContainer>
              </RightColumn>

              <MiddleColumn className="list" id="competition-question">
                {competitionStatusHandler(
                  hasModules,
                  competitionStatus,
                  competitions,
                  listView,
                )}
              </MiddleColumn>
            </CompetitionBodyListView>
          </>
        )}
        {/* <CountDown /> */}
      </CompetitionWrapper>
    )
  )
}

export { CompetitionView }

const CompetitionWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',

  hr: {
    width: '100%',
    border: `1px solid ${COLORS.borderGray} `,
    backgroundColor: COLORS.borderGray,
    borderRadius: 50,
    marginBottom: 20,
  },
})

const CompetitionPagination = styled('div')(({ customCss }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  justifyContent: 'center',

  span: {
    gridRow: '1',
    justifySelf: 'center',
    textAlign: 'center',
    marginBottom: 10,
    position: 'relative',
  },

  '#pagination': {
    gridRow: '2',
  },

  ...customCss,
}))

const CountdownTimerText = styled('div')(({ customCss }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  gridColumn: 3,
  gridRow: 1,
  color: COLORS.mainGray,
  textAlign: 'center',

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    gridColumn: 2,
  },

  ...customCss,
}))

const ChangeViewButton = styled('button')(({ customCss }) => ({
  backgroundColor: '#ffff',
  padding: 5,
  borderRadius: 15,
  fontSize: 16,
  display: 'flex',
  flexWrap: 'wrap',
  color: COLORS.secBlack,

  gap: 5,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 20,

  i: {
    fontSize: 36,
  },

  ':hover': {
    cursor: 'pointer',
  },

  ...customCss,
}))

const SubmitModuleButton = styled('button')(({ customCss, theme }) => ({
  backgroundColor: THEMES[+theme],
  border: `1px solid ${THEMES[+theme]}`,
  color: '#ffff',
  padding: '10px 20px',
  borderRadius: 10,
  fontSize: 18,
  display: 'flex',
  margin: 'auto',
  flexDirection: 'row',
  alignItems: 'center',

  ':hover': {
    cursor: 'pointer',
    color: THEMES[+theme],
    backgroundColor: '#ffff',
  },

  ...customCss,
}))

const CompetitionBody = styled('div')({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '1fr 7fr 1fr',
  gridTemplateRows: '100px 1fr',
  gridColumnGap: '30px',
  placeItems: 'center',
  width: '100%',
  height: '100%',

  '#competition-changeView-button': {
    gridColumn: '1 / 2',
    gridRow: '1',
  },

  '#competition-timer': {
    gridColumn: '3',
    gridRow: '1',
  },

  '#competition-question': {
    gridColumn: '2 / 2',
    gridRow: '1 / 3',
  },

  '#prevExc': {
    gridColumn: '1',
    gridRow: '2',
  },

  '#nextExc': {
    gridColumn: '3',
    gridRow: '2',
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    display: 'grid',
    gridTemplateColumns: '1fr  1fr ',
    gridTemplateRows: '100px  1fr 100px',

    '#competition-changeView-button': {
      position: 'relative',
      gridColumn: '1 ',
      gridRow: '1',
      margin: 0,
    },

    '#competition-timer': {
      position: 'relative',
      gridColumn: '2',
      gridRow: '1',
    },

    '#competition-question': {
      gridColumn: '1 /3 ',
      gridRow: '2 / 3',

      '.exercise': {
        padding: '0px',
      },

      '#question-answers': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },

    '#prevExc': {
      gridColumn: '1',
      gridRow: '3',
    },

    '#nextExc': {
      gridColumn: '2',
      gridRow: '3',
    },
  },
})

const CompetitionBodyListView = styled('div')({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '1fr 7fr 1fr',
  gridColumnGap: '30px',
  width: '100%',
  height: '100%',

  '#competition-changeView-button': {
    // position: 'absolute',
    // left: 0,
    // gridColumn: '1 / 2',
    // gridRow: '1',
  },

  '#competition-timer': {
    // position: 'absolute',
    // right: 0,
    //
    // gridColumn: '3',
    // gridRow: '1',
  },

  '#competition-question': {
    gridColumn: '2 / 2',
    gridRow: '1 / 2',
  },

  '#prevExc': {
    gridColumn: '1',
    gridRow: '2',
  },

  '#nextExc': {
    gridColumn: '3',
    gridRow: '2',
  },

  //==========================Mobile==================================

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    display: 'flex',
    flexDirection: 'column',
    gridTemplateRows: '100px 100px 1fr',
    gridTemplateColumns: '1fr',
    columnGap: 0,
    // display: 'flex',
    // flexDirection: 'column',

    '.competition-question': {
      // width: '100%',

      gridRow: '3/4',
      gridColumn: '1 / 3',
    },

    '#left-column': {
      position: 'relative',
      top: '0',

      '#competition-changeView-button': {
        gridColumn: '1 /2',
        margin: 'auto',
      },
    },

    '#right-column': {
      padding: 10,
      top: 50,
      zIndex: 5,
      backgroundColor: '#fff',

      '#timer-submit-container': {
        justifyContent: 'space-evenly',
        display: 'flex',
        gap: 20,

        button: {
          margin: '0',
        },
      },
    },

    '#competition-timer': {
      gridColumn: '3',
      gridRow: '1',
    },

    '#competition-question': {
      gridColumn: '1 /4 ',
      gridRow: '3 / 4',

      '.exercise': {
        padding: '1px',
      },

      '#question-answers': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },

    '#prevExc': {
      gridColumn: '1',
      gridRow: '3',
    },

    '#nextExc': {
      gridColumn: '3',
      gridRow: '3',
    },
  },
})

const LeftColumn = styled('div')(({ customCss }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',

  ...customCss,
}))

const ColumnContainer = styled('div')(({ customCss }) => ({
  position: 'sticky',
  top: 200,
  ...customCss,
}))

const ModalLabelContainer = styled('div')(({ customCss }) => ({
  zIndex: 0,
  ...customCss,
}))

const MiddleColumn = styled('div')(({ customCss }) => ({
  width: '100%',
  height: '100%',
  maxWidth: 1200,

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  ...customCss,
}))

const ModuleLabel = styled('span')(({ customCss }) => ({
  zIndex: 5,

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    position: 'relative !important',
    alignSelf: 'center',
    left: 0,
    transform: 'none',
    minWidth: '100px',
  },
  ...customCss,
}))

const RightColumn = styled('div')(({ customCss }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',

  ...customCss,
}))
