import styled from '@emotion/styled'
import update from 'immutability-helper'
import React, { useCallback, useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { EscButton, PageInfo } from 'shared'
import { Exercise } from '../components/kyoso-exercises/Exercise'
import { PreloadingExercise } from '../components/kyoso-exercises/PreloadingExercise'
import {
  createExercise,
  deleteExercise,
  duplicateExercise,
  getAllExercisesOfChild,
  updateExercise,
  updateExercisesPositions,
} from '../services/kyoso/exercises'
import { PageFooter, SubmitButtonLab } from '../shared'
import {
  lab_hideGlobalLoading,
  lab_showGlobalLoading,
} from '../store/generalSlice'

export function KyosoExercises() {
  const createUpdateExerciseModalStateDefaults = {
    isEditing: false,
    show: false,
    childId: null,
    exerciseId: null,
    exerciseIndex: null,
    exerciseHasModules: false,
    submitCallback: null,
  }
  const [
    createUpdateExerciseModalState,
    setCreateUpdateExerciseModalState,
  ] = useState(createUpdateExerciseModalStateDefaults)
  const routeLocation = useLocation()
  const routeHistory = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [exercisesArray, setExercises] = useState([])

  const getAllExercises = useCallback((childId) => {
    dispatch(lab_showGlobalLoading())
    return getAllExercisesOfChild(childId)
      .then((response) => {
        setExercises(
          JSON.parse(response.order).map((ord) => ({
            id: ord,
            ...response.exercises
              .map((exc) => {
                if (exc.answer_type === '2') {
                  return {
                    ...exc,
                    answers: exc.answers.map((x) => ({
                      ...x,
                      answer: JSON.parse(x.answer),
                    })),
                  }
                } else if (exc.answer_type === '0') {
                  return {
                    ...exc,
                    answers: exc.answers.map((a) => {
                      return {
                        ...a,
                        other_answers:
                          a.is_other && JSON.parse(a.other_answers),
                      }
                    }),
                  }
                } else {
                  return exc
                }
              })
              .find((ex) => ex.id === ord),
          })),
        )
      })
      .catch((error) => {
        console.log(error)
        setExercises([])
      })
      .finally(() => dispatch(lab_hideGlobalLoading()))
  })

  useEffect(() => {
    getAllExercises(routeLocation.state.child_id)
  }, [])

  const onSubmit = () => {
    routeHistory.push(
      `/lab/dashboard/kyoso/child/${routeLocation.state.child_id}/preview`,
    )
  }

  const actionHandler = (action, exerciseId, index) => {
    switch (action) {
      case 'edit':
        setCreateUpdateExerciseModalState({
          isEditing: true,
          show: true,
          childId: routeLocation.state.child_id,
          exerciseId,
          exerciseHasModules: routeLocation.state.has_modules,
          exerciseIndex: index,
          submitCallback: editExercise,
        })
        break
      case 'duplicate':
        duplicateExercise(exerciseId)
          .then(() => getAllExercises(routeLocation.state.child_id))
          .catch((error) => {
            console.log(error)
          })
        break
      case 'delete':
        deleteExercise(routeLocation.state.child_id, exerciseId)
          .then(() => getAllExercises(routeLocation.state.child_id))
          .catch((error) => {
            console.log(error)
          })
        break
      default:
        console.log('Error')
    }
  }

  const moveExerciseCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragExercise = exercisesArray[dragIndex]
      const newState = update(exercisesArray, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragExercise],
        ],
      })
      let orderArray = newState.map((x) => x.id)

      setExercises(newState)

      updateExercisesPositions(
        routeLocation.state.child_id,
        JSON.stringify(orderArray),
      )
        .then((res) => console.log(res))
        .catch((error) => {
          console.log(error)
        })
    },
    [exercisesArray],
  )

  function insertExercise(data) {
    createExercise(
      routeLocation.state.child_id,
      data.description,
      +data.noAnswerPoints,
      +data.wrongAnswerPoints,
      data.answerType,
      data.totalCorrect,
      data.answerType === '2'
        ? data.answers.map((x) => ({ ...x, answer: JSON.stringify(x.answer) }))
        : data.answerType === '0'
        ? data.answers.map((x) => ({
            ...x,
            other_answers: JSON.stringify(x.other_answers),
          }))
        : data.answers,
      JSON.stringify(exercisesArray.map((x) => x.id)),
      {
        solution: data.solution || '_omit_',
        kyoso_child_module_id: +data.moduleId || '_omit_',
        instructions: data.instructions || '_omit_',
        scoring_type: data.scoringType || '_omit_',
        special_scoring: data.specialScoring
          ? JSON.stringify(data.specialScoring)
          : '_omit_',
      },
    )
      .then(() => {
        getAllExercises(routeLocation.state.child_id)
        setCreateUpdateExerciseModalState(
          createUpdateExerciseModalStateDefaults,
        )
      })
      .catch((error) => {
        setCreateUpdateExerciseModalState(
          createUpdateExerciseModalStateDefaults,
        )
        console.log(error)
      })
  }

  function editExercise(data) {
    updateExercise(
      data.id,
      data.description,
      +data.noAnswerPoints,
      +data.wrongAnswerPoints,
      data.answerType,
      data.totalCorrect,
      data.answerType === '2'
        ? data.answers.map((x) => ({ ...x, answer: JSON.stringify(x.answer) }))
        : data.answerType === '0'
        ? data.answers.map((x) => ({
            ...x,
            other_answers: JSON.stringify(x.other_answers),
          }))
        : data.answers,
      {
        solution: data.solution,
        kyoso_child_module_id: +data.moduleId || '_omit_',
        instructions: data.instructions,
        scoring_type: data.scoringType || '_omit_',
        special_scoring: data.specialScoring
          ? JSON.stringify(data.specialScoring)
          : '_omit_',
      },
    )
      .then(() => {
        getAllExercises(routeLocation.state.child_id)
        setCreateUpdateExerciseModalState(
          createUpdateExerciseModalStateDefaults,
        )
      })
      .catch((error) => {
        setCreateUpdateExerciseModalState(
          createUpdateExerciseModalStateDefaults,
        )
        console.log(error)
      })
  }

  return (
    <>
      {createUpdateExerciseModalState.show && (
        <PreloadingExercise
          isEditing={createUpdateExerciseModalState.isEditing}
          childId={createUpdateExerciseModalState.childId}
          exerciseId={createUpdateExerciseModalState.exerciseId}
          exerciseHasModules={createUpdateExerciseModalState.exerciseHasModules}
          exerciseIndex={createUpdateExerciseModalState.exerciseIndex}
          submitCallback={createUpdateExerciseModalState.submitCallback}
          closeModalCallback={() =>
            setCreateUpdateExerciseModalState(
              createUpdateExerciseModalStateDefaults,
            )
          }
        />
      )}
      <PageInfo
        title={t('lab:KyosoExercises:KyosoExercisesTitle', {
          parent_name: routeLocation.state?.parent_name,
          child_grade: routeLocation.state?.child_grade.name,
        })}
        subtitle={t('lab:KyosoExercises:KyosoExercisesSubTitle')}
        customCss={{
          '& h1': {
            marginBottom: 5,
          },
          '& h2': {
            marginBottom: 20,
          },
        }}
      />

      <EscButton
        onClick={() =>
          routeHistory.push(
            `/lab/dashboard/kyoso/child/${routeLocation.state.child_id}/preview`,
          )
        }
      />

      <DndProvider backend={HTML5Backend}>
        <Questions>
          {exercisesArray.map((exercise, index) => {
            return (
              <Exercise
                key={exercise.id}
                id={exercise.id}
                title={exercise.description}
                type={exercise.answer_type}
                answers={exercise.answers.sort(
                  (a, b) => a.position - b.position,
                )}
                moduleName={exercise.module_name}
                hasModules={routeLocation.state.has_modules}
                index={index}
                moveExerciseCard={moveExerciseCard}
                actionHandler={actionHandler}
              />
            )
          })}
        </Questions>
      </DndProvider>

      <SubmitButtonLab
        className="black"
        customCss={{
          maxWidth: 300,
          marginBottom: '20px',
          i: {
            marginRight: '10px',
          },
        }}
        onClick={() => {
          setCreateUpdateExerciseModalState({
            show: true,
            childId: routeLocation.state.child_id,
            exerciseId: null,
            exerciseIndex: exercisesArray.length,
            exerciseHasModules: routeLocation.state.has_modules,
            submitCallback: insertExercise,
          })
        }}
      >
        <i className="fas fa-plus"></i>
        {t('lab:KyosoExercises:AddExercise')}
      </SubmitButtonLab>

      <PageFooter>
        <PageInfo subtitle={t('lab:KyosoExercises:FooterDescription')} />

        <SubmitButtonLab
          className="black"
          customCss={{ maxWidth: 300, marginLeft: 20 }}
          onClick={onSubmit}
        >
          {t('lab:Shared:SubmitButtonSave')}
        </SubmitButtonLab>

        <SubmitButtonLab
          className="black"
          customCss={{
            maxWidth: 300,
            marginLeft: '30px',
          }}
          onClick={onSubmit}
        >
          {t('lab:Shared:SubmitButtonView')}
        </SubmitButtonLab>
      </PageFooter>
    </>
  )
}

const Questions = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '20px',
})
