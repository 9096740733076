import styled from '@emotion/styled/macro'
import { QUERIES } from 'styling/variables'
import { IconLinkButton, IconLinkExternalButton } from '../shared'

function MobileHeader() {
  return (
    <MobileHeaderLayout>
      <IconLinkButton to="/lab/dashboard">
        <i className="fas fa-th-large"></i>
      </IconLinkButton>
      <IconLinkButton to="/lab/calendar">
        <i className="far fa-calendar-alt"></i>
      </IconLinkButton>
      <IconLinkExternalButton
        onClick={() => window.open('https://kyoso.tech/site', '_blank')}
      >
        <i className="far fa-newspaper"></i>
      </IconLinkExternalButton>
      <IconLinkButton to="/lab/settings">
        <i className="fas fa-cogs"></i>
      </IconLinkButton>
      <IconLinkButton to="/lab/support">
        <i className="far fa-question-circle" />
      </IconLinkButton>
    </MobileHeaderLayout>
  )
}

const MobileHeaderLayout = styled('div')({
  height: 50,
  background: '#ffffff 0% 0% no-repeat padding-box',
  boxShadow: '0px 0px 5px #0000001a',
  display: 'none',
  width: '100%',
  gridTemplateColumns: 'repeat(5, 1fr)',
  placeItems: 'center',
  position: 'fixed',
  bottom: 0,

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    display: 'grid',
  },

  [IconLinkButton]: {
    fontSize: 22,
  },
})

export { MobileHeader }
