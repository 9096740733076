import API from 'utils/backendApi'

function getAllParents({
  itemsPerPage = '_omit_',
  pageNumber = '_omit_',
  name = '_omit_',
  schoolGrade = '_omit_',
  schoolSubject = '_omit_',
  year = '_omit_',
  types = '_omit_',
} = {}) {
  const body = JSON.stringify({
    items: itemsPerPage,
    page: pageNumber,
    name,
    grade_id: schoolGrade,
    subject_id: schoolSubject,
    year,
    types,
  })
  return API('lab').POST('/kyoso/parents', body)
}

function getParent(parentId) {
  return API('lab').GET(`/kyoso/parent/${parentId}`)
}

function getParentPreview(parentId) {
  return API('lab').GET(`/kyoso/parent/${parentId}/preview`)
}

function insertParent(
  owner_id,
  name,
  year,
  subject_id,
  type_id,
  {
    intervals = '_omit_',
    picture = '_omit_',
    description = '_omit_',
    visibility = '_omit_',
    paid = '_omit_',
    price = '_omit_',
  } = {},
) {
  const body = new FormData()
  body.append(
    'data',
    JSON.stringify({
      id: '_omit_',
      owner_id,
      name,
      year,
      subject_id,
      type_id,
      intervals,
      description,
      visibility,
      paid,
      price,
    }),
  )
  body.append('picture', picture)

  return API('lab').POST('/kyoso/parent', body)
}

function updateParent(
  id,
  {
    name = '_omit_',
    year = '_omit_',
    subject_id = '_omit_',
    type_id = '_omit_',
    intervals = '_omit_',
    picture = '_omit_',
    description = '_omit_',
    visibility = '_omit_',
    paid = '_omit_',
    price = '_omit_',
  } = {},
) {
  const body = new FormData()
  body.append(
    'data',
    JSON.stringify({
      id,
      name,
      year,
      subject_id,
      type_id,
      intervals,
      description,
      visibility,
      paid,
      price,
    }),
  )
  body.append('picture', picture)

  return API('lab').POST('/kyoso/parent', body)
}

function duplicateParent(parentId) {
  const body = JSON.stringify({
    parentId,
  })
  return API('lab').POST('/kyoso/parent/duplicate', body)
}

export {
  getAllParents,
  getParent,
  getParentPreview,
  insertParent,
  updateParent,
  duplicateParent,
}
