import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import {
  ContentTwoColumsLayout,
  EscButton,
  FirstColumn,
  FormRadioButtons,
  FormTextArea,
  PageInfo,
  SecondColumn,
} from 'shared'
import { COLORS } from 'styling/variables'
import { emailValidation } from 'utils/helpers/validators'
import { List, ListWrapper, PageFooter, SubmitButtonLab } from '../shared'
import {
  getChildInvites,
  insertChildInvites,
  removeChildInvite,
} from '../services/kyoso/invites'
import { updateChild } from '../services/kyoso/children'
import { useDispatch } from 'react-redux'
import {
  lab_hideGlobalLoading,
  lab_showGlobalLoading,
} from '../store/generalSlice'

export function KyosoInvites() {
  const { t } = useTranslation()
  const routeLocation = useLocation()
  const routeHistory = useHistory()
  const [invitedUsers, setInvitedUsers] = useState([])
  const [errMsg, setErrMsg] = useState(null)
  const dispatch = useDispatch()
  const idRef = useRef()

  const {
    register,
    getValues,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      signup: '0',
    },
  })

  useEffect(() => {
    dispatch(lab_showGlobalLoading())
    getChildInvites(routeLocation.state?.child_id)
      .then((response) => {
        setInvitedUsers(response.invites)
        setValue('signup', response.signUpType)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => dispatch(lab_hideGlobalLoading()))

    return () => {
      const timerId = idRef.current
      clearTimeout(timerId)
    }
  }, [])

  const onFormSubmit = (data) => {
    routeHistory.replace('/lab/dashboard/kyoso/exercises', {
      child_id: routeLocation.state?.child_id,
      parent_name: routeLocation.state?.parent_name,
      child_grade: routeLocation.state?.child_grade,
      has_modules: routeLocation.state?.has_modules,
    })
  }

  const sendInvites = (invites) => {
    let timerId

    const invitesArray = [...new Set(invites.split(/[\r?\n,]/))].reduce(
      (acc, curr) => {
        if (curr) {
          const trimmedEmail = curr.trim()
          if (invitedUsers.some(({ email }) => email === trimmedEmail)) {
            setErrMsg(true)

            return [...acc]
          } else if (
            !!emailValidation(trimmedEmail) ||
            acc.some((email) => !!emailValidation(email))
          ) {
            setError('invites', {
              type: 'manual',
              message: 'InvitesInvalidEmails',
            })

            return [...acc, trimmedEmail]
          } else {
            clearErrors('invites')

            return [...acc, trimmedEmail]
          }
        } else {
          return acc
        }
      },
      [],
    )

    if (!formState.errors.invites && invitesArray?.length) {
      insertChildInvites(routeLocation.state?.child_id, invitesArray)
        .then(() => {
          dispatch(lab_showGlobalLoading())
          getChildInvites(routeLocation.state?.child_id)
            .then((response) => {
              setInvitedUsers(response.invites)
              setValue('invites', '')
            })
            .catch((error) => {
              console.log(error)
            })
            .finally(() => dispatch(lab_hideGlobalLoading()))
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(
          () =>
            (timerId = setTimeout(() => {
              setErrMsg(null)
            }, 8000)),
        )
    } else {
      timerId = setTimeout(() => {
        setErrMsg(null)
      }, 8000)
    }

    idRef.current = timerId
  }

  const removeInvitedUserHandler = (userId) => {
    removeChildInvite(userId)
      .then(() => {
        dispatch(lab_showGlobalLoading())
        getChildInvites(routeLocation.state?.child_id)
          .then((response) => {
            setInvitedUsers(response.invites)
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => dispatch(lab_hideGlobalLoading()))
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const checkStatus = (status, confirmed) => {
    let colorClass

    if (+status && confirmed === '1') {
      colorClass = 'green'
    } else if (+status && confirmed === '0') {
      colorClass = 'yellow'
    } else {
      colorClass = 'red'
    }

    return colorClass
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <PageInfo
        title={t('lab:KyosoInvites:KyosoInvitesTitle', {
          parent_name: routeLocation.state?.parent_name,
          child_grade: routeLocation.state?.child_grade.name,
        })}
        subtitle={t('lab:KyosoInvites:KyosoInvitesSubTitle')}
        customCss={{
          '& h1': {
            marginBottom: 5,
          },
          '& h2': {
            marginBottom: 20,
          },
        }}
      />

      <EscButton onClick={() => routeHistory.goBack()} />

      <ContentTwoColumsLayout customCss={{ marginBottom: '20px' }}>
        <FirstColumn>
          <WarningMessage>
            {t('lab:KyosoInvites:WarningMessage')}
          </WarningMessage>

          <FormTextArea
            id="invites"
            name="invites"
            label="lab:KyosoInvites:LabelInvites"
            onChange={(e) => {
              if (!e.target.value) {
                clearErrors('invites')
              }
            }}
            register={register()}
            error={formState.errors.invites}
          />

          {errMsg && (
            <WarningMessage>
              {t('lab:KyosoInvites:AlreadyInvitedUsersErrMsg')}
            </WarningMessage>
          )}

          <SubmitButtonLab
            type="button"
            className="black"
            customCss={{ maxWidth: '250px' }}
            onClick={() => sendInvites(getValues('invites'))}
          >
            {t('lab:KyosoInvites:SubmitFormButton')}
          </SubmitButtonLab>
        </FirstColumn>

        <SecondColumn>
          <InvitedUsersWrapper>
            <PageInfo
              subtitle={t('lab:KyosoInvites:InvitedUsers')}
              customCss={{ marginBottom: '20px' }}
            />

            <ListWrapper>
              <List>
                {invitedUsers.map((el) => {
                  return (
                    <InvitedUser key={el.id}>
                      <UserIndicator
                        className={checkStatus(el.status, el.confirmed)}
                      >
                        <i className="fas fa-circle"></i>
                        <span id="name">{el.email}</span>
                      </UserIndicator>
                      <i
                        onClick={() => removeInvitedUserHandler(el.id)}
                        className="fas fa-trash-alt"
                      ></i>
                    </InvitedUser>
                  )
                })}
              </List>
            </ListWrapper>
          </InvitedUsersWrapper>
        </SecondColumn>
      </ContentTwoColumsLayout>
      <PageFooter>
        <PageInfo subtitle={t('lab:KyosoInvites:FooterSubTitle')} />

        <SubmitButtonLab
          type="button"
          className="black"
          customCss={{ marginLeft: 25, maxWidth: '250px' }}
          onClick={() => onFormSubmit(getValues())}
        >
          {t('lab:KyosoInvites:CreateExercite')}
        </SubmitButtonLab>
      </PageFooter>
    </form>
  )
}

const WarningMessage = styled('p')({
  color: COLORS.secRed,
  fontsize: '18px',
  marginBottom: '20px',

  '&#message': {
    color: COLORS.secBlack,
  },
})

const InvitedUsersWrapper = styled('div')({
  height: '100%',
})

const InvitedUser = styled('span')({
  padding: '5px',
  height: '40px',
  borderRadius: '5px',
  border: `1px solid ${COLORS.borderGray}`,
  backgroundColor: COLORS.bgGray,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& #name': {
    marginLeft: 10,
  },
})

const UserIndicator = styled('div')({
  '&.red': {
    i: {
      color: COLORS.secRed,
    },
  },
  '&.green': {
    i: {
      color: COLORS.secGreen,
    },
  },
  '&.yellow': {
    i: {
      color: COLORS.secYellow,
    },
  },
})
