import styled from '@emotion/styled'
import { THEMES } from 'styling/variables'

export const GenericButton = styled('button')(({ customCss, theme }) => ({
  padding: 10,
  minWidth: 120,
  minHeight: 25,
  marginTop: 20,
  borderRadius: 5,
  backgroundColor: THEMES[+theme],
  border: `1px solid ${THEMES[+theme]}`,
  color: '#ffff',
  fontSize: 16,

  '&.inverted': {
    color: THEMES[+theme],
    backgroundColor: '#ffff',

    ':hover': {
      cursor: 'pointer',
      backgroundColor: THEMES[+theme],
      color: '#ffff',
    },
  },

  ':disabled': {
    opacity: 0.5,
  },

  ':hover': {
    cursor: 'pointer',
    backgroundColor: '#ffff',
    color: THEMES[+theme],
  },

  ...customCss,
}))
