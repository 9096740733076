import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import HubLogo from 'assets/img/hub-logo.svg'
import { COLORS } from 'styling/variables'
import { getPublicCompetitionData } from '../services/competition'
import { getFileLinkFromServerStorage } from 'utils/helpers/common'
import { hub_selectUser } from '../store/userSlice'
import { Footer } from 'platforms/kyoso-lab/components/Footer'
import { checkForPublic } from '../services/competition'
import { getCompetitionRankingData } from 'platforms/kyoso-lab/services/kyoso/competitions'

import {
  hub_hideGlobalLoader,
  hub_showGlobalLoader,
} from '../store/generalSlice.js'

import { ImageWrapper, AgGridCompPublic } from 'shared'

import NoImgPlaceholder from 'assets/img/no_img_placeholder.png'

function PublicRankingPage() {
  const { t } = useTranslation()
  const { childId } = useParams()
  const routeHistory = useHistory()
  const [rowData, setRowData] = useState(null)
  const [isPublic, setIsPublic] = useState(null)
  const [pageData, setPageData] = useState({})
  const dispatch = useDispatch()

  const reduxUser = useSelector(hub_selectUser)

  useEffect(() => {
    if (childId) {
      getPublicCompetitionData(childId)
        .then((response) => {
          setPageData(response.info)
          checkForPublic(childId)
            .then((response) => {
              if (response.is_public) {
                setIsPublic(response.is_public)
                getCompetitionRankingData(childId)
                  .then((response) => {
                    setRowData({
                      data: response.data,
                      hasModules: response.hasModules,
                    })
                  })
                  .catch((error) => {
                    console.log(error)
                  })
                  .finally(() => {})
              }
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch(() => routeHistory.replace('/hub'))
    } else routeHistory.replace('/hub')
  }, [])

  return (
    <>
      <PublicRanking>
        <img className="hub-logo" src={HubLogo} alt="hub_logo" />
        <ImageWrapper className="img-wrapper" customCss={{ marginTop: 25 }}>
          <img
            src={
              pageData?.picture
                ? getFileLinkFromServerStorage(pageData.picture)
                : NoImgPlaceholder
            }
            alt="exc-img"
          />
        </ImageWrapper>
        <div className="title">
          {pageData.name} - {pageData.grade_name}
        </div>
        <div className="subtitle">
          {pageData.subject_name} - {pageData.year} - {pageData.type_name}{' '}
          <br />
          {t('hub:Competitions:CompetitionOwnerInfo', {
            competition_owner: pageData.owner_name,
          })}
        </div>
        <h1>{t('hub:Competitions:ResultsTitle')}</h1>
        <div className="subtitle">
          Ако сте в профила си, редът на Вашето участие ще бъде със светлосин
          фон в таблицата по-долу.
        </div>
        {isPublic && rowData ? (
          <>
            <div className="legend">
              Оцветяване в колоните със задачи: <br />
              <span className="correct legend-item">Верен отговор</span>
              <span className="wrong legend-item">Грешен отговор</span>
              <span className="blank legend-item">Неявил се / Неотговорил</span>
            </div>
            <div style={{ width: '100%', padding: '0 30px' }}>
              <AgGridCompPublic
                gridOptions={{
                  rowDragManaged: true,
                }}
                rowData={rowData?.data}
                hasModules={rowData?.hasModules}
                customColDef={{
                  rowDrag: false,
                  suppressMovable: true,
                  suppressMenu: true,
                }}
                currentUser={reduxUser.id ? reduxUser.id : null}
              />
            </div>
          </>
        ) : (
          <div className="title big">Резултатите все още не са налице.</div>
        )}
      </PublicRanking>
      <Footer />
    </>
  )
}

export { PublicRankingPage }

const PublicRanking = styled('div')({
  width: '100%',
  height: '100%',
  display: 'grid',
  placeItems: 'center',
  textAlign: 'center',
  color: COLORS.secBlack,
  marginBottom: 20,

  '& .hub-logo': {
    marginTop: 20,
    width: 130,
    height: 50,
  },

  '& .title': {
    marginTop: 20,
    fontSize: 18,
  },

  '& .title.big': {
    marginTop: 60,
    marginBottom: 60,
  },

  '& .subtitle': {
    marginTop: 5,
    fontSize: 16,
  },

  '& .exercise': {
    marginTop: 20,
    fontSize: 22,
    color: COLORS.mainGray,
  },

  '& .attached-files-label': {
    marginTop: 40,
    fontSize: 16,
  },

  '& .legend': {
    margin: '20px 0',
    '& .legend-item': {
      color: 'white',
      boxSizing: 'border-box',
      display: 'inline-block',
      margin: '5px 5px',
      '&.correct': {
        color: COLORS.secGreen,
      },
      '&.wrong': {
        color: COLORS.secRed,
      },
      '&.blank': {
        color: COLORS.secYellow,
      },
    },
  },
})
