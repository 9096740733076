import styled from '@emotion/styled'
import { SubmitButtonLab } from 'platforms/kyoso-lab/shared'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  EscButton,
  FormInput,
  FormRadioButtons,
  ModalBackground,
  ModalLayout,
  PageInfo,
} from 'shared'
import { COLORS } from 'styling/variables'
import { numbersAndLatinLetters } from 'utils/helpers/validators'

function InvoiceModal({ modalData, saveModalCallback, closeModalCallback }) {
  const [editModal, setEditModal] = useState(false)

  const { t } = useTranslation()
  const {
    reset,
    register,
    control,
    getValues,
    trigger,
    watch,
    handleSubmit,
    setValue,
    formState,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      invoiceType: '2',
      invoice_PIN: '12',
      invoice_address: 'invoice_address',
      invoice_city: 'invoice_city',
      invoice_name: 'invoice_name',
      invoice_VAT: '12',

      invoice_firm_name: 'asd',
      invoice_receiver: '12',
      invoice_responsible_person: '12',
      invoice_unit_id_code: '21',
    },
  })

  const invoiceTypeRadioButtons = [
    {
      id: 1,
      name: 'invoiceType',
      label: t('hub:Participation:Individual'),
      register: register({ required: 'Required' }),
      error: formState.errors.invoiceType,
    },
    {
      id: 2,
      name: 'invoiceType',
      label: t('hub:Participation:LegalEntity'),
      register: register({ required: 'Required' }),
      error: formState.errors.invoiceType,
    },
  ]

  const onSubmit = (data) => {
    // console.log(data)
    setEditModal(false)
  }

  return (
    <ModalBackground>
      <ModalLayout>
        <EscButton onClick={closeModalCallback} />

        <PageInfo
          title={t('lab:KyosoOrders:InvoiceModalTitle', {
            invoice_company_name: 'invoice_company_name',
          })}
        />

        <WrapperForm onSubmit={handleSubmit(onSubmit)}>
          <div className="invoice">Empty for Monti</div>

          <p className="red">{t('lab:KyosoOrders:InvoiceModalWarning')}</p>

          <div className="footer">
            <div className="button-container">
              <SubmitButtonLab
                className="black"
                disabled={editModal}
                customCss={{ marginBottom: 20, whiteSpace: 'nowrap' }}
              >
                {t('lab:KyosoOrders:ConfirmSendButton')}
              </SubmitButtonLab>

              <SubmitButtonLab
                customCss={{
                  display: `${editModal ? 'none' : 'block'}`,
                }}
                type="button"
                className="black"
                onClick={() => setEditModal(true)}
              >
                {t('lab:KyosoOrders:Edit')}
              </SubmitButtonLab>
            </div>

            {!editModal && (
              <SubmitButtonLab
                customCss={{ maxWidth: 250 }}
                type="button"
                className="white"
                onClick={closeModalCallback}
              >
                {t('shared:Modals:ModalCloseButton')}
              </SubmitButtonLab>
            )}
          </div>

          {editModal && (
            <div>
              <FormRadioButtons
                radioButtons={invoiceTypeRadioButtons}
                id="invoice-type"
                type="radio"
                name="invoiceType"
                register={register()}
                error={formState.errors.invoiceType}
              />

              {watch('invoiceType') === '1' ? (
                <div className="invoice-type-one">
                  <FormInput
                    customCss={{
                      marginBottom: '0',
                    }}
                    id="invoice-name_one"
                    type="text"
                    name="invoice_name"
                    label={`${t('shared:LabеlFirstName')}*`}
                    register={register({ required: 'Required' })}
                    error={formState.errors.invoice_name}
                    touched={formState.touched.invoice_name}
                  />

                  <FormInput
                    customCss={{
                      marginBottom: '0',
                    }}
                    id="PIN_one"
                    type="number"
                    name="invoice_PIN"
                    label={`${t('hub:Participation:LabelPIN')}`}
                    register={register()}
                    error={formState.errors.PIN}
                    touched={formState.touched.PIN}
                  />

                  <FormInput
                    customCss={{
                      marginBottom: '0',
                    }}
                    id="invoice-address_one"
                    type="text"
                    name="invoice_address"
                    label={`${t('shared:LabelAddress')}*`}
                    register={register({ required: 'Required' })}
                    error={formState.errors.invoice_address}
                    touched={formState.touched.invoice_address}
                  />
                  <FormInput
                    customCss={{
                      marginBottom: '0',
                    }}
                    id="invoice-city_one"
                    type="text"
                    name="invoice_city"
                    label={`${t('shared:LabelCity')}*`}
                    register={register({ required: 'Required' })}
                    error={formState.errors.invoice_city}
                    touched={formState.touched.invoice_city}
                  />
                </div>
              ) : (
                <div className="invoice-type-two">
                  <FormInput
                    customCss={{
                      marginBottom: '0',
                    }}
                    id="invoice-firm-name_two"
                    type="text"
                    name="invoice_firm_name"
                    label={`${t('hub:Participation:LabelFirmName')}*`}
                    register={register({ required: 'Required' })}
                    error={formState.errors.invoice_firm_name}
                    touched={formState.touched.invoice_firm_name}
                  />

                  <FormInput
                    customCss={{
                      marginBottom: '0',
                    }}
                    id="unit-id-code_two"
                    type="number"
                    name="invoice_unit_id_code"
                    label={`${t('hub:Participation:LabelUnitIdCode')}*`}
                    register={register({ required: 'Required' })}
                    error={formState.errors.invoice_unit_id_code}
                    touched={formState.touched.invoice_unit_id_code}
                  />

                  <FormInput
                    customCss={{
                      marginBottom: '0',
                    }}
                    id="invoice-VAT_two"
                    type="text"
                    name="invoice_VAT"
                    label={`${t('hub:Participation:LabelVAT')}*`}
                    onChange={() => trigger('invoice_VAT')}
                    register={register({
                      required: 'Required',
                      validate: {
                        numbersAndLatinLetters: (value) =>
                          numbersAndLatinLetters(value),
                      },
                    })}
                    error={formState.errors.invoice_VAT}
                    touched={formState.touched.invoice_VAT}
                  />

                  <FormInput
                    customCss={{
                      marginBottom: '0',
                      gridColumn: '1 / 3',
                    }}
                    id="invoice-address_two"
                    type="text"
                    name="invoice_address"
                    label={`${t('hub:Participation:LabelAddress')}*`}
                    register={register({ required: 'Required' })}
                    error={formState.errors.invoice_address}
                    touched={formState.touched.invoice_address}
                  />

                  <FormInput
                    customCss={{
                      marginBottom: '0',
                    }}
                    id="invoice-city_two"
                    type="text"
                    name="invoice_city"
                    label={`${t('shared:LabelCity')}*`}
                    register={register({ required: 'Required' })}
                    error={formState.errors.invoice_city}
                    touched={formState.touched.invoice_city}
                  />

                  <FormInput
                    customCss={{
                      marginBottom: '0',
                    }}
                    id="invoice-responsible-person_two"
                    type="text"
                    name="invoice_responsible_person"
                    label={`${t('hub:Participation:LabelResponsiblePerson')}`}
                    register={register()}
                    error={formState.errors.invoice_responsible_person}
                    touched={formState.touched.invoice_responsible_person}
                  />

                  <FormInput
                    customCss={{
                      marginBottom: '0',
                    }}
                    id="invoice-receiver_two"
                    type="text"
                    name="invoice_receiver"
                    label={`${t('hub:Participation:LabelReceiver')}`}
                    register={register()}
                    error={formState.errors.invoice_receiver}
                    touched={formState.touched.invoice_receiver}
                  />
                </div>
              )}
            </div>
          )}

          {!!editModal && (
            <div className="footer">
              <SubmitButtonLab
                className="black"
                customCss={{ maxWidth: 250 }}
                type="submit"
              >
                {t('shared:Modals:ModalReIssue')}
              </SubmitButtonLab>

              <SubmitButtonLab
                customCss={{ maxWidth: 250 }}
                type="button"
                className="white"
                onClick={closeModalCallback}
              >
                {t('shared:Modals:ModalCloseButton')}
              </SubmitButtonLab>
            </div>
          )}
        </WrapperForm>
      </ModalLayout>
    </ModalBackground>
  )
}

export { InvoiceModal }

const WrapperForm = styled('form')({
  '.red': {
    color: COLORS.secRed,
    marginBottom: 20,
    textAlign: 'justify',
    lineHeight: '1.5',
    maxWidth: '600px',
  },

  '.invoice': {
    border: '1px solid',
    width: '100%',
    height: 600,
    textAlign: 'center',
    marginBottom: 20,
  },

  '.footer': {
    display: 'flex',
    justifyContent: 'space-between',
  },

  '.button-container': {
    display: 'flex',
    gap: 20,
  },

  '.invoice-type-one': {
    display: 'grid',
    alignContent: 'center',
    gridTemplateColumns: '2fr 1fr',
    gridGap: '30px',
    alignItems: 'end',
    marginBottom: 30,
    width: '100%',
  },

  '.invoice-type-two': {
    display: 'grid',
    alignContent: 'center',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '30px',
    alignItems: 'end',
    marginBottom: 30,
    width: '100%',
  },
})
