import styled from '@emotion/styled'
import {
  deleteAnswer,
  deleteExerciseAnswers,
} from 'platforms/kyoso-lab/services/kyoso/exercises'
import { SubmitButtonLab } from 'platforms/kyoso-lab/shared'
import { useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInput, TinyTextEditor } from 'shared'
import { KyosoChildModulesSelect } from 'shared/components/KyosoChildModulesSelect'
import { answer_types } from 'styling/answer-types'
import { COLORS, QUERIES } from 'styling/variables'
import { SharedModal } from 'shared'
import { AnswersFormsByType } from './AnswersFormsByType'

function CreateUpdateExerciseModal({
  exerciseData,
  closeModalCallback,
  modulesOptions,
  exerciseId,
  exerciseIndex,
  exerciseHasModules,
  submitCallback,
}) {
  const [answerTypeWarningModal, setAnswerTypeWarningModal] = useState(null)
  const {
    reset,
    register,
    control,
    getValues,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    trigger,
    formState,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      description: exerciseData?.description || null,
      solution: exerciseData?.solution || null,
      moduleId: exerciseData?.kyoso_child_module_id,
      instructions: exerciseData?.instructions,
      noAnswerPoints: exerciseData?.no_answer_points,
      wrongAnswerPoints: exerciseData?.wrong_answer_points,
      answerType: exerciseData?.answer_type,
      answers: exerciseData
        ? exerciseData.answer_type === '2'
          ? exerciseData.answers.map((x) => ({
              ...x,
              answer: JSON.parse(x.answer),
            }))
          : exerciseData.answer_type === '0'
          ? exerciseData.answers.map((x) => ({
              ...x,
              other_answers: JSON.parse(x.other_answers),
            }))
          : exerciseData.answers
        : [],
      scoringType: exerciseData?.scoring_type || '0',
      specialScoring: exerciseData?.special_scoring
        ? JSON.parse(exerciseData.special_scoring)
        : [],
    },
  })
  const { fields: answers, append, remove } = useFieldArray({
    control,
    name: 'answers',
    keyName: 'keyId',
  })

  const {
    fields: specialScorings,
    append: appendScoring,
    remove: removeScoring,
  } = useFieldArray({
    control,
    name: 'specialScoring',
  })
  const { t } = useTranslation()

  function onFormSubmit(data) {
    let totalCorrect = 0

    // Validate checkboxes
    if (
      (data.answerType === '0' && !data.answers.find((x) => x.is_correct)) ||
      (data.answerType === '1' &&
        data.answers.filter((x) => x.is_correct).length < 2)
    ) {
      setError('noCorrectAnswer', {
        type: 'manual',
        message: t(
          `lab:KyosoExerciseCreateUpdateModule:NoCorrectAnswerError${data.answerType}`,
        ),
      })
      document
        .querySelector(
          '#create-update-exercise-modal-layout *[id=noCorrectAnswer]',
        )
        ?.scrollIntoView()
      return false
    }
    //Calculate totalCorrect
    if (data.answerType === '0' || data.answerType === '1') {
      totalCorrect = data.answers.filter((x) => x.is_correct).length
    } else if (data.answerType === '2') {
      totalCorrect =
        data.answers.length > 1
          ? data.answers.reduce((acc, next) => acc + next.answer.length, 0)
          : data.answers[0].answer.length
    }

    submitCallback({ ...data, totalCorrect, id: exerciseId || null })
  }

  function onFormErrors(errors) {
    document
      .querySelector(
        `#create-update-exercise-modal-layout *[id=${Object.keys(errors)[0]}]`,
      )
      ?.scrollIntoView()
  }

  function onFormReset(e) {
    e.preventDefault()
    reset({
      description: null,
      solution: null,
      moduleId: null,
      instructions: null,
      noAnswerPoints: 0,
      wrongAnswerPoints: 0,
      answerType: null,
      answers: [],
      scoringType: '0',
      specialScoring: [],
    })
  }

  function getDefaultsForAnswersByType(answerType) {
    let answers = []
    switch (answerType) {
      case 0:
        answers = [0, 1].map((x) => ({
          answer: null,
          points: 0,
          is_correct: false,
          other_answers: null,
          is_other: false,
          position: x,
        }))
        break
      case 1:
        answers = [0, 1, 2].map((x) => ({
          answer: null,
          points: 0,
          is_correct: false,
          position: x,
        }))
        break
      case 2:
        answers = [
          {
            answer: [{ answer: null, points: 0 }],
            description: null,
            position: 0,
          },
        ]
        break
      case 3:
        answers = [
          {
            description: null,
            position: 0,
          },
        ]
        break
    }
    return answers
  }

  function checkAnswerType(answerTypeId) {
    if (getValues().answerType) {
      if (getValues().answerType != answerTypeId) {
        setAnswerTypeWarningModal(answerTypeId)
      }
    } else {
      document.querySelector(
        `#answer_type_${answerTypeId}>input`,
      ).checked = true
      setValue('answerType', `${answerTypeId}`)
      document
        .querySelector(`#answer_type_${answerTypeId}`)
        .classList.add('selected')
      if (formState.errors.answerType) clearErrors('answerType')
      setValue('answers', getDefaultsForAnswersByType(answerTypeId))
    }
  }

  function changeAnswerType(answerTypeId) {
    document.querySelectorAll('.answer-type').forEach((x) => {
      const inputRadioAnswerType = x.querySelector('input')
      if (inputRadioAnswerType.value == answerTypeId) {
        inputRadioAnswerType.checked = true
        x.classList.add('selected')
      } else {
        x.classList.remove('selected')
      }
    })
    setValue('answers', getDefaultsForAnswersByType(answerTypeId))
    setAnswerTypeWarningModal(null)
    if (formState.errors?.noCorrectAnswer) clearErrors('noCorrectAnswer')
    clearErrors('answers')
  }

  function getAnswerTypeInfoText(answerTypeId) {
    let textLabel = 'WrongInfo'
    switch (answerTypeId) {
      case 0:
        textLabel = 'SingleAnswersInfo'
        break
      case 1:
        textLabel = 'MultiAnswersInfo'
        break
      case 2:
        textLabel = 'OpenAnswersInfo'
        break
      case 3:
        textLabel = 'LongAnswersInfo'
        break
    }
    return t(`lab:KyosoExerciseCreateUpdateModule:${textLabel}`)
  }

  function removeAnswer(answerId, index) {
    if (answerId) {
      deleteAnswer(answerId, getValues().answerType)
        .then(() => {
          remove(index)
        })
        .catch((error) => {
          console.log(error)
        })
    } else remove(index)
  }

  return (
    <>
      <SharedModal
        show={answerTypeWarningModal || answerTypeWarningModal == 0}
        type="action"
        title={t(
          'lab:KyosoExerciseCreateUpdateModule:ChangeAnswerTypeWarningTitle',
        )}
        content={t(
          'lab:KyosoExerciseCreateUpdateModule:ChangeAnswerTypeWarningContent',
        )}
        confirmText={t('shared:Change')}
        confirmCallback={() => {
          if (exerciseId) {
            deleteExerciseAnswers(exerciseId, getValues().answerType)
              .then(() => {
                changeAnswerType(answerTypeWarningModal)
              })
              .catch((error) => {
                console.log(error)
                if (error.statCode === 115)
                  changeAnswerType(answerTypeWarningModal)
              })
          } else {
            changeAnswerType(answerTypeWarningModal)
          }
        }}
        rejectCallback={() => setAnswerTypeWarningModal(null)}
      />
      <CreateUpdateExerciseModalBackground>
        <CreateUpdateExerciseModalWrap>
          <CreateUpdateExerciseModalLayout id="create-update-exercise-modal-layout">
            <form
              noValidate
              onSubmit={handleSubmit(onFormSubmit, onFormErrors)}
              onReset={onFormReset}
            >
              <Topbar>
                <h2 className="title">
                  {t('lab:KyosoExerciseCreateUpdateModule:ExerciseTitle', {
                    exercise_index: exerciseIndex + 1,
                  })}
                </h2>
                <div className="right-content">
                  <SubmitButtonLab className="black" customCss={{ width: 160 }}>
                    {t('lab:Shared:SubmitButtonSave')}
                  </SubmitButtonLab>
                  <div
                    className="close-icon"
                    onClick={() => closeModalCallback()}
                  >
                    <i className="fas fa-times"></i>
                  </div>
                </div>
              </Topbar>
              <ExerciseFields>
                <TinyTextEditor
                  id="description"
                  name="description"
                  label="lab:KyosoExerciseCreateUpdateModule:ExerciseDescriptionLabel"
                  rules={{ required: 'Required' }}
                  control={control}
                  error={formState.errors.description}
                />

                <TinyTextEditor
                  id="solution"
                  name="solution"
                  label="lab:KyosoExerciseCreateUpdateModule:SolutionLabel"
                  control={control}
                />

                <TwoColumnsRow>
                  {exerciseHasModules ? (
                    <KyosoChildModulesSelect
                      modules={modulesOptions}
                      id="moduleId"
                      name="moduleId"
                      rules={{ required: 'Required' }}
                      label="lab:KyosoExerciseCreateUpdateModule:ModuleLabel"
                      setValue={setValue}
                      getValues={getValues}
                      onChange={() => trigger('moduleId')}
                      error={formState.errors.moduleId}
                      control={control}
                      defaultValue={null}
                      customCss={{
                        width: 300,
                        marginBottom: 0,
                      }}
                    />
                  ) : null}
                  <FormInput
                    id="instructions"
                    label="lab:KyosoExerciseCreateUpdateModule:InstructionsLabel"
                    type="text"
                    name="instructions"
                    register={register()}
                    defaultValue={null}
                    customCss={{
                      marginBottom: 0,
                    }}
                  />
                </TwoColumnsRow>
                <TwoColumnsRow>
                  <FormInput
                    id="noAnswerPoints"
                    label="lab:KyosoExerciseCreateUpdateModule:NoAnswerPointsLabel"
                    type="number"
                    min="0"
                    name="noAnswerPoints"
                    register={register({ required: 'Required' })}
                    error={formState.errors.noAnswerPoints}
                    defaultValue={0}
                    customCss={{
                      width: 400,
                      marginBottom: 0,
                    }}
                  />
                  <FormInput
                    id="wrongAnswerPoints"
                    label="lab:KyosoExerciseCreateUpdateModule:WrongAnswerPointsLabel"
                    type="number"
                    name="wrongAnswerPoints"
                    register={register({ required: 'Required' })}
                    error={formState.errors.wrongAnswerPoints}
                    defaultValue={0}
                    customCss={{
                      width: 400,
                      marginBottom: 0,
                    }}
                  />
                </TwoColumnsRow>
              </ExerciseFields>
              <div className="answer-type-label">
                {t('lab:KyosoExerciseCreateUpdateModule:AnswerTypeLabel')}
                {formState.errors.answerType && (
                  <span className="answer-type-error">
                    {` (${t(
                      'lab:KyosoExerciseCreateUpdateModule:AnswerTypeError',
                    )})`}
                  </span>
                )}
              </div>
              <AnswerTypes id="answerType">
                {answer_types.map((x, i) => (
                  <div
                    key={i}
                    id={`answer_type_${i}`}
                    className={`answer-type ${
                      watch('answerType') == i ? 'selected' : ''
                    }`}
                    onClick={() => checkAnswerType(i)}
                  >
                    <input
                      hidden
                      ref={register({ required: 'Required' })}
                      type="radio"
                      name="answerType"
                      value={i}
                    />
                    <img src={x} alt="answer-type-icon" />
                    <div className="text">{getAnswerTypeInfoText(i)}</div>
                  </div>
                ))}
              </AnswerTypes>
              <AnswersFormsByType
                answerType={watch('answerType')}
                answers={answers}
                appendAnswer={append}
                removeAnswer={removeAnswer}
                specialScorings={specialScorings}
                appendScoring={appendScoring}
                removeScoring={removeScoring}
                formSetValue={setValue}
                formRegister={register}
                formControl={control}
                formState={formState}
                formValues={getValues}
                formWatch={watch}
                formClearError={clearErrors}
                formTrigger={trigger}
                getDefaultAnswerStructure={getDefaultsForAnswersByType}
              />
              <FooterButtons>
                <SubmitButtonLab className="black" customCss={{ width: 160 }}>
                  {t('lab:Shared:SubmitButtonSave')}
                </SubmitButtonLab>
                <SubmitButtonLab
                  type="reset"
                  className="white"
                  customCss={{ width: 160 }}
                >
                  {t('lab:Shared:SubmitButtonClear')}
                </SubmitButtonLab>
              </FooterButtons>
            </form>
          </CreateUpdateExerciseModalLayout>
        </CreateUpdateExerciseModalWrap>
      </CreateUpdateExerciseModalBackground>
    </>
  )
}

export { CreateUpdateExerciseModal }

const CreateUpdateExerciseModalBackground = styled('div')({
  position: 'fixed',
  display: 'table',
  top: 65,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(218,222,230,0.7) 0% 0% no-repeat padding-box',
  zIndex: 500,

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    position: 'fixed',
    top: 50,
    height: 'calc(100% - 100px)',
  },
})

const CreateUpdateExerciseModalWrap = styled('div')({
  display: 'table-cell',
  verticalAlign: 'middle',
})

const CreateUpdateExerciseModalLayout = styled('div')({
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'relative',
  top: -33,
  width: '95%',
  height: '90%',
  maxWidth: 1400,
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 0px 5px #0000000D',
  borderRadius: 25,
  padding: '15px 35px',
  fontFamily: 'Balsamiq Sans',
  overflow: 'auto',

  '::-webkit-scrollbar': {
    display: 'none',
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    padding: '30px 20px',
  },

  '.answer-type-label': {
    color: COLORS.secBlack,
    fontSize: 18,
    marginBottom: 20,

    '.answer-type-error': {
      color: COLORS.secRed,
    },
  },
})

const Topbar = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 25,

  '.title': {
    color: COLORS.secBlack,
    fontSize: 22,
  },

  '.right-content': {
    display: 'flex',
    alignItems: 'center',
    gap: 40,
    marginLeft: 'auto',

    '.close-icon': {
      fontSize: 29,
      color: COLORS.mainGray,
      cursor: 'pointer',
    },
  },
})

const TwoColumnsRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 40,
  marginBottom: 30,
})

const AnswerTypes = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 40,
  marginBottom: 30,

  '.answer-type': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 20,
    width: 180,
    height: 150,
    border: `2px solid ${COLORS.borderGray}`,
    borderRadius: 15,
    cursor: 'pointer',

    '&.selected': {
      borderColor: COLORS.secBlue,

      '.text': {
        color: COLORS.secBlue,
      },
    },

    ':hover': {
      borderColor: COLORS.secBlue,
    },

    img: {
      width: 40,
      height: 40,
    },

    '.text': {
      textAlign: 'center',
      color: COLORS.mainGray,
      fontSize: 16,
    },
  },
})

const FooterButtons = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: 40,
})

const ExerciseFields = styled('div')({})
