import styled from '@emotion/styled'
import { checkIfUsernameExists } from 'platforms/kyoso-hub/services/user'
import {
  Avatar,
  AvatarHolder,
  PersonalizationWrapper,
} from 'platforms/kyoso-hub/shared'
import { hub_selectTheme } from 'platforms/kyoso-hub/store/generalSlice'
import { hub_selectUser } from 'platforms/kyoso-hub/store/userSlice'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import BlueTheme from 'assets/img/themes/blue.svg'
import RedTheme from 'assets/img/themes/red.svg'
import GreenTheme from 'assets/img/themes/green.svg'
import GrayTheme from 'assets/img/themes/gray.svg'
import {
  ConfirmationMessage,
  ContentDescription,
  FormInput,
  PageInfo,
  SubmitButtonHub,
} from 'shared'
import { avatars } from 'styling/avatars'
import { useForm } from 'react-hook-form'
import {
  userNamaValidator,
  whiteSpaceValidator,
} from 'utils/helpers/validators'
import { COLORS, QUERIES, THEMES } from 'styling/variables'

export function PersonalizationForm({ onSubmitHandler }) {
  const currentTheme = useSelector(hub_selectTheme)
  const userData = useSelector(hub_selectUser)

  const [selectedAvatar, setSelectedAvatar] = useState(+userData.avatar)
  const [selectedTheme, setSelectedTheme] = useState(currentTheme)
  const [onSuccess, setOnSuccess] = useState(null)

  const avatarsArray = []

  const {
    register,
    handleSubmit,
    control,
    getValues,
    trigger,
    reset,
    setError,
    setValue,
    formState,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      username: userData.username,
      theme: currentTheme,
      avatar: userData.avatar,
    },
  })

  const { t } = useTranslation()

  const onChangeHandler = (event) => {
    const formValues = getValues(['avatar', 'theme'])

    setSelectedAvatar(formValues.avatar)
    setSelectedTheme(formValues.theme)
  }

  for (let index = 0; index <= 19; index++) {
    avatarsArray.push({
      id: `avatar${index}`,
      name: 'avatar',
      type: 'radio',
      value: index,
      register: register({ required: 'Required' }),
      error: formState.errors.avatar,
      touched: formState.touched.avatar,
      avatar: avatars[index].src,
    })
  }

  const submitHandler = (data) => {
    onSubmitHandler(data, 'changePersonalInfo', setError, setOnSuccess, reset)
  }

  return (
    <>
      <PageInfo
        title={t('hub:Settings:PersonalInfoTitle')}
        subtitle={t('hub:Settings:PersonalInfoSubTitle')}
        customCss={{
          '& h1': {
            marginBottom: 10,
            marginTop: 0,
          },
          '& h2': {
            marginBottom: 30,
          },
        }}
      />

      {onSuccess === 'changePersonalInfo' && (
        <ConfirmationMessage
          color={COLORS.secBlue}
          message={t('shared:ChangedSuccessfuly')}
          removeMessageCallback={() => setOnSuccess(false)}
        />
      )}

      <Form onSubmit={handleSubmit(submitHandler)}>
        <Devider>
          <PersonalizationWrapper>
            <AvatarHolder theme={currentTheme}>
              <Avatar
                src={avatars[selectedAvatar]?.src || avatars[0].src}
              ></Avatar>
            </AvatarHolder>
            <Stretcher>
              <FormInput
                customCss={{ marginBottom: 0 }}
                id="userName"
                type="text"
                name="username"
                label="LabelUsername"
                description="UsernameDescription"
                succesMessage="CorrectInput"
                onChange={(e) => {
                  return { trim: whiteSpaceValidator(e) }
                }}
                onBlur={() => {
                  return trigger('username')
                }}
                register={register({
                  validate: {
                    usernameValidation: (value) => userNamaValidator(value),
                    checkIfUserNameExists: async (value) => {
                      if (value) {
                        return await checkIfUsernameExists(
                          value,
                          userData.id,
                        ).catch((error) => `${error.statCode}`)
                      }
                    },
                  },
                })}
                error={formState.errors.username}
                touched={formState.touched.username}
              />
            </Stretcher>

            <ThemesWrapper>
              <label htmlFor="theme1">
                <input
                  id="theme1"
                  name="theme"
                  type="radio"
                  value="0"
                  ref={register()}
                  onChange={(e) => onChangeHandler(e)}
                />
                <img src={BlueTheme} />
                <span className="checkmark"></span>
              </label>

              <label htmlFor="theme2">
                <input
                  id="theme2"
                  name="theme"
                  type="radio"
                  value="1"
                  ref={register()}
                  onChange={(e) => onChangeHandler(e)}
                />
                <img src={RedTheme} />
                <span className="checkmark"></span>
              </label>

              <label htmlFor="theme3">
                <input
                  id="theme3"
                  name="theme"
                  type="radio"
                  value="2"
                  ref={register()}
                  onChange={(e) => onChangeHandler(e)}
                />
                <img src={GreenTheme} />
                <span className="checkmark"></span>
              </label>

              <label htmlFor="theme4">
                <input
                  id="theme4"
                  name="theme"
                  type="radio"
                  value="3"
                  ref={register()}
                  onChange={(e) => onChangeHandler(e)}
                />
                <img src={GrayTheme} />
                <span className="checkmark"></span>
              </label>
            </ThemesWrapper>
          </PersonalizationWrapper>
          <ContentDescription>
            {t('hub:Onboarding:AvatarDescription')}
          </ContentDescription>{' '}
          <RadioButtonWrapper>
            {avatarsArray.map((avatarInput) => {
              return (
                <label key={avatarInput.id}>
                  <AvatarRadioInput
                    key={avatarInput.id}
                    id={avatarInput.id}
                    name={avatarInput.name}
                    type={avatarInput.type}
                    value={avatarInput.value}
                    ref={avatarInput.register}
                    onChange={(e) => onChangeHandler(e)}
                    theme={currentTheme}
                  />
                  <Avatar
                    customCss={{
                      cursor: 'pointer',
                      width: '50px',
                      height: '50px',
                      borderRadius: '50%',
                      border: `1px solid ${COLORS.mainGray} `,
                    }}
                    src={avatarInput.avatar}
                  ></Avatar>
                </label>
              )
            })}
          </RadioButtonWrapper>
        </Devider>
        <SubmitButtonHub
          id="personalization"
          customCss={{
            widht: 90,
            height: 90,
          }}
          theme={currentTheme}
          type="submit"
        />
      </Form>
    </>
  )
}

const Stretcher = styled('div')(({ customCss }) => ({
  gridColumn: '2 / 4',
  ...customCss,
}))

const Form = styled('form')(({ customCss }) => ({
  display: 'grid',
  gridTemplateColumns: '5fr 1fr',
  placeItems: 'center',

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const Devider = styled('div')(({ customCss }) => ({}))

const ThemesWrapper = styled('div')(({ customCss }) => ({
  gridRow: '2',
  gridColumn: '2 / 4',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-evenly',

  label: {
    position: 'relative',
  },

  input: {
    display: 'none',
  },

  img: {
    opacity: 0.5,

    '&:hover': {
      cursor: 'pointer',
    },
  },

  'input:checked ~ .checkmark': {
    display: 'block',
  },

  'input:checked ~ img': {
    opacity: 1,
  },

  '.checkmark': {
    content: "''",
    position: 'absolute',
    display: 'none',
    left: '25%',
    top: '55%',
    width: '15px',
    height: '25px',
    border: 'solid white',
    borderWidth: '0 6px 6px 0',
    transform: 'rotate(45deg) translate(-50%,-50%)',
  },
}))

const RadioButtonWrapper = styled('div')(({ customCss }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginBottom: '30px',
  '& label': {
    marginLeft: '10px',
  },
}))

const AvatarRadioInput = styled('input')(({ customCss, theme }) => ({
  position: 'absolute',
  opacity: '0',
  width: '0',
  height: '0',
  ':checked + img': {
    border: `3px solid ${THEMES[+theme]}`,
  },
}))
