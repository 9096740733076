import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NoImgPlaceholder from 'assets/img/no_img_placeholder.png'
import {
  AnnuledDeclarationWarning,
  ContentTwoColumsLayout,
  FirstColumn,
  ImageWrapper,
  PageInfo,
  SecondColumn,
  TitleIconWrapper,
} from 'shared'
import { COLORS, QUERIES, THEMES } from 'styling/variables'
import { getFileLinkFromServerStorage } from 'utils/helpers/common'
import { GenericButton } from '../shared'
import { useDispatch, useSelector } from 'react-redux'
import { hub_selectTheme, hub_setPage } from '../store/generalSlice'
import { useHistory } from 'react-router-dom'
import { getCompetitionsResults } from '../services/competition'
import {
  getParticipationDataForCompetitionModal,
  saveParticipationDataForCompetitionModal,
} from '../services/competitions'
import { EditCompetitionModal } from '../components/competitions/EditCompetitionModal'

function ResultsPage() {
  const dispatch = useDispatch()
  const routeHistory = useHistory()
  const currentTheme = useSelector(hub_selectTheme)
  const { t } = useTranslation()
  const [numOfEventsToLoad, setNumOfEventsToLoad] = useState(4)
  const [eventsWithResults, setEventsWithResults] = useState(null)
  const [eventsWithoutResults, setEventsWithoutResults] = useState(null)
  const [editModal, setEditModal] = useState(null)

  useEffect(() => {
    dispatch(
      hub_setPage({
        title: t('hub:Results:ResultsTitle'),
        subtitle: '',
      }),
    )
  }, [])

  useEffect(() => {
    fetchCompetitionsResults()
  }, [numOfEventsToLoad])

  const fetchCompetitionsResults = () => {
    getCompetitionsResults()
      .then((response) => {
        const compWithResults = response.competitionsWithResults.splice(
          0,
          numOfEventsToLoad,
        )

        setEventsWithResults(compWithResults)
        setEventsWithoutResults(response.competitionsWithoutResults)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const loadOlderEventsHandler = () => {
    const newEventsToLoad = 4
    setNumOfEventsToLoad((prevState) => prevState + newEventsToLoad)
  }

  const personalResultsHandler = (childId) => {
    routeHistory.push(`/hub/results/personal/${childId}`)
  }

  const rankingCompetitonHandler = (childId) => {
    routeHistory.push(`/hub/results/ranking/${childId}`)
  }

  const openEditModalHandler = ({
    child_id,
    ...competitionInfo
  }) => async () => {
    const { participation } = await getParticipationDataForCompetitionModal(
      child_id,
    )

    setEditModal({ ...participation, ...competitionInfo, child_id })
  }

  const saveEditCompetitionModal = (
    participationId,
    newUserData,
    isDataTouched,
  ) => {
    if (isDataTouched) {
      saveParticipationDataForCompetitionModal(participationId, {
        newUserData: JSON.stringify(newUserData),
        isTouchedData: isDataTouched,
      })
        .then((res) => fetchCompetitionsResults())
        .catch((err) => console.log(err))
    }
  }

  const closeModalHandler = () => {
    fetchCompetitionsResults()
    setEditModal(null)
  }

  const declarationWarningMsgRenderer = ({
    is_invalid_declaration,
    is_confirmed_declaration,
    declaration_id,
    ...rest
  }) => {
    let declarationStatus = (
      <AnnuledDeclarationWarning
        onClick={openEditModalHandler(rest)}
        className="not-signed"
      >
        <i className="fas fa-info-circle"></i>
        {t('hub:Competitions:NotSignedDeclarationWarning')}
      </AnnuledDeclarationWarning>
    )

    if (is_invalid_declaration) {
      declarationStatus = (
        <AnnuledDeclarationWarning onClick={openEditModalHandler(rest)}>
          <i className="fas fa-exclamation-triangle"></i>
          {t('hub:Competitions:AnnuledDeclarationWarning')}
        </AnnuledDeclarationWarning>
      )
    } else if (is_confirmed_declaration) {
      declarationStatus = (
        <AnnuledDeclarationWarning
          className="valid"
          onClick={openEditModalHandler(rest)}
        >
          <i className="fas fa-check-circle"></i>
          {t('hub:Competitions:ValidDeclarationWarning')}
        </AnnuledDeclarationWarning>
      )
    } else if (!!declaration_id) {
      declarationStatus = (
        <AnnuledDeclarationWarning
          className="signed"
          onClick={openEditModalHandler(rest)}
        >
          <i className="fas fa-info-circle"></i>
          {t('hub:Competitions:SignedDeclarationWarning')}
        </AnnuledDeclarationWarning>
      )
    }

    return declarationStatus
  }

  return (
    <>
      {editModal && (
        <EditCompetitionModal
          closeModalCallback={closeModalHandler}
          onSubmitCallback={saveEditCompetitionModal}
          modalData={editModal}
        />
      )}

      <TitleIconWrapper>
        <i className="far fa-chart-bar"></i>

        <PageInfo title={t('hub:Results:ResultsTitle')} />
      </TitleIconWrapper>

      <ContentTwoColumsLayout>
        <FirstColumn>
          <PageInfo
            customCss={{ h2: { color: COLORS.secBlack, marginBottom: 10 } }}
            subtitle={t('hub:Results:EventsWithResults')}
          />

          <Container>
            {eventsWithResults ? (
              eventsWithResults.map((event, index) => {
                return (
                  <Event id="event-wrapper" key={index} theme={currentTheme}>
                    <ImageWrapper
                      customCss={{ maxWidth: 100, height: 100, margin: 0 }}
                      className="img-wrapper"
                    >
                      <img
                        src={
                          event?.parent_picture
                            ? getFileLinkFromServerStorage(
                                event?.parent_picture,
                              )
                            : NoImgPlaceholder
                        }
                        alt="exc-img"
                      />
                    </ImageWrapper>

                    <div className="content-wrapper">
                      <p className="content-title">
                        {t('hub:Competitions:CompetitionTitle', {
                          parent_name: event.parent_name,
                          grade: event.grade,
                        })}
                      </p>
                      <p className="content-subtitle">
                        {t('hub:Competitions:CompetitionSubtitle', {
                          subject: event.subject,
                          year: event.year,
                          competition_type: event.parent_type,
                        })}
                        <br />
                        {t('hub:Competitions:ParticipantId')}
                        <strong>{event.participant_id}</strong>
                      </p>
                      <div className="button-wrapper">
                        <GenericButton
                          theme={currentTheme}
                          className="inverted"
                          onClick={() => personalResultsHandler(event.child_id)}
                        >
                          {t('hub:Results:Representation')}
                        </GenericButton>
                        {event.is_ranking_public ? (
                          <GenericButton
                            theme={currentTheme}
                            onClick={() =>
                              rankingCompetitonHandler(event.child_id)
                            }
                          >
                            {t('hub:Results:Ranking')}
                          </GenericButton>
                        ) : null}
                      </div>
                      {declarationWarningMsgRenderer(event)}
                    </div>
                    <GenericButton
                      className="inverted"
                      theme={currentTheme}
                      customCss={{
                        margin: 0,
                        minWidth: 45,
                        minHeight: 45,
                      }}
                      onClick={openEditModalHandler(event)}
                    >
                      <i className="fas fa-pencil-alt"></i>
                    </GenericButton>
                    {/* <div className="points">
                      <span>
                        {t('hub:Results:Points', {
                          points: 30,
                          total_points: 100,
                        })}
                      </span>
                    </div> */}
                  </Event>
                )
              })
            ) : (
              <p className="red">{t('hub:Results:MissingEvents')}</p>
            )}
            <span className="load-events" onClick={loadOlderEventsHandler}>
              {t('hub:Results:LoadEvents')}
            </span>
          </Container>
        </FirstColumn>

        <SecondColumn>
          <PageInfo
            customCss={{ h2: { color: COLORS.secBlack, marginBottom: 10 } }}
            subtitle={t('hub:Results:EventsWithoutResults')}
          />
          <Container>
            {eventsWithoutResults ? (
              eventsWithoutResults.map((event, index) => {
                return (
                  <Event
                    id="event-wrapper"
                    key={index}
                    theme={currentTheme}
                    customCss={{ display: 'flex' }}
                  >
                    <div className="content-wrapper">
                      <p className="content-title">
                        {t('hub:Competitions:CompetitionTitle', {
                          parent_name: event.parent_name,
                          grade: event.grade,
                        })}
                      </p>
                      <p className="content-subtitle">
                        {t('hub:Competitions:CompetitionSubtitle', {
                          subject: event.subject,
                          year: event.year,
                          competition_type: event.parent_type,
                        })}
                        <br />
                        {t('hub:Competitions:ParticipantId')}
                        <strong>{event.participant_id}</strong>
                      </p>
                      {declarationWarningMsgRenderer(event)}
                    </div>

                    <GenericButton
                      className="inverted"
                      theme={currentTheme}
                      customCss={{
                        margin: 0,
                        minWidth: 45,
                        minHeight: 45,
                      }}
                      onClick={openEditModalHandler(event.child_id, event)}
                    >
                      <i className="fas fa-pencil-alt"></i>
                    </GenericButton>
                  </Event>
                )
              })
            ) : (
              <p className="red">{t('hub:Results:MissingEvents')}</p>
            )}
          </Container>
        </SecondColumn>
      </ContentTwoColumsLayout>
    </>
  )
}

export { ResultsPage }

const Event = styled('div')(({ theme, customCss }) => {
  return {
    border: `1px solid ${COLORS.borderGray}`,
    placeItems: 'center',
    borderRadius: 10,
    padding: 15,
    display: 'grid',
    gridTemplateColumns: '100px 2fr 100px',
    gap: 15,

    '.content-wrapper': {
      justifySelf: 'start',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: 10,

      '.content-title': {
        fontWeight: 600,
      },
      '.button-wrapper': {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 20,

        button: { margin: 0, width: 'auto' },
      },
    },

    '.points': {
      color: THEMES[+theme],
      fontSize: 25,
    },

    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      display: 'flex',
      flexDirection: 'column',

      '.content-wrapper': {
        alignItems: 'center',
      },

      '.button-wrapper': {
        justifyContent: 'center',
      },
    },
    ...customCss,
  }
})

const Container = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,

    '.load-events': {
      alignSelf: 'center',
      textDecoration: 'underline',
      cursor: 'pointer',
    },

    '.red': {
      color: COLORS.secRed,
    },

    [`@media (max-width: ${QUERIES.mobile}px)`]: {},
  }
})
