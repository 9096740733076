import styled from '@emotion/styled'
import { SubmitButtonLab } from 'platforms/kyoso-lab/shared'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EscButton, ModalBackground, ModalLayout } from 'shared'
import { COLORS } from 'styling/variables'
import { getSchoolGradesHook } from 'utils/helpers/hooks'

function CompetitionsModal({ modalData, closeModalCallback }) {
  const [competitions, setCompetitions] = useState([])

  useEffect(() => {
    const selectedCompetitions = [
      ...modalData.used_events_ids,
      ...modalData.selected_events_ids,
    ]

    setCompetitions(
      modalData.competitions
        .filter((competition) =>
          selectedCompetitions.some((com) => +com === competition.id),
        )
        .map((competition) => ({
          ...competition,
          is_used: modalData.used_events_ids.includes(competition.id),
        }))
        .sort((a, b) => +b.is_used - +a.is_used),
    )
  }, [])

  const { t } = useTranslation()

  return (
    <ModalBackground>
      <ModalLayout
        customCss={{ width: 'auto', minWidth: '300px', height: 'auto' }}
      >
        <EscButton onClick={closeModalCallback} />

        <Header>
          <div id="particitipations">
            <p>
              <span>
                {modalData.participations - modalData.used_events_ids.length}
              </span>{' '}
              / <span>{modalData.participations}</span>
            </p>
            <p>{t('shared:Participations')}</p>
          </div>

          <div id="participant-info">
            <p>{modalData.participant_info.full_name}</p>
            <p>{modalData.order_id}</p>
          </div>
        </Header>

        <ContentWrapper>
          {competitions.map((competition) => {
            return (
              <div
                key={competition.id}
                className={competition.is_used ? 'has-passed' : null}
              >
                <span>{competition.id}</span> - <span>{competition.name}</span>{' '}
                - <span>{competition.year}</span> -{' '}
                <span>{competition.grade_name}</span>
              </div>
            )
          })}
        </ContentWrapper>

        <SubmitButtonLab
          onClick={closeModalCallback}
          customCss={{ maxWidth: 200 }}
          className="black"
        >
          {t('shared:Modals:ModalCloseButton')}
        </SubmitButtonLab>
      </ModalLayout>
    </ModalBackground>
  )
}

export { CompetitionsModal }

const Header = styled('div')({
  display: 'flex',
  gap: 20,
  marginBottom: 20,

  '#particitipations': {
    color: COLORS.secBlack,
    textAlign: 'center',

    'p:nth-of-type(1)': {
      fontSize: 24,
    },
  },

  '#participant-info': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    'p:nth-of-type(1)': {
      color: COLORS.secBlack,
      fontSize: 20,
    },
    'p:nth-of-type(2)': {
      color: COLORS.mainGray,
    },
  },
})

const ContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  color: COLORS.secBlue,
  gap: 10,
  marginBottom: 20,

  '.has-passed': {
    color: COLORS.secBlack,
    textDecoration: 'line-through',
  },
})
