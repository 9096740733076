import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { COLORS } from '../../../styling/variables'
import LabLogo from '../../../assets/img/lab-logo.svg'
import { LoginForm } from '../components/login/LoginForm'
import { ForgottenPasswordForm } from '../components/login/ForgottenPassword'
import { useHistory, useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import { lab_showSharedModal } from '../store/generalSlice'

export function LandingPage() {
  const { t } = useTranslation()
  const [isForgottenPasswordView, setForgottenPasswordView] = useState(false)
  const routeLocation = useLocation()
  const routeHistory = useHistory()
  const dispatch = useDispatch()

  const forgottenPasswordHandler = (e) => {
    e.preventDefault()
    setForgottenPasswordView((prevState) => !prevState)
  }

  useEffect(() => {
    if (new URLSearchParams(routeLocation.search).get('unauthorized')) {
      dispatch(
        lab_showSharedModal({
          title: t('shared:UnauthorizedModal:Title'),
          content: t('shared:UnauthorizedModal:Content'),
          iconClass: 'fas fa-user-clock',
          unauthorized: true,
        }),
      )
      routeHistory.push('/lab')
    }
  }, [])

  return (
    <Layout>
      <img src={LabLogo} alt="lab_logo" />

      {isForgottenPasswordView ? (
        <ForgottenPasswordForm changeViewHandler={forgottenPasswordHandler} />
      ) : (
        <LoginForm changeViewHandler={forgottenPasswordHandler} />
      )}

      <p>
        {t('lab:ContactSupport')}{' '}
        <a
          href="mailto:support@kyoso.tech"
          target="_blank"
          rel="noopener noreferrer"
        >
          support@kyoso.tech
        </a>
      </p>

      <hr />

      <p>
        {t('lab:ContactForReservation')}
        <a href="mailto:support@kyoso.tech">
          {t('lab:ContactReservationLink')}
        </a>
      </p>
    </Layout>
  )
}

const Layout = styled('div')(({ customCss, theme }) => ({
  textAlign: 'center',

  '& img': {
    padding: '0 40px',
    marginBottom: '40px',
    maxWidth: '260px',
  },

  '& form': {
    marginBottom: '40px',
  },

  '& p': {
    color: `${COLORS.mainGray}`,
    textAlign: 'center',
    marginBottom: '80px',

    '& a': {
      color: `${COLORS.secBlack}`,
      fontWeight: 'bold',
    },
  },

  '& p:last-of-type': {
    marginBottom: '0px',
  },
}))
