import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FormInput, SubmitButtonHub } from '../../../../shared/index'
import {
  emailValidation,
  whiteSpaceValidator,
  handleSetFormErrors,
} from '../../../../utils/helpers/validators'
import { hub_loginUser } from '../../store/userSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { COLORS } from '../../../../styling/variables'
import { LandingPageFormGrid, LandingPageTitle } from '../../shared/index'

function LoginForm() {
  const routeHistory = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation(['hub'])
  const { register, handleSubmit, setError, trigger, formState } = useForm({
    mode: 'onSubmit',
  })

  const onSubmitLoginHandler = (data) => {
    dispatch(
      hub_loginUser({ email: data.email, password: data.password, setError }),
    )
      .then(unwrapResult)
      .then((res) => {
        if (res.is_onboarded) {
          routeHistory.replace('/hub/dashboard')
        } else {
          routeHistory.replace('/hub/onboarding/1')
        }
      })
      .catch((error) => {
        handleSetFormErrors(error.statCode, setError)
      })
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmitLoginHandler)}>
        <LandingPageTitle color={COLORS.secRed}>{t('Login')}</LandingPageTitle>
        <LandingPageFormGrid>
          <div className="left">
            <FormInput
              id="emailLogin"
              type="text"
              name="email"
              label="hub:LabelEmail"
              onChange={(e) => whiteSpaceValidator(e)}
              register={register({
                required: 'Required',
                validate: {
                  emailValidation: (value) => emailValidation(value),
                },
              })}
              error={formState.errors.email}
              touched={formState.touched.email}
            ></FormInput>

            <FormInput
              id="passwordLogin"
              type="password"
              name="password"
              label="hub:LabelPassword"
              showPassword={true}
              onChange={(e) => {
                return {
                  trim: whiteSpaceValidator(e),
                }
              }}
              onBlur={() => {
                return trigger('password')
              }}
              register={register({
                required: 'Required',
              })}
              error={formState.errors.password}
              touched={formState.touched.password}
            ></FormInput>
          </div>

          <SubmitButtonHub id="loginSubmit" theme={1} />
        </LandingPageFormGrid>
      </form>
    </div>
  )
}

export { LoginForm }
