import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import bgTrans from '../assets/i18n/bg.json'

const resources = {
  bg: bgTrans,
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'bg',
  fallbackLng: 'bg',
  debug: false,

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
})

export default i18n
