import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { lab_selectUser } from '../../store/userSlice'
import styled from '@emotion/styled'
import { COLORS, QUERIES } from 'styling/variables'
import { subjectAndQuestionValidation } from 'utils/helpers/validators'
import {
  PageInfo,
  FormInput,
  ConfirmationMessage,
  FormTextArea,
} from 'shared/index'
import { SubmitButtonLab } from 'platforms/kyoso-lab/shared'
import { sendInquiry } from 'platforms/kyoso-lab/services/general'
import { useState } from 'react'

function SupportForm() {
  const { register, handleSubmit, formState, reset } = useForm({
    mode: 'onBlur',
  })
  const [isInquirySent, setIsInquirySent] = useState(false)
  const reduxUser = useSelector(lab_selectUser)
  const { t } = useTranslation()

  const onSubmit = (data) => {
    sendInquiry(reduxUser.id, data.subject, data.issue)
      .then(() => {
        setIsInquirySent(true)
        reset()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageInfo
          title={t('shared:Support:FormHeader')}
          subtitle={t('shared:Support:FormHeaderDescription')}
          customCss={{
            '& h1': {
              marginBottom: 10,
            },
            '& h2': {
              marginBottom: 20,
            },
          }}
        />

        {isInquirySent && (
          <ConfirmationMessage
            color={COLORS.secBlue}
            message={t('shared:Support:InquirySent')}
            removeMessageCallback={() => setIsInquirySent(false)}
          />
        )}

        <FormWrapper>
          <FormInput
            customCss={{ gridColumn: '1 / 5' }}
            id="subject"
            type="text"
            name="subject"
            label="LabelSubject"
            register={register({
              required: 'Required',
              validate: {
                subjectAndQuestionValidation: (value) =>
                  subjectAndQuestionValidation(value),
              },
            })}
            error={formState.errors.subject}
            touched={formState.touched.subject}
          ></FormInput>

          <InputCenterAndStrech>
            <FormTextArea
              id="issueDescription"
              name="issue"
              label="shared:LabelIssue"
              register={register({
                required: 'Required',
                validate: {
                  subjectAndQuestionValidation: (value) =>
                    subjectAndQuestionValidation(value),
                },
              })}
              error={formState.errors.issue}
            />
          </InputCenterAndStrech>
          <SubmitButtonLab
            id="supportSubmit"
            className="black"
            type="submit"
            customCss={{
              gridColumn: '4/ 5',

              [`@media (max-width: ${QUERIES.tablet}px)`]: {
                gridColumn: '2 /4',
              },
              [`@media (max-width: ${QUERIES.mobile}px)`]: {
                gridColumn: '1 /5',
              },
            }}
          >
            {t('lab:Support:Send')}
          </SubmitButtonLab>
        </FormWrapper>
      </form>
    </div>
  )
}

export { SupportForm }

const FormWrapper = styled('div')({
  display: 'grid',
  gap: 20,
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
})

const InputCenterAndStrech = styled('div')({
  gridColumn: '1 / 5',
})

const TextArea = styled('textarea')({
  marginTop: 5,
  border: `2px solid ${COLORS.mainGray}`,
  color: COLORS.secBlue,
  borderRadius: 5,
  height: 300,
  width: '100%',
  boxSizing: 'border-box',
  fontSize: 16,
  paddingLeft: 15,
  transition: '0.3s all',
  resize: 'vertical',
  '&:hover': {
    borderColor: COLORS.primBlue,
  },
  '&:focus': {
    borderColor: COLORS.secBlue,
  },
  '&.input-error': {
    borderColor: COLORS.secRed,
  },
  '&.input-success': {
    borderColor: COLORS.secGreen,
  },
})
