import { useParams, useHistory, useLocation, NavLink } from 'react-router-dom'
import React from 'react'
import { confirmUserEmail } from '../services/authentication'
import { Login } from '../components/login/Login'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import RegisterSpriteImg from '../../../assets/img/hub_register-button.svg'
import LoginSpriteImg from '../../../assets/img/hub_login-button.svg'
import { Registration } from '../components/registration/Registration'
import { QUERIES } from 'styling/variables'
import { useDispatch } from 'react-redux'
import { hub_showSharedModal } from '../store/generalSlice'

function LandingPage() {
  const dispatch = useDispatch()
  const routeHistory = useHistory()
  const routeLocation = useLocation()
  const { action } = useParams()
  const confirmEmailHash = new URLSearchParams(routeLocation.search).get('hash')
  const [confirmEmailStatus, setConfirmEmailStatus] = React.useState(null)
  const { t } = useTranslation(['shared'])

  React.useEffect(() => {
    if (new URLSearchParams(routeLocation.search).get('unauthorized')) {
      dispatch(
        hub_showSharedModal({
          title: t('shared:UnauthorizedModal:Title'),
          content: t('shared:UnauthorizedModal:Content'),
          iconClass: 'fas fa-user-clock',
          unauthorized: true,
        }),
      )
      routeHistory.push('/hub/login')
    }

    if (action === 'login') {
      if (routeLocation.state?.confirmEmailStatus) {
        setConfirmEmailStatus(routeLocation.state.confirmEmailStatus)
        window.history.replaceState({}, '')
      }

      if (confirmEmailHash) {
        confirmUserEmail(confirmEmailHash)
          .then((result) => {
            routeHistory.replace('/hub/login', { confirmEmailStatus: result })
          })
          .catch((error) => {
            routeHistory.replace('/hub/login', { confirmEmailStatus: error })
          })
      }
    }
  }, [action, routeLocation.state?.confirmEmailStatus])

  return (
    <HubLandingPage>
      {action === 'login' ? (
        <Login confirmEmailStatus={confirmEmailStatus} />
      ) : (
        <NavLink to="/hub/login" replace>
          <NavSprite>
            <img src={LoginSpriteImg} />
            <span>{t('Login')}</span>
          </NavSprite>
        </NavLink>
      )}
      {action === 'register' ? (
        {/* <Registration /> */}
      ) : (
        <div>Демо версията не включва регистрация.</div>
        /* <NavLink to="/hub/register" replace>
          <NavSprite>
            <img src={RegisterSpriteImg} />
            <span>{t('Register')}</span>
          </NavSprite>
        </NavLink> */
      )}
    </HubLandingPage>
  )
}

export { LandingPage }

const HubLandingPage = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-evenly',
  padding: '20px',
  flexWrap: 'wrap',
  alignItems: 'center',

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    padding: 10,
    margin: 10,
  },
})

const NavSprite = styled('div')({
  position: 'relative',
  '& img': {
    width: '100%',
    maxHeight: 456,
    transition: '0.3s all',
  },
  '& span': {
    position: 'absolute',
    left: '50%',
    top: '50%',
    fontSize: 36,
    color: 'white',
    textDecoration: 'none',
    transform: 'translate(-50%, -50%)',
  },
  '&:hover img': {
    transform: 'rotate(7deg)',
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    padding: 30,

    span: {
      fontSize: 24,
    },
  },
})
