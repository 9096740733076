import { COLORS } from './variables'

export const content_style = `
  @import url("https://fonts.googleapis.com/css2?family=Balsamiq+Sans&family=Montserrat");

  .title-1 {
    font-family: Balsamiq Sans;
    font-size: 30px;
  }

  .title-2 {
    font-family: Balsamiq Sans;
    font-size: 26px;
  }
  
  .title-3 {
    font-family: Balsamiq Sans;
    font-size: 22px;
  }
  
  .title-4 {
    font-family: Balsamiq Sans;
    font-size: 18px;
  }


  .paragraph-1 {
    font-family: Montserrat;
    font-size: 22px;
  }

  .paragraph-2 {
    font-family: Montserrat;
    font-size: 18px;
  }

  .paragraph-3 {
    font-family: Montserrat;
    font-size: 16px;
  }

  .paragraph-4 {
    font-family: Montserrat;
    font-size: 14px;
  }

  .overline { 
    text-decoration: overline;
  }

  body {
    font-family: Montserrat;
    font-size: 16px;
    color: ${COLORS.secBlack};
  }

`
