import Avatar1 from '../assets/img/avatars/avatar_1.svg'
import Avatar2 from '../assets/img/avatars/avatar_2.svg'
import Avatar3 from '../assets/img/avatars/avatar_3.svg'
import Avatar4 from '../assets/img/avatars/avatar_4.svg'
import Avatar5 from '../assets/img/avatars/avatar_5.svg'
import Avatar6 from '../assets/img/avatars/avatar_6.svg'
import Avatar7 from '../assets/img/avatars/avatar_7.svg'
import Avatar8 from '../assets/img/avatars/avatar_8.svg'
import Avatar9 from '../assets/img/avatars/avatar_9.svg'
import Avatar10 from '../assets/img/avatars/avatar_10.svg'
import Avatar11 from '../assets/img/avatars/avatar_11.svg'
import Avatar12 from '../assets/img/avatars/avatar_12.svg'
import Avatar13 from '../assets/img/avatars/avatar_13.svg'
import Avatar14 from '../assets/img/avatars/avatar_14.svg'
import Avatar15 from '../assets/img/avatars/avatar_15.svg'
import Avatar16 from '../assets/img/avatars/avatar_16.svg'
import Avatar17 from '../assets/img/avatars/avatar_17.svg'
import Avatar18 from '../assets/img/avatars/avatar_18.svg'
import Avatar19 from '../assets/img/avatars/avatar_19.svg'
import Avatar20 from '../assets/img/avatars/avatar_20.svg'

export const avatars = [
  { id: 1, src: Avatar1 },
  { id: 2, src: Avatar2 },
  { id: 3, src: Avatar3 },
  { id: 4, src: Avatar4 },
  { id: 5, src: Avatar5 },
  { id: 6, src: Avatar6 },
  { id: 7, src: Avatar7 },
  { id: 8, src: Avatar8 },
  { id: 9, src: Avatar9 },
  { id: 10, src: Avatar10 },
  { id: 11, src: Avatar11 },
  { id: 12, src: Avatar12 },
  { id: 13, src: Avatar13 },
  { id: 14, src: Avatar14 },
  { id: 15, src: Avatar15 },
  { id: 16, src: Avatar16 },
  { id: 17, src: Avatar17 },
  { id: 18, src: Avatar18 },
  { id: 19, src: Avatar19 },
  { id: 20, src: Avatar20 },
]
