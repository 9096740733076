import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { EscButton, TinyTextContentPreview } from 'shared'
import { COLORS, QUERIES } from 'styling/variables'
import { getParentPreview } from '../services/kyoso/parents'
import { SubmitButtonLab } from '../shared'
import { format } from 'date-fns'
import { bg } from 'date-fns/locale'
import NoImgPlaceholder from 'assets/img/no_img_placeholder.png'
import {
  lab_hideGlobalLoading,
  lab_showGlobalLoading,
} from '../store/generalSlice'
import { useDispatch } from 'react-redux'
import { getFileLinkFromServerStorage } from 'utils/helpers/common'
import moment from 'moment'
import { useLocation } from 'react-router-dom'

function KyosoParentPreviewPage() {
  const params = useParams()
  const routeHistory = useHistory()
  const routeLocation = useLocation()
  const [parentInfo, setParentInfo] = useState({})
  const [infoText, setInfoText] = useState('#closed')

  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(lab_showGlobalLoading())
    getParentPreview(params.parentId)
      .then((response) => setParentInfo(response.parent))
      .catch((error) => {
        console.log(error)
      })
      .finally(() => dispatch(lab_hideGlobalLoading()))
  }, [])

  function isInProgress() {
    const intervals = parentInfo?.parent_intervals
      ? JSON.parse(parentInfo.parent_intervals)
      : null
    if (intervals) {
      let isInProgressFlag = false
      intervals.forEach((int) => {
        const now = moment.utc()
        if (now >= moment(+int.startDate) && now <= moment(+int.endDate)) {
          isInProgressFlag = true
          return
        }
      })
      return isInProgressFlag
    } else return false
  }

  return (
    <KyosoParentPreviewLayout>
      {infoText !== '#closed' && (
        <InfoModalBackground>
          <InfoModalWrap>
            <InfoModalLayout>
              <EscButton onClick={() => setInfoText('#closed')} />
              <div className="content">
                <TinyTextContentPreview html={infoText} />
              </div>
            </InfoModalLayout>
          </InfoModalWrap>
        </InfoModalBackground>
      )}
      <EscButton onClick={() => routeHistory.push('/lab/dashboard/kyoso')} />

      <GeneralInfo>
        <div className="picture">
          <img
            src={
              parentInfo.parent_picture
                ? getFileLinkFromServerStorage(parentInfo.parent_picture)
                : NoImgPlaceholder
            }
            alt="parent-img"
            style={{ width: '100%' }}
          />
        </div>
        <div className="data">
          <div className="title">
            <VisibilityIcon
              status={parentInfo.parent_visibility}
              isinprogress={isInProgress()}
            />
            <span>{parentInfo.parent_name}</span>
          </div>
          <div className="subtitle">
            {parentInfo.subject_name} - {parentInfo.parent_year}г. -{' '}
            {parentInfo.type_name}
          </div>
          {parentInfo.parent_intervals
            ? JSON.parse(parentInfo.parent_intervals).map((interval, index) => (
                <div className="interval" key={index}>
                  <i className="far fa-calendar-alt"></i>
                  <span>
                    {format(
                      moment(+interval.startDate)
                        .local()
                        .toDate(),
                      'PP, p',
                      {
                        locale: bg,
                      },
                    )}{' '}
                    -{' '}
                    {format(
                      moment(+interval.endDate)
                        .local()
                        .toDate(),
                      'PP, p',
                      {
                        locale: bg,
                      },
                    )}
                  </span>
                </div>
              ))
            : null}
          {parentInfo.parent_paid ? (
            <div className="price">
              <i className="far fa-money-bill-alt"></i>
              <span data-paidstatus={parentInfo.parent_paid}>
                {parentInfo.parent_price
                  ? t('shared:Currency', { price: parentInfo.parent_price })
                  : parentInfo.parent_paid === '0'
                  ? t('shared:Unpaid')
                  : t('shared:PricePerChildLabel')}
              </span>
            </div>
          ) : null}
        </div>
        <div className="actions">
          <SubmitButtonLab
            className="black"
            type="button"
            customCss={{ width: 150 }}
            onClick={() =>
              routeHistory.push(
                `/lab/dashboard/kyoso/parent/update/${params.parentId}`,
              )
            }
          >
            <i className="fas fa-pen"></i>
            <span>{t('lab:Kyoso:SettingsWidgetEdit')}</span>
          </SubmitButtonLab>
          <SubmitButtonLab
            className="red"
            type="button"
            customCss={{ width: 150 }}
          >
            <i className="fas fa-trash"></i>
            <span>{t('lab:Kyoso:SettingsWidgetDelete')}</span>
          </SubmitButtonLab>
        </div>
      </GeneralInfo>

      <DescriptionPart>
        <TinyTextContentPreview
          html={parentInfo.parent_description}
          // onLoadMathJaxHandler={onLoadMathJaxHandler}
          id="tiny-content-description-parent"
          imgZoom
        />
      </DescriptionPart>

      <div className="children-title">{t('lab:Shared:TitleGrades')}</div>

      {parentInfo.children?.length ? (
        parentInfo.children.map((child, index) => (
          <ChildBox key={index}>
            <div className="title">
              {child.child_id} - {child.grade_name}
            </div>
            <div className="child-info">
              <div className="data">
                <div className="duration">
                  <i className="fas fa-stopwatch"></i>
                  <span>
                    {child.child_duration || '-'}{' '}
                    {t('shared:MinutesLabel').toLowerCase()}
                  </span>
                </div>
                {child.child_has_modules ? (
                  <div className="modules">
                    <div className="title">
                      {t('lab:KyosoChild:ModulesLabel')}
                    </div>
                    {child.modules.map((module, index) => (
                      <div className="module" key={index}>
                        <i className="fas fa-hourglass-half"></i>
                        <span>
                          {module.module_duration}{' '}
                          {t('shared:MinutesShortLabel').toLowerCase()} (
                          {module.module_name})
                        </span>
                      </div>
                    ))}
                  </div>
                ) : null}
                {child.child_paid !== null ? (
                  <div className="price">
                    <i className="far fa-money-bill-alt"></i>
                    <span data-paidstatus={child.child_paid}>
                      {child.child_paid === 1
                        ? t('shared:Currency', {
                            price: child.child_price,
                          })
                        : t('shared:Unpaid')}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="links">
                <div onClick={() => setInfoText(child.child_instructions)}>
                  {t('lab:KyosoChild:InstructionsLabel')}
                </div>
                <div onClick={() => setInfoText(child.child_submit_message)}>
                  {t('lab:KyosoChild:SubmitMessageLabel')}
                </div>
                <div onClick={() => setInfoText(child.child_rules_text)}>
                  {t('lab:KyosoChild:RulesTextLabel')}
                </div>
                <div
                  onClick={() =>
                    child.child_rules_link &&
                    window.open(
                      getFileLinkFromServerStorage(child.child_rules_link),
                      '_blank',
                    )
                  }
                >
                  {t('lab:KyosoChild:RulesLinkLabel')}
                </div>
              </div>
              <div className="actions">
                <SubmitButtonLab
                  className="black"
                  type="button"
                  customCss={{ width: 150 }}
                  onClick={() =>
                    routeHistory.push(
                      `/lab/dashboard/kyoso/child/${child.child_id}/preview`,
                    )
                  }
                >
                  <i className="fas fa-info-circle"></i>
                  <span>{t('shared:Details')}</span>
                </SubmitButtonLab>
                <SubmitButtonLab
                  className="black"
                  type="button"
                  customCss={{ width: 150 }}
                  onClick={() =>
                    routeHistory.push(
                      `/lab/dashboard/kyoso/child/${child.child_id}`,
                    )
                  }
                >
                  <i className="fas fa-pen"></i>
                  <span>{t('lab:Kyoso:SettingsWidgetEdit')}</span>
                </SubmitButtonLab>
                <SubmitButtonLab
                  className="red"
                  type="button"
                  customCss={{ width: 150 }}
                >
                  <i className="fas fa-trash"></i>
                  <span>{t('lab:Kyoso:SettingsWidgetDelete')}</span>
                </SubmitButtonLab>
              </div>
            </div>
          </ChildBox>
        ))
      ) : (
        <div className="no-children">
          {t('lab:KyosoParentPreview:NoChildrenLabel')}
        </div>
      )}
    </KyosoParentPreviewLayout>
  )
}

export { KyosoParentPreviewPage }

const InfoModalBackground = styled('div')({
  position: 'fixed',
  display: 'table',
  top: 65,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(218,222,230,0.7) 0% 0% no-repeat padding-box',
  zIndex: 1000,

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    position: 'fixed',
    top: 50,
    height: 'calc(100% - 100px)',
  },
})
const InfoModalWrap = styled('div')({
  display: 'table-cell',
  verticalAlign: 'middle',
})

const InfoModalLayout = styled('div')({
  position: 'relative',
  top: -33,
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '95%',
  maxWidth: 710,
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 0px 5px #0000000D',
  borderRadius: 25,
  padding: '30px 60px',
  fontFamily: 'Balsamiq Sans',

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    padding: '30px 20px',
  },

  '& > .content': {
    color: COLORS.secBlack,
    fontSize: 18,
    textAlign: 'center',
  },
})

const KyosoParentPreviewLayout = styled('div')({
  '& .children-title': {
    marginBottom: 15,
    color: COLORS.secBlack,
    fontSize: 20,
  },

  '& .no-children': {
    color: COLORS.secBlack,
    fontSize: 20,
    textAlign: 'center',
  },
})

const VisibilityIcon = styled('div')((props) => {
  let color = ''
  if (props.status === '2') color = COLORS.secBlack
  else if (props.isinprogress) color = COLORS.secGreen
  else if (props.status === '0') color = COLORS.secRed
  else if (props.status === '1') color = COLORS.secYellow

  return {
    display: 'inline-block',
    verticalAlign: 'baseline',
    width: 20,
    height: 20,
    borderRadius: '50%',
    marginRight: 10,
    backgroundColor: color,
  }
})

const GeneralInfo = styled('div')({
  display: 'flex',
  marginBottom: 40,

  '& .picture': {
    width: 220,
    height: 220,
    marginRight: 20,
    // border: '1px solid #707070',
    position: 'relative',

    img: {
      width: '100%',
      height: 'auto',
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)',
    },
  },

  '& .data': {
    display: 'flex',
    flexDirection: 'column',

    '& .title': {
      color: COLORS.secBlack,
      fontSize: 26,
    },

    '& .subtitle': {
      color: COLORS.secBlack,
      fontSize: 20,
      marginBottom: 15,
    },

    '& .interval, & .price': {
      color: COLORS.mainGray,
      fontSize: 16,
      marginBottom: 5,

      i: {
        marginRight: 5,
      },
    },

    '& .price': {
      marginTop: 15,

      '& span[data-paidstatus="0"]': {
        color: COLORS.secGreen,
      },
      '& span[data-paidstatus="2"]': {
        color: COLORS.secBlue,
      },
    },
  },

  '& .actions': {
    display: 'flex',
    alignSelf: 'center',
    gap: 30,
    marginLeft: 'auto',

    i: {
      marginRight: 10,
    },
  },
})

const DescriptionPart = styled('div')({
  marginBottom: 30,
  color: COLORS.secBlack,
  fontSize: 18,
})

const ChildBox = styled('div')({
  border: `1px solid ${COLORS.borderGray}`,
  borderRadius: 15,
  padding: 20,
  marginBottom: 20,

  '& > .title': {
    marginBottom: 20,
    color: COLORS.secBlack,
    fontSize: 20,
  },

  '& .child-info': {
    display: 'flex',

    '& .data': {
      display: 'flex',
      flexDirection: 'column',
      width: 300,

      '& .duration': {
        color: COLORS.secBlue,
        fontSize: 16,
        marginBottom: 10,

        i: {
          marginRight: 5,
        },
      },

      '& .modules': {
        '& .title': {
          color: COLORS.secBlack,
          fontSize: 16,
          marginBottom: 5,
        },

        '& .module': {
          color: COLORS.mainGray,
          fontSize: 16,
          marginBottom: 5,

          i: {
            marginRight: 5,
          },
        },
      },

      '& .price': {
        marginTop: 15,
        color: COLORS.mainGray,
        fontSize: 16,

        i: {
          marginRight: 5,
        },

        '& span[data-paidstatus="0"]': {
          color: COLORS.secGreen,
        },
      },
    },

    '& .links': {
      display: 'flex',
      flexDirection: 'column',
      gap: 5,

      '& div': {
        color: COLORS.secBlue,
        fontSize: 16,
        cursor: 'pointer',
      },
      '& div:hover': {
        textDecoration: 'underline',
      },
    },

    '& .actions': {
      display: 'flex',
      alignSelf: 'center',
      gap: 30,
      marginLeft: 'auto',

      i: {
        marginRight: 10,
      },
    },
  },
})
