import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { hub_selectPage, hub_selectTheme } from '../store/generalSlice'
import { hub_logOutUser, hub_selectUser } from '../store/userSlice'
import { avatars } from '../../../styling/avatars'
import { useTranslation } from 'react-i18next'
import { IconButton } from '../shared/index'
import { COLORS, THEMES } from '../../../styling/variables'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import hub_logo from 'assets/img/hub-logo.svg'

function MobileHeader() {
  const currentPage = useSelector(hub_selectPage)
  const currentTheme = useSelector(hub_selectTheme)
  const reduxUser = useSelector(hub_selectUser)
  const dispatch = useDispatch()
  const routeHistory = useHistory()
  const [openedWidgetDropdown, setOpenedWidgetDropdown] = useState(null)
  const profileWidget = useRef(null)
  const profileWidgetMenu = useRef(null)
  const notificationsWidget = useRef(null)
  const notificationsWidgetMenu = useRef(null)
  const { t } = useTranslation()

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideOfWidgetsMenu)

    return () => {
      document.removeEventListener('click', handleClickOutsideOfWidgetsMenu)
    }
  }, [openedWidgetDropdown])

  function handleClickOutsideOfWidgetsMenu(event) {
    if (
      (openedWidgetDropdown === 'profile' &&
        !profileWidgetMenu.current?.contains(event.target) &&
        !notificationsWidget.current?.contains(event.target)) ||
      (openedWidgetDropdown === 'notifications' &&
        !notificationsWidgetMenu.current?.contains(event.target) &&
        !profileWidget.current?.contains(event.target))
    ) {
      setOpenedWidgetDropdown(null)
    }
  }

  function goBack() {
    window.history.back()
  }

  function logOutUser() {
    dispatch(hub_logOutUser(reduxUser.id)).finally(() => {
      routeHistory.push('/hub')
    })
  }

  return (
    <HubHeader id="mobile-hub-header">
      <WidgetsContainer id="hub-header-widgets">
        <IconButton
          ref={notificationsWidget}
          id="hub-header-support"
          onClick={(e) => routeHistory.push('/hub/support')}
          customCss={{ width: '100%' }}
        >
          <i className="far fa-question-circle"></i>
        </IconButton>

        <HubLogo src={hub_logo} alt="hub_logo" />

        <ProfileWidget
          ref={profileWidget}
          id="hub-header-profile"
          theme={currentTheme}
          onClick={() =>
            openedWidgetDropdown !== 'profile' &&
            setOpenedWidgetDropdown('profile')
          }
        >
          <div id="hub-header-profile-avatar">
            <img
              src={avatars[reduxUser.avatar]?.src || avatars[0].src}
              alt="avatar"
            />
          </div>

          <div id="hub-header-profile-arrow">
            <i className="fas fa-chevron-down"></i>
          </div>
          {openedWidgetDropdown === 'profile' && (
            <div ref={profileWidgetMenu} id="hub-header-profile-menu">
              {/* <div
                onClick={() => {
                  routeHistory.push('/hub/payments')
                }}
              >
                <i className="fas fa-coins"></i>
                <span>{t('shared:Payments')}</span>
              </div> */}
              <div
                onClick={() => {
                  routeHistory.push('/hub/settings')
                  setOpenedWidgetDropdown(null)
                }}
              >
                <i className="fas fa-cogs"></i>
                <span>{t('shared:Settings')}</span>
              </div>
              <div onClick={() => logOutUser()}>
                <i className="fas fa-door-open"></i>
                <span>{t('shared:Exit')}</span>
              </div>
            </div>
          )}
        </ProfileWidget>
      </WidgetsContainer>
    </HubHeader>
  )
}

export { MobileHeader }

const HubLogo = styled('img')({
  margin: '0',
  width: 100,
  height: 42,
})

const HubHeader = styled('div')({
  gridArea: 'header',
  height: 50,
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 0px 5px #0000001A',
  display: 'flex',
  flexFlow: 'row no-wrap',
  position: 'sticky',
  top: 0,
  zIndex: 599,
})

const WidgetsContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '0.5fr 2fr 0.5fr',
  placeItems: 'center',
  width: '100%',
})

const ProfileWidget = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,
  height: '100%',
  padding: '0 10px',
  cursor: 'pointer',

  '& #hub-header-profile-avatar': {
    width: 35,
    height: 35,
    borderRadius: '50%',
    border: `3px solid ${THEMES[+theme]}`,

    '& img': {
      width: '100%',
      height: '85%',
      marginTop: '15%',
    },
  },

  '& #hub-header-profile-names': {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Balsamiq Sans',
    marginRight: 15,

    '& span:first-of-type': {
      fontSize: 18,
      color: COLORS.secBlack,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: 150,
    },

    '& span:last-of-type': {
      fontSize: 16,
      color: COLORS.mainGray,
    },
  },

  '& #hub-header-profile-arrow': {
    display: 'flex',
    fontSize: 18,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: COLORS.secBlack,
  },

  '& #hub-header-profile-menu': {
    position: 'absolute',
    top: 51,
    right: 0,
    // width: '100vw',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 5px #0000001A',
    zIndex: 1001,
    borderRadius: '0px 0px 15px 15px',
    overflow: 'hidden',

    '& div': {
      padding: '15px 30px',
      display: 'flex',
      alignItems: 'center',
      fontSize: 18,

      // '&:nth-of-type(1)': {
      //   color: '#F8D030',
      //   borderBottom: '1px solid #0000001A',
      // },
      '&:nth-of-type(1)': {
        color: THEMES[+theme],
        borderBottom: '1px solid #0000001A',
      },
      '&:nth-of-type(2)': {
        color: '#FFFFFF',
        backgroundColor: THEMES[+theme],
      },

      '& i': {
        width: 28,
        fontSize: 24,
        marginRight: 20,
      },
    },
  },
}))
