import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { COLORS, QUERIES } from 'styling/variables'
import { FiltrationBundlesForm } from '../components/kyoso-bundles/FiltrationBundlesForm'
import { FiltrationBundlesFormMobile } from '../components/kyoso-bundles/FiltrationBundlesFormMobile'
import { BundlesContent } from '../components/kyoso-bundles/BundlesContent'
import {
  FiltrationAndLegendWrapper,
  KyosoLayout,
  KyosoTitleWrapper,
  SubmitButtonLab,
} from '../shared'
import { getAllBundles } from '../services/kyoso/bundles'
import { useDispatch } from 'react-redux'
import {
  lab_hideGlobalLoading,
  lab_showGlobalLoading,
} from '../store/generalSlice'
import { EscButton } from 'shared'

export function KyosoBundles() {
  const [showFiltration, setShowFiltration] = useState(null)
  const [bundlesFilters, setBundlesFilters] = useState(null)
  const [bundlesContent, setBundlesContent] = useState([])
  const routeHistory = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(lab_showGlobalLoading())
    getAllBundles(bundlesFilters || {})
      .then((response) => {
        setBundlesContent(response.bundles.sort((a, b) => a.id - b.id))
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => dispatch(lab_hideGlobalLoading()))
  }, [bundlesFilters])

  return (
    <KyosoLayout>
      <EscButton onClick={() => routeHistory.goBack()} />

      <KyosoTitleWrapper>
        <h1 id="title">{t('lab:KyosoBundles:KyosoBundlesTitle')}</h1>
        <div id="element-spacer">
          <SubmitButtonLab
            className="white"
            onClick={(e) => routeHistory.push('/lab/dashboard/kyoso')}
            customCss={{
              display: 'inline-block',
              whiteSpace: 'nowrap',
              i: {
                marginRight: '10px',
              },
            }}
          >
            <i className="fas fa-award"></i>
            {t('lab:KyosoBundles:KyosoButton')}
          </SubmitButtonLab>
          <SubmitButtonLab
            onClick={(e) =>
              routeHistory.push('/lab/dashboard/kyoso/bundles/insert')
            }
            className="black"
            customCss={{
              display: 'inline-block',
              minWidth: '150px',
              i: {
                marginRight: '10px',
              },
            }}
          >
            <i className="fas fa-plus"></i>
            {t('lab:Kyoso:CreateButton')}
          </SubmitButtonLab>
        </div>
      </KyosoTitleWrapper>

      <FiltrationAndLegendWrapper>
        <FiltrationBundlesForm submitCallback={setBundlesFilters} />

        {!showFiltration && (
          <SubmitButtonLab
            className="black"
            id="filtrationButton"
            onClick={() => {
              setShowFiltration(!showFiltration)
            }}
          >
            <i className="fas fa-filter"></i>
            {t('lab:Kyoso:SubmitFilterButton')}
          </SubmitButtonLab>
        )}
        {showFiltration && (
          <FiltrationBundlesFormMobile
            submitCallback={setBundlesFilters}
            toggleFilter={(e) => setShowFiltration(false)}
          />
        )}

        <Legend>
          <div>
            <span>{t('lab:KyosoBundles:DraftLegend')}</span>
          </div>
          <div>
            <span>{t('lab:KyosoBundles:AvailableLegend')}</span>
          </div>
        </Legend>
      </FiltrationAndLegendWrapper>

      {bundlesContent && <BundlesContent bundlesContent={bundlesContent} />}
    </KyosoLayout>
  )
}

const Legend = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '10px',
  marginLeft: '0',
  marginBottom: '20px',
  justifyContent: 'left',
  alignItems: 'center',

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    flexDirection: 'column',
    marginLeft: '0',
    marginTop: '0px',
    marginBottom: '20px',
    alignItems: 'start',

    '& div': {
      marginBottom: '10px',
    },
  },

  '& div': {
    display: 'flex',
    alignItems: 'center',
    marginRight: '30px',
  },

  '& span': {
    marginTop: '2px',
    marginLeft: '5px',
  },

  '& div:nth-of-type(1)': {
    color: COLORS.secRed,
  },

  '& div:nth-of-type(2)': {
    color: COLORS.secGreen,
  },
})
