import { SubmitButtonLab } from 'platforms/kyoso-lab/shared'
import { lab_selectUser } from 'platforms/kyoso-lab/store/userSlice'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  ConfirmationMessage,
  ContentTwoColumsLayout,
  FormCheckBox,
  FormInput,
  PageInfo,
  TinyTextEditor,
} from 'shared'
import { COLORS } from 'styling/variables'
import {
  maxLengthValidator,
  minLengthValidator,
} from 'utils/helpers/validators'

export function VariantTwoPaymentForm({ onSubmitHandler }) {
  const { t } = useTranslation()
  const reduxUser = useSelector(lab_selectUser)
  const [onSuccess, setOnSuccess] = useState(null)

  const { register, handleSubmit, control, formState, setError } = useForm({
    mode: 'onBlur',
    defaultValues: {
      bank_info: reduxUser?.bankDetails,
      merchant_id: reduxUser?.KIN,
      secret_word: reduxUser?.secretWord,
      firm_name: reduxUser?.firmName,
      unit_id_code: reduxUser?.UIC,
      address: reduxUser?.address,
      city: reduxUser?.city,
      responsible_person: reduxUser?.responsiblePersonName,
      receiver: reduxUser?.receiver,
      HDS: reduxUser?.HDS,
      invoice_number: reduxUser?.InvoiceStartingNumber,
    },
  })

  const onSubmit = (data) => {
    onSubmitHandler(data, 'paymentInfo', setError, setOnSuccess)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageInfo
          title={t('lab:Settings:PaymentInfoTitle')}
          subtitle={t('lab:Settings:PaymentInfoSubTitle')}
          customCss={{
            marginBottom: 30,
            h1: { marginBottom: 10, fontSize: 20 },
          }}
        />

        {onSuccess === 'paymentInfo' && (
          <ConfirmationMessage
            color={COLORS.secBlue}
            message={t('shared:BankDetailsChangedSuccessfully')}
            removeMessageCallback={() => setOnSuccess(false)}
          />
        )}

        <TinyTextEditor
          id="bank-info"
          name="bank_info"
          label="lab:Settings:LabelBankInfo"
          control={control}
          error={formState.errors.bank_info}
          extendInitOptions={{
            toolbar: [
              'undo redo cut copy paste pastetext | styleselect forecolor fontsize | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent ',
              'subscript superscript | link image table  bullist numlist |',
            ],
          }}
        />

        <FormInput
          id="merchant-id"
          type="text"
          name="merchant_id"
          description={t('lab:Settings:DescriptionMerchantId')}
          label={t('lab:Settings:LabelMerchantId')}
          register={register()}
          error={formState.errors.profile_name}
          touched={formState.touched.profile_name}
        />

        <FormInput
          id="secret-word"
          type="text"
          name="secret_word"
          description={t('lab:Settings:DescriptionSecretWord')}
          label={t('lab:Settings:LabelSecretWord')}
          register={register()}
          error={formState.errors.profile_name}
          touched={formState.touched.profile_name}
        />

        <ContentTwoColumsLayout>
          <div>
            <FormInput
              id="firm-name"
              type="text"
              name="firm_name"
              label={t('lab:Settings:LabelFirmName')}
              register={register()}
              error={formState.errors.firm_name}
              touched={formState.touched.firm_name}
            />

            <FormInput
              id="address"
              type="text"
              name="address"
              label={t('shared:LabelAddress')}
              register={register()}
              error={formState.errors.address}
              touched={formState.touched.address}
            />

            <FormInput
              id="responsible-person"
              type="text"
              name="responsible_person"
              label={t('lab:Settings:LabelResponsiblePerson')}
              register={register()}
              error={formState.errors.responsible_person}
              touched={formState.touched.responsible_person}
            />
          </div>

          <div>
            <FormInput
              id="unit-identification-code"
              type="number"
              name="unit_id_code"
              label={t('lab:Settings:LabelUnitIdCode')}
              register={register()}
              error={formState.errors.unit_id_code}
              touched={formState.touched.unit_id_code}
            />

            <FormInput
              id="city"
              type="text"
              name="city"
              label={t('shared:LabelCity')}
              register={register()}
              error={formState.errors.city}
              touched={formState.touched.city}
            />

            <FormInput
              id="receiver"
              type="text"
              name="receiver"
              label={t('lab:Settings:LabelReceiver')}
              register={register()}
              error={formState.errors.receiver}
              touched={formState.touched.receiver}
            />
          </div>
        </ContentTwoColumsLayout>

        <FormCheckBox
          key="HDS"
          id="HDS"
          name="HDS"
          label={t('lab:Settings:LabelHDS')}
          register={register()}
          customCss={{
            '.text': {
              justifySelf: 'left',
            },
          }}
        />

        {/* <FormInput
          id="invoice-starting-number"
          type="number"
          name="invoice_number"
          label={t('lab:Settings:LabelInvoiceNumber')}
          description={t('lab:Settings:DescriptionInvoiceNumber')}
          register={register({
            validate: {
              minLegth: (value) =>
                minLengthValidator(value, 10, 'ErrorInvoiceNumber'),
              maxLegth: (value) =>
                maxLengthValidator(value, 10, 'ErrorInvoiceNumber'),
            },
          })}
          error={formState.errors.invoice_number}
          touched={formState.touched.invoice_number}
        /> */}

        <SubmitButtonLab className="black">
          {t('lab:Shared:SubmitButtonSave')}
        </SubmitButtonLab>
      </form>
    </>
  )
}
