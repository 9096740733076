import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import {
  ImageWrapper,
  PageInfo,
  TinyTextContentPreview,
  CONSTANTS,
} from 'shared'
import NoImgPlaceholder from 'assets/img/no_img_placeholder.png'
import { useTranslation } from 'react-i18next'
import { COLORS, QUERIES, THEMES } from 'styling/variables'
import { useDispatch, useSelector } from 'react-redux'
import {
  hub_hideGlobalLoader,
  hub_selectTheme,
  hub_showGlobalLoader,
  hub_setPage,
} from '../store/generalSlice'
import { useParams, useHistory } from 'react-router-dom'
import { getPersonalResults } from '../services/participation'
import { getFileLinkFromServerStorage } from 'utils/helpers/common'
import {
  getCompetitionScoreData,
  getCompetitionsResultsInPDF,
  resetCompetition,
} from '../services/competition'
import { GenericButton } from '../shared'

function PersonalResultsPage() {
  const routeHistory = useHistory()
  const dispatch = useDispatch()
  const currentTheme = useSelector(hub_selectTheme)
  const [competitionData, setCompetitionData] = useState(null)
  const [answersVisibility, setAnswersVisibility] = useState(null)
  const [points, setPoints] = useState(null)
  const { childId } = useParams()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(hub_showGlobalLoader())
    Promise.all([getPersonalResults(childId), getCompetitionScoreData(childId)])
      .then((response) => {
        dispatch(hub_setPage({ title: t('hub:PersonalResults:HeaderTitle') }))

        const parsedVisibility = JSON.parse(response[0].data.answers_visibility)

        const parsedData = {
          ...response[0].data,
          media_score: JSON.parse(response[0].data.score),
          user_answers: JSON.parse(response[0].data.user_answers),
          scoring_data: response[1].scoringData,
        }

        const updatedData = {
          long_scoring: parsedData.media_score,
          child_grade_name: parsedData.child_grade_name,
          child_has_modules: parsedData.child_has_modules,
          child_type_name: parsedData.child_type_name,
          child_types_to_show: parsedData.child_types_to_show,
          child_type_id: parsedData.child_type_id,
          owner_name: parsedData.owner_name,
          participant_id: parsedData.participant_id,
          parent_name: parsedData.parent_name,
          parent_picture: parsedData.parent_picture,
          parent_subject_name: parsedData.parent_subject_name,
          parent_year: parsedData.parent_year,
          has_media_score: parsedData.media_score
            ? !!Object.values(parsedData?.media_score)?.length
            : false,
          has_modules: !!parsedData.child_has_modules,
          scoring_data: parsedData.scoring_data,
          total_given_points: parsedData.media_score
            ? parsedData.scoring_data.overallPoints +
              Object.values(parsedData?.media_score).reduce((acc, curr) => {
                const sumOfSubAnswers = Object.values(curr).reduce(
                  (accOne, currOne) => {
                    return accOne + +currOne
                  },
                  0,
                )

                return sumOfSubAnswers + acc
              }, 0)
            : parsedData.scoring_data.overallPoints,

          max_total_points: Object.values(parsedData.scoring_data.data).reduce(
            (acc, curr) => {
              return curr.totalPoints + acc
            },
            0,
          ),
          modules: parsedData.modules?.map((mod) => {
            return {
              module_id: mod.id,
              module_name: mod.name,
              module_exercise_ids: mod.exercisesIds,
              module_exercises: mod.exercisesIds.map((id) =>
                mappedExercises(id, parsedData, mod.id),
              ),
            }
          }),
          exercises:
            !parsedData.child_has_modules &&
            parsedData.exercisesOrder.map((id) =>
              mappedExercises(id, parsedData),
            ),
        }

        const visibleTypes = Object.entries(CONSTANTS.visibility_types).reduce(
          (prev, [key, value]) => {
            const visibleTypes =
              parsedVisibility.types && parsedVisibility.types.split(',')

            return {
              ...prev,
              [key]:
                parsedVisibility?.intervals.end.type === '0'
                  ? visibleTypes?.includes(value) || false
                  : (parsedVisibility.intervals.end.type === '1' &&
                      !(parsedVisibility.intervals.end.date < Date.now()) &&
                      visibleTypes?.includes(value)) ||
                    false,
            }
          },
          {},
        )

        setAnswersVisibility({
          ...visibleTypes,
          intervals: parsedVisibility?.intervals,
        })
        setCompetitionData(updatedData)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => dispatch(hub_hideGlobalLoader()))
  }, [])

  const mappedExercises = (exId, data, modId = null) => {
    const { exercises, answers, user_answers } = data

    const exercise = exercises.find((ex) => ex.id === exId)
    const isTypeTwo = exercise.answer_type === '2'
    const has_modules = !!data.modules?.length
    let exercise_answers
    let exercise_total_points
    let exercise_correct_answers
    let exercise_user_answers
    let exercise_long_scoring

    if (!isTypeTwo) {
      exercise_answers = answers
        .flat()
        .filter((ans) => ans.kyoso_exercise_id === exId)
        .map((ex) => {
          let other_answers = null

          if (ex.is_other) {
            other_answers = JSON.parse(ex.other_answers)
          }

          return {
            ...ex,
            other_answers: other_answers,
          }
        })

      exercise_correct_answers = exercise_answers.reduce((prev, curr) => {
        if (curr.is_correct) {
          return [...prev, curr]
        } else {
          return prev
        }
      }, [])

      exercise_total_points = exercise_answers.reduce((prev, curr) => {
        return curr.points + prev
      }, 0)
    } else {
      exercise_answers = answers
        .flat()
        .filter((ans) => ans.kyoso_exercise_id === exId)
        .map((answer) => {
          return { ...answer, ['answer']: JSON.parse(answer.answer) }
        })

      exercise_correct_answers = exercise_answers

      exercise_total_points = exercise_answers.flat().reduce((prev, curr) => {
        return +curr.points + prev
      }, 0)
    }

    if (has_modules && modId) {
      exercise_user_answers = Object.values(user_answers)
        .find((mod) => mod.module_id === modId)
        .exercises.find((ex) =>
          Object.keys(ex).find(
            (key) => key.startsWith('id') && key.endsWith(`${exId}`),
          ),
        )[`id_${exId}`]

      exercise_long_scoring = data.media_score?.[modId]?.[exId]
    } else {
      exercise_user_answers = Object.values(user_answers).find((ex) =>
        Object.keys(ex).find(
          (key) => key.startsWith('id') && key.endsWith(`${exId}`),
        ),
      )[`id_${exId}`]

      exercise_long_scoring = data.media_score?.[exId]
    }

    return {
      ...exercise,
      long_scoring: exercise_long_scoring,
      ex_answers: exercise_answers,
      ex_total_points: exercise_total_points,
      ex_correct_answers: exercise_correct_answers,
      ex_user_answers: exercise_user_answers,
      ex_media_files: data.mediaFiles?.filter((file) => {
        const fileId = file.name.split('-')[2]

        if (fileId == exercise.id) return file
      }),
    }
  }

  const formatExerciseAnswers = (answerType, exAnswers) => {
    const { answer_types } = CONSTANTS
    let exerciseAnswer

    switch (answerType) {
      case answer_types.SINGLE:
        exerciseAnswer = (
          <TinyTextContentPreview
            id={`tiny-content${exAnswers.id}`}
            key={exAnswers.id}
            html={exAnswers.answer}
            imgZoom
          />
        )
        break
      case answer_types.MULTI:
        exerciseAnswer = (
          <TinyTextContentPreview
            id={`tiny-content${exAnswers.id}`}
            key={exAnswers.id}
            html={exAnswers.answer}
            imgZoom
          />
        )
        break
      case answer_types.OPEN:
        exerciseAnswer = <h2 key={exAnswers.id}>{exAnswers.description}</h2>
        break
      case answer_types.LONG:
        break
      default:
      // console.log(answerType)
    }

    return exerciseAnswer
  }

  const formatUserAnswer = (
    answerType,
    userAnswersIds,
    exAnswers,
    longAnsScoring,
    mediaFiles,
  ) => {
    const { answer_types } = CONSTANTS
    let userInfoAnswers
    const hasAnswer = !!userAnswersIds

    switch (answerType) {
      case answer_types.SINGLE:
        const userAnswer =
          hasAnswer &&
          exAnswers.find((ex) => {
            const userAnswerId =
              typeof userAnswersIds === 'object'
                ? userAnswersIds[0]
                : userAnswersIds

            return ex.id === +userAnswerId
          })
        const correctAnswer = exAnswers.find((ex) => ex.is_correct)

        if (userAnswer.is_other) {
          const userAnswerIsOther =
            typeof userAnswersIds === 'object' && userAnswersIds.slice(1)

          userInfoAnswers = (
            <>
              {answersVisibility.USER_ANSWERS && (
                <div className="user-answers">
                  <span>{t('hub:PersonalResults:YourAnswer')}</span>
                  {userAnswerIsOther ? (
                    userAnswerIsOther.map((answer, index) => {
                      return (
                        <span
                          key={index}
                          className={
                            checkStringMatches(
                              answer,
                              correctAnswer.other_answers[index].answers.map(
                                (ans) => ans.answer,
                              ),
                            )
                              ? 'green'
                              : 'red'
                          }
                        >
                          {answer}
                        </span>
                      )
                    })
                  ) : (
                    <span className="yellow">
                      {t('hub:PersonalResults:MissingAnswer')}
                    </span>
                  )}
                </div>
              )}
              {answersVisibility.CORRECT_ANSWERS && (
                <div className="correct-answers">
                  <span>{t('hub:PersonalResults:CorrectAnswer')}</span>
                  {correctAnswer.is_other ? (
                    correctAnswer.other_answers.map((fields) => {
                      return fields.answers.map((ans, index) => (
                        <div key={index}>
                          <span className="green">{ans.answer}</span>
                          <span>{`(${ans.points})`}</span>
                        </div>
                      ))
                    })
                  ) : (
                    <TinyTextContentPreview
                      className="green"
                      id={`tiny-content-sing-correct-ans${index}`}
                      html={correctAnswer.answer}
                      imgZoom
                    />
                  )}
                </div>
              )}
            </>
          )
        } else {
          userInfoAnswers = (
            <>
              {answersVisibility.USER_ANSWERS && (
                <div className="user-answers">
                  <span>{t('hub:PersonalResults:YourAnswer')}</span>
                  {userAnswer?.answer ? (
                    <TinyTextContentPreview
                      className={userAnswer.is_correct ? 'green' : 'red'}
                      html={userAnswer.answer}
                      id={`tiny-content-sing-user-ans${userAnswer?.id}`}
                      imgZoom
                    />
                  ) : (
                    <span className="yellow">
                      {t('hub:PersonalResults:MissingAnswer')}
                    </span>
                  )}
                </div>
              )}

              {answersVisibility.CORRECT_ANSWERS && (
                <div className="correct-answers">
                  <span>{t('hub:PersonalResults:CorrectAnswer')}</span>
                  {correctAnswer.is_other ? (
                    correctAnswer.other_answers.map((fields) => {
                      return fields.answers.map((ans, index) => (
                        <div key={index}>
                          <span className="green">{ans.answer}</span>
                          <span>{`(${ans.points})`}</span>
                        </div>
                      ))
                    })
                  ) : (
                    <div style={{ display: 'flex' }}>
                      <TinyTextContentPreview
                        className="green"
                        html={correctAnswer.answer}
                        id={`tiny-content-sing-correct-ans${correctAnswer?.id}`}
                        imgZoom
                      />
                      <span>{`(${correctAnswer.points})`}</span>
                    </div>
                  )}
                </div>
              )}
            </>
          )
        }

        break

      case answer_types.MULTI:
        const userAnswers =
          hasAnswer &&
          userAnswersIds.map((id) =>
            exAnswers.find((answer) => answer.id === +id),
          )
        const correctAnswers = exAnswers.filter((ex) => ex.is_correct)

        userInfoAnswers = (
          <>
            {answersVisibility.USER_ANSWERS && (
              <div className="user-answers">
                <span>{t('hub:PersonalResults:YourAnswer')}</span>
                {userAnswers ? (
                  userAnswers.map((answer) => {
                    return (
                      <TinyTextContentPreview
                        id={`tiny-content-multi-user-ans${answer.id}`}
                        className={answer.is_correct ? 'green' : 'red'}
                        key={answer.id}
                        html={answer.answer}
                        imgZoom
                      />
                    )
                  })
                ) : (
                  <span className="yellow">
                    {t('hub:PersonalResults:MissingAnswer')}
                  </span>
                )}
              </div>
            )}

            {answersVisibility.CORRECT_ANSWERS && (
              <div className="correct-answers">
                <span>{t('hub:PersonalResults:CorrectAnswer')}</span>
                {correctAnswers.map((answer) => {
                  return (
                    <div className="correct-answer " key={answer.id}>
                      <TinyTextContentPreview
                        id={`tiny-content-multi-correct-ans${answer.id}`}
                        className="green"
                        html={answer.answer}
                        imgZoom
                      />

                      <span>{`(${answer.points})`}</span>
                    </div>
                  )
                })}
              </div>
            )}
          </>
        )

        break
      case answer_types.OPEN:
        userInfoAnswers = (
          <>
            {answersVisibility.USER_ANSWERS && (
              <div className="user-answers-open">
                {userAnswersIds
                  ? Object.values(userAnswersIds).map((answer, index) => {
                      let answers

                      if (answer) {
                        answers = (
                          <div key={index}>
                            <span
                              className={
                                checkStringMatches(
                                  answer,
                                  exAnswers[index].answer.map(
                                    (ans) => ans.answer,
                                  ),
                                )
                                  ? 'green'
                                  : 'red'
                              }
                            >
                              {answer}
                            </span>
                          </div>
                        )
                      } else {
                        answers = (
                          <span key={index} className="yellow">
                            {t('hub:PersonalResults:MissingAnswer')}
                          </span>
                        )
                      }

                      return (
                        <div className="user-answers" key={index}>
                          <span>{`${t('hub:PersonalResults:YourAnswer')} ${
                            exAnswers[index].description
                          } :`}</span>
                          {answers}
                        </div>
                      )
                    })
                  : exAnswers.map((ans) => (
                      <div className="user-answers">
                        <span>{`${t('hub:PersonalResults:YourAnswer')} ${
                          ans.description
                        } :`}</span>

                        <span className="yellow">
                          {t('hub:PersonalResults:MissingAnswer')}
                        </span>
                      </div>
                    ))}
              </div>
            )}

            {answersVisibility.CORRECT_ANSWERS && (
              <div className="correct-answers-open">
                {exAnswers.map((ans, index) => {
                  return (
                    <div key={index} className="correct-answers">
                      <span>{t('hub:PersonalResults:CorrectAnswer')}</span>
                      {ans.answer.map((deepAns, deepIndex) => (
                        <div key={deepIndex}>
                          <span className="green">{deepAns.answer}</span>
                          <span>{`(${deepAns.points})`}</span>
                        </div>
                      ))}
                    </div>
                  )
                })}
              </div>
            )}
          </>
        )

        break
      case answer_types.LONG:
        userInfoAnswers = (
          <>
            {answersVisibility.USER_ANSWERS && (
              <div className="user-answers-open">
                {userAnswersIds ? (
                  Object.entries(userAnswersIds).map(([key, value], index) => {
                    const answerId = key.substring(key.indexOf('_') + 1)
                    const answerPoints = longAnsScoring?.[answerId]
                    const answerMediaFiles = mediaFiles?.filter((file) => {
                      const fileId = file.name.split('-')[3]

                      if (fileId == answerId) return file
                    })
                    let answers

                    if (value || answerMediaFiles?.length) {
                      answers = (
                        <div>
                          {' '}
                          <TinyTextContentPreview
                            id={`tiny-content-long-user-ans${answerId}`}
                            key={index}
                            html={value}
                            imgZoom
                          />
                          <div className="mediafiles-wrapper">
                            {answerMediaFiles?.map((media) => {
                              if (media.isImage) {
                                return (
                                  <div>
                                    {/* <img
                                    className="zoom"
                                    key={media.name}
                                    src={getFileLinkFromServerStorage(
                                      media.path,
                                    )}
                                    alt="smth"
                                  /> */}
                                    <a
                                      href={getFileLinkFromServerStorage(
                                        media.path,
                                      )}
                                      target="_blank"
                                    >
                                      Прикаченo изображение
                                    </a>
                                  </div>
                                )
                              } else {
                                return (
                                  <div key={media.name}>
                                    {/* <PdfViewer document={media.path} /> */}
                                    <a
                                      href={getFileLinkFromServerStorage(
                                        media.path,
                                      )}
                                      target="_blank"
                                    >
                                      Прикачен PDF
                                    </a>
                                  </div>
                                )
                              }
                            })}
                          </div>
                        </div>
                      )
                    } else {
                      answers = (
                        <span key={index} className="yellow">
                          {t('hub:PersonalResults:MissingAnswer')}
                        </span>
                      )
                    }

                    return (
                      <div className="correct-ans-points-wrapper" key={index}>
                        <div className="long-answer-type">
                          <span>{t('hub:PersonalResults:YourAnswer')}</span>
                          {'  '}
                          {answers}
                        </div>
                        <div className="long-answer-points">
                          <span>{t('hub:PersonalResults:GivenPoints')}</span>
                          <span className="yellow">({answerPoints})</span>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className="correct-ans-points-wrapper">
                    <span>{t('hub:PersonalResults:YourAnswer')}</span>
                    <span className="yellow">
                      {t('hub:PersonalResults:MissingAnswer')}
                    </span>
                  </div>
                )}
              </div>
            )}
          </>
        )

        break
      default:
      // console.log(answerType)
    }

    return userInfoAnswers
  }

  const checkStringMatches = (strOne, arrayOfStr) => {
    return arrayOfStr.some((str) => ` ${strOne} `.includes(` ${str.trim()} `))
  }

  const pointsCalculatorHandler = ({ id, answer_type, long_scoring }) => {
    const { answer_types } = CONSTANTS

    const {
      scoring_data: { data, ...rest },
    } = competitionData

    let validClassName

    if (answer_type === answer_types.LONG) {
      const totalPoints =
        long_scoring &&
        Object.values(long_scoring).reduce((acc, curr) => {
          return acc + +curr
        }, 0)

      return (
        <div className={`exercise-points yellow `}>{`${
          totalPoints ? totalPoints : t('hub:PersonalResults:MissingPoints')
        }`}</div>
      )
    } else {
      if (data[id]?.points) {
        if (data[id].points && data[id].points === data[id].totalPoints) {
          validClassName = 'green'
        } else {
          validClassName = 'yellow'
        }
      } else {
        validClassName = 'red'
      }

      return (
        <div className={`exercise-points ${validClassName} `}>
          {`${data?.[id]?.points} / ${data?.[id]?.totalPoints} `}
        </div>
      )
    }
  }

  const resetCompetitionHandler = () => {
    resetCompetition(childId)
      .then((res) => routeHistory.push(`/hub/competitions/${childId}`))
      .catch((err) => console.log(err))
  }

  const downloadResultsInPDF = () => {
    dispatch(hub_showGlobalLoader())
    getCompetitionsResultsInPDF(competitionData)
      .then((response) => {
        const contentDisposition = 'attachment; filename="my_results.pdf"'
        const contentType = 'application/pdf'
        const filename = contentDisposition.match(/filename="(.+)"/)[1]
        const file = new Blob([response.data], {
          type: contentType,
        })
        // For Internet Explorer and Edge
        if ('msSaveOrOpenBlob' in window.navigator) {
          window.navigator.msSaveOrOpenBlob(file, filename)
        }
        // For Firefox and Chrome
        else {
          // Bind blob on disk to ObjectURL
          const data = URL.createObjectURL(file)
          const a = document.createElement('a')
          a.style = 'display: none'
          a.href = data
          a.download = filename
          document.body.appendChild(a)
          a.click()
          // For Firefox
          setTimeout(function () {
            document.body.removeChild(a)
            // Release resource on disk after triggering the download
            window.URL.revokeObjectURL(data)
          }, 100)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => dispatch(hub_hideGlobalLoader()))
  }

  const overallPointsRenderer = () => {
    const {
      total_given_points,
      max_total_points,
      has_media_score,
    } = competitionData

    return (
      <OverallPoints>
        <span>
          {t('hub:PersonalResults:OverallPoints', {
            points: has_media_score
              ? total_given_points
              : `${total_given_points}  / ${max_total_points}`,
          })}
        </span>
      </OverallPoints>
    )
  }

  return (
    competitionData &&
    answersVisibility && (
      <Container>
        <PageHeader>
          <div className="page-info">
            <ImageWrapper
              customCss={{ maxWidth: 100, height: 100, margin: 0 }}
              className="img-wrapper"
            >
              <img
                src={
                  competitionData?.parent_picture
                    ? getFileLinkFromServerStorage(
                        competitionData.parent_picture,
                      )
                    : NoImgPlaceholder
                }
                alt="exc-img"
              />
            </ImageWrapper>
            <div className="content-wrapper">
              <p className="content-title">
                {t('hub:Competitions:CompetitionTitle', {
                  parent_name: competitionData.parent_name,
                  grade: competitionData.child_grade_name,
                })}
              </p>
              <p className="content-subtitle">
                {t('hub:Competitions:CompetitionSubtitle', {
                  subject: competitionData.parent_subject_name,
                  year: competitionData.parent_year,
                  competition_type: competitionData.child_type_name,
                })}
                <br />
                {t('hub:Competitions:ParticipantId')}
                <strong>{competitionData.participant_id}</strong>
              </p>
              <p className="content-owner">
                {t('hub:Competitions:CompetitionOwnerInfo', {
                  competition_owner: competitionData.owner_name,
                })}
              </p>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              gap: 20,
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            {competitionData.child_type_id == '5' ? (
              <div>
                <GenericButton
                  theme={currentTheme}
                  onClick={resetCompetitionHandler}
                  customCss={{
                    margin: 0,
                    display: 'flex',
                    gap: 10,
                    alignItems: 'baseline',
                  }}
                >
                  <i className="fas fa-undo-alt"></i>
                  {t('hub:PersonalResults:ResetBtn')}
                </GenericButton>
              </div>
            ) : null}

            <GenericButton
              className="inverted"
              theme={currentTheme}
              onClick={() => downloadResultsInPDF()}
              customCss={{
                margin: 0,
                display: 'flex',
                gap: 10,
                alignItems: 'baseline',
                position: 'relative',
              }}
            >
              <i className="fas fa-download"></i>
              {t('hub:PersonalResults:DownloadBtn')}
            </GenericButton>
          </div>
        </PageHeader>
        <PageInfo
          customCss={{
            h1: { color: THEMES[+currentTheme], textAlign: 'center' },
          }}
          title={t('hub:PersonalResults:HeaderTitle')}
        />
        {overallPointsRenderer()}
        {!(
          !answersVisibility.QUESTIONS &&
          !answersVisibility.USER_ANSWERS &&
          !answersVisibility.CORRECT_ANSWERS &&
          !answersVisibility.SOLUTIONS
        ) ? (
          <ContentWrapper>
            {competitionData.has_modules
              ? competitionData.modules.map((module) => {
                  return (
                    <Module key={module.module_id}>
                      <div className="module-header">
                        <hr />
                        <PageInfo
                          className="module-name"
                          title={module.module_name}
                        />
                        <hr />
                      </div>

                      {module.module_exercises.map((exercise, index) => {
                        return (
                          <Exercise key={exercise.id} theme={currentTheme}>
                            <div className="exercise-header">
                              <h3 className="exercise-title">
                                {t('hub:CompetitionView:ExerciseNumber', {
                                  exNum: index + 1,
                                })}
                              </h3>
                              {pointsCalculatorHandler(exercise)}
                            </div>

                            {answersVisibility.QUESTIONS && (
                              <TinyTextContentPreview
                                id={`tiny-content-question${exercise.id}`}
                                className="exercise-content"
                                html={exercise.description}
                                imgZoom
                                // onLoadMathJaxHandler={onLoadMathJaxHandler}
                              />
                            )}

                            <div className="exercise-answers">
                              {exercise.answer_type !== '3' &&
                                exercise.answer_type !== '2' && (
                                  <span>
                                    {t('hub:PersonalResults:Answers')}
                                  </span>
                                )}

                              {exercise.answer_type !== '3' &&
                                exercise.answer_type !== '2' &&
                                exercise.ex_answers.map((answer) => {
                                  return formatExerciseAnswers(
                                    exercise.answer_type,
                                    answer,
                                  )
                                })}
                            </div>

                            <div className="user-correct-answers">
                              {formatUserAnswer(
                                exercise.answer_type,
                                exercise.ex_user_answers,
                                exercise.ex_answers,
                                exercise.long_scoring,
                                exercise.ex_media_files,
                              )}
                            </div>

                            {answersVisibility.SOLUTIONS && exercise.solution && (
                              <div className="exercise-solution">
                                <h3 className="solution-title">
                                  {t('hub:PersonalResults:SolutionTitle')}
                                </h3>
                                <TinyTextContentPreview
                                  id={`tiny-content-solution-ans${exercise.id}`}
                                  className="solution-content"
                                  html={exercise.solution}
                                  imgZoom
                                />
                              </div>
                            )}

                            {module.module_exercises.length - 1 !== index && (
                              <hr />
                            )}
                          </Exercise>
                        )
                      })}
                    </Module>
                  )
                })
              : competitionData.exercises.map((exercise, index) => {
                  return (
                    <Exercise key={exercise.id} theme={currentTheme}>
                      <div className="exercise-header">
                        <h3 className="exercise-title">
                          {t('hub:CompetitionView:ExerciseNumber', {
                            exNum: index + 1,
                          })}
                        </h3>
                        <div className="exercise-points">
                          {pointsCalculatorHandler(exercise)}
                        </div>
                      </div>

                      {answersVisibility.QUESTIONS && (
                        <TinyTextContentPreview
                          id={`exercise-content-question${exercise.id}`}
                          className="exercise-content"
                          html={exercise.description}
                          imgZoom
                          // onLoadMathJaxHandler={onLoadMathJaxHandler}
                        />
                      )}

                      <div className="exercise-answers">
                        {exercise.answer_type !== '3' &&
                          exercise.answer_type !== '2' && (
                            <span>{t('hub:PersonalResults:Answers')}</span>
                          )}
                        {exercise.answer_type !== '3' &&
                          exercise.answer_type !== '2' &&
                          exercise.ex_answers.map((answer) => {
                            return formatExerciseAnswers(
                              exercise.answer_type,
                              answer,
                            )
                          })}
                      </div>

                      <div className="user-correct-answers">
                        {formatUserAnswer(
                          exercise.answer_type,
                          exercise.ex_user_answers,
                          exercise.ex_answers,
                          exercise.long_scoring,
                          exercise.ex_media_files,
                        )}
                      </div>

                      {answersVisibility.SOLUTIONS && exercise.solution && (
                        <div className="exercise-solution">
                          {t('hub:PersonalResults:SolutionTitle')}
                          <TinyTextContentPreview
                            id={`exercise-content-solution${exercise.id}`}
                            className="solution-content"
                            html={exercise.solution}
                            imgZoom
                            // onLoadMathJaxHandler={onLoadMathJaxHandler}
                          />
                        </div>
                      )}
                      {competitionData.exercises.length - 1 !== index && <hr />}
                    </Exercise>
                  )
                })}

            {overallPointsRenderer()}
          </ContentWrapper>
        ) : (
          <p className="red">
            {t('hub:PersonalResults:ExercisesNotAvailableToView')}
          </p>
        )}
      </Container>
    )
  )
}

export { PersonalResultsPage }

const Exercise = styled('div')(({ theme }) => {
  return {
    // marginBottom: 40,
    display: 'flex',
    flexDirection: 'column',

    gap: 20,

    hr: {
      width: '70%',
      border: `1px solid ${COLORS.borderGray}`,
      alignSelf: 'center',
    },

    '.red': {
      color: COLORS.secRed,
    },

    '.green': {
      color: COLORS.secGreen,
    },

    '.yellow': {
      color: COLORS.secYellow,
    },

    '.gray': {
      color: COLORS.mainGray,
    },

    '.exercise-header': {
      display: 'flex',
      justifyContent: 'space-between',

      '.exercise-title': {
        fontSize: 21,
      },
    },

    '.exercise-content': {
      minHeight: 100,
    },

    '.exercise-solution': {
      '.solution-title': {
        color: THEMES[+theme],
        marginBottom: 20,
      },

      '.solution-content': {
        minHeight: 100,
      },
    },

    '.exercise-answers': {
      display: 'flex',
      gap: 20,
      // flexWrap: 'warp',
    },

    '.user-correct-answers': {
      display: 'flex',
      gap: 30,
      justifyContent: 'space-between',

      '.user-answers-open, .correct-answers-open': {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        // flexWrap: 'warp',
        width: '100%',

        '.correct-ans-points-wrapper': {
          display: 'flex',
          // flexDirection: 'column',
          gap: 30,
          flexWrap: 'wrap',
          justifyContent: 'space-between',

          '.answer-header': {
            display: 'flex',
            justifyContent: 'space-between',
          },

          '.long-answer-type': {
            display: 'flex',

            gap: 10,

            '.mediafiles-wrapper': {
              display: 'flex',
              flexWrap: 'wrap',
              gap: 20,

              img: { maxWidth: 300, height: 'auto' },
            },
          },

          '.long-answer-points': {
            width: '50%',
          },
        },
      },

      '.user-answers, .correct-answers': {
        display: 'flex',
        gap: 10,
        width: '100%',

        '.correct-answer': {
          display: 'flex',
        },
      },

      [`@media (max-width: ${QUERIES.mobile}px)`]: {
        flexWrap: 'wrap',
      },
    },
  }
})

const Container = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,

    '.load-events': {
      alignSelf: 'center',
      textDecoration: 'underline',
      cursor: 'pointer',
    },

    '.red': {
      color: COLORS.secRed,
    },

    [`@media (max-width: ${QUERIES.mobile}px)`]: {},
  }
})

const Module = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',

    '.module-header': {
      margin: '50px 0 20px 0',
      display: 'flex',
      justifyContent: 'space-between',

      '.module-name': {
        padding: 10,
        h1: {
          margin: 0,
          color: COLORS.mainGray,
          whiteSpace: 'nowrap',
        },
      },

      hr: {
        border: `1px solid ${COLORS.mainGray}`,
        backgroundColor: COLORS.mainGray,
        width: '100%',
      },
    },
  }
})

const ContentWrapper = styled('div')(({ theme }) => {
  return {}
})

const OverallPoints = styled('div')(({ theme }) => {
  return {
    fontSize: 20,
    textAlign: 'right',
  }
})

const PageHeader = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    gap: 10,
    justifyContent: 'space-between',

    '.page-info': {
      placeItems: 'center',
      display: 'flex',
      gap: 15,

      '.content-wrapper': {
        justifySelf: 'start',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 10,

        '.content-title': {
          fontWeight: 600,
        },

        '.button-wrapper': {
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          gap: 20,

          button: { margin: 0, width: 'auto', minWidth: 'auto' },
        },
      },
    },

    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      display: 'flex',
      flexDirection: 'column',

      '.page-info': {},

      '.correct-ans-points-wrapper': {
        flexWrap: 'wrap',
      },
    },
  }
})
