import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import {
  FiltrationAndLegendWrapper,
  KyosoLayout,
  KyosoTitleWrapper,
} from '../shared'
import { COLORS, QUERIES } from 'styling/variables'
import { FiltrationForm } from '../components/kyoso/FiltrationForm'
import { FiltrationFormMobile } from '../components/kyoso/FiltrationFormMobile'
import { KyosoAccordion } from '../components/kyoso/KyosoAccordion'
import { duplicateParent, getAllParents } from '../services/kyoso/parents'
import { duplicateChild } from '../services/kyoso/children'
import { SubmitButtonLab } from '../shared'
import {
  lab_hideGlobalLoading,
  lab_showGlobalLoading,
} from '../store/generalSlice'
import { useDispatch } from 'react-redux'

function KyosoPage() {
  const { t } = useTranslation()
  const [showFiltration, setShowFiltration] = useState(null)
  const [parentsContent, setParentsContent] = useState([])
  const [parentsFilters, setParentsFilter] = useState(null)
  const routeHistory = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(lab_showGlobalLoading())
    getAllParents(parentsFilters || {})
      .then((response) => {
        setParentsContent(response.parents.sort((a, b) => a.id - b.id))
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => dispatch(lab_hideGlobalLoading()))
  }, [parentsFilters])

  function duplicateEntity(entity, id) {
    switch (entity) {
      case 'parent':
        duplicateParent(id)
          .then(() => setParentsFilter({ ...parentsFilters }))
          .catch((error) => {
            console.log(error)
          })
        break
      case 'child':
        duplicateChild(id).catch((error) => {
          console.log(error)
        })
        break
      default:
        console.error(
          'Wrong entity in function duplicateEntity from Kyoso(LAB): ',
          entity,
        )
        break
    }
  }

  return (
    <KyosoLayout>
      <KyosoTitleWrapper>
        <h1 id="title">{t('lab:Kyoso:KyosoTitle')}</h1>
        <div id="element-spacer">
          <SubmitButtonLab
            className="white"
            onClick={(e) => routeHistory.push('/lab/dashboard/kyoso/bundles')}
            customCss={{
              display: 'inline-block',
              whiteSpace: 'nowrap',
              i: {
                marginRight: '10px',
              },
            }}
          >
            <i className="fas fa-money-check-alt"></i>
            {t('lab:Kyoso:PackagePlansButton')}
          </SubmitButtonLab>
          <SubmitButtonLab
            onClick={(e) =>
              routeHistory.push('/lab/dashboard/kyoso/parent/insert')
            }
            className="black"
            customCss={{
              display: 'inline-block',
              minWidth: '150px',
              i: {
                marginRight: '10px',
              },
            }}
          >
            <i className="fas fa-plus"></i>
            {t('lab:Kyoso:CreateButton')}
          </SubmitButtonLab>
        </div>
      </KyosoTitleWrapper>

      <FiltrationAndLegendWrapper>
        <FiltrationForm submitCallback={setParentsFilter} />

        {!showFiltration && (
          <SubmitButtonLab
            className="black"
            id="filtrationButton"
            onClick={() => {
              setShowFiltration(!showFiltration)
            }}
          >
            <i className="fas fa-filter"></i>
            {t('lab:Kyoso:SubmitFilterButton')}
          </SubmitButtonLab>
        )}
        {showFiltration && (
          <FiltrationFormMobile
            submitCallback={setParentsFilter}
            toggleFilter={(e) => setShowFiltration(false)}
          />
        )}

        <Legend>
          <div>
            <i className="fas fa-circle"></i>
            <span>{t('lab:Kyoso:DraftLegend')}</span>
          </div>
          <div>
            <i className="fas fa-circle"></i>
            <span>{t('lab:Kyoso:HiddenLegend')}</span>
          </div>
          <div>
            <i className="fas fa-circle"></i>
            <span>{t('lab:Kyoso:VisibleLegend')}</span>
          </div>
          <div>
            <i className="fas fa-circle"></i>
            <span>{t('lab:Kyoso:CurrentlyLegend')}</span>
          </div>
        </Legend>
      </FiltrationAndLegendWrapper>

      {parentsContent && (
        <KyosoAccordion
          parents={parentsContent}
          duplicateCallback={duplicateEntity}
        />
      )}
    </KyosoLayout>
  )
}

export { KyosoPage }

const Legend = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '10px',
  marginLeft: '0',
  marginBottom: '20px',
  justifyContent: 'left',
  alignItems: 'center',

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    flexDirection: 'column',
    marginLeft: '0',
    marginTop: '0px',
    marginBottom: '20px',
    alignItems: 'start',

    '& div': {
      marginBottom: '10px',
    },
  },

  '& div': {
    display: 'flex',
    alignItems: 'center',
    marginRight: '30px',
  },

  '& span': {
    marginTop: '2px',
    marginLeft: '5px',
  },

  '& div:nth-of-type(1)': {
    color: COLORS.secBlack,
  },

  '& div:nth-of-type(2)': {
    color: COLORS.secRed,
  },

  '& div:nth-of-type(3)': {
    color: COLORS.secYellow,
  },

  '& div:nth-of-type(4)': {
    color: COLORS.secGreen,
    marginRight: '0px',
  },
})
