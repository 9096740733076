import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { COLORS, QUERIES } from 'styling/variables'
import CompetitionsModuleImg from 'assets/img/competitions_module-background.jpeg'
import { useHistory } from 'react-router-dom'

function DashboardPage() {
  const { t } = useTranslation()
  const routeHistory = useHistory()

  function goToModuleInfo(e) {
    e.stopPropagation()

    window.open('https://duckduckgo.com', 'blank')
  }

  return (
    <DashboardLayout>
      <h1 id="title">{t('lab:Dashboard:AllModulesTitle')}</h1>
      <ModuleCard
        onClick={() => routeHistory.push('/lab/dashboard/kyoso')}
        themeColor={COLORS.secBlue}
      >
        <h2 className="title">{t('lab:Dashboard:CompetitionsModule:Title')}</h2>
        <div className="content">
          {t('lab:Dashboard:CompetitionsModule:Content')}
        </div>
        <i
          onClick={(e) => goToModuleInfo(e)}
          className="info-icon fas fa-info-circle"
        ></i>
      </ModuleCard>
      <h5 id="more-modules">{t('lab:Dashboard:MoreModulesAhead')}</h5>
    </DashboardLayout>
  )
}

const DashboardLayout = styled('div')({
  '& #title': {
    fontSize: 22,
    marginBottom: 25,
    color: COLORS.secBlack,

    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      fontSize: 18,
    },
  },

  '& #more-modules': {
    fontSize: 32,
    color: COLORS.secBlue,
    textAlign: 'center',

    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      fontSize: 22,
    },
  },
})

const ModuleCard = styled('div')((props) => ({
  textDecoration: 'none',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Balsamiq Sans',
  textAlign: 'center',
  width: '100%',
  height: 250,
  border: `1px solid ${COLORS.bgGray}`,
  borderRadius: 25,
  marginBottom: 40,
  background: `transparent url(${CompetitionsModuleImg}) center center/cover no-repeat padding-box`,
  cursor: 'pointer',

  '&:before': {
    content: "''",
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
    display: 'inline-block',
    backgroundColor: '#FFFFFF',
    opacity: 0.6,
    borderRadius: 25,
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    marginBottom: 25,
  },

  '& .title': {
    fontSize: 32,
    color: props.themeColor,
    marginBottom: 5,
    zIndex: 1,

    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      fontSize: 22,
      marginBottom: 30,
    },
  },

  '& .content': {
    maxWidth: 725,
    fontSize: 18,
    color: COLORS.secBlack,
    zIndex: 1,

    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      fontSize: 16,
    },
  },

  '& .info-icon': {
    position: 'absolute',
    top: 15,
    right: 15,
    fontSize: 29,
    color: props.themeColor,
    cursor: 'pointer',
  },
}))

export { DashboardPage }
