import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SupportForm } from '../components/support/SupportForm'
import { ContentTwoColumsLayout } from 'shared/index'
import { QUERIES } from '../../../styling/variables'
import { PageInfo, Accordion } from 'shared'
import { getFAQ } from 'platforms/kyoso-lab/services/general'
import { useDispatch } from 'react-redux'
import {
  lab_hideGlobalLoading,
  lab_showGlobalLoading,
} from '../store/generalSlice'

function SupportPage() {
  const [questions, setQuestions] = useState([])
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(lab_showGlobalLoading())
    getFAQ()
      .then((response) => {
        setQuestions(response.faq.sort((a, b) => a.q_order - b.q_order))
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(lab_hideGlobalLoading()))
  }, [])

  return (
    <ContentTwoColumsLayout
      customCss={{
        [`@media (max-width: ${QUERIES.tablet}px)`]: {
          display: 'flex',
          flexDirection: 'column-reverse',
        },
      }}
    >
      <div>
        <PageInfo
          title={t('shared:Support:PageTitle')}
          subtitle={t('shared:Support:PageDescription')}
          customCss={{
            '& h1': {
              marginBottom: 10,
            },
            '& h2': {
              marginBottom: 20,
            },
          }}
        />
        {questions && <Accordion questions={questions} theme={0} />}
      </div>

      <div>
        <SupportForm />
      </div>
    </ContentTwoColumsLayout>
  )
}

export { SupportPage }
