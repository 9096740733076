import styled from '@emotion/styled'
import { getAllChildrenOfParent } from 'platforms/kyoso-lab/services/kyoso/children'
import { SettingsWidget } from 'platforms/kyoso-lab/shared'
import {
  lab_hideGlobalLoading,
  lab_showGlobalLoading,
} from 'platforms/kyoso-lab/store/generalSlice'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { getAllGrades } from 'services/shared'
import { COLORS } from 'styling/variables'
import moment from 'moment'

function KyosoAccordion({ parents, duplicateCallback }) {
  const [showChildren, setShowChildren] = useState(false)
  const [grades, setGrades] = useState([])
  const [showParentSettings, setShowParentSettings] = useState(false)
  const [showChildSettings, setShowChildSettings] = useState(false)
  const parentSettings = useRef(null)
  const childSettings = useRef(null)
  const dispatch = useDispatch()
  const [children, setChildren] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    getAllGrades()
      .then((response) => setGrades(response.grades))
      .catch((error) => console.log(error))

    document.addEventListener('click', handleClickOutsideOfWidgetsMenu)

    return () => {
      document.removeEventListener('click', handleClickOutsideOfWidgetsMenu)
    }
  }, [showParentSettings, showChildSettings])

  const handleClickOutsideOfWidgetsMenu = (event) => {
    //different than false because showParentSettings is the index of the open element, that means that it could be 0 which is falsy
    if (showParentSettings !== false) {
      !parentSettings.current?.contains(event.target) &&
        setShowParentSettings(false)
    } else if (showChildSettings !== false) {
      !childSettings.current?.contains(event.target) &&
        setShowChildSettings(false)
    }
  }

  const toggleChildSettings = (e, index) => {
    e.stopPropagation()

    if (showChildSettings === index) {
      return setShowChildSettings(null)
    }

    setShowChildSettings(index)
  }

  const toggleParentSettings = (e, index) => {
    e.stopPropagation()

    if (showParentSettings === index) {
      return setShowParentSettings(null)
    }

    setShowParentSettings(index)
  }

  const toggleParent = (e, index, parentId) => {
    if (showChildren === index) {
      setChildren([])
      return setShowChildren(null)
    }

    dispatch(lab_showGlobalLoading())
    getAllChildrenOfParent(parentId)
      .then((response) => {
        setChildren(response.children)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => dispatch(lab_hideGlobalLoading()))

    setShowChildren(index)
  }

  const checkVisibility = (visibility, parentIntervals) => {
    let colorClass

    const checkIsInProgress = () => {
      const intervals = parentIntervals ? JSON.parse(parentIntervals) : null

      if (intervals) {
        let isInProgress = false
        intervals.forEach((int) => {
          const now = moment.utc()
          if (now >= moment(+int.startDate) && now <= moment(+int.endDate)) {
            isInProgress = true
            return
          }
        })
        return isInProgress
      } else {
        return false
      }
    }

    if (visibility === '2') colorClass = 'black'
    else if (checkIsInProgress() && visibility === '1') colorClass = 'green'
    else if (visibility === '0') colorClass = 'red'
    else if (visibility === '1') colorClass = 'yellow'

    return colorClass
  }

  return (
    <div>
      <ParentsTittles>
        <span>{t('lab:Shared:TitleStatus')}</span>
        <span>{t('lab:Shared:TitleIdCode')}</span>
        <span>{t('lab:Shared:TitleName')}</span>
        <span>{t('lab:Shared:TitleYear')}</span>
        <span>{t('lab:Shared:TitleSubject')}</span>
        <span>{t('lab:Shared:TitleGrades')}</span>
        <span>{t('lab:Shared:TitleType')}</span>
        <span>{t('lab:Shared:TitleSettings')}</span>
      </ParentsTittles>

      {parents.map((parent, index) => {
        return (
          <ParentWrapper
            className="wrapper"
            key={parent.id}
            onClick={(e) => toggleParent(e, index, parent.id)}
          >
            <span
              className={checkVisibility(parent.visibility, parent.intervals)}
            >
              <i className="fas fa-circle"></i>
            </span>
            <span id="uid">{parent.uid}</span>
            <span id="name">{parent.name}</span>
            <span id="year">{parent.year}</span>
            <span id="subject-name">{parent.subject_name}</span>

            {/* checks if it needs to shool ADD Link or display the classes */}
            {showChildren === index ? (
              <NavLink
                to={{
                  pathname: '/lab/dashboard/kyoso/child/insert',
                  state: {
                    parent_id: parent.id,
                    parent_name: parent.name,
                    parent_paid_status: parent.paid,
                  },
                }}
                id="add-grade"
              >
                {t('lab:Kyoso:AddChildren')}
              </NavLink>
            ) : (
              <span id="grades">
                {parent.grades ? (
                  parent.grades
                ) : (
                  <NavLink
                    to={{
                      pathname: '/lab/dashboard/kyoso/child/insert',
                      state: {
                        parent_id: parent.id,
                        parent_name: parent.name,
                        parent_paid_status: parent.paid,
                      },
                    }}
                    id="add-grade"
                  >
                    {t('lab:Kyoso:AddChildren')}
                  </NavLink>
                )}
              </span>
            )}

            <span id="type-name">{parent.type_name}</span>
            <span
              id="settings-parent"
              onClick={(e) => toggleParentSettings(e, index)}
            >
              <i className={`fas fa-ellipsis-h`}></i>
              {showParentSettings === index && (
                <SettingsWidget ref={parentSettings}>
                  <Link to={`/lab/dashboard/kyoso/parent/update/${parent.id}`}>
                    <i className="fas fa-pencil-alt"></i>{' '}
                    <span>{t('lab:Kyoso:SettingsWidgetEdit')}</span>
                  </Link>

                  <Link
                    to={{
                      pathname: `/lab/dashboard/kyoso/parent/${parent.id}/preview`,
                      state: {
                        uid: parent.uid,
                      },
                    }}
                  >
                    <i className="far fa-eye"></i>{' '}
                    <span>{t('lab:Kyoso:SettingsWidgetView')}</span>
                  </Link>

                  <a onClick={() => duplicateCallback('parent', parent.id)}>
                    <i className="far fa-copy"></i>
                    <span>{t('lab:Kyoso:SettingsWidgetCopy')}</span>
                  </a>

                  <Link to="/lab/support">
                    <i className="fas fa-trash-alt"></i>{' '}
                    <span>{t('lab:Kyoso:SettingsWidgetDelete')}</span>
                  </Link>
                </SettingsWidget>
              )}
            </span>

            {showChildren === index &&
              children &&
              children.map((child, index) => {
                return (
                  <ChildWrapper key={child.uid}>
                    <span>{child.uid}</span>
                    <span>{child.grade_name}</span>
                    <span
                      id="settings-child"
                      onClick={(e) => toggleChildSettings(e, index)}
                    >
                      <i className={`fas fa-ellipsis-h`}></i>
                      {showChildSettings === index && (
                        <SettingsWidget ref={childSettings}>
                          <Link to={`/lab/dashboard/kyoso/child/${child.id}`}>
                            <i className="fas fa-pencil-alt"></i>{' '}
                            <span>{t('lab:Kyoso:SettingsWidgetEdit')}</span>
                          </Link>

                          <Link
                            to={{
                              pathname: `/lab/dashboard/kyoso/child/${child.id}/preview`,
                              state: {
                                uid: child.uid,
                              },
                            }}
                          >
                            <i className="far fa-eye"></i>{' '}
                            <span>{t('lab:Kyoso:SettingsWidgetView')}</span>
                          </Link>

                          <Link
                            to={{
                              pathname: '/lab/dashboard/kyoso/add-participants',
                              state: {
                                child_id: child.id,
                                parent_name: parent.name,
                                child_grade: grades.find(
                                  (grade) => grade.id === child.grade_id,
                                ),
                                has_modules: child.has_modules,
                              },
                            }}
                          >
                            <i className="fas fa-envelope-open-text"></i>{' '}
                            <span>{t('lab:Kyoso:SettingsWidgetInvites')}</span>
                          </Link>

                          <Link
                            to={{
                              pathname: '/lab/dashboard/kyoso/exercises',
                              state: {
                                child_id: child.id,
                                parent_name: parent.name,
                                child_grade: grades.find(
                                  (grade) => grade.id === child.grade_id,
                                ),
                                has_modules: child.has_modules,
                              },
                            }}
                          >
                            <i className="far fa-file-alt"></i>{' '}
                            <span>
                              {t('lab:Kyoso:SettingsWidgetExercises')}
                            </span>
                          </Link>

                          <a
                            onClick={(e) => {
                              duplicateCallback('child', child.id)
                              toggleParent(e, false, parent.id)
                            }}
                          >
                            <i className="far fa-copy"></i>
                            <span>{t('lab:Kyoso:SettingsWidgetCopy')}</span>
                          </a>

                          <Link to="/lab/support">
                            <i className="fas fa-trash-alt"></i>{' '}
                            <span>{t('lab:Kyoso:SettingsWidgetDelete')}</span>
                          </Link>
                        </SettingsWidget>
                      )}
                    </span>
                  </ChildWrapper>
                )
              })}
          </ParentWrapper>
        )
      })}
    </div>
  )
}

export { KyosoAccordion }

const ParentsTittles = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 4fr 1fr 2fr 1.5fr 1.5fr 1fr',
  placeItems: 'center',
  gridColumnGap: '20px',
  marginTop: '20px',
  color: COLORS.secBlue,
  fontWeight: '400',

  '& :nth-of-type(3)': {
    justifySelf: 'left',
  },

  '& :nth-of-type(5)': {
    justifySelf: 'left',
  },
})

const ParentWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 4fr 1fr 2fr 1.5fr 1.5fr 1fr',
  placeItems: 'center',
  gridColumnGap: '20px',
  gridRowGap: '20px',
  marginTop: '5px',
  fontWeight: '400',
  border: `1px solid ${COLORS.borderGray}`,
  borderRadius: '15px',
  padding: '20px 0',
  color: COLORS.mainGray,

  ' :hover': {
    cursor: 'pointer',
    color: COLORS.secBlack,
    backgroundColor: COLORS.bgGray,
  },

  '& .black': { color: COLORS.secBlack },
  '& .red': { color: COLORS.secRed },
  '& .yellow': { color: COLORS.secYellow },
  '& .green': { color: COLORS.secGreen },

  '& #add-grade': {
    color: COLORS.secBlue,
    textDecoration: 'underline',
  },

  '& #name': {
    justifySelf: 'left',
  },

  '& #subject-name': {
    fontSize: 14,
    justifySelf: 'left',
  },

  '& #grades, & #type-name': {
    fontSize: 14,
  },

  '& #settings-parent,#settings-child': {
    width: '20px',
    textAlign: 'center',
    position: 'relative',

    i: {
      fontSize: 'larger',
    },

    ':hover': {
      color: COLORS.secBlue,
    },
  },
})

const ChildWrapper = styled('div')({
  gridColumn: '1 / 9',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 4fr 1fr 2fr 1.5fr 1.5fr 1fr',
  placeItems: 'center',
  gridColumnGap: '20px',
  fontWeight: '400',

  '& :nth-of-type(1)': {
    gridColumn: '2',
  },

  '& :nth-of-type(2)': {
    gridColumn: '6',
  },

  '& :nth-of-type(3)': {
    gridColumn: '8',
  },
})
