import API from 'utils/backendApi'

function getAllChildrenOfParent(parentId) {
  return API('lab').GET(`/kyoso/childrenOfParent?parent=${parentId}`)
}

function getChild(childId) {
  return API('lab').GET(`/kyoso/child/${childId}`)
}

function getChildPreview(childId) {
  return API('lab').GET(`/kyoso/child/${childId}/preview`)
}

function insertChild(
  kyoso_parent_id,
  grade_id,
  {
    duration = '_omit_',
    has_modules = '_omit_',
    modules = '_omit_',
    instructions = '_omit_',
    submit_message = '_omit_',
    paid = '_omit_',
    price = '_omit_',
    rules_text = '_omit_',
    rules_file = '_omit_',
    answers_visibility = '_omit_',
    types_to_download_as_file = '_omit_',
  } = {},
) {
  const body = new FormData()
  body.append(
    'data',
    JSON.stringify({
      id: '_omit_',
      kyoso_parent_id,
      grade_id,
      duration,
      has_modules,
      modules,
      instructions,
      submit_message,
      rules_text,
      paid,
      price,
      answers_visibility,
      types_to_download_as_file,
    }),
  )
  body.append('rules_file', rules_file)

  return API('lab').POST('/kyoso/child', body)
}

function updateChild(
  id,
  {
    grade_id = '_omit_',
    duration = '_omit_',
    has_modules = '_omit_',
    modules = '_omit_',
    instructions = '_omit_',
    submit_message = '_omit_',
    paid = '_omit_',
    price = '_omit_',
    rules_text = '_omit_',
    rules_file = '_omit_',
    sign_up_type = '_omit_',
    answers_visibility = '_omit_',
    types_to_download_as_file = '_omit_',
  } = {},
) {
  const body = new FormData()
  body.append(
    'data',
    JSON.stringify({
      id,
      grade_id,
      duration,
      has_modules,
      modules,
      instructions,
      submit_message,
      rules_text,
      sign_up_type,
      paid,
      price,
      answers_visibility,
      types_to_download_as_file,
    }),
  )
  body.append('rules_file', rules_file)

  return API('lab').POST('/kyoso/child', body)
}

function duplicateChild(childId) {
  const body = JSON.stringify({
    childId,
  })
  return API('lab').POST('/kyoso/child/duplicate', body)
}

function removeChildModule(moduleId) {
  return API('lab').DELETE(`/kyoso/child/module/${moduleId}`)
}

function getChildModules(childId) {
  return API('lab').GET(`/kyoso/child/${childId}/modules`)
}

export {
  getAllChildrenOfParent,
  getChild,
  getChildPreview,
  insertChild,
  updateChild,
  duplicateChild,
  removeChildModule,
  getChildModules,
}
