import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  clearAuthToken,
  loginUser,
  logOutUser,
  setAuthToken,
} from '../services/authentication'
import { decodeJWT, handleRejectedFetch } from '../../../utils/helpers/common'
import { updateUser } from '../services/user'
import { hub_setTheme } from './generalSlice'
import { askForToken } from 'utils/backendApi'

// Thunks
export const hub_loginUser = createAsyncThunk(
  'user/hub_loginUser',
  async (data, thunkAPI) => {
    try {
      const response = await loginUser(data.email, data.password)
      const userData = response.userData
      const tokenData = decodeJWT(response.token)
      setAuthToken(response.token)
      thunkAPI.dispatch(hub_setTheme(userData?.theme))

      return {
        id: tokenData?.id,
        email: data.email,
        username: tokenData?.username,
        avatar: userData?.avatar,
        full_name: userData?.full_name,
        city: userData?.city,
        school: userData?.school,
        is_onboarded: userData?.is_onboarded,
        grade_id: userData?.grade_id,
        parent_names: userData?.parent_names,
        parent_phone: userData?.parent_phone,
      }
    } catch (error) {
      handleRejectedFetch(error, thunkAPI)

      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const hub_updateUser = createAsyncThunk(
  'user/hub_updateUser',
  async (data, thunkAPI) => {
    try {
      const { user_id, ...fields } = data
      await updateUser(user_id, fields)
      thunkAPI.dispatch(hub_setTheme(fields?.theme))
      return {
        avatar: fields?.avatar,
        username: fields?.username,
        full_name: fields?.full_name,
        city: fields?.city,
        school: fields?.school,
        grade_id: fields?.grade_id,
        parent_names: fields?.parent_names,
        parent_phone: fields?.parent_phone,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
export const hub_logOutUser = createAsyncThunk(
  'user/hub_logOutUser',
  async (data, thunkAPI) => {
    const { id } = decodeJWT(askForToken('hub'))

    try {
      await logOutUser(id)
      clearAuthToken()
      return null
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

// Selectors
export const hub_selectUser = (state) => state.hub.user

// Configure Slice
const initialState = {
  id: '',
  email: '',
  avatar: '',
  username: '',
  full_name: '',
  city: '',
  school: '',
  is_onboarded: '',
  grade_id: '',
  parent_names: '',
  parent_phone: '',
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: {
    [hub_loginUser.fulfilled]: (state, action) => {
      state.id = action.payload.id
      state.email = action.payload.email
      state.avatar = action.payload.avatar
      state.username = action.payload.username
      state.full_name = action.payload.full_name
      state.city = action.payload.city
      state.is_onboarded = action.payload.is_onboarded
      state.school = action.payload.school
      state.grade_id = action.payload.grade_id
      state.parent_names = action.payload.parent_names
      state.parent_phone = action.payload.parent_phone
    },
    [hub_updateUser.fulfilled]: (state, action) => {
      Object.keys(action.payload).forEach((k) => {
        if (action.payload[k]) {
          state[k] = action.payload[k]
        }
      })
    },
    [hub_logOutUser.fulfilled]: (state) => {
      Object.keys(initialState).forEach((k) => {
        state[k] = initialState[k]
      })
    },
  },
})

// Actions and Reducer
export default userSlice.reducer
