import { useEffect } from 'react'
import { PageInfo, TileElement } from 'shared'
import { useDispatch } from 'react-redux'
import { hub_setPage } from '../store/generalSlice'
import styled from '@emotion/styled'
import { QUERIES } from 'styling/variables'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function DashboardPage() {
  const dispatch = useDispatch()
  const routeHistory = useHistory()
  const COMPETITIONS = 'COMPETITIONS'
  const RESULTS = 'RESULTS'
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(
      hub_setPage({
        title: t('hub:Dashboard:Title'),
        subtitle: '',
      }),
    )
  }, [])

  const handleTileActions = (actionType) => {
    switch (actionType) {
      case COMPETITIONS:
        routeHistory.push('/hub/competitions')
        break
      case RESULTS:
        routeHistory.push('/hub/results')
        break
      default:
    }
  }

  return (
    <div>
      <PageInfo
        title="Начален екран"
        customCss={{
          '& h1': {
            marginBottom: 10,
          },
          '& h2': {
            marginBottom: 20,
          },
        }}
      />
      <div style={{ marginTop: 20 }}>
        <p>Добре дошли в платформата Kyoso HUB!</p>
        <p>
          Вие сте едни от първите потребители, които ще използват платформата.
          Надяваме се тя да бъде полезна за вас. <br />
          Ще продължаваме да я доразвиваме и планираме още много неща в нея в
          бъдеще.
        </p>
        <p>
          Ако сте поканени за събитие, можете да продължите към Секция
          Състезания, където ще го намерите в категория "Предстоящи събития, за
          които си поканен:"
        </p>
      </div>
      <ActionContainer customCss={{ marginTop: 30 }}>
        <div id="tiles-wrapper">
          <TileElement
            customCss={{ width: '100%', fontSize: 16, lineHeight: 1.5 }}
            onClick={() => handleTileActions(COMPETITIONS)}
          >
            <i className="fas fa-award"></i>
            <p>Секция Състезания</p>
          </TileElement>
          <TileElement
            customCss={{ width: '100%', fontSize: 16, lineHeight: 1.5 }}
            onClick={() => handleTileActions(RESULTS)}
          >
            <i className="fas fa-chart-bar"></i>
            <p>Секция Резултати</p>
          </TileElement>
        </div>
      </ActionContainer>
    </div>
  )
}

const ActionContainer = styled('div')(({ customCss }) => ({
  '#tiles-wrapper': {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 500,
    marginBottom: 20,
    gap: 10,
    i: { fontSize: 35 },
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    '#currently-conducting': {
      '#wrapper': {
        '#competition-wrapper': {
          flexDirection: 'column',
          gap: 10,
          '.kyoso_buttons': {
            width: '100%',
          },

          '.competition-content': {
            width: '100%',
          },
        },
      },
    },

    '#tiles-wrapper': {
      flexDirection: 'column',
      maxWidth: 'initial',
      gap: 20,
      button: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        height: 100,

        i: { fontSize: 35 },
        p: { fontSize: 16 },
      },
    },
  },
  ...customCss,
}))

export { DashboardPage }
