import styled from '@emotion/styled'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getSchoolGradesHook } from 'utils/helpers/hooks'
import { COLORS } from 'styling/variables'
import { formatDateAndTime } from 'utils/helpers/common'
import { CONSTANTS } from 'shared'

function KyosoBundleOrdersContent({
  ordersContent,
  openModalCallback,
  changePaidStatusCallback,
}) {
  const { t } = useTranslation()
  const grades = getSchoolGradesHook()

  const paymentMethods = [
    t('lab:Shared:PaymentMethodFree'),
    t('lab:Shared:PaymentMethodBank'),
    'EPay',
  ]

  const openModalHandler = (orderId, e) => {
    const modalType = e.target.getAttribute('name')
    openModalCallback(orderId, modalType)
  }

  const changeIsPaidHandler = (paid, payment_method, orderId) => {
    if (payment_method === '1' && !paid) {
      changePaidStatusCallback(orderId, true, payment_method)
    }
  }

  const checkPaymentStatus = (isPaid, price) => {
    let className = 'red'

    if (+isPaid && +price) {
      className = 'green'
    }

    return className
  }

  // console.log(ordersContent)

  return (
    <>
      <TableHeader>
        <span>{t('lab:KyosoOrders:Payment')}</span>
        <span>{t('lab:KyosoBundleOrders:RemainingParticipations')}</span>
        <span>{t('lab:KyosoOrders:OrderId')}</span>
        <span>{t('lab:KyosoOrders:ParticipantInfo')}</span>
        <span>{t('lab:KyosoOrders:ContactInfo')}</span>
        {/* <span>{t('lab:KyosoOrders:FilterCheckboxInvoice')}</span> */}
        <span>{t('lab:KyosoOrders:FilterCheckboxDeclaration')}</span>
        <span>{t('lab:KyosoOrders:OrderDate')}</span>
        <span>{t('shared:Events')}</span>
        <span>{t('lab:KyosoOrders:Edit')}</span>
      </TableHeader>

      {ordersContent.map((order) => {
        return (
          <OrderWrapper className="wrapper" key={order.id}>
            <span
              className={checkPaymentStatus(order.paid, order.price)}
              onClick={() =>
                changeIsPaidHandler(order.paid, order.payment_method, order.id)
              }
            >
              {paymentMethods[order.payment_method]}
            </span>

            <div id="particitipations">
              <p>
                <span>
                  {order.participations - order.used_events_ids.length}
                </span>{' '}
                / <span>{order.participations}</span>
              </p>
              <p>{t('shared:Participations')}</p>
            </div>

            <div id="order-id">
              <span>{order.order_id}</span>
            </div>

            <div id="participant-info">
              <span id="participant-name">
                {order.participant_info.full_name}
              </span>
              <span id="participant-school-grade" className="no-wrap">
                {`${order.participant_info.school} (${
                  grades?.[order.participant_info.grade_id].name
                })`}
              </span>
              <span id="participant-town">{order.participant_info.city}</span>
            </div>

            <div id="contact-info">
              <span id="contact-name" className="no-wrap">
                {order.participant_info.parent_names}
              </span>
              <span id="contact-phone">
                {order.participant_info.parent_phone}
              </span>
              <span id="contact-email">{order.participant_info.email}</span>
            </div>

            {/* <span id="invoice">
            
              {order.invoice ? (
                <i
                  className="fas fa-check"
                  name={CONSTANTS.modals.INVOICE_MODAL}
                  onClick={(e) => openModalHandler(order.order_id, e)}
                ></i>
              ) : null}
            </span> */}

            <span id="declaration">
              {order.declaration_info.signature ? (
                <i
                  className="fas fa-check"
                  name={CONSTANTS.modals.DECLARATION_MODAL}
                  onClick={(e) => openModalHandler(order.order_id, e)}
                ></i>
              ) : (
                <i className="fas fa-times"></i>
              )}
            </span>

            <span id="order-date">
              <p>{formatDateAndTime(order.order_date)}</p>
            </span>

            <span id="competitions">
              <i
                name={CONSTANTS.modals.COMPETITIONS_MODAL}
                className="far fa-list-alt"
                onClick={(e) => openModalHandler(order.order_id, e)}
              ></i>
            </span>

            <span id="order-edit">
              <i
                className="fas fa-user-edit"
                name={CONSTANTS.modals.EDIT_MODAL}
                onClick={(e) => openModalHandler(order.order_id, e)}
              ></i>
            </span>
          </OrderWrapper>
        )
      })}
    </>
  )
}

export { KyosoBundleOrdersContent }

const TableHeader = styled('div')({
  display: 'grid',
  // gridTemplateColumns: '1fr 1fr 1fr 2fr 2fr 1fr 1fr 1fr 1fr 1fr ',
  gridTemplateColumns: '1fr 1fr 1fr 2fr 2fr 1fr 1fr  1fr 1fr  ',

  textAlign: 'center',
  placeItems: 'center',
  alignItems: 'end',
  gridColumnGap: '20px',
  marginTop: '20px',
  color: COLORS.secBlue,
  fontWeight: '400',

  '.no-wrap': {
    whiteSpace: 'nowrap',
  },

  '& :nth-of-type(4)': {
    justifySelf: 'left',
  },

  '& :nth-of-type(5)': {
    justifySelf: 'left',
  },
})

const OrderWrapper = styled('div')({
  display: 'grid',
  // gridTemplateColumns: '1fr 1fr 1fr 2fr 2fr 1fr 1fr 1fr 1fr 1fr  ',
  gridTemplateColumns: '1fr 1fr 1fr 2fr 2fr 1fr 1fr  1fr 1fr  ',

  placeItems: 'center',
  gridColumnGap: '20px',
  gridRowGap: '20px',
  marginTop: '5px',
  fontWeight: '400',
  border: `1px solid ${COLORS.borderGray}`,
  borderRadius: '15px',
  padding: '20px 0',
  color: COLORS.mainGray,

  '#particitipations': {
    color: COLORS.secBlack,
    textAlign: 'center',

    'p:nth-of-type(1)': {
      fontSize: 20,
    },
  },

  '.no-wrap': {
    whiteSpace: 'nowrap',
  },

  '#participant-info,#contact-info': {
    justifySelf: 'start',
    display: 'flex',
    flexDirection: 'column',

    '#participant-name': {
      color: COLORS.secBlack,
      marginBottom: 10,
    },

    '#contact-name': {
      marginBottom: 10,
    },

    '#contact-email': {
      color: COLORS.secBlack,
    },
  },

  '#invoice,#declaration,#invited,#competitions': {
    i: {
      fontSize: 30,
    },

    '.fa-times': {
      color: COLORS.secRed,
    },

    '.fa-check': {
      color: COLORS.secGreen,
    },
  },

  '#order-date': {
    textAlign: 'center',
  },

  '#order-edit': {
    i: { fontSize: 30 },
  },

  ' :hover': {
    cursor: 'pointer',
    color: COLORS.secBlack,
    backgroundColor: COLORS.bgGray,
  },

  '& .red': { color: COLORS.secRed },
  '& .blue': { color: COLORS.secBlue },
  '& .green': { color: COLORS.secGreen },
})
