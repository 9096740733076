import styled from '@emotion/styled'
import { GenericButton, HubModalBackground } from 'platforms/kyoso-hub/shared'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ContentTwoColumsLayout,
  EscButton,
  FirstColumn,
  PageInfo,
  ModalLayout,
  SecondColumn,
  TinyTextContentPreview,
} from 'shared'
import bpay from 'assets/img/payments/bpay.png'
import easypay from 'assets/img/payments/easypay.png'
import mastercard from 'assets/img/payments/mastercard.png'
import visa from 'assets/img/payments/visa.png'
import { formatDateAndTime } from 'utils/helpers/common'
import HTMLReactParser from 'html-react-parser'
import { COLORS, THEMES } from 'styling/variables'
import { useSelector } from 'react-redux'
import { hub_selectTheme } from 'platforms/kyoso-hub/store/generalSlice'

function SignUpBundleModal({ modalData, closeModalCallback }) {
  const currentTheme = useSelector(hub_selectTheme)
  const { t } = useTranslation()

  return (
    <HubModalBackground>
      <ModalLayout
        customCss={{
          width: '70%',
        }}
      >
        <ContentWrap>
          <PageInfo />
          <EscButton onClick={closeModalCallback} />

          <h1 id="modal-title" className="green">
            {t('hub:Participation:ModalTitle')}
          </h1>

          <ContentTwoColumsLayout>
            <FirstColumn id="order-info">
              <Bundle theme={currentTheme}>
                <div className="participations">
                  <span>{modalData.bundle_participations}</span>
                  <p>{t('shared:Participations')}</p>
                </div>

                <div className="content-wrapper">
                  <p className="content-title">{modalData.bundle_name}</p>
                  <p className="content-subtitle">
                    {t('hub:Competitions:CompetitionSubtitle', {
                      subject: modalData.subject,
                      year: modalData.year,
                      competition_type: modalData.competition_type,
                    })}
                  </p>
                  <p className="content-owner">
                    {t('hub:Competitions:CompetitionOwnerInfo', {
                      competition_owner: modalData.bundle_owner,
                    })}
                  </p>
                </div>
              </Bundle>

              <p id="order-id">
                <span>{t('lab:KyosoOrders:OrderId')}: </span>
                <span className="blue">{modalData.order_id}</span>
              </p>

              <p id="order-date">
                <span>{t('lab:KyosoOrders:OrderDate')}: </span>
                <span className="blue">
                  {formatDateAndTime(modalData.order_date)}
                </span>
              </p>

              <p id="price" className="red">
                {t('hub:Participation:Price', {
                  price: modalData.price,
                })}
              </p>
            </FirstColumn>

            <SecondColumn id="order-payment-type">
              <p className="black">{`${t(
                'hub:Participation:PaymentTypeLabel',
              )}: ${
                +modalData.payment_type === 1
                  ? t('hub:Participation:BankPayment')
                  : t('hub:Participation:ЕPay')
              }`}</p>

              {+modalData.payment_type === 1 ? (
                <>
                  <p className="gray">
                    {t('hub:Participation:ModalPaymentBankInstruction')}
                  </p>

                  <p className="black">{t('lab:Settings:LabelBankInfo')}:</p>

                  <TinyTextContentPreview
                    customCss={{
                      gridRow: '3',
                      gridColumn: '1 /4',
                      margin: '20px 0',
                      border: `1px solid ${COLORS.borderGray}`,
                      padding: 10,
                      borderRadius: 10,
                    }}
                    html={modalData.owner_bank_details}
                  />
                </>
              ) : (
                <>
                  <p className="gray">
                    {t('hub:Participation:ModalPaymentEpayInstruction')}
                  </p>
                  <div className="payment-types">
                    <img src={visa}></img>
                    <img src={mastercard}></img>
                    <img src={easypay}></img>
                    <img src={bpay}></img>
                  </div>
                </>
              )}
            </SecondColumn>
          </ContentTwoColumsLayout>
          <div id="modal-footer">
            {+modalData.payment_type === 1 ? (
              <>
                <p className="gray">{t('hub:Participation:ModalFooter')}</p>
                <GenericButton
                  customCss={{ width: 250, marginTop: 10 }}
                  theme={currentTheme}
                  onClick={closeModalCallback}
                >
                  {t('shared:Modals:ModalCloseButton')}
                </GenericButton>
              </>
            ) : (
              <p className="gray">
                {HTMLReactParser(
                  t('hub:Participation:ModalFooterEpay', {
                    link: 'https://www.facebook.com/',
                  }),
                )}
              </p>
            )}
          </div>
        </ContentWrap>
      </ModalLayout>
    </HubModalBackground>
  )
}

export { SignUpBundleModal }

const Bundle = styled('div')(({ theme }) => {
  return {
    placeItems: 'center',
    borderRadius: 10,
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    gap: 15,
    marginBottom: 30,

    '.gray': {
      color: COLORS.mainGray,
    },

    '.participations': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      color: THEMES[+theme],
      span: {
        textAlign: 'center',
        margin: 'auto',
        fontSize: 50,
      },
    },

    '.content-wrapper': {
      justifySelf: 'start',
      display: 'flex',
      flexDirection: 'column',
      gap: 10,

      '.content-title': {
        fontWeight: 600,
        fontSize: 24,
      },
    },
  }
})

const ContentWrap = styled('div')(({ theme }) => {
  return {
    '#modal-title': {
      textAlign: 'center',
    },

    '#order-info': {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,

      '.red': {
        fontSize: 20,
      },

      '#headline': {
        fontSize: 22,
        marginBottom: 10,
      },

      '#sub-headline': {
        marginBottom: 20,
      },
    },

    '#order-payment-type': {
      '.black': {
        fontSize: 20,
        marginBottom: 10,
      },

      '.gray': {
        fontSize: 14,
        marginBottom: 20,
      },

      '.payment-types': {
        img: {
          margin: '0  5px',
        },
      },
    },

    '#modal-footer': {
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      p: { textAlign: 'center' },
    },
  }
})
