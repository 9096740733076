import { useEffect, useState } from 'react'
import { getSidebarMenus } from '../../services/general'
import VeskoAvatar from 'assets/img/avatars/vesko_avatar.jpeg'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { hub_selectUser, hub_updateUser } from '../../store/userSlice'
import { useHistory } from 'react-router'
import Parser from 'html-react-parser'
import { hub_selectTheme, hub_setPage } from '../../store/generalSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Avatar,
  AvatarHolder,
  DialogBubble,
  DialogLine,
  Text,
} from '../../shared/index'
import styled from '@emotion/styled'
import { COLORS, QUERIES } from '../../../../styling/variables'
import { SubmitButtonHub } from '../../../../shared/index'

function OnboardingThirdStepForm() {
  const currentTheme = useSelector(hub_selectTheme)
  const reduxUser = useSelector(hub_selectUser)
  const dispatch = useDispatch()
  const [sideBarMenus, setSideBarMenus] = useState([])
  const routeHistory = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(
      hub_setPage({
        title: t('hub:Onboarding:PageTitle'),
        subtitle: t('hub:Onboarding:Second&ThirdStepSubtitle'),
      }),
    )
    getSidebarMenus()
      .then((response) => {
        setSideBarMenus(response.sidebarMenus)
      })
      .catch((error) => console.log(error))
  }, [])

  const onFinishOnboardingHandler = () => {
    dispatch(hub_updateUser({ user_id: reduxUser.id, is_onboarded: true }))
      .then(unwrapResult)
      .then(() => {
        routeHistory.replace('/hub/dashboard')
      })
  }
  return (
    <>
      <DialogLine>
        <AvatarHolder
          customCss={{
            marginRight: '20px',
            [`@media (max-width: ${QUERIES.tablet}px)`]: {
              width: '140px',
            },
          }}
          theme={currentTheme}
        >
          <Avatar src={VeskoAvatar} />
        </AvatarHolder>
        <DialogBubble className="arrow-left" theme={currentTheme}>
          <Text>{t('hub:Onboarding:VeskoDialog3')}</Text>
        </DialogBubble>
      </DialogLine>
      <ContentWrapper>
        <SubmitButtonHub
          onClick={() => routeHistory.goBack()}
          theme={currentTheme}
          customCss={{
            transform: 'rotate(180deg)',
            widht: 90,
            height: 90,
          }}
        />
        <HelpIconsWrapper>
          {sideBarMenus.map((sidebarMenu) => {
            return (
              <HelpIcons className="table-el" key={sidebarMenu.id}>
                {Parser(sidebarMenu.icon)}
                <h3>{sidebarMenu.title}</h3>
                <p>{sidebarMenu.content}</p>
              </HelpIcons>
            )
          })}
        </HelpIconsWrapper>
        <SubmitButtonHub
          id="submiThirdForm"
          theme={currentTheme}
          onClick={onFinishOnboardingHandler}
          customCss={{
            flex: 'none',
            margin: 'auto',
          }}
        />
      </ContentWrapper>
    </>
  )
}

export { OnboardingThirdStepForm }

const HelpIconsWrapper = styled('div')(({ customCss }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
  gridGap: '20px',

  [`@media (max-width: ${QUERIES.tablet}px)`]: {
    display: 'flex',
    flexWrap: 'wrap',
    '.table-el': {
      maxWidth: 250,
    },
  },
  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    display: 'flex',
    flexDirection: 'column',

    '.table-el': {
      maxWidth: 400,
    },
  },
}))

const ContentWrapper = styled('div')(({ customCss }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 11fr 1fr',
  gap: 20,
  placeItems: 'center',
  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}))

const HelpIcons = styled('div')(({ customCss }) => ({
  display: 'grid',
  gridTemplateRows: '1fr 50px 1fr',
  color: `${COLORS.mainGray}`,
  placeItems: 'center',
  textAlign: 'center',
  gridGap: '10px',

  '& i': {
    fontSize: '63px',
  },

  '& h3': {
    fontSize: 28,
    fontWeight: 'normal',
  },
  '& p': {
    alignSelf: 'start',
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    '& i': {
      fontSize: '35px',
    },

    '& h3': {
      fontSize: 20,
    },
  },
}))
