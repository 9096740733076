import styled from '@emotion/styled'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { COLORS } from 'styling/variables'
import {
  getCompetitionStructure,
  getCompetitionTracking,
  getParticipationsForChildWithFilter,
  resetChildParticipation,
  updateParticipation,
} from '../services/kyoso/competitions'
import Parser from 'html-react-parser'
import ReactCountdown from 'react-countdown'
import { OrderModal } from '../components/kyoso-orders/OrderModal'
import {
  lab_hideGlobalLoading,
  lab_showGlobalLoading,
  lab_showSharedModal,
} from '../store/generalSlice'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { EscButton, SharedModal } from 'shared'
import { formatDateAndTime } from 'utils/helpers/common'
import { SubmitButtonLab } from '../shared'

function KyosoChildTrackingPage() {
  const routeHistory = useHistory()
  const { childId } = useParams()
  const [trackingInfo, setTrackingInfo] = useState()
  const [competitionStruct, setCompetitionStruct] = useState()
  const [showModal, setShowModal] = useState()
  const [showSharedModal, setShowSharedModal] = useState(false)
  const dispatch = useDispatch()
  const getCompTrackingInformation = useCallback((childId) =>
    getCompetitionTracking(childId)
      .then((response) => {
        setTrackingInfo(response.tracking_info)
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
      }),
  )
  const routeLocation = useLocation()
  const { t } = useTranslation()

  useEffect(() => {
    getCompetitionStructure(childId)
      .then((response) => {
        setCompetitionStruct({
          modules: response.modules,
          exercisesOrder: response.exercisesOrder,
          allAnswers: response.allAnswers,
          exercises: response.exercises,
        })

        getCompTrackingInformation(childId)
      })
      .catch((error) => {
        console.log(error)
      })

    let fetchInterval = setInterval(() => {
      getCompTrackingInformation(childId)
    }, 5000)
    return () => {
      clearInterval(fetchInterval)
    }
  }, [childId])

  function renderAnswer(exerciseId, trackInfo) {
    const answer = competitionStruct.modules.length
      ? Object.values(trackInfo.answers)
          .flatMap((x) => x.exercises)
          .find((x) => Object.keys(x).includes(`id_${exerciseId}`))
      : Object.values(trackInfo.answers).find((x) =>
          Object.keys(x).includes(`id_${exerciseId}`),
        )
    const answerValue = answer[`id_${exerciseId}`]
    const answerType = +answer.answer_type
    const mediaFiles = answer.media_files
    const answerDescriptions = answer.answer_descriptions

    if (answerValue || answerType === 3) {
      let answerView,
        renderAnswers = ''
      let scoringType = null
      switch (answerType) {
        case 0:
          if (typeof answerValue === 'string') {
            // single answer was provided
            const answerText = competitionStruct.allAnswers[answerType].find(
              (x) => x.id === +answerValue,
            ).answer
            const answerIsCorrect = competitionStruct.allAnswers[
              answerType
            ].find((x) => x.id === +answerValue).is_correct
            answerView = (
              <div
                style={{
                  color: answerIsCorrect ? COLORS.secGreen : COLORS.secRed,
                }}
              >
                {Parser(answerText)}
              </div>
            )
          } else {
            // single other answers were provided
            const otherAnswers = JSON.parse(
              competitionStruct.allAnswers[answerType].find(
                (x) => x.id === +answerValue[0],
              ).other_answers,
            )
            const answerIsCorrect = competitionStruct.allAnswers[
              answerType
            ].find((x) => x.id === +answerValue[0]).is_correct
            for (let i = 0; i < otherAnswers.length; i++) {
              const element = otherAnswers[i]
              const innerAnswerIsCorrect = element.answers
                .map((x) => ` ${x.answer} `)
                .some((y) =>
                  ` ${answerValue[i + 1]} `.includes(y),
                )
              renderAnswers += `
                <div
                  style="color: ${
                    answerIsCorrect && innerAnswerIsCorrect
                      ? COLORS.secGreen
                      : COLORS.secRed
                  };${i > 0 ? 'margin-top: 10px;' : ''}"
                >
                  ${element.description} ${answerValue[i + 1]}
                </div>
              `
            }
            answerView = Parser(renderAnswers)
          }
          break
        case 1:
          // multiple answers were provided
          scoringType = competitionStruct.exercises.find(
            (x) => x.id === exerciseId,
          ).scoring_type
          const answersMulti = competitionStruct.allAnswers[
            answerType
          ].filter((x) => answerValue.includes(`${x.id}`))
          const allAnswersAreCorrect =
            answersMulti.length ===
              competitionStruct.allAnswers[answerType].filter(
                (x) => x.kyoso_exercise_id === exerciseId && x.is_correct,
              ).length &&
            answersMulti.filter((x) => x.is_correct).length ===
              competitionStruct.allAnswers[answerType].filter(
                (x) => x.kyoso_exercise_id === exerciseId && x.is_correct,
              ).length
          for (let i = 0; i < answersMulti.length; i++) {
            const element = answersMulti[i]
            renderAnswers += `
              <div
                style="color: ${
                  scoringType === '1'
                    ? allAnswersAreCorrect
                      ? COLORS.secGreen
                      : COLORS.secRed
                    : element.is_correct
                    ? COLORS.secGreen
                    : COLORS.secRed
                };${i > 0 ? 'margin-top: 10px;' : ''}"
              >
                ${element.answer}
              </div>
            `
          }
          answerView = Parser(renderAnswers)
          break
        case 2:
          // short text answer was provided
          scoringType = competitionStruct.exercises.find(
            (x) => x.id === exerciseId,
          ).scoring_type
          const answersShort = competitionStruct.allAnswers[
            answerType
          ].filter((x) => Object.keys(answerValue).includes(`id_${x.id}`))
          const allShortAnswersAreCorrect =
            answersShort.filter((x) =>
              JSON.parse(x.answer)
                .map((y) => ` ${y.answer} `)
                .some((z) =>
                  ` ${answerValue[`id_${x.id}`]} `.includes(z),
                ),
            ).length === answersShort.length
          for (let i = 0; i < answersShort.length; i++) {
            const element = answersShort[i]
            const innerAnswerIsCorrect = JSON.parse(element.answer)
              .map((x) => ` ${x.answer} `)
              .some((y) =>
                ` ${answerValue[`id_${element.id}`]} `.includes(
                  y,
                ),
              )

            renderAnswers += `
              <div
                style="color: ${
                  scoringType === '1'
                    ? allShortAnswersAreCorrect
                      ? COLORS.secGreen
                      : COLORS.secRed
                    : innerAnswerIsCorrect
                    ? COLORS.secGreen
                    : COLORS.secRed
                };${i > 0 ? 'margin-top: 10px;' : ''}"
              >
                ${element.description} ${answerValue[`id_${element.id}`]}
              </div>
            `
          }
          answerView = Parser(renderAnswers)
          break
        case 3:
          // long text or file answer was provided
          answerView = Object.keys(answerValue).map((key, i) => {
            return (
              <div key={key}>
                <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                  <div>
                    {answerDescriptions.find(
                      (x) => x.ans_id === +key.split('_')[1],
                    ).ans_description || `${i + 1}.`}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'column',
                      gap: 10,
                    }}
                  >
                    {answerValue[key] && (
                      <div
                        style={{
                          color: COLORS.secYellow,
                        }}
                      >
                        {t(
                          'lab:KyosoChildTracking:TrackingTable:SubmitedTextLabel',
                        )}
                      </div>
                    )}
                    {mediaFiles.find((x) => x.ans_id === +key.split('_')[1])
                      .ans_files > 0 && (
                      <div
                        style={{
                          color: COLORS.secYellow,
                        }}
                      >
                        {t(
                          'lab:KyosoChildTracking:TrackingTable:AttachedFilesLabel',
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {Object.keys(answerValue).length > 1 &&
                  i < Object.keys(answerValue).length - 1 && (
                    <div
                      style={{
                        height: 1,
                        margin: '10px 0px',
                        backgroundColor: COLORS.secBlack,
                      }}
                    ></div>
                  )}
              </div>
            )
          })
          break
        default:
          console.error(
            'Answer type was not correct in function renderAnswer from KyosoChilTrackingPage(LAB): ',
            answerType,
          )
          break
      }

      return <td key={exerciseId}>{answerView}</td>
    } else return <td key={exerciseId}></td>
  }

  function calculatePoints(answers, moduleId = null) {
    const iteration = competitionStruct.modules.length
      ? Object.values(answers).flatMap((x) => x.exercises)
      : Object.values(answers)
    let totalPoints = {}
    competitionStruct.exercisesOrder.forEach((id) => {
      totalPoints[id] = 0
    })

    iteration.forEach((answer) => {
      const exerciseId = +Object.keys(answer)
        .find((x) => x.includes('id_'))
        .split('_')[1]
      const answerValue = answer[`id_${exerciseId}`]
      const answerType = +answer.answer_type
      const exerciseWrongAnswerPoints = competitionStruct.exercises.find(
        (x) => x.id === exerciseId,
      ).wrong_answer_points
      const exerciseNoAnswerPoints = competitionStruct.exercises.find(
        (x) => x.id === exerciseId,
      ).no_answer_points
      const scoringType = competitionStruct.exercises.find(
        (x) => x.id === exerciseId,
      ).scoring_type
      const specialScoring = JSON.parse(
        competitionStruct.exercises.find((x) => x.id === exerciseId)
          ?.special_scoring,
      )

      if (answerValue) {
        let answerIsCorrect = null
        switch (answerType) {
          case 0:
            if (typeof answerValue === 'string') {
              // single answer was provided
              answerIsCorrect = competitionStruct.allAnswers[answerType].find(
                (x) => x.id === +answerValue,
              ).is_correct

              totalPoints[exerciseId] += competitionStruct.allAnswers[
                answerType
              ].find((x) => x.id === +answerValue).points
              if (!answerIsCorrect) {
                totalPoints[exerciseId] += exerciseWrongAnswerPoints
              }
            } else {
              // single other answers were provided
              const otherAnswers = JSON.parse(
                competitionStruct.allAnswers[answerType].find(
                  (x) => x.id === +answerValue[0],
                ).other_answers,
              )
              answerIsCorrect = competitionStruct.allAnswers[answerType].find(
                (x) => x.id === +answerValue[0],
              ).is_correct

              let innerAnswersCorrect = 0
              for (let i = 0; i < otherAnswers.length; i++) {
                const element = otherAnswers[i]
                const innerAnswerIsCorrect = element.answers
                  .map((x) => ` ${x.answer} `)
                  .some((y) =>
                    ` ${answerValue[i + 1]} `.includes(y),
                  )
                if (innerAnswerIsCorrect) {
                  innerAnswersCorrect++
                  totalPoints[exerciseId] += +element.answers.find((x) =>
                    ` ${answerValue[i + 1]} `.includes(
                      ` ${x.answer} `,
                    ),
                  ).points
                }
              }
              if (answerIsCorrect) {
                if (innerAnswersCorrect !== otherAnswers.length) {
                  totalPoints[exerciseId] = exerciseWrongAnswerPoints
                }
              } else {
                totalPoints[exerciseId] += exerciseWrongAnswerPoints
              }
            }
            break
          case 1:
            // multiple answers were provided
            const answersMulti = competitionStruct.allAnswers[
              answerType
            ].filter((x) => answerValue.includes(`${x.id}`))

            switch (scoringType) {
              case '0':
                answersMulti.forEach((answer) => {
                  if (answer.is_correct) {
                    totalPoints[exerciseId] += answer.points
                  }
                })
                break
              case '1':
                const allAnswersAreCorrect =
                  answersMulti.length ===
                    competitionStruct.allAnswers[answerType].filter(
                      (x) => x.kyoso_exercise_id === exerciseId && x.is_correct,
                    ).length &&
                  answersMulti.filter((x) => x.is_correct).length ===
                    competitionStruct.allAnswers[answerType].filter(
                      (x) => x.kyoso_exercise_id === exerciseId && x.is_correct,
                    ).length
                if (allAnswersAreCorrect) {
                  answersMulti.forEach((answer) => {
                    if (answer.is_correct) {
                      totalPoints[exerciseId] += answer.points
                    }
                  })
                } else {
                  totalPoints[exerciseId] += exerciseWrongAnswerPoints
                }
                break
              case '2':
                let correctAnswers = answersMulti.filter(
                  (answer) => answer.is_correct,
                )
                totalPoints[exerciseId] +=
                  +specialScoring[correctAnswers.length - 1]?.points || 0
                break
              default:
                console.error(
                  'Scoring type was not correct in calculatePoints from KyosoChilTrackingPage(LAB): ',
                  scoringType,
                )
                break
            }
            break
          case 2:
            // short text answer was provided
            const answersShort = competitionStruct.allAnswers[
              answerType
            ].filter((x) => Object.keys(answerValue).includes(`id_${x.id}`))

            switch (scoringType) {
              case '0':
                answersShort.forEach((element) => {
                  const innerAnswerIsCorrect = JSON.parse(element.answer)
                    .map((x) => ` ${x.answer} `)
                    .some((y) =>
                      ` ${answerValue[
                        `id_${element.id}`
                      ]} `.includes(y),
                    )
                  if (innerAnswerIsCorrect) {
                    totalPoints[exerciseId] += +JSON.parse(
                      element.answer,
                    ).find((x) =>
                      ` ${answerValue[
                        `id_${element.id}`
                      ]} `.includes(
                        ` ${x.answer} `,
                      ),
                    ).points
                  }
                })
                break
              case '1':
                const allShortAnswersAreCorrect =
                  answersShort.filter((x) =>
                    JSON.parse(x.answer)
                      .map((y) => ` ${y.answer} `)
                      .some((z) =>
                        ` ${answerValue[`id_${x.id}`]} `.includes(
                          z,
                        ),
                      ),
                  ).length === answersShort.length

                if (allShortAnswersAreCorrect) {
                  answersShort.forEach((element) => {
                    const innerAnswerIsCorrect = JSON.parse(element.answer)
                      .map((x) => ` ${x.answer} `)
                      .some((y) =>
                        ` ${answerValue[
                          `id_${element.id}`
                        ]} `.includes(y),
                      )
                    if (innerAnswerIsCorrect) {
                      totalPoints[exerciseId] += +JSON.parse(
                        element.answer,
                      ).find((x) =>
                        ` ${answerValue[
                          `id_${element.id}`
                        ]} `.includes(
                          ` ${x.answer} `,
                        ),
                      ).points
                    }
                  })
                } else {
                  totalPoints[exerciseId] += exerciseWrongAnswerPoints
                }
                break
              case '2':
                let correctAnswers = answersShort.filter((x) =>
                  JSON.parse(x.answer)
                    .map((y) => ` ${y.answer} `)
                    .some((z) =>
                      ` ${answerValue[`id_${x.id}`]} `.includes(
                        z,
                      ),
                    ),
                )

                totalPoints[exerciseId] +=
                  +specialScoring?.[correctAnswers.length - 1]?.points || 0
                break
              default:
                console.error(
                  'Scoring type was not correct in function calculatePoints from KyosoChilTrackingPage(LAB): ',
                  scoringType,
                )
                break
            }
            break
          case 3:
            // Nothing needed here
            break
          default:
            console.error(
              'Answer type was not correct in function calculatePoints from KyosoChilTrackingPage(LAB): ',
              answerType,
            )
            break
        }
      } else {
        totalPoints[exerciseId] += exerciseNoAnswerPoints
      }
    })

    let pointsToShow = 0
    if (moduleId) {
      Object.keys(totalPoints)
        .filter((x) =>
          competitionStruct.exercises
            .filter((y) => y.kyoso_child_module_id === moduleId)
            .map((z) => z.id)
            .includes(+x),
        )
        .forEach((key) => {
          pointsToShow += totalPoints[key]
        })
    } else {
      pointsToShow = Object.values(totalPoints).reduce((a, b) => a + b)
    }

    return <div>{pointsToShow}</div>
  }

  function saveModalChanges(
    orderId,
    isDataTouched,
    newUserData,
    paymentType,
    isPaid,
  ) {
    updateParticipation(orderId, {
      newUserData: isDataTouched ? JSON.stringify(newUserData) : '_omit_',
      paymentMethod: paymentType,
      isPaid: isPaid,
    })
      .catch((err) => console.log(err))
      .finally(() => setShowModal(null))
  }

  function openEditOrderDialog(userEmail) {
    dispatch(lab_showGlobalLoading())
    getParticipationsForChildWithFilter(childId, {
      searchText: userEmail,
    })
      .then((res) => {
        setShowModal(res.participations[0])
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(lab_hideGlobalLoading()))
  }

  function showResetModalHandler(userInfo) {
    setShowSharedModal(userInfo)
  }

  function closeResetModalHandler() {
    setShowSharedModal(false)
  }

  function confirmResetResetModalHandler() {
    resetChildParticipation(childId, showSharedModal.participant_id)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
      .finally(() => setShowSharedModal(false))
  }

  return (
    <KyosoChildTrackingLayout>
      <EscButton onClick={() => routeHistory.goBack()} />

      {showModal && (
        <OrderModal
          modalData={showModal}
          closeModalCallback={() => setShowModal(null)}
          saveModalCallback={saveModalChanges}
        />
      )}

      <SharedModal
        show={showSharedModal}
        type="action"
        confirmCallback={confirmResetResetModalHandler}
        rejectCallback={closeResetModalHandler}
        title={t('lab:KyosoChildTracking:ResetModalTitle')}
        content={t('lab:KyosoChildTracking:ResetModalContent')}
        confirmText={t('lab:KyosoChildTracking:ResetModalConfirm')}
        iconClass="fas fa-undo-alt"
      />
      <div className="title">
        <i className="far fa-id-badge"></i>
        <span>
          {childId} - {routeLocation.state?.parent_name} -{' '}
          {routeLocation.state?.child_grade?.name}
        </span>
      </div>
      <div className="tracking-table">
        <table>
          <thead>
            <tr>
              <th rowSpan="2" className="participant-num">
                №
              </th>
              <th rowSpan="2">
                {t(
                  'lab:KyosoChildTracking:TrackingTable:ColumnsNames:Participant',
                )}
              </th>
              <th rowSpan="2">
                {t(
                  'lab:KyosoChildTracking:TrackingTable:ColumnsNames:TimeLeft',
                )}
              </th>
              <th rowSpan="2">
                {t(
                  'lab:KyosoChildTracking:TrackingTable:ColumnsNames:TotalPoints',
                )}
              </th>
              {/* If there are modules show answers and points labels per module in table head */}
              {competitionStruct ? (
                competitionStruct.modules.length ? (
                  competitionStruct.modules.map((v, i) => (
                    <React.Fragment key={i}>
                      <th colSpan={v.exercisesIds.length}>
                        {t(
                          'lab:KyosoChildTracking:TrackingTable:ColumnsNames:ExercisesForModule',
                          { module_name: v.name },
                        )}
                      </th>
                      <th rowSpan="2">
                        {t(
                          'lab:KyosoChildTracking:TrackingTable:ColumnsNames:PointsForModule',
                          { module_name: v.name },
                        )}
                      </th>
                    </React.Fragment>
                  ))
                ) : (
                  <th colSpan={competitionStruct.exercisesOrder.length}>
                    {/* If there are NO modules show one label for answers and points in table head */}
                    {t(
                      'lab:KyosoChildTracking:TrackingTable:ColumnsNames:ExercisesForModule',
                    )}
                  </th>
                )
              ) : null}
            </tr>
            <tr>
              {/* If there are modules show answers with index label per module in table head */}
              {competitionStruct
                ? competitionStruct.modules.length
                  ? competitionStruct.modules.map((module) => {
                      return competitionStruct.exercisesOrder
                        .filter((x) => module.exercisesIds.includes(x))
                        .map((exercise, index) => (
                          <th key={exercise}>
                            {t(
                              'lab:KyosoChildTracking:TrackingTable:ColumnsNames:Exercise',
                              { exercise_index: index + 1 },
                            )}
                          </th>
                        ))
                    })
                  : competitionStruct.exercisesOrder.map((v, i) => (
                      <th key={v}>
                        {/* If there are NO modules show answers with index label in table head */}
                        {t(
                          'lab:KyosoChildTracking:TrackingTable:ColumnsNames:Exercise',
                          { exercise_index: i + 1 },
                        )}
                      </th>
                    ))
                : null}
            </tr>
          </thead>
          <tbody>
            {trackingInfo &&
            trackingInfo.some((x) => {
              return x.answers ? true : false
            }) &&
            competitionStruct?.allAnswers ? (
              trackingInfo.map((track_info, index) => {
                return track_info.answers ? (
                  <tr key={track_info.participant_id}>
                    <td>{++index}.</td>
                    <td className="participant-container">
                      <div>
                        <div className="participant-id">
                          No.: <span>{track_info.participant_id}</span>
                        </div>

                        <div
                          className="participant-name"
                          onClick={() => openEditOrderDialog(track_info.email)}
                        >
                          {track_info.name}
                        </div>
                        <div className="starting-date">
                          {t(
                            'lab:KyosoChildTracking:TrackingTable:StartingDateLabel',
                          )}{' '}
                          <span>
                            {formatDateAndTime(track_info.start_date)}
                          </span>
                        </div>
                      </div>
                      <SubmitButtonLab
                        className="red"
                        customCss={{ maxWidth: 50 }}
                        onClick={() => showResetModalHandler(track_info)}
                      >
                        <i className="fas fa-undo-alt"></i>
                      </SubmitButtonLab>
                    </td>
                    <td>
                      <div className="module-name">
                        {track_info.module_name}
                      </div>
                      <div
                        className="module-time-left"
                        style={{
                          marginTop: track_info.module_name ? 10 : null,
                        }}
                      >
                        {!track_info.end_date && track_info.end_time ? (
                          <ReactCountdown
                            renderer={({ hours, minutes, seconds }) =>
                              `${hours}:${minutes}:${seconds}`
                            }
                            key={track_info.end_time}
                            date={track_info.end_time}
                          />
                        ) : (
                          '00:00:00'
                        )}
                      </div>
                    </td>
                    <td className="total-points">
                      {calculatePoints(track_info.answers)}
                    </td>

                    {/* Rendering results from user based on competition structure and answers structure */}
                    {competitionStruct
                      ? competitionStruct.modules.length
                        ? competitionStruct.modules.map((module) => {
                            return (
                              <React.Fragment key={module.id}>
                                {competitionStruct.exercisesOrder
                                  .filter((x) =>
                                    module.exercisesIds.includes(x),
                                  )
                                  .map((exercise) =>
                                    renderAnswer(exercise, track_info),
                                  )}
                                <td className="module-points">
                                  {calculatePoints(
                                    track_info.answers,
                                    module.id,
                                  )}
                                </td>
                              </React.Fragment>
                            )
                          })
                        : competitionStruct.exercisesOrder.map((exercise) =>
                            renderAnswer(exercise, track_info),
                          )
                      : null}
                  </tr>
                ) : null
              })
            ) : (
              <tr>
                <td
                  colSpan={
                    3 +
                    (competitionStruct && competitionStruct.exercisesOrder
                      ? competitionStruct.exercisesOrder.length
                      : 0) +
                    (competitionStruct &&
                    competitionStruct.exercisesOrder &&
                    competitionStruct.exercisesOrder.length
                      ? competitionStruct.exercisesOrder.length
                      : 1)
                  }
                  style={{ textAlign: 'center' }}
                >
                  Няма данни
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </KyosoChildTrackingLayout>
  )
}

export { KyosoChildTrackingPage }

const KyosoChildTrackingLayout = styled('div')({
  '& .title': {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    color: COLORS.secBlack,
    marginLeft: 20,

    '& i': {
      fontSize: 50,
    },

    '& span': {
      fontSize: 20,
    },
  },

  '& .tracking-table': {
    marginTop: 25,
    width: '100%',
    overflowX: 'auto',

    '.participant-num': {
      textAlign: 'center',
    },

    '& table': {
      width: '100%',
      borderCollapse: 'separate',
      border: `1px solid ${COLORS.mainGray}`,
      borderRadius: 18,

      'tr:first-of-type th:first-of-type ': {
        borderTopLeftRadius: 15,
      },

      'tr:first-of-type th:last-of-type ': {
        borderTopRightRadius: 15,
      },

      'tr:last-of-type td:first-of-type ': {
        borderBottomLeftRadius: 15,
      },

      'tr:last-of-type td:last-of-type ': {
        borderBottomRightRadius: 15,
      },

      'tr:nth-of-type(even)': { backgroundColor: COLORS.bgGray },

      th: {
        border: `1px solid ${COLORS.mainGray}`,
        color: COLORS.secBlue,
        fontSize: 16,
        verticalAlign: 'middle',
      },

      td: {
        border: `1px solid ${COLORS.mainGray}`,
        verticalAlign: 'middle',
        textAlign: 'center',
        padding: 15,
      },

      'td:first-of-type': {
        textAlign: 'left',
        color: COLORS.mainGray,
      },

      '.module-time-left, .module-name': {
        color: COLORS.mainGray,
      },

      '.starting-date, .participant-name': {
        marginTop: 10,
      },

      '.participant-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        flexDirection: 'row-reverse',
        gap: 20,
      },

      '.participant-name': {
        cursor: 'pointer',
      },

      '.participant-name, .starting-date span, .participant-id span': {
        color: COLORS.secBlack,
      },
    },
  },
})
