import styled from '@emotion/styled'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormInputMessage, FormInputDescription } from 'shared/index'

import { COLORS } from 'styling/variables'

export const FormInput = styled(
  ({
    succesMessage,
    register,
    error,
    label,
    type,
    description,
    id,
    onBlur,
    onChange,
    readOnly,
    disabled,
    touched,
    showPassword = false,
    className,
    ...inputProps
  }) => {
    const { t } = useTranslation(['shared'])
    const [isPasswordShown, setShowPassword] = useState(false)
    const showPasswordHandler = () => {
      setShowPassword(!isPasswordShown)
    }

    return (
      <label htmlFor={id} className={className}>
        {t(label) || label}
        <br />
        {description && (
          <FormInputDescription>
            {t(description)}
            <br />
          </FormInputDescription>
        )}
        <FormInputWrapper>
          <Input
            id={id}
            ref={register}
            key={id}
            type={isPasswordShown ? 'text' : type}
            readOnly={readOnly}
            onBlur={onBlur}
            onChange={onChange}
            disabled={disabled}
            {...inputProps}
            className={
              readOnly
                ? 'disabled'
                : error
                ? 'input-error'
                : succesMessage && touched
                ? 'input-success'
                : ''
            }
          ></Input>
          {showPassword && (
            <Eye onClick={showPasswordHandler}>
              <i className="far fa-eye"></i>
            </Eye>
          )}
          {error && (
            <FormInputMessage className="input-error">
              {' '}
              <i className="fas fa-exclamation-triangle"></i>{' '}
              {t(`errors:${error.message}`)}
            </FormInputMessage>
          )}
          {touched && !error && succesMessage && (
            <FormInputMessage className="input-success">
              {' '}
              <i className="fas fa-check-circle"></i> {t(succesMessage)}
            </FormInputMessage>
          )}
        </FormInputWrapper>
      </label>
    )
  },
)(({ customCss, theme, readOnly }) => ({
  textAlign: 'left',
  color: COLORS.mainGray,
  fontSize: 16,

  transition: '0.3s all',
  marginBottom: 30,
  display: 'inline-block',
  position: 'relative',
  width: '100%',

  'input:not(matches([type="email"], [type="number"], [type="password"], [type="search"], [type="tel"], [type="text"], [type="url"]))': {
    display: 'none',
  },

  '&:hover': {
    color: COLORS.primBlue,
  },
  '&:focus-within': {
    color: COLORS.secBlue,
  },
  '& .checkbox-wrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',

    '& input[type="checkbox"]:checked + label ': {
      color: COLORS.secBlue,
    },

    '& input': {
      width: 30,
      marginRight: 10,
    },
  },
  ...customCss,
}))

const Input = styled('input')({
  marginTop: 5,
  border: `2px solid ${COLORS.mainGray}`,
  color: COLORS.secBlue,
  borderRadius: 5,
  height: 45,
  width: '100%',
  boxSizing: 'border-box',
  fontSize: 16,
  paddingLeft: 10,
  transition: '0.3s all',
  fontFamily: 'Balsamiq Sans',
  '&:hover': {
    borderColor: COLORS.primBlue,
  },
  '&:focus': {
    borderColor: COLORS.secBlue,
  },
  '&.input-error': {
    borderColor: COLORS.secRed,
  },
  '&.input-success': {
    borderColor: COLORS.secGreen,
  },

  '&:disabled,&.disabled': {
    border: `1px solid ${COLORS.borderGray}`,
    height: 45,
    backgroundColor: COLORS.disabledGray,
    color: COLORS.mainGray,
  },
})

const Eye = styled('span')({
  background: 'none',
  fontSize: 16,
  position: 'absolute',
  top: 20,
  right: 10,
})

const FormInputWrapper = styled('div')({
  position: 'relative',
})
