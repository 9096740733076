import answer_long from 'assets/img/answer-types/answers_long.svg'
import answer_multi from 'assets/img/answer-types/answers_multi.svg'
import answer_open from 'assets/img/answer-types/answers_open.svg'
import answer_single from 'assets/img/answer-types/answers_single.svg'

export const answer_types = [
  answer_single,
  answer_multi,
  answer_open,
  answer_long,
]
