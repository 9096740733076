import API from 'utils/backendApi'

function getCompetitionTracking(childId) {
  return API('lab').GET(`/kyoso/competition/tracking/${childId}`)
}

function getCompetitionStructure(childId) {
  return API('lab').GET(`/kyoso/competition/structure/${childId}`)
}

function getParticipationsForChildWithFilter(
  childId,
  {
    itemsPerPage = '_omit_',
    pageNumber = '_omit_',
    searchText = '_omit_',
    startDate = '_omit_',
    endDate = '_omit_',
    paymentMethod = '_omit_',
    isPaid = '_omit_',
    isUnpaid = '_omit_',
    isInvited = '_omit_',
    isAutoSignedUp = '_omit_',
    declaration = '_omit_',
  } = {},
) {
  const body = JSON.stringify({
    items: itemsPerPage,
    page: pageNumber,
    search_text: searchText,
    start_date: startDate,
    end_date: endDate,
    payment_method: paymentMethod,
    is_paid: isPaid,
    is_unpaid: isUnpaid,
    is_invited: isInvited,
    is_auto_signedup: isAutoSignedUp,
    declaration,
  })
  return API('lab').POST(
    `/kyoso/competition/child/${childId}/participations`,
    body,
  )
}

function updateParticipation(
  participationId,
  { newUserData = '_omit_', paymentMethod = '_omit_', isPaid = '_omit_' } = {},
) {
  const body = JSON.stringify({
    new_user_data: newUserData,
    payment_method: paymentMethod,
    is_paid: isPaid,
  })
  return API('lab').POST(
    `/kyoso/competition/participation/${participationId}/edit`,
    body,
  )
}

function getCompetitionEvaluationData(childId) {
  return API('lab').GET(`/kyoso/competition/${childId}/evaluation`)
}

function getCompetitionManualScoring(childId) {
  return API('lab').GET(`/kyoso/competition/${childId}/manualScoring`)
}

function saveCompetitionNewManualScoring(childId, userId, score) {
  const body = JSON.stringify({
    user_id: userId,
    score,
  })
  return API('lab').POST(`/kyoso/competition/${childId}/manualScoring`, body)
}

function getCompetitionScoresData(childId) {
  return API('lab').GET(`/kyoso/competition/${childId}/score`)
}

function resetChildParticipation(childId, participant_id) {
  const body = JSON.stringify({
    participant_id,
  })

  return API('lab').POST(`/kyoso/competition/${childId}/reset`, body)
}

function saveInvalidateDeclaration(declarationId) {
  return API('lab').PUT(`/kyoso/declaration/${declarationId}/invalidate`)
}

function saveValidateDeclaration(declarationId) {
  return API('lab').PUT(`/kyoso/declaration/${declarationId}/validate`)
}

function saveResetValidatedDeclaration(declarationId) {
  return API('lab').PUT(`/kyoso/declaration/${declarationId}/initial`)
}

function getCompetitionRankingData(childId, isPublic = true) {
  return API('lab').GET(`/kyoso/competition/${childId}/ranking`, {
    headers: { 'X-Is_Public': isPublic },
  })
}

function updateCompetitionRankingData(childId, data) {
  const body = JSON.stringify({
    childId,
    rankingData: JSON.stringify(data),
  })

  return API('lab').PUT('/kyoso/competition/ranking', body)
}

export {
  getCompetitionStructure,
  getCompetitionTracking,
  getParticipationsForChildWithFilter,
  updateParticipation,
  getCompetitionEvaluationData,
  getCompetitionManualScoring,
  saveCompetitionNewManualScoring,
  getCompetitionScoresData,
  resetChildParticipation,
  saveInvalidateDeclaration,
  saveValidateDeclaration,
  saveResetValidatedDeclaration,
  getCompetitionRankingData,
  updateCompetitionRankingData,
}
