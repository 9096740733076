import { Footer } from '../Footer'
import styled from '@emotion/styled'
import HubLogo from '../../../../assets/img/hub-logo.svg'
import { QUERIES } from 'styling/variables'
import { SharedModal } from 'shared'

function LandingLayout({ children }) {
  return (
    <HubLanding id="hub-landing-layout">
      <div id="hub-logo">
        <img src={HubLogo} alt="hub_logo" />
      </div>
      <div id="hub-content">
        <div id="hub-content-children">{children}</div>
        <Footer />
      </div>
      <SharedModal
        customBackgroundCss={{
          top: 0,
          [`@media (max-width: ${QUERIES.mobile}px)`]: {
            position: 'fixed',
          },
        }}
        customModalCss={{}}
      />
    </HubLanding>
  )
}

export { LandingLayout }

const HubLanding = styled('div')({
  height: '100vh',
  position: 'relative',

  '& #hub-logo': {
    textAlign: 'center',
    padding: '1%',

    '& img': {
      width: 320,
      height: 116,
    },
  },

  '& #hub-content': {
    position: 'relative',
    width: '100%',
    paddingBottom: 20,

    '& #hub-content-children': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      paddingBottom: 20,
      minHeight: 'calc(100vh - 200px)',
    },
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    '& #hub-logo': {
      '& img': {
        transform: 'scale(0.6)',
      },
    },
  },
})
