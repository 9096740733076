import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { AgGridComp } from 'shared'
import {
  getCompetitionRankingData,
  updateCompetitionRankingData,
} from '../services/kyoso/competitions'
import {
  lab_hideGlobalLoading,
  lab_showGlobalLoading,
} from '../store/generalSlice'

function KyosoResultsPage() {
  const { childId } = useParams()
  const [rowData, setRowData] = useState(null)
  const [gridApi, setGridApi] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(lab_showGlobalLoading())
    getCompetitionRankingData(childId, false)
      .then((res) => {
        setRowData({ data: res.data, hasModules: res.hasModules })
      })
      .catch((err) => console.log(err))
      .finally(() => {
        dispatch(lab_hideGlobalLoading())
      })
  }, [])

  const updateRanking = () => {
    const dataToSave = gridApi.getModel().rowsToDisplay.map((x) => ({
      rank: +x.data.rank,
      participant_id: x.data.participant_info.participant_uid,
      selected: x.selected,
    }))
    updateCompetitionRankingData(childId, dataToSave).catch((error) => {
      console.log(error)
    })
  }

  return (
    rowData && (
      <div>
        <button onClick={updateRanking}>Test</button>
        <AgGridComp
          setGridApiCallback={(api) => {
            setGridApi(api)
            api.forEachNode((node) => node.setSelected(node.data.selected))
          }}
          gridOptions={{
            rowDragManaged: false,
          }}
          rowData={rowData?.data}
          hasModules={rowData?.hasModules}
          customColDef={{
            rowDrag: false,
            suppressMovable: true,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            suppressMenu: true,
          }}
        />
      </div>
    )
  )
}

export { KyosoResultsPage }
