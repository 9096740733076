import PDFViewer from 'pdf-viewer-reactjs'
import React from 'react'
import { getFileLinkFromServerStorage } from 'utils/helpers/common'

function PdfViewer({ document }) {
  return (
    <PDFViewer
      document={{
        url: getFileLinkFromServerStorage(document),
      }}
    />
  )
}

export { PdfViewer }
