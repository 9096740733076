import styled from '@emotion/styled'
import { GenericButton } from 'platforms/kyoso-hub/shared'
import { hub_selectTheme } from 'platforms/kyoso-hub/store/generalSlice'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SignatureCanvas from 'react-signature-canvas'
import { COLORS, QUERIES } from 'styling/variables'

function DeclarationCanvas({
  onClearCallback,
  onSaveCallback,
  onCloseCallback,
  isFinalized = false,
  signatureURL = null,
}) {
  const { t } = useTranslation()
  const currentTheme = useSelector(hub_selectTheme)
  const [signatureState, setSignatureState] = useState()
  const [isSigned, setIsSigned] = useState(false)
  const sigCanvas = useRef({})

  useEffect(() => {
    setSignatureState(signatureURL)
    if (signatureURL) setIsSigned(true)
    sigCanvas.current.fromDataURL(signatureURL)
  }, [signatureURL])

  const clearSignatureHandler = () => {
    sigCanvas.current.clear()
    setSignatureState(null)
    onClearCallback(null)
    setIsSigned(false)
  }

  const saveSignatureHandler = () => {
    const signature = sigCanvas.current.getCanvas().toDataURL('image/png')
    setSignatureState(signature)
    onSaveCallback(signature)
    sigCanvas.current.clear()
  }

  const setSignature = () => {
    setIsSigned(true)
  }

  return (
    <SignatureElement hasSignature={signatureState}>
      <div className="buttons-wrapper">
        {signatureState ? (
          <>
            {onCloseCallback && signatureURL && (
              <GenericButton
                customCss={{ margin: 0 }}
                type="button"
                onClick={onCloseCallback}
                theme={currentTheme}
              >
                {t('shared:Modals:ModalCloseButtonHub')}
              </GenericButton>
            )}

            {isFinalized ? null : (
              <GenericButton
                type="button"
                className="inverted"
                customCss={{ margin: 0 }}
                onClick={clearSignatureHandler}
                theme={currentTheme}
              >
                {t('shared:ResetSignature')}
              </GenericButton>
            )}
          </>
        ) : (
          <>
            {isSigned ? (
              <GenericButton
                customCss={{ margin: 0 }}
                onClick={saveSignatureHandler}
                theme={currentTheme}
              >
                {t('hub:SignatureButton')}
              </GenericButton>
            ) : (
              <p className="canvas-warning">
                {t('shared:IsNotSignedCanvasWarning')}
              </p>
            )}
          </>
        )}
      </div>

      <SignatureCanvas
        ref={sigCanvas}
        clearOnResize={false}
        onEnd={setSignature}
        canvasProps={{
          className: 'signature-canvas',
        }}
      />
    </SignatureElement>
  )
}

export { DeclarationCanvas }

const SignatureElement = styled('div')(({ customCss, hasSignature }) => {
  return {
    display: 'flex',
    gap: 20,
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: 60,

    '.signature-canvas': {
      height: 150,
      width: 300,
      padding: 5,
      pointerEvents: `${hasSignature ? 'none' : 'auto'}`,
      borderRadius: '25px',
      border: `2px solid ${COLORS.secRed}`,
    },

    '.buttons-wrapper': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 20,
    },

    '.canvas-warning': {
      color: COLORS.secRed,
    },

    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      display: 'flex',
      flexDirection: 'column-reverse',

      justifyContent: 'center',
      alignItems: 'center',
      gap: 20,

      '.buttons-wrapper': {
        justifyContent: 'center',
        gap: 20,
      },
    },

    ...customCss,
  }
})
