import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { hub_selectUser } from '../../store/userSlice'
import styled from '@emotion/styled'
import { COLORS, QUERIES } from '../../../../styling/variables'
import {
  emailValidation,
  telephoneValidation,
  subjectAndQuestionValidation,
} from '../../../../utils/helpers/validators'
import { sendInquiry } from '../../services/general'
import { hub_selectTheme } from '../../store/generalSlice'
import {
  PageInfo,
  FormInput,
  SubmitButtonHub,
  FormInputLabel,
  FormInputMessage,
  ConfirmationMessage,
} from '../../../../shared/index'
import { useState } from 'react'

function SupportForm() {
  const currentTheme = useSelector(hub_selectTheme)
  const [isInquirySent, setIsInquirySent] = useState(false)
  const { register, handleSubmit, reset, formState } = useForm({
    mode: 'onBlur',
  })
  const reduxUser = useSelector(hub_selectUser)
  const { t } = useTranslation()

  const onSubmit = (data) => {
    sendInquiry(reduxUser.id, data.subject, data.issue, {
      alt_email: data.email,
      phone: data.telephone,
    })
      .then((response) => {
        setIsInquirySent(true)
        reset()
      })
      .catch((error) => console.log(error))
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageInfo
          title={t('shared:Support:FormHeader')}
          subtitle={t('shared:Support:FormHeaderDescription')}
          customCss={{
            '& h1': {
              marginBottom: 10,
            },
            '& h2': {
              marginBottom: 20,
            },
          }}
        />

        {isInquirySent && (
          <ConfirmationMessage
            color={COLORS.secBlue}
            message={t('shared:Support:InquirySent')}
            removeMessageCallback={() => setIsInquirySent(false)}
          />
        )}

        <FormWrapper>
          <FormInput
            customCss={{ gridColumn: '1 / 3', alignSelf: 'end' }}
            id="emailSupport"
            type="text"
            name="email"
            label="hub:LabelEmail"
            description="EmailSupportPageDescription"
            register={register({
              validate: {
                emailValidation: (value) => {
                  if (value) {
                    return emailValidation(value)
                  }
                },
              },
            })}
            error={formState.errors.email}
            touched={formState.touched.email}
          ></FormInput>

          <FormInput
            customCss={{ gridColumn: '3 / 5' }}
            id="telephone"
            type="text"
            name="telephone"
            label="LabelTelephone"
            description="TelephoneSupportPageDescription"
            register={register({
              validate: {
                telephoneValidation: (value) => telephoneValidation(value),
              },
            })}
            error={formState.errors.telephone}
            touched={formState.touched.telephone}
          ></FormInput>

          <FormInput
            customCss={{ gridColumn: '1 / 5' }}
            id="subject"
            type="text"
            name="subject"
            label="LabelSubject"
            register={register({
              required: 'Required',
              validate: {
                subjectAndQuestionValidation: (value) =>
                  subjectAndQuestionValidation(value),
              },
            })}
            error={formState.errors.subject}
            touched={formState.touched.subject}
          ></FormInput>

          <InputCenterAndStrech>
            <FormInputLabel customCss={{ margin: 0 }}>
              {t('shared:LabelIssue')}{' '}
              <TextArea
                className={formState.errors.issue ? 'input-error' : null}
                name="issue"
                ref={register({
                  required: 'Required',
                  validate: {
                    subjectAndQuestionValidation: (value) =>
                      subjectAndQuestionValidation(value),
                  },
                })}
              ></TextArea>
              {formState.errors.issue && (
                <FormInputMessage className="input-error">
                  <i class='fas fa-exclamation-triangle'></i> {' '}
                  {t(`errors:${formState.errors.issue.message}`)}
                </FormInputMessage>
              )}
            </FormInputLabel>
          </InputCenterAndStrech>
          <SubmitButtonHub id="supportSubmit" theme={currentTheme} />
        </FormWrapper>
      </form>
    </div>
  )
}

export { SupportForm }

const FormWrapper = styled('div')({
  display: 'grid',
  gap: 20,
  gridTemplateColumns: '1fr 1fr 1fr 1fr',

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})

const InputCenterAndStrech = styled('div')({
  gridColumn: '1 / 4',
})

const TextArea = styled('textarea')({
  marginTop: 5,
  border: `2px solid ${COLORS.mainGray}`,
  color: COLORS.secBlue,
  borderRadius: 5,
  height: 300,
  width: '100%',
  boxSizing: 'border-box',
  fontSize: 18,
  paddingLeft: 15,
  transition: '0.3s all',
  resize: 'vertical',
  '&:hover': {
    borderColor: COLORS.primBlue,
  },
  '&:focus': {
    borderColor: COLORS.secBlue,
  },
  '&.input-error': {
    borderColor: COLORS.secRed,
  },
  '&.input-success': {
    borderColor: COLORS.secGreen,
  },
})
