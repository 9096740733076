import styled from '@emotion/styled'
import { unwrapResult } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import VeskoAvatar from 'assets/img/avatars/vesko_avatar.jpeg'
import { useHistory } from 'react-router'
import { avatars } from '../../../../styling/avatars.js'
import { COLORS, QUERIES, THEMES } from '../../../../styling/variables.js'
import {
  hub_hideGlobalLoader,
  hub_selectTheme,
  hub_setPage,
  hub_showGlobalLoader,
} from '../../store/generalSlice.js'
import { hub_selectUser, hub_updateUser } from '../../store/userSlice.js'
import {
  Avatar,
  AvatarHolder,
  DialogBubble,
  DialogLine,
  Text,
} from '../../shared/index'
import {
  ContentTwoColumsLayout,
  PersonalDataDeclaration,
  SubmitButtonHub,
} from 'shared/index.js'
import {
  deleteDeclaration,
  getDeclaration,
  saveDeclaration,
} from 'platforms/kyoso-hub/services/user.js'

function OnboardingSecondStepForm({ userData }) {
  const currentTheme = useSelector(hub_selectTheme)
  const reduxUser = useSelector(hub_selectUser)
  const [signature, setSignature] = useState(null)
  const routeHistory = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(
      hub_setPage({
        title: t('hub:Onboarding:PageTitle'),
        subtitle: t('hub:Onboarding:Second&ThirdStepSubtitle'),
      }),
    )

    getSignature()
  }, [])

  const getSignature = () => {
    getDeclaration(reduxUser.id)
      .then((response) => {
        setSignature(response.declaration?.signature)
      })
      .catch((error) => console.log(error))
  }

  const nextStepHandler = () => {
    routeHistory.push('/hub/onboarding/3')
  }

  const saveSignatureHandler = (signature) => {
    if (signature)
      saveDeclaration(signature, '0')
        .then(() => {
          setSignature(signature)
        })
        .catch((error) => {
          console.log(error)
        })
    else
      deleteDeclaration({ type: '0' })
        .then(() => {
          getSignature()
          setSignature(null)
        })
        .catch((error) => {
          console.log(error)
        })
  }

  return (
    <ContentTwoColumsLayout
      customCss={{ gridTemplateColumns: '1fr 11fr 1fr', placeItems: 'center' }}
    >
      <div>
        <SubmitButtonHub
          onClick={() => routeHistory.goBack()}
          theme={currentTheme}
          customCss={{
            transform: 'rotate(180deg)',
            widht: 90,
            height: 90,
          }}
        />
      </div>

      <PersonalDataDeclaration
        data={{ ...reduxUser, signature: signature }}
        onSubmitHandler={(e) => {
          saveSignatureHandler(e)
        }}
      />

      <div>
        <SubmitButtonHub
          onClick={nextStepHandler}
          theme={currentTheme}
          customCss={{
            widht: 90,
            height: 90,
          }}
        />
      </div>
      {/* <DialogLine>
        <AvatarHolder
          customCss={{
            marginRight: '20px',
            [`@media (max-width: ${QUERIES.tablet}px)`]: {
              width: '140px',
            },
          }}
          theme={currentTheme}
        >
          <Avatar src={VeskoAvatar} />
        </AvatarHolder>
        <DialogBubble className="arrow-left" theme={currentTheme}>
          <Text>
            {t('hub:Onboarding:VeskoDialog1', {
              name: userData.username
                ? `${userData.username}`
                : `${userData.full_name}`,
            })}
          </Text>
          <Text>{t('hub:Onboarding:VeskoDialog2')}</Text>
        </DialogBubble>
      </DialogLine>

      <DialogLine customCss={{ gridTemplateColumns: '2fr auto' }}>
        <DialogBubble
          className="arrow-right"
          customCss={{
            color: '#fff',
            backgroundColor: `${THEMES[+currentTheme]}`,
          }}
          theme={currentTheme}
        >
          <Text>{t('hub:Onboarding:UserDialog')}</Text>
        </DialogBubble>
        <AvatarHolder
          customCss={{
            marginLeft: '20px',
            gridRow: '1',
            gridColumn: '2',
            [`@media (max-width: ${QUERIES.tablet}px)`]: {
              width: '140px',
            },
          }}
          theme={currentTheme}
        >
          <Avatar src={avatars[reduxUser.avatar || 3].src} />
        </AvatarHolder>
      </DialogLine>

      <ButtonsWrapper>
       

        <Button onClick={onFinishOnboardingHandler} theme={currentTheme}>
          {t('hub:Onboarding:UserResponse2')}
        </Button>
      </ButtonsWrapper> */}
    </ContentTwoColumsLayout>
  )
}

export { OnboardingSecondStepForm }

const Button = styled('button')(({ customCss, theme }) => ({
  borderRadius: '10px',
  border: `2px solid ${THEMES[+theme]} `,
  marginBottom: '25px',
  backgroundColor: '#fff',
  padding: '15px',
  fontSize: '22px',
  color: `${THEMES[+theme]}`,
  width: '450px',
  transition: '0.3s all',

  ...customCss,

  '&:hover': {
    cursor: 'pointer',
    backgroundColor: `${THEMES[+theme]}`,
    color: '#fff',
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    width: '100%',
    padding: 10,
    fontSize: 18,
  },
}))

const ButtonsWrapper = styled('div')(({ customCss }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  ...customCss,
}))
