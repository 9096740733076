import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInput, ConfirmationMessage, SubmitButtonHub } from 'shared/index'
import { COLORS } from 'styling/variables'
import {
  emailValidation,
  matchingPasswordsValidation,
  minLengthValidator,
  whiteSpaceValidator,
  handleSetFormErrors,
} from 'utils/helpers/validators'
import {
  resetUserPassword,
  forgotUserPassword,
} from '../../services/authentication'
import { LandingPageFormGrid, LandingPageTitle } from '../../shared'

function ForgottenPasswordForm() {
  const [isTokenSent, setIsTokenSent] = useState(false)
  const [isPasswordChanged, setIsPaswordChanged] = useState(false)
  const { t } = useTranslation(['shared'])

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    setError,
    formState,
  } = useForm({
    mode: 'onSubmit',
  })

  const {
    register: registerChangePass,
    handleSubmit: handleSubmitChangePass,
    trigger: triggerChangePass,
    getValues: getValuesChangePass,
    setError: setErrorChangePass,
    formState: formStateChangePass,
  } = useForm({
    mode: 'onSubmit',
  })

  const onSubmitTokenRequest = (data) => {
    forgotUserPassword(data.email)
      .then(() => {
        setValue('email', '')
        setIsTokenSent(true)
      })
      .catch((error) => {
        handleSetFormErrors(error.statCode, setError)
      })
  }

  const onSubmitChangePassword = (data) => {
    resetUserPassword(data.token, data.password)
      .then(() => {
        setIsPaswordChanged(true)
      })
      .catch((error) => {
        handleSetFormErrors(error.statCode, setErrorChangePass, 'token')
      })
  }

  return (
    <div>
      <LandingPageTitle color={COLORS.secRed}>
        {t('shared:ForgottenPassword')}
      </LandingPageTitle>

      {isPasswordChanged ? (
        <ConfirmationMessage
          color={COLORS.secBlue}
          message={t('shared:PasswordChangedSuccessfully')}
        />
      ) : isTokenSent ? (
        //IF token is sent
        <form onSubmit={handleSubmitChangePass(onSubmitChangePassword)}>
          <LandingPageFormGrid>
            <div className="left">
              <FormInput
                id="tokenForgottenPass"
                type="text"
                name="token"
                label="LabelForgottenPassCode"
                onChange={(e) => {
                  return {
                    trim: whiteSpaceValidator(e),
                    trigger: triggerChangePass('token'),
                  }
                }}
                register={registerChangePass({
                  required: 'Required',
                })}
                error={formStateChangePass.errors.token}
                touched={formStateChangePass.touched.token}
              ></FormInput>

              <FormInput
                id="passwordForgotten"
                type="password"
                name="password"
                label="hub:LabelPassword"
                succesMessage="CorrectInput"
                description="PasswordDescription"
                showPassword={true}
                onChange={(e) => {
                  return {
                    trim: whiteSpaceValidator(e),
                    trigger: triggerChangePass('password'),
                  }
                }}
                register={registerChangePass({
                  required: 'Required',
                  validate: {
                    minLength: (value) =>
                      minLengthValidator(value, 8, 'PasswordTooShort'),
                  },
                })}
                error={formStateChangePass.errors.password}
                touched={formStateChangePass.touched.password}
              ></FormInput>

              <FormInput
                id="confirmPasswordForgotten"
                type="password"
                name="confirmPassword"
                label="LabelConfirmPassword"
                succesMessage="CorrectInput"
                onChange={(e) => {
                  return {
                    trim: whiteSpaceValidator(e),
                    trigger: triggerChangePass('confirmPassword'),
                  }
                }}
                register={registerChangePass({
                  required: 'Required',
                  validate: {
                    confirmPassword: (value) =>
                      matchingPasswordsValidation(
                        value,
                        getValuesChangePass('password'),
                      ),
                  },
                })}
                error={formStateChangePass.errors.confirmPassword}
                touched={formStateChangePass.touched.confirmPassword}
              ></FormInput>
            </div>
            <div className="right">
              <SubmitButtonHub id="changePasswordSubmit" theme={1} />
            </div>
          </LandingPageFormGrid>
        </form>
      ) : (
        // ID token is not sent
        <form onSubmit={handleSubmit(onSubmitTokenRequest)}>
          <LandingPageFormGrid>
            <div className="left">
              <FormInput
                id="emailForgottenPass"
                type="email"
                name="email"
                onBlur={() => trigger('email')}
                label="hub:LabelEmail"
                register={register({
                  required: 'Required',
                  validate: {
                    emailValidation: (value) => emailValidation(value),
                  },
                })}
                error={formState.errors.email}
                touched={formState.touched.email}
              ></FormInput>
            </div>
            <div className="right">
              <SubmitButtonHub id="requestTokenSubmit" theme={1} />
            </div>
          </LandingPageFormGrid>
        </form>
      )}
    </div>
  )
}

export { ForgottenPasswordForm }
