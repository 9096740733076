import styled from '@emotion/styled'
import { QUERIES } from 'styling/variables'

export const ModalBackground = styled('div')(({ customCss }) => ({
  position: 'fixed',
  display: 'table',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(218,222,230,0.7) 0% 0% no-repeat padding-box',
  zIndex: 500,

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    position: 'fixed',
    top: 50,
    height: 'calc(100% - 100px)',
  },
  ...customCss,
}))
