import styled from '@emotion/styled'
import { render } from '@testing-library/react'
import HTMLReactParser from 'html-react-parser'
import { SubmitButtonLab } from 'platforms/kyoso-lab/shared'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  AnnuledDeclarationWarning,
  CONSTANTS,
  DeclarationInformation,
  EscButton,
  ImgSignatureWrapper,
  ModalBackground,
  ModalLayout,
  PageInfo,
} from 'shared'
import { calculateSchoolYears, formatDateAndTime } from 'utils/helpers/common'
import { getSchoolGradesHook } from 'utils/helpers/hooks'

function DeclarationModal({
  modalData: { participant_info, ...rest },
  closeModalCallback,
  saveModalCallback,
}) {
  const schoolGrades = getSchoolGradesHook()
  const { t } = useTranslation()

  const buttonRenderHandler = ({ isInvalid, isFinalized }) => {
    let renderedButtons = null

    if (!isInvalid && !isFinalized) {
      renderedButtons = (
        <>
          <SubmitButtonLab
            onClick={() =>
              saveModalCallback(
                rest.declaration_info.id,
                CONSTANTS.declaration_status.IS_VALID,
              )
            }
            customCss={{
              width: 'auto',
              minWidth: '200px',
            }}
            className="black"
          >
            {t('shared:ValidateButton')}
          </SubmitButtonLab>

          <SubmitButtonLab
            onClick={() =>
              saveModalCallback(
                rest.declaration_info.id,
                CONSTANTS.declaration_status.ANNULED,
              )
            }
            customCss={{
              width: 'auto',
              minWidth: '200px',
            }}
            className="black"
          >
            {t('shared:AnnulButton')}
          </SubmitButtonLab>

          <SubmitButtonLab
            onClick={closeModalCallback}
            customCss={{
              width: 'auto',
              minWidth: '200px',
            }}
            className="white"
          >
            {t('shared:Modals:ModalCloseButton')}
          </SubmitButtonLab>
        </>
      )
    } else if (isFinalized && !isInvalid) {
      renderedButtons = (
        <>
          <AnnuledDeclarationWarning
            className="valid"
            customCss={{ alignItems: 'center', width: 'auto' }}
          >
            <i className="fas fa-check-circle"></i>
            {t('lab:KyosoOrders:DeclarationModalValidatedDeclaration')}
          </AnnuledDeclarationWarning>

          <SubmitButtonLab
            onClick={() =>
              saveModalCallback(
                rest.declaration_info.id,
                CONSTANTS.declaration_status.RESET,
              )
            }
            customCss={{
              width: 'auto',
              minWidth: '200px',
            }}
            className="black"
          >
            {t('shared:ResetButton')}
          </SubmitButtonLab>

          <SubmitButtonLab
            onClick={closeModalCallback}
            customCss={{
              width: 'auto',
              minWidth: '200px',
            }}
            className="white"
          >
            {t('shared:Modals:ModalCloseButton')}
          </SubmitButtonLab>
        </>
      )
    } else if (!isFinalized && isInvalid) {
      renderedButtons = (
        <>
          <AnnuledDeclarationWarning
            customCss={{ alignItems: 'center', width: 'auto' }}
          >
            <i className="fas fa-exclamation-triangle"></i>
            {t('lab:KyosoOrders:DeclarationModalAnnuledDeclaration')}
          </AnnuledDeclarationWarning>

          <SubmitButtonLab
            onClick={closeModalCallback}
            customCss={{
              width: 'auto',
              minWidth: '200px',
            }}
            className="white"
          >
            {t('shared:Modals:ModalCloseButton')}
          </SubmitButtonLab>
        </>
      )
    }

    return renderedButtons
  }

  return (
    <ModalBackground>
      <ModalLayout>
        <PageInfo
          title={t('lab:KyosoOrders:DeclarationModalTitle', {
            participant_id: rest.participant_id,
          })}
        />

        <EscButton onClick={() => closeModalCallback()} />

        <DeclarationInformation>
          <div className="declaration">
            {HTMLReactParser(
              rest.declaration_info.type === '0'
                ? t(`${'shared:PersonalDeclarationTypeOne'}`, {
                    competition_owner: rest?.owner,
                    competition_name: rest?.competition_name,
                    parent_names: participant_info?.parent_names,
                    child_names: participant_info?.full_name,
                    grade: schoolGrades?.find(
                      (grade) => grade.id === participant_info.grade_id,
                    ).name,
                    school: participant_info?.school,
                    city: participant_info?.city,
                    date_time: formatDateAndTime(rest.order_date),
                    school_year_start: calculateSchoolYears().startSchoolYear,
                    school_year_end: calculateSchoolYears().endSchoolYear,
                  })
                : t(`${'shared:PersonalDeclarationTypeTwo'}`, {
                    competition_owner: rest?.owner,
                    competition_name: rest?.competition_name,
                    parent_names: participant_info?.parent_names,
                    child_names: participant_info?.full_name,
                    grade: schoolGrades?.find(
                      (grade) => grade.id === participant_info.grade_id,
                    ).name,
                    school: participant_info?.school,
                    city: participant_info?.city,
                    date_time: formatDateAndTime(rest.order_date),
                    school_year_start: calculateSchoolYears().startSchoolYear,
                    school_year_end: calculateSchoolYears().endSchoolYear,
                  }),
            )}
          </div>
        </DeclarationInformation>

        <FooterContainer>
          <div className="wrapper">
            {!rest.declaration_info?.isInvalid ? (
              <p className="red">
                {t('lab:KyosoOrders:DeclarationModalWarning')}
              </p>
            ) : null}

            <div className="button-container">
              {buttonRenderHandler(rest.declaration_info)}
            </div>
          </div>

          <ImgSignatureWrapper>
            <img src={rest.declaration_info.signature}></img>
          </ImgSignatureWrapper>
        </FooterContainer>
      </ModalLayout>
    </ModalBackground>
  )
}

export { DeclarationModal }

const FooterContainer = styled('div')(({ customCss }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 300px',
  gap: 200,

  '.red': {
    textAlign: 'justify',
    lineHeight: '1.5',
  },

  '.wrapper': {
    display: 'flex',
    gap: 20,
    flexDirection: 'column',
    justifyContent: 'flex-end',

    '.button-container': {
      display: 'grid',
      gridTemplateColumns: '250px 250px',
      gridTemplateRows: '1fr 1fr',
      gap: 20,

      '.close-button': {
        gridRow: 2,
      },
    },
  },
}))
