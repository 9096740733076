import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { EscButton, PageInfo } from 'shared'
import { KyosoBundleForm } from '../components/kyoso-bundle/KyosoBundleForm'
import {
  getBundle,
  insertBundle,
  updateBundle,
} from '../services/kyoso/bundles'
import {
  lab_hideGlobalLoading,
  lab_showGlobalLoading,
} from '../store/generalSlice'
import { lab_selectUser } from '../store/userSlice'

export function KyosoBundle() {
  const reduxUser = useSelector(lab_selectUser)
  const routeHistory = useHistory()
  const saveUrl = '/lab/dashboard/kyoso/bundles'
  const { action, bundleId } = useParams()
  const [bundleContent, setBundleContent] = useState(null)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (action !== 'insert') {
      dispatch(lab_showGlobalLoading())
      getBundle(bundleId)
        .then((response) => setBundleContent(response.bundle))
        .catch((error) => {
          console.log(error)
        })
        .finally(() => dispatch(lab_hideGlobalLoading()))
    }
  }, [])

  const editBundle = (data) => {
    updateBundle(+bundleId, data.name.trim(), data.schoolSubject, data.grade, {
      year: +data.year,
      price: +data.price,
      participations: +data.participants,
      open: data.isOpen,
      kyoso_children: data.children.length ? data.children.join(',') : null,
      selected_types: data.selected_types.length
        ? data.selected_types.join(',')
        : null,
    })
      .then(() => routeHistory.replace(saveUrl))
      .catch((error) => {
        console.log(error)
      })
  }

  const createBundle = (data) => {
    insertBundle(
      reduxUser.id,
      data.name.trim(),
      data.schoolSubject,
      data.grade,
      {
        year: +data.year || '_omit_',
        price: +data.price || '_omit_',
        participations: +data.participants || '_omit_',
        open: data.isOpen || '_omit_',
        kyoso_children: data.children.length
          ? data.children.join(',')
          : '_omit_',
        selected_types: data.selected_types.length
          ? data.selected_types.join(',')
          : '_omit_',
      },
    )
      .then(() => routeHistory.replace(saveUrl))
      .catch((error) => {
        console.log(error)
      })
  }

  const KyosoBundleActionHandler = (action) => {
    let kyosoBundleForm = null

    if (action === 'insert') {
      kyosoBundleForm = (
        <KyosoBundleForm submitCallback={createBundle} isDisabled={false} />
      )
    } else {
      kyosoBundleForm = bundleContent && (
        <KyosoBundleForm
          isDisabled={!!bundleContent.open}
          bundleContent={bundleContent}
          submitCallback={editBundle}
        />
      )
    }

    return kyosoBundleForm
  }

  return (
    <>
      <PageInfo
        title={
          action === 'insert'
            ? t('lab:KyosoBundle:BundleInsertTitle')
            : t('lab:KyosoBundle:BundleEditTitle')
        }
        customCss={{
          '& h1': {
            marginBottom: 5,
          },
          '& h2': {
            marginBottom: 20,
          },
        }}
      />

      <EscButton onClick={() => routeHistory.replace(saveUrl)} />
      {KyosoBundleActionHandler(action)}
    </>
  )
}
