import styled from '@emotion/styled'
import HTMLReactParser from 'html-react-parser'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PageInfo, TitleIconWrapper } from 'shared'
import { COLORS, QUERIES, THEMES } from 'styling/variables'
import { CompetitionFilterForm } from '../components/competitions/CompetitionFilterForm'
import { filterBundles } from '../services/orders/bundle'
import { CompetitionsContainer, GenericButton } from '../shared'
import {
  hub_hideGlobalLoader,
  hub_selectTheme,
  hub_showGlobalLoader,
} from '../store/generalSlice'

function BundlesSearch() {
  const dispatch = useDispatch()
  const currentTheme = useSelector(hub_selectTheme)
  const { t } = useTranslation()
  const routeHistory = useHistory()
  const [filters, setFilters] = useState([])
  const [bundles, setBundles] = useState([])

  useEffect(() => {
    dispatch(hub_showGlobalLoader())
    filterBundles({
      bundleName: filters?.competition_name || '_omit_',
      schoolGrade: filters?.competition_grade || '_omit_',
      schoolSubject: filters?.competition_school_subject || '_omit_',
      owner: filters?.competition_owner || '_omit_',
      selectedTypes: filters?.types?.length
        ? filters.types.join(',')
        : '_omit_',
    })
      .then((response) => {
        setBundles(response.bundles)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => dispatch(hub_hideGlobalLoader()))
  }, [filters])

  const filtrationHandler = (filters) => {
    setFilters(filters)
  }

  const signUpBundleParticipation = (bundleId) => {
    routeHistory.push(`/hub/bundles/purchase/${bundleId}`)
  }

  return (
    <>
      <TitleIconWrapper>
        <i className="fas fa-money-check-alt"></i>
        <PageInfo title={t('lab:KyosoBundles:KyosoBundlesTitle')} />
      </TitleIconWrapper>
      <PageInfo
        customCss={{
          h2: { color: COLORS.secBlack, marginBottom: 20 },
        }}
        subtitle={t('hub:Competitions:CompetitionsSearchSubtitle')}
      />
      <CompetitionFilterForm submitCallback={filtrationHandler} />

      <CompetitionsContainer>
        {bundles.map((bundle) => {
          return (
            <Bundle key={bundle.id} theme={currentTheme}>
              <div className="participations">
                <span>{bundle.participations}</span>
                <p>{t('shared:Participations')}</p>
              </div>

              <div className="content-wrapper">
                <p className="content-title">
                  {t('hub:Competitions:CompetitionTitle', {
                    parent_name: bundle.name,
                    grade: bundle.grade_name,
                  })}{' '}
                </p>
                <p className="content-subtitle">
                  {t('hub:Competitions:BundleDetails', {
                    subject: bundle.subject_name,
                    year: bundle.year,
                  })}
                </p>
                <p className="content-owner">
                  {t('hub:Competitions:CompetitionOwnerInfo', {
                    competition_owner: bundle.owner_name,
                  })}
                </p>

                <p className="content-price">
                  {HTMLReactParser(
                    t('hub:Competitions:CompetitionPrice', {
                      competition_price: bundle.price,
                    }),
                  )}
                </p>
              </div>

              <div className="button-wrapper">
                <GenericButton
                  customCss={{ margin: 0 }}
                  theme={currentTheme}
                  onClick={() => signUpBundleParticipation(bundle.id)}
                >
                  {t('hub:Competitions:SignUpButton')}
                </GenericButton>
              </div>
            </Bundle>
          )
        })}
      </CompetitionsContainer>
    </>
  )
}

export { BundlesSearch }

const Bundle = styled('div')(({ theme }) => {
  return {
    border: `1px solid ${COLORS.borderGray}`,
    placeItems: 'center',
    borderRadius: 10,
    padding: 15,
    display: 'grid',
    gridTemplateColumns: '100px 2fr 1fr',
    gap: 15,

    '.gray': {
      color: COLORS.mainGray,
    },

    '.participations': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      color: THEMES[+theme],
      span: {
        textAlign: 'center',
        margin: 'auto',
        fontSize: 50,
      },
    },

    '.content-wrapper': {
      justifySelf: 'start',
      display: 'flex',
      flexDirection: 'column',
      gap: 10,

      '.content-title': {
        fontWeight: 600,
      },

      '.content-price ': {
        display: 'flex',
        alignItems: 'center',
        color: THEMES[+theme],

        i: { marginRight: 15, fontSize: 20 },
      },
    },

    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      gridTemplateColumns: '100px 1fr',

      '.button-wrapper': {
        gridColumn: '1 /3',
      },
    },
  }
})
