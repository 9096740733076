import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PageInfo, FormInput, ConfirmationMessage } from 'shared/index'
import { COLORS } from 'styling/variables'
import {
  emailValidation,
  handleSetFormErrors,
  matchingPasswordsValidation,
  minLengthValidator,
  whiteSpaceValidator,
} from 'utils/helpers/validators'
import {
  forgotUserPassword,
  resetUserPassword,
} from '../../services/authentication'
import { SubmitButtonLab } from '../../shared/index'

function ForgottenPasswordForm({ changeViewHandler }) {
  const [isTokenSent, setIsTokenSent] = useState(false)
  const [isPasswordChanged, setIsPaswordChanged] = useState(false)
  const { t } = useTranslation(['shared'])

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    setError,
    formState,
  } = useForm({
    mode: 'onSubmit',
  })

  const {
    register: registerChangePass,
    handleSubmit: handleSubmitChangePass,
    trigger: triggerChangePass,
    getValues: getValuesChangePass,
    setError: setErrorChangePass,
    formState: formStateChangePass,
  } = useForm({
    mode: 'onSubmit',
  })

  const onSubmitTokenRequest = (data) => {
    forgotUserPassword(data.email)
      .then(() => {
        setValue('email', '')
        setIsTokenSent(true)
      })
      .catch((error) => {
        handleSetFormErrors(error.statCode, setError)
      })
  }

  const onSubmitChangePassword = (data) => {
    resetUserPassword(data.token, data.password)
      .then(() => {
        setIsPaswordChanged(true)
      })
      .catch((error) => {
        handleSetFormErrors(error.statCode, setErrorChangePass, 'token')
      })
  }

  return (
    <div>
      <PageInfo
        title={t('shared:ForgottenPassword')}
        subtitle={t('lab:ForgottenPasswordDescription')}
        customCss={{
          '& h1': {
            marginBottom: 10,
          },
          '& h2': {
            marginBottom: 60,
          },
          gridColumn: '1/ 3',
          textAlign: 'center',
        }}
      />

      {isPasswordChanged ? (
        <ConfirmationMessage
          color={COLORS.secBlue}
          message={t('hub:PasswordChangedSuccessfully')}
        />
      ) : isTokenSent ? (
        //IF token is sent
        <form onSubmit={handleSubmitChangePass(onSubmitChangePassword)}>
          <div>
            <FormInput
              id="tokenForgottenPassLab"
              type="text"
              name="token"
              label="LabelForgottenPassCode"
              onChange={(e) => {
                return {
                  trim: whiteSpaceValidator(e),
                  trigger: triggerChangePass('token'),
                }
              }}
              register={registerChangePass({
                required: 'Required',
              })}
              error={formStateChangePass.errors.token}
              touched={formStateChangePass.touched.token}
            ></FormInput>

            <FormInput
              id="passwordForgottenLab"
              type="password"
              name="password"
              label="lab:LabelPassword"
              succesMessage="CorrectInput"
              description="PasswordDescription"
              showPassword={true}
              onChange={(e) => {
                return {
                  trim: whiteSpaceValidator(e),
                  trigger: triggerChangePass('password'),
                }
              }}
              register={registerChangePass({
                required: 'Required',
                validate: {
                  minLength: (value) =>
                    minLengthValidator(value, 8, 'PasswordTooShort'),
                },
              })}
              error={formStateChangePass.errors.password}
              touched={formStateChangePass.touched.password}
            ></FormInput>

            <FormInput
              id="confirmPasswordForgottenLab"
              type="password"
              name="confirmPassword"
              label="LabelConfirmPassword"
              succesMessage="CorrectInput"
              onChange={(e) => {
                return {
                  trim: whiteSpaceValidator(e),
                  trigger: triggerChangePass('confirmPassword'),
                }
              }}
              register={registerChangePass({
                required: 'Required',
                validate: {
                  confirmPassword: (value) =>
                    matchingPasswordsValidation(
                      value,
                      getValuesChangePass('password'),
                    ),
                },
              })}
              error={formStateChangePass.errors.confirmPassword}
              touched={formStateChangePass.touched.confirmPassword}
            ></FormInput>
          </div>
          <div className="right">
            <SubmitButtonLab type="submit" className="black">
              {t('shared:ChangePassword')}
            </SubmitButtonLab>
          </div>
        </form>
      ) : (
        // ID token is not sent
        <form onSubmit={handleSubmit(onSubmitTokenRequest)}>
          <div className="left">
            {/* <div style={{ height: '68px', marginBottom: '30px' }}></div> */}
            <FormInput
              id="emailForgottenPassLab"
              type="text"
              name="email"
              label="lab:LabelEmail"
              onChange={(e) => whiteSpaceValidator(e)}
              onBlur={() => trigger('email')}
              register={register({
                required: 'Required',
                validate: {
                  emailValidation: (value) => emailValidation(value),
                },
              })}
              error={formState.errors.email}
              touched={formState.touched.email}
            ></FormInput>
          </div>
          <div className="right">
            <SubmitButtonLab type="submit" className="black">
              {t('lab:RequestTokenForForgottenPass')}
            </SubmitButtonLab>
          </div>
        </form>
      )}

      <SubmitButtonLab
        onClick={changeViewHandler}
        className="white"
        customCss={{
          marginBottom: 20,
        }}
      >
        {t('shared:BackToLogin')}
      </SubmitButtonLab>
    </div>
  )
}

export { ForgottenPasswordForm }
