import styled from '@emotion/styled'
import { COLORS } from 'styling/variables'

export const AnnuledDeclarationWarning = styled('div')(({ customCss }) => ({
  display: 'flex',
  width: 'max-content',
  gap: 10,
  padding: 10,
  fontSize: 14,
  alignItems: 'baseline',
  backgroundColor: COLORS.secYellow,
  border: `1px solid ${COLORS.secYellow}`,
  borderRadius: 5,
  color: COLORS.secBlack,
  cursor: 'pointer',

  '&.valid': {
    backgroundColor: COLORS.secGreen,
    border: `1px solid ${COLORS.secGreen}`,
    color: '#ffff',
  },

  '&.signed': {
    backgroundColor: COLORS.mainGray,
    border: `1px solid ${COLORS.mainGray}`,
    color: '#ffff',
  },

  '&.not-signed': {
    backgroundColor: '#ffff',
    border: `1px solid ${COLORS.secBlack}`,
  },

  ...customCss,
}))
