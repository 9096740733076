import styled from '@emotion/styled'
import { mergeDeepRight } from 'ramda'
import { COLORS, THEMES } from 'styling/variables'
import Parser from 'html-react-parser'

export const PageInfo = styled(({ id, title, subtitle, className }) => {
  return (
    <div id={id} className={className}>
      {title && <h1>{title}</h1>}
      {subtitle && <h2>{Parser(subtitle)}</h2>}
    </div>
  )
})(({ customCss = {}, theme }) =>
  mergeDeepRight(
    {
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'Balsamiq Sans',
      justifyContent: 'center',
      '& h1': {
        color: THEMES[theme || 3],
        fontSize: 18,
      },
      '& h2': {
        color: COLORS.mainGray,
        fontSize: 16,
        fontWeight: 'normal',
      },
    },
    customCss,
  ),
)
