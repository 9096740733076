import styled from '@emotion/styled'
import HTMLReactParser from 'html-react-parser'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import {
  ContentDescription,
  ContentTwoColumsLayout,
  FirstColumn,
  PersonalDataDeclaration,
  SecondColumn,
} from 'shared'
import { THEMES } from 'styling/variables'
import { BundlePurchaseForm } from '../components/bundle-purchase/bundlePurchaseForm'
import { SignUpBundleModal } from '../components/bundle-purchase/SignUpBundleModal'
import {
  createBundleOrder,
  filterBundles,
  getChildrenForBundle,
} from '../services/orders/bundle'
import {
  deleteDeclaration,
  getDeclaration,
  saveDeclaration,
} from '../services/user'
import {
  hub_hideGlobalLoader,
  hub_selectTheme,
  hub_setPage,
  hub_showGlobalLoader,
} from '../store/generalSlice'
import { hub_selectUser } from '../store/userSlice'

function BundlePurchasePage() {
  const routeHistory = useHistory()
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(null)
  const getUserData = useSelector(hub_selectUser)
  const [userData, setUserData] = useState(getUserData)
  const [bundleData, setBundleData] = useState(null)
  const [signature, setSignature] = useState(null)
  const [bundleChildrenData, setBundleChildrenData] = useState(null)
  const { bundleId } = useParams()
  const currentTheme = useSelector(hub_selectTheme)
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(hub_showGlobalLoader())
    Promise.all([filterBundles({ bundleId }), getChildrenForBundle(bundleId)])
      .then((responses) => {
        getSignature()
        setBundleChildrenData(responses[1].children)
        setBundleData(responses[0].bundles[0])
        dispatch(
          hub_setPage({
            title: t('hub:BundlePurchase:BundlePurchaseTitle', {
              bundle_name: responses[0].bundles[0].name,
            }),
          }),
        )
      })
      .catch((error) => {
        console.log(error)
        if (error.statCode === 130) {
          routeHistory.replace('/hub/bundles/search')
        }
      })
      .finally(() => dispatch(hub_hideGlobalLoader()))
  }, [])

  const onInputChangeHandler = (data) => {
    setUserData({ ...userData, ...data })
  }

  const onSubmit = (data, formState) => {
    const hasInvoiceData = data.invoice || null
    const invoiceData = (({
      invoiceType,
      invoice_PIN,
      invoice_VAT,
      invoice_address,
      invoice_city,
      invoice_name,
      invoice_firm_name,
      invoice_receiver,
      invoice_responsible_person,
      invoice_unit_id_code,
    }) => ({
      type: invoiceType,
      company: {
        invoice_VAT,
        invoice_address,
        invoice_city,
        invoice_firm_name,
        invoice_receiver,
        invoice_responsible_person,
        invoice_unit_id_code,
      },
      individual: {
        invoice_PIN,
        invoice_address,
        invoice_city,
        invoice_name,
      },
    }))(data)
    const newUserData = {
      city: data.city,
      fullName: data.fullName,
      grade_id: data.grade_id,
      parent_names: data.parent_names,
      parent_phone: data.parent_phone,
      school: data.school,
    }

    const touchedFields = Object.keys(formState.touched).filter(
      (field) => !['invoice', 'paymentType'].some((key) => field.includes(key)),
    )
    let isDataTouched = false
    if (touchedFields.length || data.grade_id != userData.grade_id) {
      isDataTouched = true
    }

    let selectedChildrenArray = data.bundled_competitions

    if (typeof selectedChildrenArray !== 'object') {
      if (!selectedChildrenArray) {
        selectedChildrenArray = []
      } else {
        selectedChildrenArray = [selectedChildrenArray]
      }
    }

    createBundleOrder(
      bundleId,
      isDataTouched,
      data.paymentType,
      selectedChildrenArray.join(','),
      bundleChildrenData
        .filter((x) => selectedChildrenArray.includes(x.id.toString()))
        .reduce((prev, next) => {
          prev[next.id] = next.parent_id
          return prev
        }, {}),
      {
        newUserData: isDataTouched ? JSON.stringify(newUserData) : null,
        invoiceData: hasInvoiceData
          ? invoiceData.type === '1'
            ? JSON.stringify(invoiceData.individual)
            : JSON.stringify(invoiceData.company)
          : null,
      },
    )
      .then((response) => {
        setOpenModal({
          bundle_name: bundleData.name,
          subject: bundleData.subject_name,
          year: bundleData.year,
          bundle_owner: bundleData.owner_name,
          bundle_participations: bundleData.participations,
          order_id: response.order_id,
          order_date: response.order_data,
          price: bundleData.price,
          payment_type: data.paymentType,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getSignature = () => {
    getDeclaration(userData.id)
      .then((response) => {
        setSignature(response.declaration?.signature)
      })
      .catch((error) => console.log(error))
  }

  const saveSignatureHandler = (signature) => {
    if (signature)
      saveDeclaration(signature, '0')
        .then(() => {})
        .catch((error) => {
          console.log(error)
        })
    else
      deleteDeclaration({ type: '0' })
        .then(() => {
          getSignature()
        })
        .catch((error) => {
          console.log(error)
        })
  }

  const closeModalHandler = () => {
    setOpenModal(null)

    routeHistory.replace('/hub/bundles/search')
  }

  return (
    <>
      {openModal && (
        <SignUpBundleModal
          modalData={openModal}
          closeModalCallback={closeModalHandler}
        />
      )}

      <ContentTwoColumsLayout>
        <FirstColumn>
          <BundleHead theme={currentTheme}>
            <div className="participations">
              <span>{bundleData?.participations}</span>
              <p>{t('shared:Participations')}</p>
            </div>

            <div className="content-wrapper">
              <p className="content-title">{bundleData?.name}</p>
              <p className="content-subtitle">
                {t('hub:Competitions:CompetitionSubtitle', {
                  subject: bundleData?.subject_name,
                  year: bundleData?.year,
                })}
              </p>
              <p className="content-owner">
                {t('hub:Competitions:CompetitionOwnerInfo', {
                  competition_owner_id: bundleData?.owner_id,
                  competition_owner: bundleData?.owner_name,
                })}
              </p>
            </div>
          </BundleHead>

          <ContentDescription customCss={{ p: { marginBottom: 10 } }}>
            {HTMLReactParser(t('hub:BundlePurchase:InfoText'))}
          </ContentDescription>

          <BundlePurchaseForm
            pageContent={{
              ...userData,
              children: bundleChildrenData,
              bundleData: bundleData,
            }}
            onChangeCallback={onInputChangeHandler}
            isFree={false}
            onSubmitHandler={onSubmit}
          />
        </FirstColumn>

        <SecondColumn>
          <PersonalDataDeclaration
            data={{ ...userData, ['signature']: signature }}
            onSubmitHandler={saveSignatureHandler}
          />
        </SecondColumn>
      </ContentTwoColumsLayout>
    </>
  )
}

export { BundlePurchasePage }

const BundleHead = styled('div')(({ theme }) => {
  return {
    padding: 20,
    display: 'flex',
    gap: 40,

    '.participations': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      color: THEMES[+theme],
      span: {
        textAlign: 'center',
        margin: 'auto',
        fontSize: 50,
      },
    },

    '.content-wrapper': {
      justifySelf: 'start',
      display: 'flex',
      flexDirection: 'column',
      gap: 10,

      '.content-title': {
        fontWeight: 600,
        fontSize: 24,
      },

      '.content-price ': {
        display: 'flex',
        alignItems: 'center',
        color: THEMES[+theme],

        i: { marginRight: 15, fontSize: 20 },
      },
    },
  }
})
