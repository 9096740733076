import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import {
  ContentTwoColumsLayout,
  ImageWrapper,
  TinyTextContentPreview,
} from 'shared'

import NoImgPlaceholder from 'assets/img/no_img_placeholder.png'
import styled from '@emotion/styled'
import { COLORS, QUERIES, THEMES } from 'styling/variables'
import { useDispatch, useSelector } from 'react-redux'
import {
  hub_hideGlobalLoader,
  hub_selectTheme,
  hub_setPage,
  hub_showGlobalLoader,
} from '../store/generalSlice'
import { useTranslation } from 'react-i18next'
import {
  formatDateAndTime,
  getFileLinkFromServerStorage,
} from 'utils/helpers/common'
import { hub_competitionInfo } from '../store/competitionSlice'
import HTMLReactParser from 'html-react-parser'
import { unwrapResult } from '@reduxjs/toolkit'
import { getCompetitionsExercisesInPDF } from '../services/competition'
import { GenericButton } from '../shared'

function CompetitionPage() {
  const SIGN_UP = 'sign_up'
  const START_COMP = 'start_comp'
  const NO_BUTTON = 'no_button'
  const dispatch = useDispatch()
  const currentTheme = useSelector(hub_selectTheme)
  const [pageContent, setPageContent] = useState(null)
  const { t } = useTranslation()

  const routeHistory = useHistory()
  const { childId } = useParams()

  useEffect(() => {
    dispatch(hub_showGlobalLoader())
    dispatch(hub_competitionInfo({ childId }))
      .then(unwrapResult)
      .then((res) => {
        setPageContent(res)
        dispatch(
          hub_setPage({
            title: `${res.name} - ${res.grade_name} `,
          }),
        )
      })
      .catch((err) => console.log('51', err))
      .finally(() => dispatch(hub_hideGlobalLoader()))
  }, [])

  const startCompetitionHandler = () => {
    routeHistory.push(`/hub/competitions/${childId}/0/0`)
  }

  const participateCompetitionHandler = () => {
    routeHistory.push(`/hub/competitions/participation/${childId}`)
  }

  const downloadExercisesInPDF = () => {
    dispatch(hub_showGlobalLoader())
    getCompetitionsExercisesInPDF(pageContent.child_id)
      .then((response) => {
        const contentDisposition = 'attachment; filename="exercises.pdf"'
        const contentType = 'application/pdf'
        const filename = contentDisposition.match(/filename="(.+)"/)[1]
        const file = new Blob([response.data], {
          type: contentType,
        })
        // For Internet Explorer and Edge
        if ('msSaveOrOpenBlob' in window.navigator) {
          window.navigator.msSaveOrOpenBlob(file, filename)
        }
        // For Firefox and Chrome
        else {
          // Bind blob on disk to ObjectURL
          const data = URL.createObjectURL(file)
          const a = document.createElement('a')
          a.style = 'display: none'
          a.href = data
          a.download = filename
          document.body.appendChild(a)
          a.click()
          // For Firefox
          setTimeout(function () {
            document.body.removeChild(a)
            // Release resource on disk after triggering the download
            window.URL.revokeObjectURL(data)
          }, 100)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => dispatch(hub_hideGlobalLoader()))
  }

  const buttonTypeHandler = (isFooter) => {
    const { is_signed_up, is_ongoing } = pageContent
    const action = is_signed_up
      ? is_ongoing
        ? START_COMP
        : NO_BUTTON
      : SIGN_UP
    let renderButtonType = null

    switch (action) {
      case SIGN_UP:
        renderButtonType = (
          <StartButton
            customCss={{ position: isFooter ? 'relative' : null }}
            theme={currentTheme}
            onClick={participateCompetitionHandler}
          >
            {t('hub:Competitions:SignUpButton')}
          </StartButton>
        )

        break
      case NO_BUTTON:
        renderButtonType = null
        break
      case START_COMP:
        renderButtonType = (
          <>
            <StartButton
              customCss={{ position: isFooter ? 'relative' : null }}
              theme={currentTheme}
              onClick={startCompetitionHandler}
            >
              {t('hub:Competition:StartCompetitionButton')}
            </StartButton>
            {pageContent.child_type_id === 5 ? (
              <div style={{ margin: isFooter ? '0px 10px' : '10px 0px' }}>
                <GenericButton
                  className="inverted"
                  theme={currentTheme}
                  onClick={() => downloadExercisesInPDF()}
                  customCss={{
                    margin: 0,
                    display: 'flex',
                    gap: 10,
                    alignItems: 'baseline',
                    position: 'relative',
                    marginTop: 20,
                  }}
                >
                  <i className="fas fa-download"></i>
                  {t('hub:CompetitionInfo:DownloadBtn')}
                </GenericButton>
              </div>
            ) : null}
          </>
        )

        break

      default:
        break
    }

    return renderButtonType
  }

  return pageContent ? (
    <div style={{ width: '100%' }}>
      <CompetitionHeader>
        <CompetitionInfo theme={currentTheme}>
          <ImageWrapper className="img-wrapper" customCss={{ margin: 0 }}>
            <img
              src={
                pageContent?.picture
                  ? getFileLinkFromServerStorage(pageContent.picture)
                  : NoImgPlaceholder
              }
              alt="exc-img"
            />
          </ImageWrapper>
          <div className="content-wrapper">
            <h2 className="content-title">{`${pageContent?.name} - ${pageContent.grade_name} `}</h2>
            <h3 className="content-subtitle">{`${pageContent?.subject_name} - ${pageContent?.year} - ${pageContent?.type_name} `}</h3>

            <p className="content-owner">
              {t('hub:Competitions:CompetitionOwnerInfo', {
                competition_owner: pageContent.owner_name,
              })}
            </p>
            <p className="content-code">{`${pageContent?.child_uid}`}</p>
            <div className="content-details gray">
              {JSON.parse(pageContent?.parent_intervals).map((dates, index) => (
                <div key={index}>
                  {HTMLReactParser(
                    t('hub:Competitions:CompetitionDetails', {
                      start_date: formatDateAndTime(dates.startDate),
                      end_date: formatDateAndTime(dates.endDate),
                    }),
                  )}
                </div>
              ))}
            </div>
            <p className="content-price">
              {!pageContent.child_price && !pageContent.parent_price ? (
                <span className="free">
                  {HTMLReactParser(t('hub:Participation:PaymentFreeLabel'))}
                </span>
              ) : (
                HTMLReactParser(
                  t('hub:Competitions:CompetitionPrice', {
                    competition_price: pageContent.child_price
                      ? pageContent.child_price
                      : pageContent.parent_price,
                  }),
                )
              )}
            </p>
          </div>
        </CompetitionInfo>
        {buttonTypeHandler(false)}
      </CompetitionHeader>

      <ContentTwoColumsLayout
        customCss={{
          marginBottom: '30px',
          h2: { marginBottom: 20, fontSize: 20 },
          p: { color: COLORS.mainGray },
        }}
      >
        <div>
          <h2>{t('hub:Competition:Rules')}</h2>
          <TinyTextContentPreview html={pageContent?.rules_text} />
        </div>
        <div>
          <h2>{t('hub:Competition:Instructions')}</h2>
          <TinyTextContentPreview html={pageContent?.instructions} />
        </div>
      </ContentTwoColumsLayout>

      <CompetitionFooter>{buttonTypeHandler(true)}</CompetitionFooter>
    </div>
  ) : null
}

export { CompetitionPage }

const CompetitionHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  placeItems: 'center',
  position: 'relative',
  marginBottom: 30,

  [`@media (max-width: ${QUERIES.tablet}px)`]: {
    gap: 10,
  },
})

const CompetitionFooter = styled('div')({
  display: 'flex',
  justifyContent: 'center',
})

const CompetitionInfo = styled('div')(({ theme }) => {
  return {
    placeItems: 'center',
    borderRadius: 10,
    padding: 15,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: 15,

    '.gray': {
      color: COLORS.mainGray,
    },

    '.content-wrapper': {
      justifySelf: 'start',
      display: 'flex',
      flexDirection: 'column',
      gap: 10,

      '.content-title': {
        fontWeight: 600,
        fontSize: 22,
      },

      '.content-price ': {
        display: 'flex',
        alignItems: 'center',
        color: THEMES[+theme],

        i: { marginRight: 15, fontSize: 20 },
      },
    },

    [`@media (max-width: ${QUERIES.mobile}px)`]: {
      gridTemplateColumns: '1fr',
      textAlign: 'center',

      '.content-wrapper': {
        '.content-title': {
          fontSize: 18,
        },

        '.content-price ': {
          justifyContent: 'center',
        },
      },

      '.button-wrapper': {
        gridColumn: '1 /3',
        button: { width: '100%' },
      },
    },
  }
})

const StartButton = styled('button')(({ customCss, theme }) => ({
  position: 'absolute',
  right: '0',
  padding: 10,
  minWidth: 200,
  minHeight: 25,
  borderRadius: 5,
  backgroundColor: THEMES[+theme],
  border: 'none',
  color: '#ffff',
  fontSize: 16,

  ':hover': {
    cursor: 'pointer',
  },

  [`@media (max-width: ${QUERIES.tablet}px)`]: {
    position: 'relative',
  },
  ...customCss,
}))
