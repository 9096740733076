import API from 'utils/backendApi'

function getAllExercisesOfChild(childId) {
  return API('lab').GET(`/kyoso/exercisesOfChild?child=${childId}`)
}

function getExercise(exerciseId) {
  return API('lab').GET(`/kyoso/exercise/${exerciseId}`)
}

function createExercise(
  kyoso_child_id,
  description,
  no_answer_points,
  wrong_answer_points,
  answer_type,
  total_correct,
  answers,
  order,
  {
    solution = '_omit_',
    kyoso_child_module_id = '_omit_',
    instructions = '_omit_',
    scoring_type = '_omit_',
    special_scoring = '_omit_',
  } = {},
) {
  const body = JSON.stringify({
    id: '_omit_',
    kyoso_child_id,
    description,
    no_answer_points,
    wrong_answer_points,
    answer_type,
    total_correct,
    answers,
    order,
    solution,
    kyoso_child_module_id,
    instructions,
    scoring_type,
    special_scoring,
  })
  return API('lab').POST('/kyoso/exercise', body)
}

function updateExercise(
  id,
  description,
  no_answer_points,
  wrong_answer_points,
  answer_type,
  total_correct,
  answers,
  {
    solution = '_omit_',
    kyoso_child_module_id = '_omit_',
    instructions = '_omit_',
    scoring_type = '_omit_',
    special_scoring = '_omit_',
  } = {},
) {
  const body = JSON.stringify({
    id,
    description,
    no_answer_points,
    wrong_answer_points,
    answer_type,
    total_correct,
    answers,
    solution,
    kyoso_child_module_id,
    instructions,
    scoring_type,
    special_scoring,
  })
  return API('lab').POST('/kyoso/exercise', body)
}

function duplicateExercise(exerciseId) {
  const body = JSON.stringify({
    exerciseId,
  })
  return API('lab').POST('/kyoso/exercise/duplicate', body)
}

function deleteExercise(childId, exerciseId) {
  return API('lab').DELETE(`/kyoso/child/${childId}/exercise/${exerciseId}`)
}

function deleteAnswer(answerId, answerType) {
  return API('lab').DELETE(
    `/kyoso/exercise/answer/${answerId}/type/${answerType}`,
  )
}

function deleteExerciseAnswers(exerciseId, answerType) {
  return API('lab').DELETE(
    `/kyoso/exercise/${exerciseId}/answers/type/${answerType}`,
  )
}

function updateExercisesPositions(childId, order) {
  const body = JSON.stringify({
    childId,
    order,
  })
  return API('lab').POST('/kyoso/exercises/positions', body)
}

export {
  getAllExercisesOfChild,
  getExercise,
  createExercise,
  updateExercise,
  duplicateExercise,
  deleteExercise,
  deleteAnswer,
  deleteExerciseAnswers,
  updateExercisesPositions,
}
