import styled from '@emotion/styled'

export const DialogLine = styled('div')(({ customCss }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 2fr',

  alignItems: 'center',
  marginBottom: '40px',
  ...customCss,
}))
