import styled from '@emotion/styled'
import { COLORS } from 'styling/variables'

export const FormInputLabel = styled('label')(({ customCss }) => ({
  color: COLORS.mainGray,
  fontSize: 16,

  transition: '0.3s all',
  marginBottom: 30,
  display: 'inline-block',
  position: 'relative',
  width: '100%',
  '&:hover': {
    color: COLORS.primBlue,
  },
  '&:focus-within': {
    color: COLORS.secBlue,
  },
  ...customCss,
}))
