export const emailValidation = (value) => {
  const regEx = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )

  if (!regEx.test(value)) {
    return 'InvalidEmailFormat'
  }
}

export const matchingPasswordsValidation = (value, passwordToMatch) => {
  const password = passwordToMatch

  if (password !== value) {
    return 'PasswordsDontMatch'
  }
}

export const minMaxValue = (value, valueRange, type, validationMessage) => {
  if (type === 'min') {
    if (value < valueRange) {
      return validationMessage
    }
  } else if (type === 'max') {
    if (value > valueRange) {
      return validationMessage
    }
  }
}

export const minLengthValidator = (value, minLenght, validationMessage) => {
  const valueLength = Array.of(...value).length

  if (valueLength < minLenght) {
    return validationMessage
  }
}

export const maxLengthValidator = (value, maxLenght, validationMessage) => {
  const valueLength = Array.of(...value).length

  if (valueLength > maxLenght) {
    return validationMessage
  }
}

export const whiteSpaceValidator = (event) => {
  return (event.target.value = event.target.value.trim())
}

export const handleSetFormErrors = (errCode, setError, inputName = null) => {
  const errorsByName = [
    {
      codes: [101, 102, 103],
      name: 'email',
    },
    {
      codes: [104],
      name: 'password',
    },
  ]

  if (inputName) {
    setError(inputName, {
      type: 'manual',
      message: `${errCode}`,
    })
  } else {
    const error = errorsByName.find((x) => x.codes.includes(errCode))
    setError(error?.name, {
      type: 'manual',
      message: `${errCode}`,
    })
  }
}

export const userNamaValidator = (value) => {
  const regEx = new RegExp(/^[^\!\@\#\$\%\^\&\*\(\)\+\=\{\}\;\<\>\/\\]*$/)

  if (!regEx.test(value)) {
    return 'InvalidUserNameFormat'
  }
}

export const numbersAndLatinLetters = (value) => {
  const regEx = new RegExp(/[^A-Za-z0-9]+/)

  if (regEx.test(value)) {
    return 'LettersAndNumbersOnly'
  }
}

export const firstAndLastNameValidator = (value) => {
  const regEx = new RegExp(
    /^[^\!\@\#\$\%\^\&\*\(\)\_\+\=\{\}\;\<\>\/\?\.\,0-9]*$/,
  )

  if (!regEx.test(value)) {
    return 'InvalidFirstAndLastNameFormat'
  }
}

export const locationValidator = (value) => {
  const regEx = new RegExp(/^[^\!\@\#\$\%\^\&\*\(\)\_\+\=\{\}\;\<\>\/\?\.\,]*$/)

  if (!regEx.test(value)) {
    return 'InvalidUserNameFormat'
  }
}

export const schoolNameValidator = (value) => {
  const regEx = new RegExp(/^[^\!\@\#\$\%\^\&\*\(\)\_\+\=\{\}\;\<\>\/\?\.\,]*$/)

  if (!regEx.test(value)) {
    return 'InvalidSchoolNameFormat'
  }
}

export const telephoneValidation = (value) => {
  const regEx = new RegExp(
    /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/,
  )
  // console.log(!regEx.test(value))

  if (!regEx.test(value)) {
    return 'InvalidPhoneNumber'
  }
}

export const subjectAndQuestionValidation = (value) => {
  const regEx = new RegExp(/^[^\@\#\$\%\^\&\*\_\+\=\{\}\;\<\>\/]*$/)

  if (!regEx.test(value)) {
    return 'InvalidSubjectAndQuestionFormat'
  }
}

export const yearValidator = (value) => {
  const regEx = new RegExp(/^\d{4}$/)

  if (!regEx.test(value)) {
    return 'InvalidYearFormat'
  }
}
