import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { PageInfo } from '../index'
import { ContentDescription } from 'shared/elements/ContentDescription'
import { DeclarationCanvas } from './DeclarationCanvas'
import { DeclarationInformation } from 'shared/elements/DeclarationInformation'
import HTMLReactParser from 'html-react-parser'
import { calculateSchoolYears, formatDateAndTime } from 'utils/helpers/common'
import { getSchoolGradesHook } from 'utils/helpers/hooks'

export function PersonalDataDeclaration({ data, onSubmitHandler }) {
  const { full_name: fullName } = data

  const { t } = useTranslation(['shared'])
  const schoolGrdes = getSchoolGradesHook()

  return (
    <Wrapper>
      <PageInfo
        title={t('PersonalDataDeclaration')}
        customCss={{
          '& h1': {
            marginBottom: 10,
          },
          '& h2': {
            marginBottom: 20,
          },
        }}
      />
      <ContentDescription
        customCss={{
          marginBottom: '0',
          '& :nth-of-type(2)': {
            marginBottom: '0',
          },
          a: {
            marginBottom: '20px',
            display: 'inline-block',
          },
          p: { marginBottom: 20 },
        }}
      >
        {HTMLReactParser(
          t('PersonalDeclarationDescriptionTypeOne', {
            link: 'https://www.kyoso.tech/site/index.php/privacy-policy/',
          }),
        )}
      </ContentDescription>

      <DeclarationInformation>
        <div className="declaration">
          {HTMLReactParser(
            t('PersonalDeclarationTypeOne', {
              parent_names: data?.parent_names,
              child_names: data?.fullName || fullName,
              grade: schoolGrdes?.find((grade) => grade.id === data?.grade_id)
                ?.name,
              school: data?.school,
              city: data?.city,
              date_time: formatDateAndTime(Date.now()),
              school_year_start: calculateSchoolYears().startSchoolYear,
              school_year_end: calculateSchoolYears().endSchoolYear,
            }),
          )}
        </div>
      </DeclarationInformation>

      <DeclarationCanvas
        signatureURL={data?.signature}
        onSaveCallback={(data) => {
          onSubmitHandler(data, 'declarationInfo')
        }}
        onClearCallback={(data) => {
          onSubmitHandler(data, 'declarationInfo')
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')(({ customCss }) => ({
  display: 'flex',
  flexDirection: 'column',
}))
