import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { RouterConfig } from './routing/routerConfig'
import store from './utils/reduxStore'

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <RouterConfig />
      </BrowserRouter>
    </Provider>
  )
}

export { App }
