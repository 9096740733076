import styled from '@emotion/styled'
import React, { useState } from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { COLORS } from 'styling/variables'
import { formatDateAndTime } from 'utils/helpers/common'

export const AgGridComp = styled(
  ({
    hasModules,
    rowData,
    gridOptions,
    customColDef,
    columnDefs,
    className,
    setGridApiCallback = () => {},
    setGridColumnApiCallback = () => {},
  }) => {
    const [gridApi, setGridApi] = useState(null)
    const [gridColumnApi, setGridColumnApi] = useState(null)

    const defaultColDef = {
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: 'agTextColumnFilter',
      ...customColDef,
    }

    const defaultColGroupDef = {}

    const columnTypes = {
      editableColumn: { editable: true },
    }

    const isFirstColumn = (params) => {
      const displayedColumns = params.columnApi.getAllDisplayedColumns()
      const thisIsFirstColumn = displayedColumns[0] === params.column
      return thisIsFirstColumn
    }

    const onGridReady = (params) => {
      setGridApi(params.api)
      setGridColumnApi(params.columnApi)
      setGridApiCallback(params.api)
      setGridColumnApiCallback(params.columnApi)
    }

    const onBtnExport = () => {
      gridApi.exportDataAsCsv();
    };

    const getDeclarationStat = () => (params) => {
      if (params.data.participant_info.is_declaration_finalized === null) {
        return '<i class="fas fa-times denied"></i>'
      } else if (!params.data.participant_info.is_declaration_finalized) {
        return '<i class="fas fa-info-circle not-confirmed"></i>'
      } else {
        return '<i class="fas fa-check confirmed"></i>'
      }
    }

    const dateFormatter = (time) => (params) => {
      if (time == "start") {
        return formatDateAndTime(params.data.participant_info.start_date)
      } else {
        return formatDateAndTime(params.data.participant_info.end_date)
      }
    }

    const getExerciseValue = (exIndex, modIndex) => (params) => {
      const {
        node: { data },
      } = params
      const exercisesData = hasModules
        ? data.modules[modIndex].exercises[exIndex]
        : data.exercises[exIndex]

      let userAnswer = ''

      if (exercisesData.user_answer) {
        switch (exercisesData.exercise_type) {
          case '0':
            if (exercisesData.user_answer[0].sub_is_other) {
              userAnswer = exercisesData.user_answer
                .map((x) => `${x.sub_answer_description} ${x.sub_answer}`)
                .join('<br/>')
            } else {
              userAnswer = exercisesData.user_answer[0].sub_answer.replace(
                /<[^>]+>/g,
                '',
              )
            }
            break
          case '1':
            userAnswer = exercisesData.user_answer
              .map((x) => `${x.sub_answer.replace(/<[^>]+>/g, '')}`)
              .join('<br/>')
            break
          case '2':
            if (exercisesData.user_answer.length > 1) {
              userAnswer = exercisesData.user_answer
                .map((x) => `${x.sub_answer_description} ${x.sub_answer}`)
                .join('<br/>')
            } else {
              userAnswer = exercisesData.user_answer
                .map((x) => `${x.sub_answer}`)
                .join('<br/>')
            }
            break
          case '3':
            if (exercisesData.user_answer.length > 1) {
              userAnswer = exercisesData.user_answer
                ? exercisesData.user_answer
                    .map((x) => `${x.sub_answer_description} (${x.points}т.)`)
                    .join('<br/>')
                : ''
            } else {
              userAnswer = exercisesData.user_answer
                ? exercisesData.user_answer
                    .map((x) => `${x.points}т.`)
                    .join('<br/>')
                : ''
            }
            break
          default:
            break
        }
      }

      return userAnswer
    }

    const cellClassHandler = (excIndex, modIndex) => (params) => {
      const {
        node: { data, rowIndex },
      } = params

      let className = null

      if (params.value) {
        const {
          user_answer_is_correct: isCorrect,
          user_answer: isAnswered,
          exercise_type,
        } = hasModules
          ? data.modules[modIndex].exercises[excIndex]
          : data.exercises[excIndex]

        if (exercise_type === '3') {
          if (isAnswered) {
            className = 'answer-gray'
          } else {
            className = 'answer-yellow'
          }
        } else {
          if (isCorrect) {
            className = 'answer-green'
          } else {
            className = 'answer-red'
          }
        }
      } else {
        className = 'answer-yellow'
      }

      return className + ' bordered whitespace-normal center-aligned'
    }

    return (
      <>
      <button onClick={() => onBtnExport()}>
            Download CSV export file
          </button>
      <div className={`${className} ag-theme-alpine`}>
        <AgGridReact
          style={{ width: '100%', height: '100%;' }}
          defaultColDef={{
            ...defaultColDef,
            headerCheckboxSelection:
              defaultColDef.headerCheckboxSelection && isFirstColumn,
            checkboxSelection: defaultColDef.checkboxSelection && isFirstColumn,
            rowDrag: defaultColDef.rowDrag && isFirstColumn,
            resizable: true,
          }}
          columnDefs={columnDefs}
          defaultColGroupDef={defaultColGroupDef}
          columnTypes={columnTypes}
          rowData={rowData}
          gridOptions={{
            getRowClass: (params) => {
              if (params.node.rowIndex % 2 === 0) {
                return 'row-odd'
              } else return 'row-even'
            },
            enableCellTextSelection: true,
            ...gridOptions,
          }}
          suppressRowClickSelection={true}
          rowSelection={'multiple'}
          onGridReady={onGridReady}
        >
          <AgGridColumn
            maxWidth={50}
            pinned="left"
            checkboxSelection={true}
            rowDrag={false}
            cellClass="bordered"
          ></AgGridColumn>
          <AgGridColumn
            field="rank"
            pinned="left"
            headerName="No."
            editable={true}
            maxWidth={60}
            cellClass="center-aligned bordered"
            checkboxSelection={false}
            rowDrag={false}
          ></AgGridColumn>
          <AgGridColumn
            field="participant_info.participant_uid"
            headerName="Участник No."
            checkboxSelection={false}
            rowDrag={false}
            maxWidth={120}
            suppressMenu={false}
            pinned="left"
            cellClass="bordered center-aligned"
          ></AgGridColumn>
          <AgGridColumn
            field="participant_info.email"
            headerName="Email"
            checkboxSelection={false}
            rowDrag={false}
            editable={true}
            cellClass="whitespace-normal bordered"
            autoHeight={true}
            width={300}
            maxWidth={400}
          ></AgGridColumn>
          <AgGridColumn
            field="participant_info.name"
            headerName="Име на участник"
            checkboxSelection={false}
            rowDrag={false}
            editable={true}
            cellClass="whitespace-normal bordered"
            autoHeight={true}
            width={300}
            maxWidth={400}
          ></AgGridColumn>
          <AgGridColumn
            field="participant_info.school"
            headerName="Учебно заведение"
            checkboxSelection={false}
            rowDrag={false}
            cellClass="bordered whitespace-normal"
            width={250}
            autoHeight={true}
            maxWidth={300}
          ></AgGridColumn>
          <AgGridColumn
            field="participant_info.city"
            headerName="Населено място"
            checkboxSelection={false}
            rowDrag={false}
            cellClass="bordered whitespace-normal"
            width={150}
            autoHeight={true}
            maxWidth={200}
          ></AgGridColumn>
          <AgGridColumn
            cellRenderer={getDeclarationStat()}
            valueGetter={getDeclarationStat()}
            headerName="Декл."
            checkboxSelection={false}
            rowDrag={false}
            cellClass="bordered center-aligned"
            maxWidth={80}
          ></AgGridColumn>
          <AgGridColumn
            cellRenderer={dateFormatter("start")}
            valueGetter={dateFormatter("start")}
            headerName="Начало"
            checkboxSelection={false}
            rowDrag={false}
            cellClass="bordered center-aligned"
            maxWidth={200}
          ></AgGridColumn>
          <AgGridColumn
            cellRenderer={dateFormatter("end")}
            valueGetter={dateFormatter("end")}
            headerName="Край"
            checkboxSelection={false}
            rowDrag={false}
            cellClass="bordered center-aligned"
            maxWidth={200}
          ></AgGridColumn>
          <AgGridColumn
            field="participant_info.total_points_given"
            headerName="Точки"
            checkboxSelection={false}
            rowDrag={false}
            maxWidth={74}
            cellClass="bold center-aligned bordered"
          ></AgGridColumn>
          <AgGridColumn
            field="participant_info.total_points_given"
            headerName="Точки"
            checkboxSelection={false}
            rowDrag={false}
            pinned="right"
            maxWidth={74}
            cellClass="bold center-aligned bordered"
          ></AgGridColumn>

          {hasModules
            ? rowData[0].modules.map((mod, modIndex) => {
                return (
                  <AgGridColumn
                    key={mod.module_id}
                    headerName={mod.module_name}
                  >
                    {mod.exercises.map((exc, excIndex) => {
                      return (
                        <AgGridColumn
                          cellClass={cellClassHandler(excIndex, modIndex)}
                          cellRenderer={getExerciseValue(excIndex, modIndex)}
                          valueGetter={getExerciseValue(excIndex, modIndex)}
                          key={exc.exercise_id}
                          checkboxSelection={false}
                          autoHeight={true}
                          width={160}
                          headerName={`${exc.exercise_name} ${
                            exc.total_max_points !== null
                              ? `(${exc.total_max_points}т.)`
                              : ' '
                          } `}
                        ></AgGridColumn>
                      )
                    })}
                  </AgGridColumn>
                )
              })
            : rowData[0].exercises.map((exc, index) => {
                return (
                  <AgGridColumn
                    wrapText={true}
                    cellClass={cellClassHandler(index)}
                    cellRenderer={getExerciseValue(index)}
                    valueGetter={getExerciseValue(index)}
                    key={exc.exercise_id}
                    autoHeight={true}
                    width={160}
                    checkboxSelection={false}
                    headerName={`${exc.exercise_name} ${
                      exc.total_max_points !== null
                        ? `(${exc.total_max_points}т.)`
                        : ' '
                    } `}
                  ></AgGridColumn>
                )
              })}
        </AgGridReact>
      </div>
      </>
    )
  },
)(({ customCss, theme }) => ({
  height: '800px',

  '.row-odd': {
    backgroundColor: COLORS.bgGray,
    opacity: 0.85,
  },
  '.row-even': {
    backgroundColor: '#e6e6e6',
  },

  '.cell-styling': {
    whiteSpace: 'normal !important',
    // lineHeight: '20px !important',
  },

  '.ag-header-cell-label': {
    justifyContent: 'left',
  },

  '.answer-red': {
    color: '#ffff',
    backgroundColor: COLORS.secRed,
    textAlign: 'center',
  },

  '.answer-green': {
    color: '#ffff',
    backgroundColor: COLORS.secGreen,
    textAlign: 'center',
  },

  '.answer-yellow': {
    backgroundColor: COLORS.secYellow,
    textAlign: 'center',
  },

  '.ag-cell': {
    lineHeight: '30px',
  },

  '.answer-gray': {
    backgroundColor: `rgba(${COLORS.borderGray}, 0.8)`,
    textAlign: 'center',
  },



  '.ag-row-hover':{
    backgroundColor: '#fff5a6'
  },


  '.center-aligned': {
    textAlign: 'center',
    justifyContent: 'center',
    '& .ag-cell-wrapper': {
      justifyContent: 'center',
    }
  },

  '.bold': {
    fontWeight: 700,
  },

  '.bordered': {
    outline: `1px solid white`,
  },

  '.denied': {
    color: COLORS.secRed,
    fontSize: 20,
  },

  '.not-confirmed': {
    color: COLORS.secBlue,
    fontSize: 20,
  },

  '.confirmed': {
    color: COLORS.secGreen,
    fontSize: 20,
  },

  '.whitespace-normal': {
    whiteSpace: 'normal',
    wordBreak: 'break-word'
  },
  ...customCss,
}))
