import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import {
  FormInputMessage,
  FormInputDescription,
  TinyTextContentPreview,
} from 'shared/index'

import { COLORS } from 'styling/variables'
import selected from 'assets/img/selected.svg'

export const CompetitionRadioButton = styled(
  ({ id, type, key, name, register, label, value, onChange, className }) => {
    const { t } = useTranslation(['shared'])

    return (
      <Label key={key} htmlFor={id} className={className}>
        <input
          type={type}
          name={name}
          ref={register}
          value={value}
          onChange={onChange && onChange}
        />
        <TinyTextContentPreview className="label" html={label} />
      </Label>
    )
  },
)(({ customCss, theme }) => ({
  ...customCss,
}))

// backgroundImage: `url(${selected})`,

export const Label = styled('label')({
  position: 'relative',
  marginTop: '15px',
  display: 'block',
  position: 'relative',
  marginBottom: '12px',
  cursor: 'pointer',
  fontSize: '16px',
  userSelect: 'none',

  'input ': {
    opacity: '0',
    position: 'absolute',
    cursor: 'pointer',
    top: '-4px',
    left: '0px',
    height: '25px',
    width: '100%',
  },

  '.label': {
    zIndex: 1,
    position: 'relative',

    ':before': {
      content: `url(${selected})`,
      position: 'absolute',
      display: 'none',
      bottom: '-22px',
      left: '-5px',
      zIndex: -1,
    },
  },

  ':hover input ~ .label ': {
    color: COLORS.secBlue,
  },

  'input:checked ~ .checkmark:before ': {
    display: 'block',
  },

  'input:checked ~ .label:before': {
    display: 'block',
  },

  'input:checked ~ .checkmark': {
    // backgroundColor: '#fff',
  },

  'input:checked ~ .label': {
    color: COLORS.secBlue,
  },
})
