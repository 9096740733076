import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  FormCheckBox,
  FormInput,
  SchoolGradeSelect,
  SchoolSubjectSelect,
} from 'shared'
import { COLORS } from 'styling/variables'
import styled from '@emotion/styled'
import { SubmitButtonLab } from 'platforms/kyoso-lab/shared'
import { useTranslation } from 'react-i18next'
import { getAllKyosoTypes } from 'services/shared'
import { yearValidator } from 'utils/helpers/validators'

function FiltrationFormMobile({ toggleFilter, submitCallback }) {
  const {
    reset,
    register,
    control,
    getValues,
    handleSubmit,
    setValue,
    formState,
  } = useForm({
    mode: 'onSubmit',
  })
  const [kyosoTypes, setKyosoTypes] = useState([])
  const [isChecked, setIsChecked] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    getAllKyosoTypes()
      .then((response) => {
        setKyosoTypes(response.types)
      })
      .catch((error) => console.log(error))
  }, [])

  const onSubmitFilter = (data) => {
    let selectedKyosoTypes = []
    Object.keys(data).forEach((k) => {
      if (/^type_\d+\w+/.test(k) && data[k]) {
        selectedKyosoTypes.push(k.split('_')[1])
      }
    })
    submitCallback({
      name: data.name.trim() || null,
      schoolGrade: data.schoolGrade,
      schoolSubject: data.schoolSubject,
      year: data.year || null,
      types: selectedKyosoTypes.length ? selectedKyosoTypes : null,
    })
  }

  const resetDataHandler = () => {
    const defaultValues = {
      schoolGrade: null,
      schoolSubject: null,
    }
    setIsChecked(false)
    reset(defaultValues)
    submitCallback(null)
  }

  return (
    <FormLayout onSubmit={handleSubmit(onSubmitFilter)}>
      <h2>
        <i className="fas fa-filter"></i>
        {t('lab:Kyoso:FiltrationTitle')}
      </h2>
      <FormInput
        customCss={{ marginBottom: '0' }}
        id="filterName"
        type="text"
        name="name"
        label="lab:Kyoso:FilterName"
        register={register()}
        error={formState.errors.username}
        touched={formState.touched.username}
      />

      <FormInput
        customCss={{ marginBottom: '0' }}
        id="year"
        label="lab:Kyoso:FilterYear"
        type="number"
        name="year"
        onChange={() => trigger('year')}
        register={register({
          validate: {
            yearValidation: (value) => {
              if (value) {
                return yearValidator(value)
              }
            },
          },
        })}
        error={formState.errors.year}
      />

      <SchoolSubjectSelect
        customCss={{ marginBottom: '0' }}
        id="schoolSubject"
        label="lab:Kyoso:FilterSchoolSubject"
        name="schoolSubject"
        onChange={() => trigger('schoolSubject')}
        setValue={setValue}
        getValues={getValues}
        control={control}
        isClearable={true}
        defaultValue={null}
      />

      <SchoolGradeSelect
        customCss={{ marginBottom: '0' }}
        id="schoolGrade"
        label="lab:Kyoso:FilterSchoolGrade"
        name="schoolGrade"
        isClearable={true}
        setValue={setValue}
        getValues={getValues}
        onChange={() => trigger('schoolGrade')}
        control={control}
        defaultValue={null}
      />

      {kyosoTypes.map((x) => (
        <FormCheckBox
          key={`${x.id}_${x.name}`}
          id={`type_${x.id}_${x.name}`}
          name={`type_${x.id}_${x.name}`}
          label={x.name}
          register={register()}
          checked={() => isChecked}
          customCss={{
            marginBottom: '0',
            ' .text': {
              marginLeft: '10px',
              gridColumn: '2',
              color: COLORS.mainGray,
              justifySelf: 'left',
            },
          }}
        />
      ))}

      <SubmitButtonLab
        className="black"
        type="submit"
        customCss={{ alignSelf: 'end' }}
      >
        {t('lab:Kyoso:SubmitFilterButton')}
      </SubmitButtonLab>

      <SubmitButtonLab
        className="white"
        type="reset"
        onClick={() => resetDataHandler()}
        customCss={{
          alignSelf: 'end',
          marginTop: '20px',
          i: {
            marginRight: '10px',
          },
        }}
      >
        <i className="fas fa-times"></i>
        {t('lab:Kyoso:ClearFilterButton')}
      </SubmitButtonLab>
      <button type="button" className="button" onClick={() => toggleFilter()}>
        <i className="fas fa-times"></i>
      </button>
    </FormLayout>
  )
}

export { FiltrationFormMobile }

const FormLayout = styled('form')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  border: ` 2px solid ${COLORS.secBlack} `,
  padding: '15px',
  borderRadius: '5px',
  position: 'relative',

  '& h2': {
    textAlign: 'center',
    marginBottom: '20px',
    marginTop: '5px',
    i: {
      marginRight: '10px',
    },
  },

  '& label': {
    marginBottom: '20px',
  },

  '& .button': {
    cursor: 'pointer',

    backgroundColor: '#fff',
    border: 'none',
    alignSelf: 'center',
    position: 'absolute',
    top: '5px',
    right: '5px',

    '& i': {
      fontSize: '30px',
      color: COLORS.mainGray,
    },
  },
})
