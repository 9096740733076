import API from '../../../utils/backendApi'

function getSidebarMenus() {
  return API('hub').GET('/general/sidebar_menus')
}

function getFAQ() {
  return API('hub').GET('/general/faq')
}

function sendInquiry(
  user_id,
  title,
  content,
  { alt_email = '_omit_', phone = '_omit_' } = {},
) {
  const body = JSON.stringify({
    user_id,
    title,
    content,
    alt_email,
    phone,
  })
  return API('hub').POST('/general/new-inquiry', body)
}

export { getSidebarMenus, getFAQ, sendInquiry }
