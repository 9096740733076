import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { hub_selectUser } from '../store/userSlice'
import { OnboardingFirstStepForm } from '../components/onboarding/FirstStepForm'
import { OnboardingSecondStepForm } from '../components/onboarding/SecondStepForm'
import { OnboardingThirdStepForm } from '../components/onboarding/ThirdStepForm'
import { getUserData } from '../services/user'
import styled from '@emotion/styled'
import {
  hub_hideGlobalLoader,
  hub_showGlobalLoader,
} from '../store/generalSlice'

function OnboardingPage() {
  const dispatch = useDispatch()
  const reduxUser = useSelector(hub_selectUser)
  const [userData, setUserData] = useState({})
  const routeHistory = useHistory()
  const { step } = useParams()

  useEffect(() => {
    dispatch(hub_showGlobalLoader())
    getUserData(reduxUser.id)
      .then((response) => {
        if (response.userData.is_onboarded) {
          routeHistory.replace('/hub/dashboard')
        } else {
          setUserData(response.userData)
        }
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(hub_hideGlobalLoader()))
  }, [step])

  const OnboardingStep = (step) => {
    let onboardingStep = null

    switch (step) {
      case '1':
        onboardingStep = <OnboardingFirstStepForm userData={userData} />
        break
      case '2':
        onboardingStep = <OnboardingSecondStepForm userData={userData} />
        break
      case '3':
        onboardingStep = <OnboardingThirdStepForm />
        break
      default:
        onboardingStep = <OnboardingFirstStepForm userData={userData} />
        break
    }

    return onboardingStep
  }

  return <>{OnboardingStep(step)}</>
}

export { OnboardingPage }
