import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router'
import { CONSTANTS, EscButton, PageInfo, TitleIconWrapper } from 'shared'
import { CompetitionsModal } from '../components/kyoso-bundle-orders/CompetitionsModal'
import { EditOrderModal } from '../components/kyoso-bundle-orders/EditOrderModal'
import { KyosoBundleOrdersContent } from '../components/kyoso-bundle-orders/KyosoBundleOrdersContent'
import { KyosoBundleOrdersFiltrationForm } from '../components/kyoso-bundle-orders/KyosoBundleOrdersFiltrationForm'
import { OrderModal } from '../components/kyoso-orders/OrderModal'
import {
  getBundleEvents,
  getOrdersForBundleWithFilter,
} from '../services/kyoso/bundles'

function KyosoBundleOrdersPage() {
  const {
    EDIT_MODAL,
    DECLARATION_MODAL,
    INVOICE_MODAL,
    COMPETITIONS_MODAL,
  } = CONSTANTS.modals
  const routeLocation = useLocation()
  const routeHistory = useHistory()
  const { t } = useTranslation()
  const [filters, setFilters] = useState({})
  const [openModal, setOpenModal] = useState(null)
  const [bundleOrders, setBundleOrders] = useState(null)
  const { bundleId } = useParams()

  useEffect(() => {
    getBundlesOrderData(filters)
  }, [getBundlesOrderData])

  const getBundlesOrderData = useCallback(
    (filtersData) => {
      getOrdersForBundleWithFilter(bundleId, {
        searchText: filtersData?.search || '_omit_',
        startDate: filtersData?.start_date || '_omit_',
        endDate: filtersData?.end_date || '_omit_',
        paymentMethod: filtersData?.payment_type?.value || '_omit_',
        isPaid: filtersData?.is_paid,
        isUnpaid: filtersData?.is_not_paid,
        declaration: filtersData?.declaration,
      })
        .then((response) => {
          setBundleOrders(response.orders)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    [filters],
  )

  const submitHandler = (filters) => {
    setFilters(filters)
  }

  const openModalHandler = async (orderId, modalType) => {
    const [bundleOrder] = bundleOrders.filter((ord) => ord.order_id === orderId)

    try {
      switch (modalType) {
        case EDIT_MODAL:
          // console.log(modalType)
          setOpenModal({ show: modalType, ...bundleOrder })
          break
        case DECLARATION_MODAL:
          setOpenModal({ show: modalType, ...bundleOrder })
          break
        case INVOICE_MODAL:
          setOpenModal({ show: modalType, ...bundleOrder })
          break
        case COMPETITIONS_MODAL:
          const { events } = await getBundleEvents(bundleId)
          // console.log(73, events)

          setOpenModal({
            show: modalType,
            ...bundleOrder,
            competitions: events,
          })
          break
        default:
          // console.log(modalType)
      }
    } catch (err) {
      console.error(err)
    }
  }
  const closeModalHandler = () => {
    setOpenModal(null)
  }

  const saveEditOrderModal = (orderId, isPaid) => {
    //makes request and calls getBundlesOrderData()
  }

  return (
    <>
      {openModal && openModal.show === COMPETITIONS_MODAL && (
        <CompetitionsModal
          modalData={openModal}
          closeModalCallback={closeModalHandler}
        />
      )}

      {openModal && openModal.show === EDIT_MODAL && (
        <EditOrderModal
          modalData={openModal}
          closeModalCallback={closeModalHandler}
          saveModalCallback={saveEditOrderModal}
        />
      )}

      <TitleIconWrapper>
        <i className="fas fa-money-check-alt"></i>{' '}
        <PageInfo
          customCss={{ h1: { fontSize: 20 } }}
          title={t('lab:KyosoBundleOrders:Title', {
            bundle_name: routeLocation.state.name,
          })}
        />
      </TitleIconWrapper>

      <EscButton onClick={() => routeHistory.goBack()} />

      <KyosoBundleOrdersFiltrationForm submitCallback={submitHandler} />

      {bundleOrders && (
        <KyosoBundleOrdersContent
          ordersContent={bundleOrders}
          openModalCallback={openModalHandler}
          changePaidStatusCallback={(orderId, isPaid, paymentType) => {
            saveEditOrderModal(orderId, isPaid)
          }}
        />
      )}
    </>
  )
}

export { KyosoBundleOrdersPage }
