import styled from '@emotion/styled'
import { COLORS, THEMES } from 'styling/variables'

export const CompetitionWrapper = styled('div')(({ customCss, theme }) => {
  return {
    width: '100%',
    border: `1px solid ${COLORS.borderGray} `,
    display: 'flex',
    padding: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 10,

    '.competition-content': {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,

      '#competition-title': {
        fontWeight: 600,
        color: `${THEMES[+theme]}`,
        lineHeight: 1.3,
      },

      '#competition-details': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    '.kyoso_buttons': {
      display: 'flex',
      gap: 20,
    },

    ...customCss,
  }
})
