import styled from '@emotion/styled'
import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { COLORS } from 'styling/variables'
import { answer_types } from 'styling/answer-types'
import { useTranslation } from 'react-i18next'
import { TinyTextContentPreview } from 'shared'
import { AnswersLayout } from 'shared'

export function Exercise({
  id,
  title,
  type,
  answers,
  moduleName,
  hasModules,
  index,
  moveExerciseCard,
  actionHandler,
}) {
  const ref = useRef(null)
  const { t } = useTranslation()
  const ItemTypes = {
    QUESTION: 'question',
  }

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.QUESTION,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveExerciseCard(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.QUESTION,
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <Layout ref={ref} data-handler-id={handlerId} style={{ opacity }}>
      <ExerciseNumber>{index + 1}.</ExerciseNumber>
      <i className="fas fa-exchange-alt drag-icon"></i>
      <ExerciseLayout>
        <ExerciseTitle>
          <TinyTextContentPreview html={title} id="exercise-title" />
        </ExerciseTitle>
        <ExerciseModule
          style={moduleName ? {} : { color: COLORS.secRed, fontSize: 14 }}
        >
          {hasModules
            ? moduleName
              ? moduleName
              : t('lab:KyosoExercises:NoModules')
            : null}
        </ExerciseModule>
        <ExerciseActions>
          <i
            className="fas fa-pencil-alt"
            onClick={() => actionHandler('edit', id, index)}
          ></i>
          <i
            className="far fa-copy"
            onClick={() => actionHandler('duplicate', id, index)}
          ></i>
          <i
            className="fas fa-trash-alt"
            onClick={() => actionHandler('delete', id, index)}
          ></i>
        </ExerciseActions>
        <SecondRow>
          <img id="answer-type" src={answer_types[type]} />

          {type === '2' ? (
            <AnswersLayout>
              {answers.map((ans, index) => {
                const answersModyfied = ans.answer
                  .map((a) => a.answer)
                  .join(' / ')

                const points = ans.answer.map((a) => a.points).join(' / ')

                return (
                  <Answer key={index} className={'correct'}>
                    <div style={{ display: 'flex' }}>
                      <span style={{ color: COLORS.mainGray, marginRight: 5 }}>
                        {t('lab:KyosoExercises:Answer')}
                      </span>

                      <TinyTextContentPreview html={answersModyfied} />
                    </div>
                    <span>{t('lab:Shared:Points', { points: points })}</span>
                  </Answer>
                )
              })}
            </AnswersLayout>
          ) : type === '3' ? (
            <AnswersLayout>
              <span style={{ color: COLORS.mainGray, marginBottom: 20 }}>
                {t('lab:KyosoExercises:AnswerType4Answer')}
              </span>
              {answers.map((ans, index) => {
                return (
                  <Answer key={index}>
                    <div>
                      <span>{t('lab:KyosoExercises:DescriptionAns')}: </span>
                      <span>{ans.description ? ans.description : ''}</span>
                    </div>
                  </Answer>
                )
              })}
            </AnswersLayout>
          ) : (
            <AnswersLayout>
              {answers.map((answer, index) => {
                if (answer.is_other) {
                  const answersModyfied = answer.other_answers
                    .reduce((prev, cur) => {
                      let ans = []
                      cur.answers.forEach((a) => {
                        ans.push(a.answer)
                      })

                      return [...prev, ...ans]
                    }, [])
                    .join(' / ')

                  const points = answer.other_answers
                    .reduce((prev, cur) => {
                      let ans = []
                      cur.answers.forEach((a) => {
                        ans.push(a.points)
                      })

                      return [...prev, ...ans]
                    }, [])
                    .join(' / ')

                  return (
                    <Answer
                      key={index}
                      className={answer.is_correct && 'correct'}
                    >
                      <div style={{ display: 'flex' }}>
                        <TinyTextContentPreview html={answer.answer} />

                        <TinyTextContentPreview html={answersModyfied} />
                      </div>
                      <span>{t('lab:Shared:Points', { points: points })}</span>
                    </Answer>
                  )
                } else {
                  return (
                    <Answer
                      key={index}
                      className={answer.is_correct && 'correct'}
                    >
                      <TinyTextContentPreview html={answer.answer} />
                      <span>
                        {t('lab:Shared:Points', { points: answer.points })}
                      </span>
                    </Answer>
                  )
                }
              })}
            </AnswersLayout>
          )}
        </SecondRow>
      </ExerciseLayout>
    </Layout>
  )
}

const Layout = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '10px',
  position: 'relative',

  ' .drag-icon': {
    display: 'none',
    position: 'absolute',
    fontSize: 'larger',
    color: COLORS.secBlue,
    top: '50%',
    left: '-10px',
    transform: 'translateY(-50%) rotate(90deg)',
  },

  '&:hover ': {
    cursor: 'pointer',
    ' .drag-icon': {
      display: 'block',
    },
  },
})

const ExerciseNumber = styled('span')({
  color: COLORS.mainGray,
  marginRight: '10px',
  marginTop: '15px',
})

const ExerciseLayout = styled('div')({
  padding: 15,
  paddingLeft: 30,
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '3fr 2fr 0.5fr',
  gridRowGap: '20px',
  gridColumnGap: '10px',
  border: `1px solid ${COLORS.borderGray}`,
  borderRadius: '15px',
  alignContent: 'center',
  ':hover': {
    backgroundColor: COLORS.bgGray,
  },
})

const ExerciseTitle = styled('h3')({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  img: {
    height: 'auto',
  },
})

const ExerciseModule = styled('span')({
  color: COLORS.mainGray,

  justifySelf: 'center',
})

const ExerciseActions = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',

  i: {
    fontSize: 'larger',

    ' :hover': {
      cursor: 'pointer',
    },
  },

  '& :last-of-type': {
    color: COLORS.secRed,
  },
})

const SecondRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',

  '& #answer-type': {
    width: '30px',
    alignSelf: 'center',
  },
})

const Answer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10px',
  color: COLORS.mainGray,

  '&.correct': {
    color: `${COLORS.secGreen} !important`,
  },
})
