import styled from '@emotion/styled'
import { COLORS } from 'styling/variables'

export const EscButton = styled(({ className, onClick }) => {
  return (
    <div className={className}>
      <i className="fas fa-times" onClick={onClick} />
    </div>
  )
})(({ customCss }) => ({
  position: 'absolute',
  top: '15px',
  right: '20px',
  width: '20px',
  height: 'auto',
  display: 'grid',
  placeItems: 'center',

  ':hover': {
    cursor: 'pointer',
    '& i': {
      color: COLORS.secBlack,
    },
  },

  i: {
    fontSize: 'x-large',
    color: COLORS.mainGray,
  },

  ...customCss,
}))
