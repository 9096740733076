import styled from '@emotion/styled'
import { mergeDeepRight } from 'ramda'
import { THEMES, QUERIES } from 'styling/variables'

export const AvatarHolder = styled('div')(({ customCss = {}, theme }) =>
  mergeDeepRight(
    {
      width: '150px',
      height: '150px',
      borderRadius: '50%',
      border: `3px solid ${THEMES[+theme]} `,
      overflow: 'hidden',
      gridRow: '1 / span 2',

      [`@media (max-width: ${QUERIES.tablet}px)`]: {
        height: '120px',
        width: '120px',
        justifySelf: 'center',
      },

      [`@media (max-width: ${QUERIES.mobile}px)`]: {
        height: '90px',
        width: '90px',
        justifySelf: 'center',
      },
    },
    customCss,
  ),
)
