import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import hub_logo from '../../../assets/img/hub-logo.svg'
import { IconLinkButton, IconLinkExternalButton } from '../shared/index'
import { useSelector } from 'react-redux'
import { hub_selectTheme } from '../store/generalSlice'

function Sidebar() {
  const { t } = useTranslation()
  const currentTheme = useSelector(hub_selectTheme)

  return (
    <HubSidebar id="hub-sidebar">
      <HubLogo src={hub_logo} alt="hub_logo" />
      <SidebarLinksContainer>
        <IconLinkButton
          theme={currentTheme}
          title={t('shared:Dashboard')}
          to="/hub/dashboard"
        >
          <i className="fas fa-th-large"></i>
        </IconLinkButton>
        <IconLinkButton
          theme={currentTheme}
          title={t('shared:Competitions')}
          to="/hub/competitions"
        >
          <i className="fas fa-award"></i>
        </IconLinkButton>
        {/* <IconLinkButton
          theme={currentTheme}
          title={t('shared:CommingSoon')}
          to="/hub/soon"
        >
          <i className="fas fa-chalkboard-teacher"></i>
        </IconLinkButton> */}
        <IconLinkButton
          className="soon"
          theme={currentTheme}
          title={t('shared:Calendar')}
          to="#"
        >
          <i className="far fa-calendar-alt"></i>
        </IconLinkButton>
        <IconLinkButton
          theme={currentTheme}
          title={t('shared:Statistics')}
          to="/hub/results"
        >
          <i className="far fa-chart-bar"></i>
        </IconLinkButton>
        <IconLinkExternalButton
          onClick={() => window.open('https://kyoso.tech/site', '_blank')}
          theme={currentTheme}
          title={t('shared:News')}
        >
          <i className="far fa-newspaper"></i>
        </IconLinkExternalButton>
        {/* <IconLinkButton
          theme={currentTheme}
          title={t('shared:Notifications')}
          to="/hub/notifications"
        >
          <i className="far fa-bell"></i>
        </IconLinkButton> */}
        <IconLinkButton
          theme={currentTheme}
          title={t('shared:Settings')}
          to="/hub/settings"
        >
          <i className="fas fa-cogs"></i>
        </IconLinkButton>
        <IconLinkButton
          theme={currentTheme}
          title={t('shared:Help')}
          to="/hub/support"
        >
          <i className="far fa-question-circle"></i>
        </IconLinkButton>
      </SidebarLinksContainer>
    </HubSidebar>
  )
}

export { Sidebar }

const HubSidebar = styled('div')({
  gridArea: 'sidebar',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 0px 5px #0000001A',
  position: 'fixed',
  height: '100vh',
  zIndex: 1000,
})

const SidebarLinksContainer = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto',
  gridTemplateColumns: '100%',
  height: '45%',
  minHeight: 400,
})

const HubLogo = styled('img')({
  margin: '25px 10px 30px 10px',
  width: 80,
})
