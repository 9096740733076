import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getAllGrades } from 'services/shared'
import { CONSTANTS } from 'shared'
import { COLORS } from 'styling/variables'
import { formatDateAndTime } from 'utils/helpers/common'
import { getSchoolGradesHook } from 'utils/helpers/hooks'

function OrdersAccordion({
  orders,
  openModalCallback,
  changePaidStatusCallback,
}) {
  const schoolGrades = getSchoolGradesHook()
  const { t } = useTranslation()

  const paymentMethods = [
    t('lab:Shared:PaymentMethodFree'),
    t('lab:Shared:PaymentMethodBank'),
    'EPay',
  ]

  const checkVisibility = (paid, isAutoSignUp, payment_method) => {
    let colorClass

    if ((isAutoSignUp && paid) || payment_method === '0') {
      colorClass = 'blue'
    } else {
      if (paid) {
        colorClass = 'green'
      } else {
        colorClass = 'red'
      }
    }

    return colorClass
  }

  const changeIsPaidHandler = (paid, payment_method, orderId) => {
    if (payment_method === '1' && !paid) {
      changePaidStatusCallback(orderId, true, payment_method)
    }
  }

  const openModalHandler = (participantId, event) => {
    const modalType = event.target.getAttribute('name')

    openModalCallback(participantId, modalType)
  }

  return (
    <div>
      <TableHeader>
        <span>{t('lab:KyosoOrders:Payment')}</span>
        <div className="no-wrap">
          <span>{t('lab:KyosoOrders:ParticipantId')}</span>
          <br />
          <span>{t('lab:KyosoOrders:OrderId')}</span>
        </div>
        <span>{t('lab:KyosoOrders:ParticipantInfo')}</span>
        <span>{t('lab:KyosoOrders:ContactInfo')}</span>
        {/* <span>{t('lab:KyosoOrders:FilterCheckboxInvoice')}</span> */}
        <span>{t('lab:KyosoOrders:FilterCheckboxDeclaration')}</span>
        <span>{t('lab:KyosoOrders:FilterCheckboxInvited')}</span>
        <span>{t('lab:KyosoOrders:FilterCheckboxAutoSignedUp')}</span>
        <span>{t('lab:KyosoOrders:OrderDate')}</span>
        <span>{t('lab:KyosoOrders:Edit')}</span>
      </TableHeader>

      {orders.map((order, index) => {
        const formatedDate = formatDateAndTime(order.order_date)
        const { isInvalid, isFinalized, id } = order.declaration_info

        const participantSchoolAndGrade = `${
          order.participant_info.school ? order.participant_info.school : ''
        } ${
          order.participant_info.grade_id
            ? schoolGrades?.find(
                (grade) => grade.id === order.participant_info.grade_id,
              )?.name
            : ''
        }`

        let declarationStatus = <i className="fas fa-times"></i>

        if (!!isInvalid)
          declarationStatus = (
            <i
              className="fas fa-exclamation-triangle yellow"
              name={CONSTANTS.modals.DECLARATION_MODAL}
              onClick={(e) => openModalHandler(order.order_id, e)}
            ></i>
          )
        else if (!!isFinalized)
          declarationStatus = (
            <i
              className="fas fa-check"
              name={CONSTANTS.modals.DECLARATION_MODAL}
              onClick={(e) => openModalHandler(order.order_id, e)}
            ></i>
          )
        else if (!!id)
          declarationStatus = (
            <i
              className="fas fa-info-circle blue"
              name={CONSTANTS.modals.DECLARATION_MODAL}
              onClick={(e) => openModalHandler(order.order_id, e)}
            ></i>
          )

        return (
          <OrderWrapper className="wrapper" key={order.id}>
            {order.from_bundle_order ? (
              <span className={order.is_bundle_order_paid ? 'green' : 'red'}>
                {t('lab:KyosoOrders:BundleParticipation')}
              </span>
            ) : (
              <span
                className={checkVisibility(
                  order.paid,
                  order.auto_sign_up,
                  order.payment_method,
                  order.id,
                )}
                onClick={() =>
                  changeIsPaidHandler(
                    order.paid,
                    order.payment_method,
                    order.id,
                  )
                }
              >
                {paymentMethods[order.payment_method]}
              </span>
            )}

            <div id="participant-order-id">
              <span>{order.participant_id}</span>
              <br />
              <span>{order.order_id}</span>
            </div>

            <div id="participant-info">
              <span id="participant-name">
                {order.participant_info.full_name}
              </span>
              <span id="participant-school-grade">
                {participantSchoolAndGrade}
              </span>
              <span id="participant-town">{order.participant_info.city}</span>
            </div>

            <div id="contact-info">
              <span id="contact-name">
                {order.participant_info.parent_names}
              </span>
              <span id="contact-phone">
                {order.participant_info.parent_phone}
              </span>
              <span id="contact-email">{order.participant_info.email}</span>
            </div>

            {/* <span id="invoice">
             
                {false ? (
                  <i
                    className="fas fa-check"
                    name={CONSTANTS.modals.INVOICE_MODAL}
                    onClick={(e) => openModalHandler(order.order_id, e)}
                  ></i>
                ) : null}
              </span> */}

            <span id="declaration">{declarationStatus}</span>

            <span id="invited">
              {order.invited ? <i className="fas fa-check"></i> : null}
            </span>

            <span id="signed-up">
              {order.auto_sign_up ? <i className="fas fa-check"></i> : null}
            </span>

            <span id="order-date">
              <p>{formatedDate}</p>
            </span>

            <span id="order-edit">
              <i
                className="fas fa-user-edit"
                name={CONSTANTS.modals.EDIT_MODAL}
                onClick={(e) => openModalHandler(order.order_id, e)}
              ></i>
            </span>
          </OrderWrapper>
        )
      })}
    </div>
  )
}

export { OrdersAccordion }

const TableHeader = styled('div')({
  display: 'grid',
  // gridTemplateColumns: '1fr 1fr 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr ',
  gridTemplateColumns: '1fr 1fr 2fr 2fr 1fr 1fr  1fr 1fr 1fr ',

  textAlign: 'center',
  placeItems: 'center',
  alignItems: 'end',
  gridColumnGap: '20px',
  marginTop: '20px',
  color: COLORS.secBlue,
  fontWeight: '400',

  '.no-wrap': {
    whiteSpace: 'nowrap',
  },

  '& :nth-of-type(2)': {
    justifySelf: 'left',
  },

  '& :nth-of-type(3)': {
    justifySelf: 'left',
  },
})

const OrderWrapper = styled('div')({
  display: 'grid',
  // gridTemplateColumns: '1fr 1fr 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr ',
  gridTemplateColumns: '1fr 1fr 2fr 2fr 1fr 1fr  1fr 1fr 1fr ',

  placeItems: 'center',
  gridColumnGap: '20px',
  gridRowGap: '20px',
  marginTop: '5px',
  fontWeight: '400',
  border: `1px solid ${COLORS.borderGray}`,
  borderRadius: '15px',
  padding: '20px 0',
  color: COLORS.mainGray,

  '#participant-order-id': {
    color: COLORS.mainGray,
    textAlign: 'center',

    '& :nth-of-type(1)': {
      display: 'inline-block',
      marginBottom: 10,
    },
  },

  '#participant-info,#contact-info': {
    justifySelf: 'start',
    display: 'flex',
    flexDirection: 'column',

    '#participant-name': {
      color: COLORS.secBlack,
      marginBottom: 10,
    },

    '#contact-name': {
      marginBottom: 10,
    },

    '#contact-email': {
      color: COLORS.secBlack,
    },
  },

  '#invoice,#declaration,#invited,#signed-up': {
    i: {
      fontSize: 30,
    },

    '.fa-times': {
      color: COLORS.secRed,
    },

    '.fa-check': {
      color: COLORS.secGreen,
    },
  },

  '#order-date': {
    textAlign: 'center',
  },

  '#order-edit': {
    i: { fontSize: 30 },
  },

  ' :hover': {
    cursor: 'pointer',
    color: COLORS.secBlack,
    backgroundColor: COLORS.bgGray,
  },

  '& .red': { color: COLORS.secRed },
  '& .blue': { color: COLORS.secBlue },
  '& .green': { color: COLORS.secGreen },
  '& .yellow': { color: COLORS.secYellow },
})
