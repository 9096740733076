import styled from '@emotion/styled'
import HTMLReactParser from 'html-react-parser'
import {
  CompetitionsContainer,
  CompetitionWrapper,
  GenericButton,
  HubModalBackground,
} from 'platforms/kyoso-hub/shared'
import { hub_selectTheme } from 'platforms/kyoso-hub/store/generalSlice'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  EscButton,
  FormCheckBox,
  ModalLayout,
  PageInfo,
  TileElement,
} from 'shared'
import { COLORS, QUERIES, THEMES } from 'styling/variables'
import { formatDateAndTime } from 'utils/helpers/common'
import { getSchoolGradesHook } from 'utils/helpers/hooks'

function EditBundleModal({ modalData, closeModalCallback, saveModalCallback }) {
  const grades = getSchoolGradesHook()
  const currentTheme = useSelector(hub_selectTheme)
  const [totalParticipations, setTotalParticipations] = useState(
    modalData.bundleOrder.bundleParticipations,
  )
  const [remainingParticipations, setRemainingParticipations] = useState(
    modalData.bundleOrder.bundleParticipationsLeft,
  )
  const { t } = useTranslation()
  const {
    reset,
    register,
    control,
    getValues,
    handleSubmit,
    trigger,
    watch,
    setValue,
    setError,
    clearErrors,
    formState,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      bundled_competitions: modalData.bundleOrder.bundleOrderIncludedChildrenIds
        .length
        ? modalData.bundleOrder.bundleOrderIncludedChildrenIds
        : false,
    },
  })

  const onSubmitHandler = ({ bundled_competitions }) => {
    saveModalCallback(
      modalData.bundle_order_id,
      bundled_competitions,
      modalData.bundleOrder.bundleChildren,
    )
  }

  return (
    <HubModalBackground>
      <ModalLayout>
        <PageInfo
          title={t('hub:Competitions:EditBundleModalTitle', {
            bundle_name: modalData.bundle_name,
          })}
        />
        <EscButton onClick={closeModalCallback} />

        {modalData?.bundleOrder.bundleOrderIsPaid ? (
          <h2
            style={{
              color: COLORS.secGreen,
              textAlign: 'center',
              fontSize: 18,
            }}
          >
            {t('shared:Modals:ModalPaid')}
          </h2>
        ) : (
          <h2
            style={{ color: COLORS.secRed, textAlign: 'center', fontSize: 18 }}
          >
            {t('shared:Modals:ModalExpectedPayment')}
          </h2>
        )}

        <TileAndParticipationsWrapper theme={currentTheme}>
          <div id="tiles-wrapper">
            <TileElement
              className="total-participations"
              customCss={{
                width: 200,
                fontSize: 14,
                lineHeight: 1.5,
                // h1: { fontSize: 50, margin: 0, fontWeight: 600 },
              }}
            >
              <h1>{totalParticipations}</h1>
              <p>{t('hub:Competitions:EditBundleModalTotalParticipations')}</p>
            </TileElement>
            <TileElement
              className="remaining-participations"
              customCss={{
                width: 200,
                fontSize: 14,
                lineHeight: 1.5,
              }}
            >
              <h1>{remainingParticipations}</h1>
              <p>
                {t('hub:Competitions:EditBundleModalRemainingParticipations')}
              </p>
            </TileElement>
          </div>

          <div id="passed-competitions">
            <PageInfo
              customCss={{
                marginBottom: 10,
                h1: { fontSize: 18 },
              }}
              title={t(
                'hub:Competitions:EditBundleModalPastBundledCompetitions',
              )}
            />

            {modalData?.used_children?.length ? (
              modalData?.used_children.map((competition, index) => {
                return (
                  <CompetitionWrapper
                    customCss={{
                      border: `none`,
                      opacity: 0.5,
                    }}
                    id="competition-wrapper"
                    key={index}
                    theme={currentTheme}
                  >
                    <div className="competition-content">
                      <p id="competition-title">
                        {t('hub:Competitions:CompetitionTitle', {
                          parent_name: competition.parent_name,
                          grade: competition.grade_name,
                        })}
                      </p>
                      <p id="competition-subtitle">
                        {t('hub:Competitions:CompetitionSubtitle', {
                          subject: competition.subject_name,
                          year: competition.year,
                          competition_type: competition.type,
                        })}
                      </p>
                      <div id="competition-details" className="gray">
                        {competition.parent_intervals.map((dates, index) => (
                          <div key={index}>
                            {HTMLReactParser(
                              t('hub:Competitions:CompetitionDetails', {
                                start_date: formatDateAndTime(dates.startDate),
                                end_date: formatDateAndTime(dates.endDate),
                              }),
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </CompetitionWrapper>
                )
              })
            ) : (
              <p className="red">
                {t('hub:Competitions:MissingSignedUpCompetitions')}
              </p>
            )}
          </div>
        </TileAndParticipationsWrapper>

        <Form onSubmit={handleSubmit(onSubmitHandler)}>
          <PageInfo
            customCss={{
              marginBottom: 10,
              h1: { fontSize: 18 },
            }}
            title={t('hub:Competitions:EditBundleModalBundledCompetitions')}
          />
          <CompetitionsContainer
            customCss={{ columnGap: 30, marginBottom: 20 }}
          >
            {modalData?.available_children?.length ? (
              modalData?.available_children?.map((competition, index) => {
                const checkedCompetitions = watch('bundled_competitions')
                let isDisabled = false

                if (
                  (checkedCompetitions?.length &&
                    !checkedCompetitions?.includes(competition.id.toString()) &&
                    checkedCompetitions.length >= remainingParticipations) ||
                  competition.is_used
                ) {
                  isDisabled = true
                }

                return (
                  <div
                    id="bundled-competitions-wrapper"
                    className={isDisabled ? 'disabled' : null}
                    key={competition.id}
                  >
                    <FormCheckBox
                      key={`${competition.id}`}
                      id={`competition_${competition.id}_${competition.name}`}
                      name="bundled_competitions"
                      value={competition.id}
                      register={register()}
                      customCss={{
                        marginBottom: '0',
                      }}
                      disabled={isDisabled}
                    />

                    <CompetitionWrapper
                      customCss={{
                        border: `1px solid ${COLORS.borderGray}`,
                      }}
                      id="competition-wrapper"
                      key={index}
                      theme={currentTheme}
                    >
                      <div className="competition-content">
                        <p id="competition-title">
                          {t('hub:Competitions:CompetitionTitle', {
                            parent_name: competition.parent_name,
                            grade: competition.grade_name,
                          })}
                        </p>
                        <p id="competition-subtitle">
                          {t('hub:Competitions:CompetitionSubtitle', {
                            subject: competition.subject_name,
                            year: competition.year,
                            competition_type: competition.type,
                          })}
                        </p>
                        <div id="competition-details" className="gray">
                          {competition.parent_intervals.map((dates, index) => (
                            <div key={index}>
                              {HTMLReactParser(
                                t('hub:Competitions:CompetitionDetails', {
                                  start_date: formatDateAndTime(
                                    dates.startDate,
                                  ),
                                  end_date: formatDateAndTime(dates.endDate),
                                }),
                              )}
                            </div>
                          ))}
                        </div>
                      </div>

                      <GenericButton
                        type="button"
                        customCss={{
                          margin: 0,
                          minWidth: 45,
                          minHeight: 45,
                        }}
                        className="inverted"
                        theme={currentTheme}
                      >
                        <i className="fas fa-info-circle"></i>
                      </GenericButton>
                    </CompetitionWrapper>
                  </div>
                )
              })
            ) : (
              <p className="red">
                {t('hub:Competitions:MissingSignedUpCompetitions')}
              </p>
            )}
          </CompetitionsContainer>

          <div id="button-wrapper">
            <GenericButton
              customCss={{ margin: 0 }}
              theme={currentTheme}
              type="submit"
            >
              {t('shared:Modals:ModalSaveButtonHub')}
            </GenericButton>
            <GenericButton
              type="button"
              className="inverted"
              theme={currentTheme}
              onClick={closeModalCallback}
            >
              {t('shared:Modals:ModalCloseButtonHub')}
            </GenericButton>
          </div>
        </Form>
      </ModalLayout>
    </HubModalBackground>
  )
}

export { EditBundleModal }

const TileAndParticipationsWrapper = styled('div')(({ customCss, theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  alignItems: 'center',

  '#tiles-wrapper': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    gap: 30,

    '.total-participations': {
      color: COLORS.mainGray,
      borderColor: COLORS.borderGray,
    },

    '.remaining-participations': {
      color: THEMES[+theme],
      borderColor: THEMES[+theme],
    },
  },

  '#passed-competitions': {
    width: '100%',
  },

  [`@media (max-width: ${QUERIES.tablet}px)`]: {
    display: 'flex',
    flexDirection: 'column',

    '.button-wrapper': {
      gridColumn: '1 /3',
      button: { width: '100%' },
    },
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    display: 'flex',
    flexDirection: 'column',

    '#tiles-wrapper': {
      flexDirection: 'column',
      gap: 10,

      button: {},
    },

    '.button-wrapper': {
      gridColumn: '1 /3',
      button: { width: '100%' },
    },
  },
}))

const Form = styled('form')(({ customCss }) => ({
  '#button-wrapper': {
    display: 'flex',
    gap: 20,
    button: {
      margin: 0,
    },
  },

  '#bundled-competitions-wrapper': {
    display: 'flex',

    '&.disabled': {
      opacity: '0.5',
    },
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    '#button-wrapper': {
      flexDirection: 'column',

      button: {
        margin: 0,
      },
    },
  },
}))
