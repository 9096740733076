import { createSlice } from '@reduxjs/toolkit'
import { COLORS } from 'styling/variables'

// Thunks

// Selectors
export const hub_selectSharedModal = (state) => state.hub.general.sharedModal
export const hub_selectTheme = (state) => state.hub.general.theme
export const hub_selectPage = (state) => state.hub.general.page
export const hub_selectGlobalLoader = (state) => state.hub.general.globalLoader

// Configure Slice
const initialState = {
  page: {
    title: '',
    subtitle: '',
  },
  theme: 3,
  globalLoader: false,
  sharedModal: {
    platform: 'hub',
    show: false,
    color: COLORS.secRed,
    type: 'info',
    iconClass: 'fas fa-exclamation-triangle',
    title: 'Your Title',
    content: 'Your Content',
    unauthorized: false,
  },
}

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    hub_showSharedModal(state, action) {
      const allowedFields = [
        'color',
        'iconClass',
        'title',
        'content',
        'unauthorized',
      ]
      Object.keys(action.payload).forEach((k) => {
        if (state.sharedModal[k] !== undefined && allowedFields.includes(k)) {
          state.sharedModal[k] = action.payload[k]
        }
      })
      state.sharedModal.show = true
    },
    hub_hideSharedModal(state) {
      Object.keys(initialState.sharedModal).forEach((k) => {
        state.sharedModal[k] = initialState.sharedModal[k]
      })
    },
    hub_setPage(state, action) {
      state.page.title = action.payload.title
      state.page.subtitle = action.payload.subtitle
    },
    hub_setTheme(state, action) {
      if (action.payload) state.theme = action.payload
    },
    hub_showGlobalLoader(state) {
      state.globalLoader = true
    },
    hub_hideGlobalLoader(state) {
      state.globalLoader = false
    },
  },
})

// Actions and Reducer
export const {
  hub_showSharedModal,
  hub_hideSharedModal,
  hub_setPage,
  hub_setTheme,
  hub_showGlobalLoader,
  hub_hideGlobalLoader,
} = generalSlice.actions
export default generalSlice.reducer
