import styled from '@emotion/styled'
import { COLORS, QUERIES } from 'styling/variables'

export function ModalLayout({ children, customCss }) {
  return (
    <ModalPositionWrap>
      <ModalInner customCss={customCss}>{children}</ModalInner>
    </ModalPositionWrap>
  )
}

const ModalPositionWrap = styled('div')(({ customCss }) => ({
  display: 'table-cell',
  verticalAlign: 'middle',
}))

const ModalInner = styled('div')(({ customCss }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'relative',
  width: '90%',
  height: '90%',
  maxWidth: 1400,
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 0px 5px #0000000D',
  borderRadius: 25,
  padding: 30,
  fontFamily: 'Balsamiq Sans',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',

  '.blue': {
    color: COLORS.secBlue,
  },

  '.red': {
    color: COLORS.secRed,
  },

  '.green': {
    color: COLORS.secGreen,
  },

  '::-webkit-scrollbar': {
    display: 'none',
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    padding: '30px 20px',
  },

  '.answer-type-label': {
    color: COLORS.secBlack,
    fontSize: 18,
    marginBottom: 20,

    '.answer-type-error': {
      color: COLORS.secRed,
    },
  },
  ...customCss,
}))
