import API from '../../../../utils/backendApi'

function filterBundles({
  itemsPerPage = '_omit_',
  pageNumber = '_omit_',
  schoolGrade = '_omit_',
  schoolSubject = '_omit_',
  bundleName = '_omit_',
  owner = '_omit_',
  selectedTypes = '_omit_',
  bundleId = '_omit_',
} = {}) {
  const body = JSON.stringify({
    id: bundleId,
    items: itemsPerPage,
    page: pageNumber,
    grade_id: schoolGrade,
    subject_id: schoolSubject,
    owner_id: owner,
    name: bundleName,
    types: selectedTypes,
  })
  return API('hub').POST('/orders/bundles', body)
}

function getChildrenForBundle(bundleId) {
  return API('hub').GET(`/orders/bundle/${bundleId}/children`)
}

function createBundleOrder(
  bundleId,
  isDataTouched,
  paymentMethod,
  childrenIdsIncluded,
  childrenParentsIds,
  { invoiceData = '_omit_', newUserData = '_omit_' } = {},
) {
  const body = JSON.stringify({
    kyoso_bundle_id: bundleId,
    is_touched_data: isDataTouched,
    payment_method: paymentMethod,
    new_user_data: newUserData,
    included_children_ids: childrenIdsIncluded,
    invoice_data: invoiceData,
    children_parents_ids: childrenParentsIds,
  })

  return API('hub').POST('/orders/bundle', body)
}

function getBundleOrders() {
  return API('hub').GET(`/orders/bundle-orders`)
}

function getBundleOrder(bundleOrderId) {
  return API('hub').GET(`/orders/bundle-order/${bundleOrderId}`)
}

function updateBundleOrder(
  bundleOrderId,
  childrenIdsIncluded,
  childrenParentsIds,
) {
  const body = JSON.stringify({
    bundle_order_id: bundleOrderId,
    included_children_ids: childrenIdsIncluded,
    children_parents_ids: childrenParentsIds,
  })

  return API('hub').PUT('/orders/bundle', body)
}

export {
  filterBundles,
  getChildrenForBundle,
  createBundleOrder,
  getBundleOrders,
  getBundleOrder,
  updateBundleOrder,
}
