import styled from '@emotion/styled'
import { COLORS } from '../../../../styling/variables'
import { RegistrationForm } from './RegistrationForm'
import RegisterImage from '../../../../assets/img/hub_register-background.svg'
import { DefaultButton, LoginRegisterSection } from '../../shared/index'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useState } from 'react'
import { ConfirmationMessage } from 'shared/index'

function Registration() {
  const { t } = useTranslation()
  const routeHistory = useHistory()
  const [registrationStatus, setRegistrationStatus] = useState(null)

  function showRegistrationStatus() {
    let message = ''
    let iconClass = ''
    if (registrationStatus.statCode !== 200) {
      if (!registrationStatus?.statCode) {
        message = t(`errors:400`)
      } else {
        message = t(`errors:${registrationStatus.statCode}`)
      }
      iconClass = 'fas fa-times'
    } else {
      message = t('hub:UserRegisterSuccessfully', {
        email: registrationStatus.email,
      })
      iconClass = 'fas fa-check'
    }

    return (
      <>
        <ConfirmationMessage
          color={COLORS.secBlue}
          message={message}
          iconClass={iconClass}
        />
        <DefaultButton
          color={COLORS.secBlue}
          onClick={() => routeHistory.replace('/hub/login')}
          customCss={{
            marginTop: 10,
          }}
        >
          {t('shared:BackToLogin')}
        </DefaultButton>
      </>
    )
  }

  return (
    <LoginRegisterSection
      color={COLORS.secBlue}
      backgroundImgSrc={RegisterImage}
      customCss={{
        '&:before': {
          top: -100,
          left: -100,
          width: 'calc(100% + 300px)',
          height: 'calc(100% + 300px)',
        },
      }}
    >
      {registrationStatus ? (
        showRegistrationStatus()
      ) : (
        <>
          <RegistrationForm setRegistrationStatus={setRegistrationStatus} />
          {/* <AlternativeRegister id="alternative-register">
            <div>{t('hub:AlternativeRegister')}</div>
            <DefaultButton color={COLORS.secBlue}>
              <i className="fab fa-google"></i>
              <span>Google</span>
            </DefaultButton>
          </AlternativeRegister> */}
        </>
      )}
    </LoginRegisterSection>
  )
}

export { Registration }

const AlternativeRegister = styled('div')({
  '& div': {
    color: COLORS.secBlue,
    fontSize: 18,
    width: '100%',
    marginBottom: 10,
  },
  '& button:nth-of-type(1)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    float: 'left',

    '& i': {
      fontSize: 34,
      marginRight: 15,
    },
  },
})
