import { useState } from 'react'
import styled from '@emotion/styled'
import { THEMES } from 'styling/variables'
import { ContentDescription } from 'shared/elements/ContentDescription'

export const Accordion = styled(({ questions, className }) => {
  const [clicked, setClicked] = useState('false')

  const toggleQuestion = (index) => {
    if (clicked === index) {
      return setClicked(null)
    }

    setClicked(index)
  }

  return (
    <div className={className}>
      {questions.map((question, index) => {
        return (
          <div className="wrapper" key={question.id}>
            <h2 onClick={() => toggleQuestion(index)}>{question.title}</h2>
            <i
              className={`fas fa-chevron-right ${
                clicked === index ? 'open' : null
              }`}
            />
            {clicked === index && (
              <ContentDescription>{question.content}</ContentDescription>
            )}
          </div>
        )
      })}
    </div>
  )
})(({ customCss, theme }) => ({
  border: '2px solid #00000026',
  borderRadius: 25,
  padding: 20,
  backgroudColor: 'lightgray',
  '& .wrapper': {
    position: 'relative',
    margin: '10px 0px',

    '& h2': {
      fontSize: 'larger',
      marginBottom: 10,
      cursor: 'pointer',
      fontWeight: 'bold',
      color: THEMES[theme || 0],
    },

    '& i': {
      position: 'absolute',
      top: 0,
      right: 0,
      fontSize: 'x-large',
      color: THEMES[theme || 0],
      '&.open': {
        transform: 'rotate(90deg)',
      },
    },
  },
  ...customCss,
}))
