import styled from '@emotion/styled'

export const ImageWrapper = styled('div')(({ customCss }) => ({
  width: 150,
  height: 150,
  marginRight: 20,
  // border: '1px solid #707070',
  position: 'relative',

  img: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
  },
  ...customCss,
}))
