import styled from '@emotion/styled'
import LabImg from '../../../../assets/img/lab_login-background.jpeg'
import { QUERIES } from '../../../../styling/variables'
import { Footer } from '../Footer'
import { SharedModal } from 'shared'

function LandingLayout({ children }) {
  return (
    <Layout id="lab-landing-layout">
      <div id="lab-img" />
      <div id="lab-content">
        <div id="lab-content-children">{children}</div>
        <Footer />
      </div>
      <SharedModal
        customBackgroundCss={{
          top: 0,
          [`@media (max-width: ${QUERIES.mobile}px)`]: {
            position: 'fixed',
          },
        }}
        customModalCss={{}}
      />
    </Layout>
  )
}

export { LandingLayout }

const Layout = styled('div')(({ customCss, theme }) => ({
  display: 'grid',
  minHeight: '100vh',
  gridTemplateColumns: '2fr 1fr',

  '#lab-img': {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${LabImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },

  img: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
  '#lab-content': {
    overflowY: 'scroll',
    padding: '40px 60px',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    ' #hub-footer': {
      marginTop: '60px',
    },
  },

  [`@media (max-width: ${QUERIES.tablet}px)`]: {
    gridTemplateColumns: '1fr 1fr',
    '#lab-content': {
      padding: '30px 30px',
    },
  },

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    display: 'flex',
    flexDirection: 'column',

    '#lab-img': {
      height: 0,
      paddingTop: '56.25%',
    },

    '#lab-content': {
      padding: '20px 20px',
      overflowY: 'initial',
      height: 'auto',

      ' form': {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '0',

        '& button': {
          marginBottom: '20px',
        },
      },
    },
  },
}))
