import styled from '@emotion/styled'
import { SubmitButtonLab } from 'platforms/kyoso-lab/shared'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'styling/variables'
import { ChildMediaResults } from './ChildMediaResults'

function ChildInformation({
  prevChildCallback,
  nextChildCallback,
  canGoNext,
  canGoPrev,
  childInfo,
  scoring,
  changePoints,
}) {
  const { t } = useTranslation()

  return (
    <ChildInformationLayout>
      <div className="header">
        <SubmitButtonLab
          className="black"
          disabled={!canGoPrev()}
          type="button"
          customCss={{ width: 210 }}
          onClick={() => prevChildCallback()}
        >
          {t('lab:KyosoChildEvaluation:PrevParticipant')}
        </SubmitButtonLab>
        <div className="info">
          <div className="child-info">
            <div>{childInfo.full_name}</div>
            <div>
              <span>{t('lab:KyosoChildEvaluation:ParticipantNo')}.: </span>
              <span className="blue">{childInfo.participant_id}</span>
            </div>
            <div>
              <span>{t('lab:KyosoChildEvaluation:OrderNo')}.: </span>
              <span className="blue">KY-{childInfo.id}</span>
            </div>
          </div>
          <div className="parent-info">
            <div className="gray">{childInfo.parent_names || '-'}</div>
            <div className="gray">{childInfo.parent_phone || '-'}</div>
            <div>{childInfo.email}</div>
          </div>
        </div>
        <SubmitButtonLab
          className="black"
          disabled={!canGoNext()}
          type="button"
          customCss={{ width: 210 }}
          onClick={() => nextChildCallback()}
        >
          {t('lab:KyosoChildEvaluation:NextParticipant')}
        </SubmitButtonLab>
      </div>
      {childInfo.answers && (
        <ChildMediaResults
          childInfo={childInfo}
          scoring={scoring}
          changePoints={changePoints}
        />
      )}
    </ChildInformationLayout>
  )
}

export { ChildInformation }

const ChildInformationLayout = styled('div')({
  marginTop: 50,

  '& .header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .info': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 30,

      '.child-info,.parent-info': {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
      },

      '.gray': {
        color: COLORS.mainGray,
      },

      '.blue': {
        color: COLORS.secBlue,
      },
    },
  },
})
