import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormCheckBox, FormInput, FormSelect } from 'shared'
import { COLORS, QUERIES } from 'styling/variables'
import styled from '@emotion/styled'
import { SubmitButtonLab } from 'platforms/kyoso-lab/shared'
import { useTranslation } from 'react-i18next'
import ReactSelect from 'react-select'
import { FormDatePicker } from 'shared/components/FormDatePicker'

function KyosoBundleOrdersFiltrationForm({ submitCallback, totalOrders }) {
  const {
    reset,
    register,
    control,
    getValues,
    handleSubmit,
    setValue,
    formState,
  } = useForm({
    mode: 'onSubmit',
  })
  const { t } = useTranslation()

  const paymentTypes = [
    { value: '', label: t('shared:All') },
    { value: '0', label: t('shared:Unpaid') },
    { value: '1', label: t('shared:Bank') },
    { value: '2', label: 'Epay' },
  ]

  const onSubmitFilter = (data) => {
    submitCallback(data)
  }

  useEffect(() => {
    submitCallback(getValues())
  }, [])

  const resetDataHandler = () => {
    const defaultValues = {
      schoolGrade: null,
      schoolSubject: null,
    }
    reset(defaultValues)
    submitCallback(null, true)
  }

  return (
    <FormLayout noValidate onSubmit={handleSubmit(onSubmitFilter)}>
      <div className="inputs-top">
        <FormInput
          customCss={{ marginBottom: '0' }}
          id="search"
          type="text"
          name="search"
          label="lab:KyosoOrders:FilterSearchLabel"
          register={register()}
        />

        <FormSelect
          id={'payment-type'}
          customCss={{ marginBottom: '0' }}
          label={'lab:KyosoOrders:FilterPaymentTypeLabel'}
          name="payment_type"
          control={control}
          render={(props) => (
            <ReactSelect
              options={paymentTypes}
              placeholder=" "
              isClearable={true}
              className={formState.errors.payment_type ? 'select-error' : ''}
              isDisabled={false}
              {...props}
            ></ReactSelect>
          )}
          defaultValue={''}
        />

        <FormDatePicker
          id="startDate"
          control={control}
          label="lab:KyosoOrders:FilterDateFromLabel"
          name="start_date"
          setValue={setValue}
          error={formState.errors.start_date}
          defaultValue=""
        />

        <FormDatePicker
          id="startDate"
          control={control}
          label="lab:KyosoOrders:FilterDateToLabel"
          name="end_date"
          setValue={setValue}
          error={formState.errors.end_date}
          defaultValue=""
        />
        <SubmitButtonLab
          type="reset"
          className="white clear"
          onClick={(e) => resetDataHandler(e)}
          customCss={{}}
        >
          <i className="fas fa-times"></i>
        </SubmitButtonLab>

        <SubmitButtonLab className="black" type="submit">
          {t('lab:Kyoso:SubmitFilterButton')}
        </SubmitButtonLab>
      </div>

      <CheckBoxWrapper>
        <CheckBoxGroup>
          <FormCheckBox
            key="paid"
            id={`is-paid`}
            name="is_paid"
            label={t('lab:KyosoOrders:FilterCheckboxPayed')}
            register={register()}
            customCss={{
              marginBottom: '0',
              ' .text': {
                marginLeft: '10px',
                gridColumn: '2',
                color: COLORS.mainGray,
                justifySelf: 'left',
              },
            }}
          />

          <FormCheckBox
            key="not-paid"
            id={`is-not-paid`}
            name="is_not_paid"
            label={t('lab:KyosoOrders:FilterCheckboxNotPayed')}
            register={register()}
            customCss={{
              marginBottom: '0',
              ' .text': {
                marginLeft: '10px',
                gridColumn: '2',
                color: COLORS.mainGray,
                justifySelf: 'left',
              },
            }}
          />
        </CheckBoxGroup>

        <CheckBoxGroup>
          {/* <FormCheckBox
            key="invoice"
            id={`invoice`}
            name="invoice"
            label={t('lab:KyosoOrders:FilterCheckboxInvoice')}
            register={register()}
            customCss={{
              marginBottom: '0',
              ' .text': {
                marginLeft: '10px',
                gridColumn: '2',
                color: COLORS.mainGray,
                justifySelf: 'left',
              },
            }}
          /> */}

          <FormCheckBox
            key="declaration"
            id={`declaration`}
            name="declaration"
            label={t('lab:KyosoOrders:FilterCheckboxDeclaration')}
            register={register()}
            customCss={{
              marginBottom: '0',
              ' .text': {
                marginLeft: '10px',
                gridColumn: '2',
                color: COLORS.mainGray,
                justifySelf: 'left',
              },
            }}
          />
        </CheckBoxGroup>

        <span style={{ margin: 'auto 0px auto auto', color: COLORS.mainGray }}>
          {t('lab:KyosoOrders:FilterResults', { total_results: totalOrders })}
        </span>
      </CheckBoxWrapper>
    </FormLayout>
  )
}

export { KyosoBundleOrdersFiltrationForm }

const FormLayout = styled('form')({
  width: '100%',

  '.inputs-top': {
    display: 'grid',
    gridTemplateColumns: '1.5fr 1fr 1fr 1fr 50px 0.5fr',
    gridColumnGap: '30px',
    placeItems: 'end',
    marginBottom: 20,
  },

  [`@media (max-width: ${QUERIES.tablet}px)`]: {
    gridColumnGap: '15px',
  },

  '& .button': {
    cursor: 'pointer',
    gridColumn: '5',
    backgroundColor: '#fff',
    border: 'none',
    alignSelf: 'center',
    marginBottom: '5px',

    '& i': {
      fontSize: '30px',
      color: COLORS.mainGray,
    },
  },
})

const CheckBoxWrapper = styled('div')({
  gridColumn: '1 / 4',
  display: 'flex',
  gap: 50,
})

const CheckBoxGroup = styled('div')({
  display: 'flex',
  gap: 30,
})
