import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  userNamaValidator,
  whiteSpaceValidator,
  firstAndLastNameValidator,
  locationValidator,
  schoolNameValidator,
  telephoneValidation,
} from '../../../../utils/helpers/validators'
import { useTranslation } from 'react-i18next'
import { hub_selectUser, hub_updateUser } from '../../store/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { checkIfUsernameExists } from '../../services/user'
import { avatars } from '../../../../styling/avatars.js'
import { unwrapResult } from '@reduxjs/toolkit'
import { hub_selectTheme, hub_setPage } from '../../store/generalSlice'
import {
  Avatar,
  AvatarHolder,
  PersonalizationWrapper,
} from '../../shared/index'
import { COLORS, THEMES } from '../../../../styling/variables'
import styled from '@emotion/styled'
import BlueTheme from 'assets/img/themes/blue.svg'
import RedTheme from 'assets/img/themes/red.svg'
import GreenTheme from 'assets/img/themes/green.svg'
import GrayTheme from 'assets/img/themes/gray.svg'

import {
  PageInfo,
  FormSelect,
  SubmitButtonHub,
  FormInput,
  PersonalDataDeclaration,
  ContentDescription,
  ContentTwoColumsLayout,
  SchoolGradeSelect,
  UserDataForm,
} from 'shared/index'

function OnboardingFirstStepForm({ userData }) {
  const currentTheme = useSelector(hub_selectTheme)
  const reduxUser = useSelector(hub_selectUser)
  const dispatch = useDispatch()
  const routeHistory = useHistory()
  const [selectedAvatar, setSelectedAvatar] = useState('0')
  const [selectedTheme, setSelectedTheme] = useState('3')
  const [signature, setSignature] = useState(null)
  const [inputsData, setInputsData] = useState(null)
  const { t } = useTranslation()
  const avatarsArray = []

  const {
    register,
    handleSubmit,
    control,
    getValues,
    trigger,
    setValue,
    formState,
  } = useForm({
    mode: 'onBlur',
  })

  useEffect(() => {
    dispatch(
      hub_setPage({
        title: t('hub:Onboarding:PageTitle'),
        subtitle: t('hub:Onboarding:FirstStepSubtitle'),
      }),
    )
  }, [])

  useEffect(() => {
    const defaultFormValues = {
      theme: userData?.theme || selectedTheme,
      avatar: userData?.avatar || selectedAvatar,
      username: userData?.username || '',
      fullName: userData?.full_name || '',
      grade_id: userData?.grade_id || null,
      city: userData?.city || '',
      school: userData?.school || '',
      parent_names: userData?.parent_names || '',
      parentPhone: userData?.parent_phone || '',
    }
    for (const key in defaultFormValues) {
      setValue(key, defaultFormValues[key])
    }
    setSelectedAvatar(defaultFormValues.avatar)
    setSelectedTheme(defaultFormValues.theme)
    setInputsData(defaultFormValues)
  }, [userData])

  const onSubmit = (data) => {
    // console.log(data)

    dispatch(
      hub_updateUser({
        user_id: reduxUser.id,
        username: data.username,
        full_name: data.fullName,
        avatar: data.avatar,
        grade_id: data.grade_id || '_omit_',
        school: data.school,
        theme: data.theme,
        city: data.city,
        parent_names: data.parent_names,
        parent_phone: data.parentPhone,
        is_onboarded: false,
      }),
    )
      .then(unwrapResult)
      .then((response) => {
        routeHistory.push('/hub/onboarding/2')
      })
  }

  const onChangeHandler = (event) => {
    const formValues = getValues(['avatar', 'theme'])

    setSelectedAvatar(formValues.avatar)
    setSelectedTheme(formValues.theme)
  }

  for (let index = 0; index <= 19; index++) {
    avatarsArray.push({
      id: `avatar${index}`,
      name: 'avatar',
      type: 'radio',
      value: index,
      register: register({ required: 'Required' }),
      error: formState.errors.avatar,
      touched: formState.touched.avatar,
      avatar: avatars[index].src,
    })
  }

  const onInputChangeHandler = (event) => {
    let inputName = null

    if (event.target?.name) {
      inputName = event.target.name
    } else {
      inputName = event
    }

    setInputsData({ ...inputsData, [inputName]: getValues(inputName) })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onChange={onInputChangeHandler}>
        <ContentTwoColumsLayout
          customCss={{ gridTemplateColumns: '2fr 2fr 0.5fr', gap: 20 }}
        >
          <div>
            <div>
              <PageInfo
                title={t('hub:Onboarding:Personalization')}
                subtitle={t('hub:Onboarding:PersonalizationDescription')}
                customCss={{
                  '& h1': {
                    marginBottom: 10,
                  },
                  '& h2': {
                    marginBottom: 20,
                  },
                }}
              />
              <PersonalizationWrapper>
                <AvatarHolder theme={currentTheme}>
                  <Avatar src={avatars[selectedAvatar].src}></Avatar>
                </AvatarHolder>
                <Stretcher>
                  <FormInput
                    customCss={{ marginBottom: 0 }}
                    id="userName"
                    type="text"
                    name="username"
                    label="LabelUsername"
                    description="UsernameDescription"
                    succesMessage="CorrectInput"
                    onChange={(e) => {
                      return { trim: whiteSpaceValidator(e) }
                    }}
                    onBlur={() => {
                      return trigger('username')
                    }}
                    register={register({
                      validate: {
                        usernameValidation: (value) => userNamaValidator(value),
                        checkIfUserNameExists: async (value) => {
                          if (value) {
                            return await checkIfUsernameExists(
                              value,
                              reduxUser.id,
                            ).catch((error) => `${error.statCode}`)
                          }
                        },
                      },
                    })}
                    error={formState.errors.username}
                    touched={formState.touched.username}
                  ></FormInput>
                </Stretcher>

                <ThemesWrapper>
                  <label htmlFor="theme1">
                    <input
                      id="theme1"
                      name="theme"
                      type="radio"
                      value="0"
                      ref={register()}
                      onChange={(e) => onChangeHandler(e)}
                    />
                    <img src={BlueTheme} />
                    <span className="checkmark"></span>
                  </label>

                  <label htmlFor="theme2">
                    <input
                      id="theme2"
                      name="theme"
                      type="radio"
                      value="1"
                      ref={register()}
                      onChange={(e) => onChangeHandler(e)}
                    />
                    <img src={RedTheme} />
                    <span className="checkmark"></span>
                  </label>

                  <label htmlFor="theme3">
                    <input
                      id="theme3"
                      name="theme"
                      type="radio"
                      value="2"
                      ref={register()}
                      onChange={(e) => onChangeHandler(e)}
                    />
                    <img src={GreenTheme} />
                    <span className="checkmark"></span>
                  </label>

                  <label htmlFor="theme4">
                    <input
                      id="theme4"
                      name="theme"
                      type="radio"
                      value="3"
                      ref={register()}
                      onChange={(e) => onChangeHandler(e)}
                    />
                    <img src={GrayTheme} />
                    <span className="checkmark"></span>
                  </label>
                </ThemesWrapper>
              </PersonalizationWrapper>

              <div>
                <ContentDescription>
                  {t('hub:Onboarding:AvatarDescription')}
                </ContentDescription>

                <RadioButtonWrapper>
                  {avatarsArray.map((avatarInput) => {
                    return (
                      <label key={avatarInput.id}>
                        <AvatarRadioInput
                          key={avatarInput.id}
                          id={avatarInput.id}
                          name={avatarInput.name}
                          type={avatarInput.type}
                          value={avatarInput.value}
                          ref={avatarInput.register}
                          onChange={(e) => onChangeHandler(e)}
                          theme={currentTheme}
                        />
                        <Avatar
                          customCss={{
                            cursor: 'pointer',
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            border: `1px solid ${COLORS.mainGray} `,
                          }}
                          src={avatarInput.avatar}
                        ></Avatar>
                      </label>
                    )
                  })}
                </RadioButtonWrapper>
              </div>
            </div>

            {/* <AdditionalInformation> */}
            {/* </AdditionalInformation> */}
          </div>
          <div>
            <PageInfo
              title={t('hub:Onboarding:MoreInformation')}
              subtitle={t('hub:Onboarding:MoreInformationDescription')}
              customCss={{
                '& h1': {
                  marginBottom: 10,
                },
                '& h2': {
                  marginBottom: 20,
                },
              }}
            />
            <UserDataForm>
              <FormInput
                customCss={{
                  marginBottom: '0',
                  gridColumn: '1 / 3',
                  gridRow: '1',
                }}
                id="fullName"
                type="text"
                name="fullName"
                label="LabelFirstAndLastName"
                register={register({
                  validate: {
                    firstAndLastNameValidator: (value) =>
                      firstAndLastNameValidator(value),
                  },
                })}
                error={formState.errors.fullName}
                touched={formState.touched.fullName}
              ></FormInput>

              <SchoolGradeSelect
                id="schoolGrade"
                customCss={{ marginBottom: '0' }}
                label="lab:Kyoso:FilterSchoolGrade"
                name="grade_id"
                onChange={onInputChangeHandler}
                setValue={setValue}
                getValues={getValues}
                description="SchoolClassDescription"
                control={control}
                isMulti={false}
                defaultValue={null}
              ></SchoolGradeSelect>

              <FormInput
                customCss={{
                  marginBottom: '0',
                  gridColumn: '1/ 2',
                  gridRow: '2',
                }}
                id="town"
                type="text"
                name="city"
                label="LabelTown"
                description={'TownDescription'}
                register={register({
                  validate: {
                    locationValidator: (value) => locationValidator(value),
                  },
                })}
                error={formState.errors.city}
                touched={formState.touched.city}
              ></FormInput>

              <FormInput
                customCss={{
                  marginBottom: '0',
                  gridColumn: '2/ 4',
                  gridRow: '2',
                }}
                id="schoolName"
                type="text"
                name="school"
                label="LabelSchoolName"
                description={'SchoolNameDescription'}
                onBlur={(e) => trigger('school')}
                register={register({
                  validate: {
                    schoolNameValidator: (value) => schoolNameValidator(value),
                  },
                })}
                error={formState.errors.school}
                touched={formState.touched.school}
              ></FormInput>

              <div id="parent-info">
                <FormInput
                  customCss={{
                    marginBottom: '0',
                  }}
                  id="parentName"
                  type="text"
                  name="parent_names"
                  label="LabelFirstAndLastNameParent"
                  register={register({
                    validate: {
                      firstAndLastNameValidator: (value) =>
                        firstAndLastNameValidator(value),
                    },
                  })}
                  error={formState.errors.parent_names}
                  touched={formState.touched.parent_names}
                />

                <FormInput
                  customCss={{
                    marginBottom: '0',
                  }}
                  id="parentPhone"
                  type="tel"
                  name="parentPhone"
                  label="LabelTelephone"
                  onChange={(e) => trigger('parentPhone')}
                  register={register({
                    validate: {
                      telephoneValidation: (value) => {
                        // console.log(value)
                        if (value) return telephoneValidation(value)
                      },
                    },
                  })}
                  error={formState.errors.parentPhone}
                  touched={formState.touched.parentPhone}
                />
              </div>
            </UserDataForm>
            <ContentDescription
              customCss={{
                marginBottom: '0 ',
                paddingRight: '15px',
                alignSelf: 'center',
              }}
            >
              {t('shared:PersonalDataDeclatartionDescritpion4')}
            </ContentDescription>
          </div>

          <ThirdColumn>
            <SubmitButtonHub
              id="firstStepOnboarding"
              customCss={{
                widht: 90,
                height: 90,
                margin: 'auto',
              }}
              theme={currentTheme}
            />
            {/* <Controller
              control={control}
              className="select"
              name="signature"
              render={(props) => (
                <PersonalDataDeclaration
                  data={{ ...inputsData, signature: signature }}
                  onSubmitHandler={(e) => {
                    props.onChange(e)
                    saveSignatureHandler(e)
                  }}
                />
              )}
              defaultValue={''}
            /> */}
          </ThirdColumn>
        </ContentTwoColumsLayout>
      </form>
    </>
  )
}

export { OnboardingFirstStepForm }

const ThemesWrapper = styled('div')(({ customCss }) => ({
  gridRow: '2',
  gridColumn: '2 / 4',
  alignItems: 'center',

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',

  label: {
    position: 'relative',
  },

  input: {
    display: 'none',
  },

  img: {
    opacity: 0.5,
    '&:hover': {
      cursor: 'pointer',
    },
  },

  'input:checked ~ .checkmark': {
    display: 'block',
  },

  'input:checked ~ img': {
    opacity: 1,
  },

  '.checkmark': {
    content: "''",
    position: 'absolute',
    display: 'none',
    left: '25%',
    top: '55%',
    width: '15px',
    height: '25px',
    border: 'solid white',
    borderWidth: '0 6px 6px 0',
    transform: 'rotate(45deg) translate(-50%,-50%)',
  },
}))

const Stretcher = styled('div')(({ customCss }) => ({
  gridColumn: '2 / 4',
  ...customCss,
}))

const AvatarRadioInput = styled('input')(({ customCss, theme }) => ({
  position: 'absolute',
  opacity: '0',
  width: '0',
  height: '0',
  ':checked + img': {
    border: `3px solid ${THEMES[+theme]}`,
  },
}))

const RadioButtonWrapper = styled('div')(({ customCss }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginBottom: '20px',
  '& label': {
    marginLeft: '10px',
  },
}))

const ThirdColumn = styled('div')(({ customCss }) => ({
  display: 'grid',
  placeItems: 'center',
}))
