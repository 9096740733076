import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormCheckBox, FormInput } from 'shared'
import { COLORS, QUERIES } from 'styling/variables'
import styled from '@emotion/styled'
import { SubmitButtonLab } from 'platforms/kyoso-lab/shared'
import { useTranslation } from 'react-i18next'
import { SchoolGradeSelect } from 'shared'
import { SchoolSubjectSelect } from 'shared'
import { getAllKyosoTypes } from 'services/shared'
import { yearValidator } from 'utils/helpers/validators'

function FiltrationForm({ submitCallback }) {
  const {
    reset,
    register,
    control,
    getValues,
    handleSubmit,
    trigger,
    setValue,
    setError,
    clearErrors,
    formState,
  } = useForm({
    mode: 'onSubmit',
  })
  const [kyosoTypes, setKyosoTypes] = useState([])
  const [isChecked, setIsChecked] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    getAllKyosoTypes()
      .then((response) => {
        setKyosoTypes(response.types)
      })
      .catch((error) => console.log(error))
  }, [])

  const onSubmitFilter = (data) => {
    submitCallback({
      name: data.name.trim() || null,
      schoolGrade: data.schoolGrade,
      schoolSubject: data.schoolSubject,
      year: data.year || null,
      types: data.types.length ? data.types : null,
    })
  }

  const resetDataHandler = () => {
    const defaultValues = {
      schoolGrade: null,
      schoolSubject: null,
    }
    setIsChecked(false)
    reset(defaultValues)
    submitCallback(null)
  }

  return (
    <FormLayout noValidate onSubmit={handleSubmit(onSubmitFilter)}>
      <div className="top-inputs">
        <FormInput
          customCss={{ marginBottom: '0' }}
          id="filterName"
          type="text"
          name="name"
          label="lab:Kyoso:FilterName"
          register={register()}
        />

        <FormInput
          customCss={{ marginBottom: '0' }}
          id="year"
          label="lab:Kyoso:FilterYear"
          type="number"
          name="year"
          onChange={() => trigger('year')}
          register={register({
            validate: {
              yearValidation: (value) => {
                if (value) {
                  return yearValidator(value)
                }
              },
            },
          })}
          error={formState.errors.year}
        />

        <SchoolSubjectSelect
          customCss={{ marginBottom: '0' }}
          id="schoolSubject"
          label="lab:Kyoso:FilterSchoolSubject"
          name="schoolSubject"
          setValue={setValue}
          getValues={getValues}
          onChange={() => trigger('schoolSubject')}
          control={control}
          defaultValue={null}
          setError={setError}
          clearErrors={clearErrors}
          isClearable={true}
        />

        <SchoolGradeSelect
          customCss={{ marginBottom: '0' }}
          id="schoolGrade"
          label="lab:Kyoso:FilterSchoolGrade"
          name="schoolGrade"
          onChange={() => trigger('schoolGrade')}
          setValue={setValue}
          isClearable={true}
          getValues={getValues}
          control={control}
          defaultValue={null}
        />

        <SubmitButtonLab
          type="reset"
          className="white clear"
          onClick={(e) => resetDataHandler(e)}
          customCss={{}}
        >
          <i className="fas fa-times"></i>
        </SubmitButtonLab>

        <SubmitButtonLab className="black" type="submit" customCss={{}}>
          {t('lab:Kyoso:SubmitFilterButton')}
        </SubmitButtonLab>
      </div>

      <div className="bottom-checkboxes">
        {kyosoTypes.map((x) => (
          <FormCheckBox
            key={`${x.id}_${x.name}`}
            id={`type_${x.id}_${x.name}`}
            name={`types`}
            label={x.name}
            value={x.id}
            register={register()}
            checked={() => isChecked}
            customCss={{
              marginBottom: '0',
              ' .text': {
                marginLeft: '10px',
                gridColumn: '2',
                color: COLORS.mainGray,
                justifySelf: 'left',
              },
            }}
          />
        ))}
      </div>
    </FormLayout>
  )
}

export { FiltrationForm }

const FormLayout = styled('form')({
  width: '100%',

  '.top-inputs': {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1.5fr 1fr 50px 0.5fr',
    gridColumnGap: '30px',
    marginBottom: 20,
    alignItems: 'end',
  },

  '.bottom-checkboxes': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 30,
  },

  [`@media (max-width: ${QUERIES.tablet}px)`]: {
    gridColumnGap: '15px',
  },

  '& .button': {
    cursor: 'pointer',
    gridColumn: '5',
    backgroundColor: '#fff',
    border: 'none',
    alignSelf: 'center',
    marginBottom: '5px',

    '& i': {
      fontSize: '30px',
      color: COLORS.mainGray,
    },
  },
})
