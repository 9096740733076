import styled from '@emotion/styled'

export const ImgSignatureWrapper = styled('div')(({ customCss }) => ({
  width: 300,
  height: 150,
  padding: 15,
  border: '1px solid #707070',

  img: {
    width: '100%',
    height: '100%',
  },
  ...customCss,
}))
