import styled from '@emotion/styled'
import HTMLReactParser from 'html-react-parser'
import { getDeclarationCheckInfo } from 'platforms/kyoso-hub/services/participation'
import {
  deleteDeclaration,
  getDeclaration,
  saveDeclaration,
} from 'platforms/kyoso-hub/services/user'
import { GenericButton, HubModalBackground } from 'platforms/kyoso-hub/shared'
import { hub_selectTheme } from 'platforms/kyoso-hub/store/generalSlice'
import { hub_selectUser } from 'platforms/kyoso-hub/store/userSlice'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  DeclarationCanvas,
  DeclarationInformation,
  EscButton,
  FormInput,
  ModalLayout,
  PageInfo,
  SchoolGradeSelect,
  UseGenralDeclarationBtn,
} from 'shared'
import { UserDataForm } from 'shared/elements/UserDataForm'
import { COLORS } from 'styling/variables'
import { calculateSchoolYears, formatDateAndTime } from 'utils/helpers/common'
import { getSchoolGradesHook } from 'utils/helpers/hooks'
import {
  firstAndLastNameValidator,
  locationValidator,
  schoolNameValidator,
  telephoneValidation,
} from 'utils/helpers/validators'

function EditCompetitionModal({
  modalData: { participant_info, declaration_info, ...rest },
  closeModalCallback,
  onSubmitCallback,
}) {
  const { t } = useTranslation()
  const schoolGrades = getSchoolGradesHook()
  const reduxUser = useSelector(hub_selectUser)
  const currentTheme = useSelector(hub_selectTheme)
  const [isDataChanged, setIsDataChanged] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [declarationInfo, setDeclarationInfo] = useState(null)
  const [userData, setUserData] = useState({
    ...participant_info,
    competition_name: rest?.parent_name,
    owner_name: rest?.owner_name,
  })
  const {
    register,
    handleSubmit,
    control,
    getValues,
    trigger,
    setValue,
    formState,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      full_name: userData?.full_name || null,
      grade_id: userData?.grade_id || null,
      city: userData?.city || null,
      school: userData?.school || null,
      parent_names: userData?.parent_names || null,
      parent_phone: userData?.parent_phone || null,
    },
  })

  useEffect(() => {
    retrieveDeclaration()
  }, [])

  function submitHandler(data) {
    onSubmitCallback(rest.participation_id, data, isDataChanged)
  }

  function retrieveDeclaration() {
    getDeclaration(reduxUser.id, rest.child_id)
      .then((response) => {
        setIsDisabled(!!response.declaration?.isFinalized)
        setDeclarationInfo(response.declaration || null)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onFormInputChangeHandler = (e) => {
    let inputName = null

    if (e.target?.name) {
      inputName = e.target.name
    } else {
      inputName = e
    }

    setIsDataChanged(true)
    setUserData({ ...userData, ...{ [inputName]: getValues(inputName) } })
  }

  const useGenralDeclarationHandler = () => {
    getDeclarationCheckInfo(rest.child_id)
      .then(({ redirect, ...rest }) => {
        if (redirect) {
          window.open('/hub/settings', '_blank')
        } else {
          getDeclaration(reduxUser.id, rest.child_id)
            .then(({ declaration }) => {
              setDeclarationInfo({ ...declarationInfo, ...declaration })
            })
            .catch((error) => console.log(error))
        }
      })
      .catch((err) => console.log(err))
  }

  const saveSignatureHandler = (signature) => {
    if (signature)
      saveDeclaration(signature, '1', { child_id: rest.child_id })
        .then(() => {
          retrieveDeclaration()
        })
        .catch((error) => {
          console.log(error)
        })
    else
      deleteDeclaration({
        child_id: rest.child_id,
        type: declarationInfo?.type,
      })
        .then(() => {
          retrieveDeclaration()
        })
        .catch((error) => {
          console.log(error)
        })
  }

  return (
    <HubModalBackground>
      <ModalLayout>
        <EscButton onClick={closeModalCallback} />

        <PageInfo
          title={t('hub:Competitions:EditCompetitionTitle', {
            parent_name: rest?.parent_name,
            child_grade: rest?.grade_name || rest?.grade,
          })}
        />

        <Form
          onSubmit={handleSubmit(submitHandler)}
          onChange={onFormInputChangeHandler}
        >
          <UserDataForm>
            <FormInput
              customCss={{
                marginBottom: '0',
                gridColumn: '1 / 3',
                gridRow: '1',
              }}
              disabled={isDisabled}
              id="fullName"
              type="text"
              name="full_name"
              label={`${t('shared:LabelFirstAndLastName')} *`}
              register={register({
                required: 'Required',
                validate: {
                  firstAndLastNameValidator: (value) =>
                    firstAndLastNameValidator(value),
                },
              })}
              error={formState.errors.full_name}
              touched={formState.touched.full_name}
            />

            <SchoolGradeSelect
              id="schoolGrade"
              disabled={isDisabled}
              customCss={{ marginBottom: '0' }}
              label={`${t('shared:LabelSchoolClass')} *`}
              name="grade_id"
              setValue={setValue}
              getValues={getValues}
              onChange={onFormInputChangeHandler}
              description="SchoolClassDescription"
              control={control}
              rules={{ required: 'Required' }}
              isMulti={false}
              defaultValue={null}
              error={formState.errors.grade_id}
            />

            <FormInput
              customCss={{
                marginBottom: '0',
                gridColumn: '1/ 2',
                gridRow: '2',
              }}
              disabled={isDisabled}
              id="town"
              type="text"
              name="city"
              label="LabelTown"
              description={'TownDescription'}
              register={register({
                validate: {
                  locationValidator: (value) => locationValidator(value),
                },
              })}
              error={formState.errors.city}
              touched={formState.touched.city}
            />

            <FormInput
              customCss={{
                marginBottom: '0',
                gridColumn: '2/ 4',
                gridRow: '2',
              }}
              disabled={isDisabled}
              id="schoolName"
              type="text"
              name="school"
              label="LabelSchoolName"
              description={'SchoolNameDescription'}
              onBlur={(e) => trigger('school')}
              register={register({
                validate: {
                  schoolNameValidator: (value) => schoolNameValidator(value),
                },
              })}
              error={formState.errors.school}
              touched={formState.touched.school}
            />

            <div id="parent-info">
              <FormInput
                customCss={{
                  marginBottom: '0',
                }}
                disabled={isDisabled}
                id="parentName"
                type="text"
                name="parent_names"
                label="LabelFirstAndLastNameParent"
                register={register({
                  validate: {
                    firstAndLastNameValidator: (value) =>
                      firstAndLastNameValidator(value),
                  },
                })}
                error={formState.errors.parent_names}
                touched={formState.touched.parent_names}
              />

              <FormInput
                customCss={{
                  marginBottom: '0',
                }}
                disabled={isDisabled}
                id="parentPhone"
                type="tel"
                name="parent_phone"
                label="LabelTelephone"
                register={register({
                  validate: {
                    telephoneValidation: (value) => {
                      if (value) return telephoneValidation(value)
                    },
                  },
                })}
                error={formState.errors.parent_phone}
                touched={formState.touched.parent_phone}
              />
            </div>

            <div id="button-wrapper">
              <GenericButton
                disabled={!isDataChanged}
                customCss={{ margin: 0 }}
                theme={currentTheme}
              >
                {t('shared:Modals:ModalSaveButtonHub')}
              </GenericButton>

              <GenericButton
                type="button"
                className="inverted"
                customCss={{ margin: 0 }}
                theme={currentTheme}
                onClick={closeModalCallback}
              >
                {t('shared:Modals:ModalCloseButtonHub')}
              </GenericButton>
            </div>
          </UserDataForm>

          {+rest.payment_method ? (
            rest?.is_paid ? (
              <h1
                style={{
                  color: COLORS.secGreen,
                  textAlign: 'center',
                  fontSize: 18,
                }}
              >
                {t('shared:Modals:ModalPaid')}
              </h1>
            ) : (
              <h1
                style={{
                  color: COLORS.secRed,
                  textAlign: 'center',
                  fontSize: 18,
                }}
              >
                {t('shared:Modals:ModalExpectedPayment')}
              </h1>
            )
          ) : null}

          {declarationInfo?.isInvalid ? (
            <AnnuledDeclarationWarning
              customCss={{
                display: 'flex',
                maxWidth: 580,
                gap: 20,
                padding: 15,
                fontSize: 16,
                alignItems: 'center',
                backgroundColor: COLORS.secYellow,
                border: `1px solid ${COLORS.secYellow}`,
                borderRadius: 5,
                color: COLORS.secBlack,

                i: {
                  fontSize: 48,
                },
              }}
            >
              <i className="fas fa-exclamation-triangle"></i>
              {t('hub:Competitions:AnnuledDeclarationInfo')}
            </AnnuledDeclarationWarning>
          ) : declarationInfo?.isFinalized ? (
            <AnnuledDeclarationWarning
              customCss={{
                display: 'flex',
                maxWidth: 580,
                gap: 20,
                padding: 15,
                fontSize: 16,
                alignItems: 'center',
                backgroundColor: COLORS.secGreen,
                border: `1px solid ${COLORS.secGreen}`,
                borderRadius: 5,
                color: '#ffff',

                i: {
                  fontSize: 48,
                },
              }}
            >
              <i className="fas fa-check-circle"></i>
              {t('hub:Competitions:ValidDeclarationInfo')}
            </AnnuledDeclarationWarning>
          ) : null}

          {!declarationInfo?.signature && (
            <UseGenralDeclarationBtn onClick={useGenralDeclarationHandler}>
              <i className="fas fa-signature"></i>
              <span>{t('shared:UseGenralDeclaration')}</span>
            </UseGenralDeclarationBtn>
          )}

          <DeclarationInformation>
            <div className="declaration">
              {HTMLReactParser(
                declarationInfo?.declaration?.type === '0'
                  ? t(`${'shared:PersonalDeclarationTypeOne'}`, {
                      competition_owner: userData?.owner_name,
                      competition_name: userData?.competition_name,
                      parent_names: userData?.parent_names,
                      child_names: userData?.full_name,
                      grade: schoolGrades?.find(
                        (grade) => grade.id === userData?.grade_id,
                      )?.name,
                      school: userData?.school,
                      city: userData?.city,
                      date_time: formatDateAndTime(Date.now()),
                      school_year_start: calculateSchoolYears().startSchoolYear,
                      school_year_end: calculateSchoolYears().endSchoolYear,
                    })
                  : t(`${'shared:PersonalDeclarationTypeTwo'}`, {
                      competition_owner: userData?.owner_name,
                      competition_name: userData?.competition_name,
                      parent_names: userData?.parent_names,
                      child_names: userData?.full_name,
                      grade: schoolGrades?.find(
                        (grade) => grade.id === userData?.grade_id,
                      )?.name,
                      school: userData?.school,
                      city: userData?.city,
                      date_time: formatDateAndTime(Date.now()),
                      school_year_start: calculateSchoolYears().startSchoolYear,
                      school_year_end: calculateSchoolYears().endSchoolYear,
                    }),
              )}
            </div>
          </DeclarationInformation>
          <DeclarationCanvas
            isFinalized={!!declarationInfo?.isFinalized}
            signatureURL={declarationInfo?.signature || null}
            onSaveCallback={saveSignatureHandler}
            onCloseCallback={closeModalCallback}
            onClearCallback={saveSignatureHandler}
          />
        </Form>
      </ModalLayout>
    </HubModalBackground>
  )
}

export { EditCompetitionModal }

const Form = styled('form')(({ customCss }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const AnnuledDeclarationWarning = styled('div')(({ customCss }) => ({
  ...customCss,
}))
