import { useForm } from 'react-hook-form'
import {
  emailValidation,
  handleSetFormErrors,
  whiteSpaceValidator,
} from '../../../../utils/helpers/validators'
import { unwrapResult } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import { lab_loginUser } from '../../store/userSlice'
import { useHistory } from 'react-router'
import { PageInfo, FormInput } from '../../../../shared/index'
import { SubmitButtonLab } from '../../shared/index'

function LoginForm({ changeViewHandler }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const routeHistory = useHistory()
  const { register, handleSubmit, setError, trigger, formState } = useForm({
    mode: 'onSubmit',
  })

  const onSubmitLoginHandler = (data) => {
    dispatch(lab_loginUser({ email: data.email, password: data.password }))
      .then(unwrapResult)
      .then(() => {
        routeHistory.replace('/lab/dashboard')
      })
      .catch((error) => {
        handleSetFormErrors(error.statCode, setError)
      })
  }

  return (
    <LoginFormWrapper onSubmit={handleSubmit(onSubmitLoginHandler)}>
      <PageInfo
        title={t('lab:Login')}
        subtitle={t('lab:LoginDescription')}
        customCss={{
          '& h1': {
            marginBottom: 10,
          },
          '& h2': {
            marginBottom: 60,
          },
          gridColumn: '1/ 3',
          textAlign: 'center',
        }}
      />

      <FormInput
        customCss={{ gridColumn: '1/ 3' }}
        id="emailLoginLab"
        type="text"
        name="email"
        label="lab:LabelEmail"
        onChange={(e) => whiteSpaceValidator(e)}
        register={register({
          required: 'Required',
          validate: {
            emailValidation: (value) => emailValidation(value),
          },
        })}
        error={formState.errors.email}
        touched={formState.touched.email}
      ></FormInput>

      <FormInput
        customCss={{ gridColumn: '1/ 3' }}
        id="passwordLoginLab"
        type="password"
        name="password"
        label="lab:LabelPassword"
        showPassword={true}
        onChange={(e) => {
          return {
            trim: whiteSpaceValidator(e),
          }
        }}
        register={register({
          required: 'Required',
        })}
        error={formState.errors.password}
        touched={formState.touched.password}
      ></FormInput>
      <SubmitButtonLab
        type="submit"
        className="black"
        customCss={{ gridColumn: '1/ 2' }}
      >
        {t('shared:Login')}
      </SubmitButtonLab>
      <SubmitButtonLab
        className="white"
        customCss={{
          gridColumn: '2/ 3',
        }}
        onClick={changeViewHandler}
      >
        {t('shared:ForgottenPassword')}
      </SubmitButtonLab>
    </LoginFormWrapper>
  )
}

export { LoginForm }

const LoginFormWrapper = styled('form')(({ customCss }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  placeItems: 'center',
  gridColumnGap: '20px',

  ...customCss,
}))
