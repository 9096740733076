import styled from '@emotion/styled'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ConfirmationMessage,
  FormInput,
  PageInfo,
  SchoolGradeSelect,
  SubmitButtonHub,
  UserDataForm,
} from 'shared'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { hub_selectTheme } from 'platforms/kyoso-hub/store/generalSlice'
import {
  firstAndLastNameValidator,
  locationValidator,
  schoolNameValidator,
  telephoneValidation,
  whiteSpaceValidator,
} from 'utils/helpers/validators'
import { COLORS, QUERIES } from 'styling/variables'

export function AdditionalInfoForm({ userData, onSubmitHandler }) {
  const currentTheme = useSelector(hub_selectTheme)
  const [onSuccess, setOnSuccess] = useState(null)

  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    control,
    getValues,
    trigger,
    setValue,
    reset,
    setError,
    formState,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      fullName: userData?.full_name,
      schoolGrade: userData?.grade_id,
      town: userData?.city,
      schoolName: userData?.school,
      parentName: userData?.parent_names,
      parentPhone: userData?.parent_phone,
    },
  })

  const submitHandler = (data) => {
    onSubmitHandler(data, 'changeAdditionalInfo', setError, setOnSuccess, reset)
  }

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <div>
        <PageInfo
          title={t('hub:Settings:AdditionalInfoTitle')}
          subtitle={t('hub:Settings:AdditionalInfoSubTitle')}
          customCss={{
            '& h1': {
              marginBottom: 10,
              marginTop: 0,
            },
            '& h2': {
              marginBottom: 30,
            },
          }}
        />

        {onSuccess === 'changeAdditionalInfo' && (
          <ConfirmationMessage
            color={COLORS.secBlue}
            message={t('shared:ChangedSuccessfuly')}
            removeMessageCallback={() => setOnSuccess(false)}
          />
        )}

        <UserDataForm>
          <FormInput
            customCss={{
              marginBottom: '0',
              gridColumn: '1 / 3',
              gridRow: '1',
            }}
            id="fullName"
            type="text"
            name="fullName"
            label="LabelFirstAndLastName"
            register={register({
              validate: {
                firstAndLastNameValidator: (value) =>
                  firstAndLastNameValidator(value),
              },
            })}
            error={formState.errors.fullName}
            touched={formState.touched.fullName}
          ></FormInput>

          <SchoolGradeSelect
            id="schoolGrade"
            customCss={{ marginBottom: '0' }}
            label="lab:Kyoso:FilterSchoolGrade"
            name="schoolGrade"
            setValue={setValue}
            getValues={getValues}
            description="SchoolClassDescription"
            control={control}
            isMulti={false}
            defaultValue={null}
            translationKeys={{
              lastYear: `${new Date().getFullYear() - 1}`,
              currentYear: `${new Date().getFullYear()}`,
            }}
          ></SchoolGradeSelect>

          <FormInput
            customCss={{
              marginBottom: '0',
              gridColumn: '1/ 2',
              gridRow: '2',
            }}
            id="town"
            type="text"
            name="town"
            label="LabelTown"
            description={'TownDescription'}
            register={register({
              validate: {
                locationValidator: (value) => locationValidator(value),
              },
            })}
            error={formState.errors.town}
            touched={formState.touched.town}
          ></FormInput>

          <FormInput
            customCss={{
              marginBottom: '0',
              gridColumn: '2/ 4',
              gridRow: '2',
            }}
            id="schoolName"
            type="text"
            name="schoolName"
            label="LabelSchoolName"
            description={'SchoolNameDescription'}
            onBlur={(e) => trigger('schoolName')}
            register={register({
              validate: {
                schoolNameValidator: (value) => schoolNameValidator(value),
              },
            })}
            error={formState.errors.schoolName}
            touched={formState.touched.schoolName}
          ></FormInput>

          <div id="parent-info">
            <FormInput
              customCss={{
                marginBottom: '0',
              }}
              id="parentName"
              type="text"
              name="parentName"
              label="LabelFirstAndLastNameParent"
              register={register({
                validate: {
                  firstAndLastNameValidator: (value) =>
                    firstAndLastNameValidator(value),
                },
              })}
              error={formState.errors.parentName}
              touched={formState.touched.parentName}
            />

            <FormInput
              customCss={{
                marginBottom: '0',
              }}
              id="parentPhone"
              type="tel"
              name="parentPhone"
              label="LabelTelephone"
              register={register({
                validate: {
                  telephoneValidation: (value) => {
                    if (value) return telephoneValidation(value)
                  },
                },
              })}
              error={formState.errors.parentPhone}
              touched={formState.touched.parentPhone}
            />
          </div>
        </UserDataForm>
      </div>

      <SubmitButtonHub
        id="additionalInfo"
        customCss={{
          widht: 90,
          height: 90,
        }}
        theme={currentTheme}
        type="submit"
      />
    </Form>
  )
}

const Form = styled('form')(({ customCss }) => ({
  display: 'grid',
  gridTemplateColumns: '5fr 1fr',
  placeItems: 'center',

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    display: 'flex',
    flexDirection: 'column',
  },
}))
