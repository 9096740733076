import React from 'react'
import { useForm } from 'react-hook-form'
import { FormInput } from 'shared'
import { COLORS, QUERIES } from 'styling/variables'
import styled from '@emotion/styled'
import { SubmitButtonLab } from 'platforms/kyoso-lab/shared'
import { useTranslation } from 'react-i18next'
import { SchoolGradeSelect } from 'shared'
import { SchoolSubjectSelect } from 'shared'
import { yearValidator } from 'utils/helpers/validators'

export function FiltrationBundlesForm({ submitCallback }) {
  const {
    reset,
    register,
    control,
    getValues,
    handleSubmit,
    trigger,
    setValue,
    setError,
    clearErrors,
    formState,
  } = useForm({
    mode: 'onSubmit',
  })
  const { t } = useTranslation()

  const onSubmitFilter = (data) => {
    submitCallback({
      schoolGrade: data.schoolGrade,
      schoolSubject: data.schoolSubject,
      year: data.year || null,
    })
  }

  const resetDataHandler = () => {
    const defaultValues = {
      schoolGrade: null,
      schoolSubject: null,
    }
    reset(defaultValues)
    submitCallback(null)
  }

  return (
    <FormLayout noValidate onSubmit={handleSubmit(onSubmitFilter)}>
      <FormInput
        customCss={{ marginBottom: '0' }}
        id="year"
        label="lab:Kyoso:FilterYear"
        type="number"
        name="year"
        onChange={() => trigger('year')}
        register={register({
          validate: {
            yearValidation: (value) => {
              if (value) {
                return yearValidator(value)
              }
            },
          },
        })}
        error={formState.errors.year}
      />

      <SchoolSubjectSelect
        customCss={{ marginBottom: '0' }}
        id="schoolSubject"
        label="lab:Kyoso:FilterSchoolSubject"
        name="schoolSubject"
        setValue={setValue}
        getValues={getValues}
        onChange={() => trigger('schoolSubject')}
        control={control}
        defaultValue={null}
        setError={setError}
        clearErrors={clearErrors}
        isClearable={true}
      />

      <SchoolGradeSelect
        customCss={{ marginBottom: '0' }}
        id="schoolGrade"
        label="lab:Kyoso:FilterSchoolGrade"
        name="schoolGrade"
        onChange={() => trigger('schoolGrade')}
        setValue={setValue}
        isClearable={true}
        getValues={getValues}
        control={control}
        defaultValue={null}
      />

      <SubmitButtonLab
        type="reset"
        className="white clear"
        onClick={(e) => resetDataHandler(e)}
        customCss={{}}
      >
        <i className="fas fa-times"></i>
      </SubmitButtonLab>

      <SubmitButtonLab
        className="black"
        type="submit"
        customCss={{ alignSelf: 'end' }}
      >
        {t('lab:Kyoso:SubmitFilterButton')}
      </SubmitButtonLab>
    </FormLayout>
  )
}

const FormLayout = styled('form')({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 2fr 1fr 50px 1fr',
  alignItems: 'end',
  gridColumnGap: '30px',
  marginBottom: '20px',

  [`@media (max-width: ${QUERIES.tablet}px)`]: {
    gridColumnGap: '15px',
  },

  '& .button': {
    cursor: 'pointer',
    gridColumn: '5',
    backgroundColor: '#fff',
    border: 'none',
    alignSelf: 'center',
    marginBottom: '5px',

    '& i': {
      fontSize: '30px',
      color: COLORS.mainGray,
    },
  },
})
