import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { EscButton, TinyTextContentPreview, AnswersLayout } from 'shared'
import { COLORS, QUERIES } from 'styling/variables'
import { SubmitButtonLab } from '../shared'
import { getChildPreview } from '../services/kyoso/children'
import { answer_types } from 'styling/answer-types'
import { Link, useLocation } from 'react-router-dom'
import manual_evaluation from 'assets/img/manual_evaluation.png'
import {
  lab_hideGlobalLoading,
  lab_showGlobalLoading,
} from '../store/generalSlice'
import { useDispatch } from 'react-redux'
import { getFileLinkFromServerStorage } from 'utils/helpers/common'
import moment from 'moment'

function KyosoChildPreviewPage() {
  const params = useParams()
  const routeHistory = useHistory()
  const routeLocation = useLocation()
  const [childInfo, setChildInfo] = useState({})
  const [infoText, setInfoText] = useState('#closed')
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(lab_showGlobalLoading())
    getChildPreview(params.childId)
      .then((response) => {
        const sortedChild = response.child
        sortedChild.exercises = sortedChild.exercises.sort(
          (a, b) => a.id - b.id,
        )
        setChildInfo(sortedChild)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => dispatch(lab_hideGlobalLoading()))
  }, [])

  function isParentInProgress() {
    const intervals = childInfo.parent_intervals
      ? JSON.parse(childInfo.parent_intervals)
      : null
    if (intervals) {
      let isInProgressFlag = false
      intervals.forEach((int) => {
        const now = moment.utc()
        if (now >= moment(+int.startDate) && now <= moment(+int.endDate)) {
          isInProgressFlag = true
          return
        }
      })
      return isInProgressFlag
    } else return false
  }

  return (
    <KyosoChildPreviewLayout>
      {infoText !== '#closed' && (
        <InfoModalBackground>
          <InfoModalWrap>
            <InfoModalLayout>
              <EscButton onClick={() => setInfoText('#closed')} />
              <div className="content">
                <TinyTextContentPreview html={infoText} />
              </div>
            </InfoModalLayout>
          </InfoModalWrap>
        </InfoModalBackground>
      )}
      <EscButton onClick={() => routeHistory.push('/lab/dashboard/kyoso')} />

      <GeneralInfo>
        <div className="data">
          <div className="title">
            <VisibilityIcon
              status={childInfo.parent_visibility}
              isinprogress={isParentInProgress()}
            />
            <span>
              {routeLocation.state?.uid} - {childInfo.parent_name} -{' '}
              {childInfo.grade_name}
            </span>
          </div>
          <div className="subtitle">
            {childInfo.parent_subject_name} - {childInfo.parent_year}г. -{' '}
            {childInfo.parent_type_name}
          </div>
          <div className="duration">
            <i className="fas fa-stopwatch"></i>
            <span>
              {childInfo.duration || '-'}{' '}
              {t('shared:MinutesLabel').toLowerCase()}
            </span>
          </div>
          {childInfo.has_modules ? (
            <div className="modules">
              <div className="title">{t('lab:KyosoChild:ModulesLabel')}</div>
              {childInfo.modules.map((module, index) => (
                <div className="module" key={index}>
                  <i className="fas fa-hourglass-half"></i>
                  <span>
                    {module.duration}{' '}
                    {t('shared:MinutesShortLabel').toLowerCase()} ({module.name}
                    )
                  </span>
                </div>
              ))}
            </div>
          ) : null}
          {childInfo.paid !== null ? (
            <div className="price">
              <i className="far fa-money-bill-alt"></i>
              <span data-paidstatus={childInfo.paid}>
                {childInfo.paid === 1
                  ? t('shared:Currency', {
                      price: childInfo.price,
                    })
                  : t('shared:Unpaid')}
              </span>
            </div>
          ) : null}
        </div>
        <div className="actions">
          <SubmitButtonLab
            className="black"
            type="button"
            customCss={{ width: 150 }}
            onClick={() =>
              routeHistory.push(`/lab/dashboard/kyoso/child/${params.childId}`)
            }
          >
            <i className="fas fa-pen"></i>
            <span>{t('lab:Kyoso:SettingsWidgetEdit')}</span>
          </SubmitButtonLab>
          <SubmitButtonLab
            className="red"
            type="button"
            customCss={{ width: 150 }}
          >
            <i className="fas fa-trash"></i>
            <span>{t('lab:Kyoso:SettingsWidgetDelete')}</span>
          </SubmitButtonLab>
        </div>
      </GeneralInfo>

      <Bundles>
        {childInfo.bundles?.length ? (
          childInfo.bundles.map((bundle, index) => (
            <div className="bundle" key={index}>
              {t('lab:KyosoChildPreview:BundlePreLabel')}{' '}
              <Link to={`/lab/dashboard/kyoso/bundles/update/${bundle.id}`}>
                {bundle.name}
              </Link>
            </div>
          ))
        ) : (
          <div className="no-bundles">
            {t('lab:KyosoChildPreview:NoBundlesLabel')}
          </div>
        )}
      </Bundles>

      <LinksPart>
        <div className="links">
          <div onClick={() => setInfoText(childInfo.instructions)}>
            {t('lab:KyosoChild:InstructionsLabel')}
          </div>
          <div onClick={() => setInfoText(childInfo.submit_message)}>
            {t('lab:KyosoChild:SubmitMessageLabel')}
          </div>
          <div onClick={() => setInfoText(childInfo.rules_text)}>
            {t('lab:KyosoChild:RulesTextLabel')}
          </div>
          <div
            onClick={() =>
              childInfo.rules_link &&
              window.open(
                getFileLinkFromServerStorage(childInfo.rules_link),
                '_blank',
              )
            }
          >
            {t('lab:KyosoChild:RulesLinkLabel')}
          </div>
        </div>
        <div className="tiles">
          <div
            className="tile"
            onClick={() =>
              routeHistory.push('/lab/dashboard/kyoso/add-participants', {
                child_id: params.childId,
                parent_name: childInfo.parent_name,
                child_grade: { name: childInfo.grade_name },
                has_modules: childInfo.has_modules,
              })
            }
          >
            <i className="fas fa-envelope-open-text"></i>
            <div>{t('lab:KyosoChildPreview:InvitedChildrenTile')}</div>
          </div>
          <div
            className="tile"
            onClick={() =>
              routeHistory.push(
                `/lab/dashboard/kyoso/${params.childId}/orders`,
                {
                  id: params.childId,
                  name: childInfo.parent_name,
                  grade_name: childInfo.grade_name,
                  has_modules: childInfo.has_modules,
                },
              )
            }
          >
            <i className="fas fa-users"></i>
            <div>{t('lab:KyosoChildPreview:ChildrenOrdersTile')}</div>
          </div>
          <div
            className="tile"
            onClick={(e) =>
              routeHistory.push(
                `/lab/dashboard/kyoso/child/${params.childId}/tracking`,
                {
                  parent_name: childInfo.parent_name,
                  child_grade: { name: childInfo.grade_name },
                },
              )
            }
          >
            <i className="far fa-id-badge"></i>
            <div>{t('lab:KyosoChildPreview:RealTimeTile')}</div>
          </div>
          <div
            className="tile"
            onClick={(e) => {
              routeHistory.push(
                `/lab/dashboard/kyoso/child/${params.childId}/evaluation`,
              )
            }}
          >
            <img src={manual_evaluation}></img>
            <div>{t('lab:KyosoChildPreview:ManualEvaluation')}</div>
          </div>
          <div
            className="tile"
            onClick={() => {
              routeHistory.push(
                `/lab/dashboard/kyoso/child/${params.childId}/ranking`,
              )
            }}
          >
            <i className="far fa-list-alt"></i>
            <div>{t('lab:KyosoChildPreview:ResultsTile')}</div>
          </div>
        </div>
      </LinksPart>

      <div className="exercises-title">
        {t('lab:Kyoso:SettingsWidgetExercises')}:
      </div>

      {childInfo.exercises?.length ? (
        childInfo.exercises.map((exercise, index) => {
          return (
            <div className="exercises-wrapper" key={exercise.id}>
              <div className="exercise-index">{index + 1}.</div>
              <ExerciseBox>
                <div className="info-line">
                  <div className="description">
                    <TinyTextContentPreview
                      id={`tiny-content-${exercise.id}}`}
                      html={exercise.description}
                      // onLoadMathJaxHandler={onLoadMathJaxHandler}
                      imgZoom
                    />
                  </div>
                  <div
                    className={`module-name ${
                      childInfo.has_modules
                        ? exercise.module_name
                          ? ''
                          : 'no-module'
                        : ''
                    }`}
                  >
                    {childInfo.has_modules
                      ? exercise.module_name ||
                        t('lab:KyosoExercises:NoModules')
                      : ''}
                  </div>
                </div>
                <div className="answers">
                  <img
                    id="answer-type"
                    src={answer_types[exercise.answer_type]}
                  />
                  <div className="answers-list">
                    {exercise.answer_type === '2' ? (
                      exercise.answers.map((ans, index) => {
                        const answersModyfied = JSON.parse(ans.answer)
                          .map((a) => a.answer)
                          .join(' / ')

                        const points = JSON.parse(ans.answer)
                          .map((a) => a.points)
                          .join(' / ')

                        return (
                          <div key={index} className="answer correct">
                            <div style={{ display: 'flex' }}>
                              <span>{t('lab:KyosoExercises:Answer')}</span>
                              <span style={{ marginLeft: '5px' }}>
                                <TinyTextContentPreview
                                  html={answersModyfied}
                                />
                              </span>
                            </div>
                            <span>
                              {t('lab:Shared:Points', { points: points })}
                            </span>
                          </div>
                        )
                      })
                    ) : exercise.answer_type === '3' ? (
                      <AnswersLayout>
                        <span
                          style={{ color: COLORS.mainGray, marginBottom: 20 }}
                        >
                          {t('lab:KyosoExercises:AnswerType4Answer')}
                        </span>
                        {exercise.answers.map((ans, index) => {
                          return (
                            <div key={index}>
                              <div className="answers">
                                <span>
                                  {t('lab:KyosoExercises:DescriptionAns')}:{' '}
                                </span>
                                <span>
                                  {ans.description ? ans.description : ''}
                                </span>
                              </div>
                            </div>
                          )
                        })}
                      </AnswersLayout>
                    ) : (
                      exercise.answers.map((answer, index) => {
                        if (answer.is_other) {
                          const answersModyfied = JSON.parse(
                            answer.other_answers,
                          )
                            .reduce((prev, cur) => {
                              let ans = []
                              cur.answers.forEach((a) => {
                                ans.push(a.answer)
                              })

                              return [...prev, ...ans]
                            }, [])
                            .join(' / ')

                          const points = JSON.parse(answer.other_answers)
                            .reduce((prev, cur) => {
                              let ans = []
                              cur.answers.forEach((a) => {
                                ans.push(a.points)
                              })

                              return [...prev, ...ans]
                            }, [])
                            .join(' / ')

                          return (
                            <div
                              key={index}
                              className={`answer ${
                                answer.is_correct && 'correct'
                              }`}
                            >
                              <div style={{ display: 'flex' }}>
                                <span>
                                  <TinyTextContentPreview
                                    html={answer.answer}
                                  />
                                </span>
                                <span>
                                  <TinyTextContentPreview
                                    html={answersModyfied}
                                  />
                                </span>
                              </div>
                              <span>
                                {t('lab:Shared:Points', { points: points })}
                              </span>
                            </div>
                          )
                        } else {
                          return (
                            <div
                              key={index}
                              className={`answer ${
                                answer.is_correct && 'correct'
                              }`}
                            >
                              <span>
                                <TinyTextContentPreview html={answer.answer} />
                              </span>
                              <span>
                                {t('lab:Shared:Points', {
                                  points: answer.points,
                                })}
                              </span>
                            </div>
                          )
                        }
                      })
                    )}
                  </div>
                </div>
              </ExerciseBox>
            </div>
          )
        })
      ) : (
        <div className="no-exercises">
          {t('lab:KyosoChildPreview:NoExercisesLabel')}
        </div>
      )}

      <FooterButtons>
        <SubmitButtonLab
          className="black"
          type="button"
          customCss={{ width: 250 }}
          onClick={() => {}}
        >
          <i className="fas fa-eye"></i>
          <span>{t('lab:KyosoChildPreview:PreviewLikeHubUserButton')}</span>
        </SubmitButtonLab>
        <SubmitButtonLab
          className="black"
          type="button"
          customCss={{ width: 250 }}
          onClick={() =>
            routeHistory.push('/lab/dashboard/kyoso/exercises', {
              child_id: params.childId,
              parent_name: childInfo.parent_name,
              child_grade: { name: childInfo.grade_name },
              has_modules: childInfo.has_modules,
            })
          }
        >
          <i className="fas fa-pen"></i>
          <span>{t('lab:KyosoChildPreview:EditExercisesButton')}</span>
        </SubmitButtonLab>
      </FooterButtons>
    </KyosoChildPreviewLayout>
  )
}

export { KyosoChildPreviewPage }

const InfoModalBackground = styled('div')({
  position: 'fixed',
  display: 'table',
  top: 65,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(218,222,230,0.7) 0% 0% no-repeat padding-box',
  zIndex: 1000,

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    position: 'fixed',
    top: 50,
    height: 'calc(100% - 100px)',
  },
})

const InfoModalWrap = styled('div')({
  display: 'table-cell',
  verticalAlign: 'middle',
})

const InfoModalLayout = styled('div')({
  position: 'relative',
  top: -33,
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '95%',
  maxWidth: 710,
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 0px 5px #0000000D',
  borderRadius: 25,
  padding: '30px 60px',
  fontFamily: 'Balsamiq Sans',

  [`@media (max-width: ${QUERIES.mobile}px)`]: {
    padding: '30px 20px',
  },

  '& > .content': {
    color: COLORS.secBlack,
    fontSize: 18,
    textAlign: 'center',
  },
})

const KyosoChildPreviewLayout = styled('div')({
  '& .exercises-title': {
    marginBottom: 15,
    color: COLORS.secBlack,
    fontSize: 20,
  },

  '& .no-exercises': {
    color: COLORS.secBlack,
    fontSize: 20,
    textAlign: 'center',
  },

  '& .exercises-wrapper': {
    display: 'flex',

    '& .exercise-index': {
      width: 20,
      color: COLORS.mainGray,
      fontSize: 16,
      paddingTop: 21,
    },
  },
})

const VisibilityIcon = styled('div')((props) => {
  let color = ''
  if (props.status === '2') color = COLORS.secBlack
  else if (props.isinprogress) color = COLORS.secGreen
  else if (props.status === '0') color = COLORS.secRed
  else if (props.status === '1') color = COLORS.secYellow

  return {
    display: 'inline-block',
    verticalAlign: 'baseline',
    width: 20,
    height: 20,
    borderRadius: '50%',
    marginRight: 10,
    backgroundColor: color,
  }
})

const GeneralInfo = styled('div')({
  display: 'flex',
  marginBottom: 20,

  '& .data': {
    display: 'flex',
    flexDirection: 'column',

    '& .title': {
      color: COLORS.secBlack,
      fontSize: 26,
    },

    '& .subtitle': {
      color: COLORS.secBlack,
      fontSize: 20,
      marginBottom: 15,
    },

    '& .duration': {
      color: COLORS.secBlue,
      fontSize: 16,
      marginBottom: 10,

      i: {
        marginRight: 5,
      },
    },

    '& .modules': {
      '& .title': {
        color: COLORS.secBlack,
        fontSize: 16,
        marginBottom: 5,
      },

      '& .module': {
        color: COLORS.mainGray,
        fontSize: 16,
        marginBottom: 5,

        i: {
          marginRight: 5,
        },
      },
    },

    '& .price': {
      marginTop: 10,
      color: COLORS.mainGray,
      fontSize: 16,

      i: {
        marginRight: 5,
      },

      '& span[data-paidstatus="0"]': {
        color: COLORS.secGreen,
      },
    },
  },

  '& .actions': {
    display: 'flex',
    alignSelf: 'center',
    gap: 30,
    marginLeft: 'auto',

    i: {
      marginRight: 10,
    },
  },
})

const LinksPart = styled('div')({
  marginBottom: 50,
  display: 'flex',
  alignItems: 'center',

  '& .links': {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,

    '& div': {
      color: COLORS.secBlue,
      fontSize: 18,
      cursor: 'pointer',
    },
    '& div:hover': {
      textDecoration: 'underline',
    },
  },

  '& .tiles': {
    display: 'flex',
    gap: 10,
    marginLeft: 'auto',

    '& .tile': {
      width: 180,
      height: 150,
      border: `2px solid ${COLORS.mainGray}`,
      borderRadius: 15,
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: 20,
      color: COLORS.mainGray,
      fontSize: 14,
      cursor: 'pointer',

      '&.disabled': {
        opacity: 0.5,
        cursor: 'initial',
      },

      i: {
        fontSize: 55,
        color: COLORS.secBlack,
      },
    },
  },
})

const Bundles = styled('div')({
  marginBottom: 30,
  fontSize: 16,

  '& .bundle, & .bundle a': {
    color: COLORS.secBlue,
  },

  '& .no-bundles': {
    color: COLORS.secRed,
  },
})

const ExerciseBox = styled('div')({
  border: `1px solid ${COLORS.borderGray}`,
  borderRadius: 15,
  padding: 20,
  marginBottom: 20,
  width: '100%',

  '& .info-line': {
    display: 'flex',
    gap: 200,
    marginBottom: 20,

    '& .description': {
      width: '50%',
      fontSize: 16,
      color: COLORS.secBlack,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      img: {
        height: 'auto',
      },
    },

    '& .module-name': {
      color: COLORS.mainGray,
      fontSize: 16,

      '&.no-module': {
        color: COLORS.secRed,
      },
    },
  },

  '& .answers': {
    display: 'flex',
    alignItems: 'center',
    gap: 30,
    width: '50%',
    color: COLORS.mainGray,

    '#answer-type': {
      display: 'block',
      width: 33,
      height: 33,
    },

    '& .answers-list': {
      width: '100%',

      '& .answer': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '10px',

        '&:last-of-type': {
          marginBottom: 0,
        },

        '&.correct': {
          color: COLORS.secGreen,
        },
      },
    },
  },
})

const FooterButtons = styled('div')({
  display: 'flex',
  gap: 30,
  justifyContent: 'flex-end',
  marginTop: 40,

  i: {
    marginRight: 10,
  },
})
