import { getChildModules } from 'platforms/kyoso-lab/services/kyoso/children'
import { getExercise } from 'platforms/kyoso-lab/services/kyoso/exercises'
import {
  lab_hideGlobalLoading,
  lab_showGlobalLoading,
} from 'platforms/kyoso-lab/store/generalSlice'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CreateUpdateExerciseModal } from './CreateUpdateExerciseModal'

export function PreloadingExercise({
  isEditing,
  childId,
  exerciseId,
  exerciseHasModules,
  exerciseIndex,
  submitCallback,
  closeModalCallback,
}) {
  const [exerciseData, setExerciseData] = useState(null)
  const [modulesOptions, setModulesOptions] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(lab_showGlobalLoading())
    if (exerciseId) {
      Promise.allSettled([getExercise(exerciseId), getChildModules(childId)])
        .then((response) => {
          if (response[1].status === 'fulfilled') {
            const optionSelect = response[1].value.modules.map((module) => ({
              value: module.id,
              label: module.name,
            }))
            setModulesOptions(optionSelect)
          }
          if (response[0].status === 'fulfilled') {
            setExerciseData(response[0].value.exercise)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => dispatch(lab_hideGlobalLoading()))
    } else {
      getChildModules(childId)
        .then((response) => {
          const optionSelect = response.modules.map((module) => ({
            value: module.id,
            label: module.name,
          }))
          setModulesOptions(optionSelect)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => dispatch(lab_hideGlobalLoading()))
    }
  }, [])

  const modal = (
    <CreateUpdateExerciseModal
      exerciseData={exerciseData}
      modulesOptions={modulesOptions}
      exerciseId={exerciseId}
      exerciseHasModules={exerciseHasModules}
      exerciseIndex={exerciseIndex}
      submitCallback={submitCallback}
      closeModalCallback={closeModalCallback}
    />
  )

  if (
    (!isEditing && modulesOptions) ||
    (isEditing && exerciseData && modulesOptions)
  )
    return modal
  else return null
}
